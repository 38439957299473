<template>
    <DefaultLayout>
    <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
</DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import {useRoute} from 'vue-router'
import { onMounted, ref } from 'vue'
import ProfileService from '@/services/ProfileService'
import router from '@/router'

const route = useRoute()
const id = route.params.id
const service = new ProfileService()
const user = ref()
const nickname = ref('')

onMounted(async () => {
    user.value = await service.UserInfo(id)
    if(user.value.nickname) {
        nickname.value = user.value.nickname
    } else {
        nickname.value = user.value.name.substring(0,3) + user.value.id
    }
    router.push({name: 'social-profile-nickname', params: { nickname: nickname.value}})
})


</script>