export default class PayPalService{
    private base_url = 'https://gogodev.net/gogolearn/api/lms_paypal'

    public async storeSub(token:string, subscriptionId:string|string[]) {
        const uri = `${this.base_url}/store/${subscriptionId}`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async checkSub( token:string ) {

        let uri = `https://gogodev.net/gogolearn/api/my-user`
        let rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`
            }
        })
        let response = await (rawResponse).json()

        const clientId = process.env.CLI
        const secretId = process.env.SEC
        const credentials = `${clientId}:${secretId}`;
        const encodedCredentials = btoa(credentials);
        
         uri = `https://api-m.paypal.com/v1/billing/subscriptions/${response.paypal_id}`;
         rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
            }
        });
        
        response = await rawResponse.json();
        return response;
    }


    public async checkSubFromId( id:string ) {

        let uri = `https://gogodev.net/gogolearn/api/user/${id}`
        let rawResponse = await fetch(uri, {
            method: 'GET',
        })
        let response = await (rawResponse).json()

        const clientId = process.env.CLI
        const secretId = process.env.SEC
        const credentials = `${clientId}:${secretId}`;
        const encodedCredentials = btoa(credentials);
        
         uri = `https://api-m.paypal.com/v1/billing/subscriptions/${response.paypal_id}`;
         rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
            }
        });
        
        response = await rawResponse.json();
        return response;
    }


    public async cancelSub( token:string, reason:string ) {

        let uri = `https://gogodev.net/gogolearn/api/my-user`
        let rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`
            }
        })
        let response = await (rawResponse).json()

        const clientId = process.env.CLI
        const secretId = process.env.SEC
        const credentials = `${clientId}:${secretId}`;
        const encodedCredentials = btoa(credentials);
        
         uri = `https://api-m.paypal.com/v1/billing/subscriptions/${response.paypal_id}/cancel`;
         rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'reason': reason})
        });
        
        response = await rawResponse.json();
        return response;
    }
}