<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else class="animate">
            <h1>Mis Preguntas</h1>
            <div class="spacer" />
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar." @keyup="handleSearch"
                    class="input-job" />
            </form>
            <div class="spacer" />
            <div class="table-container">
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Resuelta</th>
                            <th>Pregunta</th>
                            <th v-if="windowWidth>tableSmall">Fecha</th>
                            <th>Seleccionar</th>
                        </tr>
                    </thead>
                    <tbody>
                       <!-- <tr><td colspan="4"><AdvertisementMain AdType="table"/></td></tr> --> 
                        <tr v-for="item in filtered" :key="item.id">
                            <td>
                                <v-icon v-if="item.is_solved == '0'" name="ri-forbid-fill" fill="#ccc" scale="2"></v-icon>
                                <v-icon v-if="item.is_solved == '1'" name="bi-check-circle-fill" fill="#bece49" scale="2"></v-icon>
                            </td>
                            <td> {{ item.name }} </td> 
                            <td v-if="windowWidth>tableSmall">{{ new Date(item.updated_at).getDate() }}/{{ new Date(item.updated_at).getMonth()+1 }}/{{ new Date(item.updated_at).getFullYear() }}</td>    
                            <td v-if="item.course_id">
                                <RouterLink :to="{ name: 'detail-question-lessons-course', params: { course_id: item.course_id, lesson_id: item.lesson_id, question_id: item.id } }"><v-icon
                                        name="md-fileopen-round" class="ver" fill="#57afc4" scale="2"></v-icon></RouterLink>
                            </td>
                            <td v-else>
                                <RouterLink :to="{ name: 'detail-question-general', params: { topic_id: item.topic_id, question_id: item.id, topic_name: item.topic.name } }"><v-icon
                                        name="md-fileopen-round" class="ver" fill="#57afc4" scale="2"></v-icon></RouterLink>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ForumService from '@/services/ForumService';
import { onMounted, ref,Ref } from 'vue';
import { useAuth } from '@/store/useAuth';
import useWindowWidth from '@/composables/useWindowWidth';
//import AdvertisementMain from '@/components/advertisement/AdevertisementMain.vue'

const forumService = new ForumService()
const items = ref([])
const filtered:Ref<any> = ref({})
const loading = ref(true)
const search = ref('')
const store = useAuth()
const {windowWidth} = useWindowWidth()
const tableSmall= ref(600);

onMounted(async () => {
    if(!store.token) { return }
    loading.value = true
    items.value = await forumService.GetUserQuestions(store.token);
    filtered.value = items.value;
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filtered.value = items.value.filter((item:any) => item.name.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;
    h3 {
        margin-bottom: 15px;
    }
    p {
        font-size: 12px;
    }
}
.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    margin-top: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.new-question:hover {
    background-color: $secondary-color;
    color: white;
    svg {
        fill: white;
    }
}
.breadcrumbs {
    text-decoration: none;
    color: $secondary-color;
}

.breadcrumbs:hover {
    color: $third-color;
}
.employ-card {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: $secondary-color;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.btn {
    text-decoration: none;
    margin-left: 20px;
}

.input-job {
    width: 100vw;
}

/* Estilos para la tabla */
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
th{
    color: $secondary-color!important;
}
.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}
.table-container {
  overflow-x: auto;
}
.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}
</style>