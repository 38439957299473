
import { onMounted, onUnmounted, ref } from 'vue'

export default function useWindowWidth() {
    const windowWidth = ref(window.innerWidth)

    const onResize = () => windowWidth.value = window.innerWidth

    onMounted(() => { window.addEventListener('resize', onResize) })
    onUnmounted(() => { window.removeEventListener('resize', onResize) } )

    return {
        windowWidth
    }
}