<template>
    <div v-if="store.token" class="button-follow">
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2"/>
        </div>
        <div v-else>
        <div class="button-follow-inner" v-if="follow" @click="handleUnfollowRoadmap">
            <v-icon name="bi-heart-fill" fill="#bece49" hover="true" animation="wrench"  class="heart"  scale="1"/> <span>Siguiendo</span>
        </div>
        <div class="button-follow-inner" v-else @click="handleFollowRoadmap">
            <v-icon name="bi-heart-fill" fill="#ccc" hover="true" animation="wrench"  class="heart"  scale="1"/> <span>Seguir</span>
        </div>
    </div>
    </div>
</template>

<script lang="ts" setup>
import {defineProps, onMounted, ref} from 'vue'
import { useAuth } from '@/store/useAuth'
import FollowRoadmapService from '@/services/FollowRoadmapService'
import {useToast} from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';

const props = defineProps(['roadmapId'])
const store = useAuth()
const toast = useToast()
const follow = ref(false)
const loading = ref(true)
const followRoadmapService = new FollowRoadmapService()

onMounted(async () => {
    if(!store.token) {
        return;
    }
    follow.value = await followRoadmapService.checkFollowRoadmapStatus(store.token, props.roadmapId)
    loading.value = false
})

const handleFollowRoadmap = async () => {
    if(!store.token) {
        return;
    }
    loading.value = true
    const response = await followRoadmapService.doFollowToRoadmap(store.token, props.roadmapId)
    toast.showToast("Has comenzado a seguir la hoja de ruta.", Toast.SUCCESS)
    setTimeout(function() {toast.hideToast()}, 4000);
    follow.value = true
    loading.value = false
}

const handleUnfollowRoadmap = async () => {
    if(!store.token) {
        return;
    }
    loading.value = true
    const response = await followRoadmapService.doUnfollowToRoadmap(store.token, props.roadmapId)
    toast.showToast("Has dejado de seguir la hoja de ruta.", Toast.WARNING)
    setTimeout(function() {toast.hideToast()}, 4000);
    follow.value = false
    loading.value = false
}
</script>

<style lang="scss" scoped>
span {
    font-size: 14px;
}
.button-follow-inner {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.button-follow-inner:hover {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: white;
    span {
        color: white;
    }
}
</style>