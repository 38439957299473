export default class RoadmapService {
    private base_url = 'https://gogodev.net/gogolearn/api/roadmap'

    public async GetAllRoadmaps() {
        const uri = this.base_url
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetRoadmap(id:string | string[]) {
        const uri = `${this.base_url}/detail/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }
}