<template>
    <DefaultLayout>
        <div v-if="showFeedback" class="animate modal">
            <div class="modal-inner">
                <h3>CodeBite Creado</h3>
                <img v-if="windowWidth >= 1000" src="../../public/img/lms/fullstack.jpg" style="width: 100%;" />
                <RouterLink :to="{ name: 'social-profile-edit' }" style="text-decoration: none;">
                    <button class="new-question">Gestionar Mi Canal</button>
                </RouterLink>
            </div>
        </div>
        <div class="animate form">
            <h1>Redactar CodeBite</h1>
            <div class="spacer"></div>
            <RouterLink :to="{ name: 'social-profile-edit' }" style="text-decoration: none;">
                <button class="new-question">Volver a Gestionar Mi Canal</button>
            </RouterLink>
            <div class="spacer"></div>
            <h3>Título del CodeBite</h3>
            <p>Debe ser conciso y no extenderse más allá de 60 caracteres</p>
            <input class="input" v-model="title" type="text" max="60" maxlength="60" required />
            <div class="spacer"></div>
            <h3>Contenido del CodeBite</h3>
            <p>Usa la herramienta <b>&lt;/&gt;</b> para escribir código. Mejorará mucho su legibilidad. Puede contener la
                extensión que desees, pero lo ideal es que no sea demasiado extenso para mantener el espíritu de los
                CodeBites. Si deseas publicar algo más extenso, prueba a escribir un <RouterLink
                    :to=" { name: 'new-codepost' } ">nuevo CodePost</RouterLink>
            </p>
            <quill-editor ref="descriptionBox" theme="snow" v-model="description" toolbar="full"
                @update:content="hanldeDescription"></quill-editor>
            <div class="spacer"></div>
            <button class="new-question" @click="storeCodebite">Crear CodeBite <v-icon name="io-send"
                    style="margin-left: 10px;"></v-icon></button>
        </div>
    </DefaultLayout>
</template>

<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import ChannelService from '@/services/ChannelService'
import { useAuth } from '@/store/useAuth'
import useWindowWidth from '@/composables/useWindowWidth'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';


const toast = useToast()
const service = new ChannelService()
const store = useAuth()
const title = ref('')
const description = ref('')
const showFeedback = ref(false)
const { windowWidth } = useWindowWidth()
const descriptionBox = ref()

const hanldeDescription = () => {
    description.value = descriptionBox.value.getHTML()
}

const storeCodebite = async () => {
    if (!store.token) { return; }

    if(title.value.length < 3 || title.value.length > 255) {
        toast.showToast("La extensión del título no es válida.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    if(description.value.length < 10) {
        toast.showToast("La extensión de la descripción es demasiado corta.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }

    const response = await service.CreateCodeBite(store.token, title.value, description.value)

    if (response != true) {
        toast.showToast("Upps... Algo salió mal.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }

    showFeedback.value = true
}
</script>

<style scoped lang="scss">
.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;

    h3 {
        margin-bottom: 15px;
    }

    p {
        font-size: 12px;
    }
}

.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    margin-top: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.new-question:hover {
    background-color: $secondary-color;
    color: white;

    svg {
        fill: white;
    }
}

h3 {
    color: $secondary-color;
}

.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    margin-top: 15px;
    margin-left: 0;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.new-question:hover {
    background-color: $secondary-color;
    color: white;

    svg {
        fill: white;
    }
}

.form {
    width: 100%;
}

.input {
    width: 100%;
    padding: 10px;
    outline: 0;
    border: 2px solid $primary-color;
    border-radius: 5px;
}

p {
    font-size: 12px;
    margin-bottom: 5px;
}</style>