<template>
    <v-icon name="RiLoader2Fill" />
    <div v-if="loading">
        <v-icon name="RiLoader2Fill" />
    </div>
    <div v-else>
        <div v-if="courses.length > 0" class="content">
            <h1>Seguir aprendiendo</h1>
            <div class="container">
                <CourseList v-for="item in coursesContent" :key="item.id" :content="item" />
                
            </div>
        </div>
    </div>
</template>
 
<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import FollowCourseService from '@/services/FollowCourseService'
import { useAuth } from '@/store/useAuth'
import CourseList from '@/components/cards/CourseList.vue'
import CourseService from '@/services/CourseService'


const store = useAuth()
const followCourseService = new FollowCourseService()
const courseService = new CourseService()
const courses = ref([])
const coursesContent:any = ref([])
const loading = ref(false)

onMounted(async () => {
    loading.value = true
    if (store.token) {
        courses.value = await followCourseService.getAllCoursesFromUser(store.token)
    }
    courses.value.forEach(async (course:any) => {
        const item = await courseService.GetCourse(course.course_id)
        coursesContent.value.push(item)
    });
    Promise.all(coursesContent.value)
    loading.value = false
})
</script>

<style lang="scss" scoped>
.content {
    margin-bottom: 20px;
}
.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
h1 {
    margin-bottom: 10px;
}

@media(max-width: 950px) {
    .container {
        width: 100%;
    }
}
</style>