<template>
    <div v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
    </div>
    
</template>

<script  lang="ts" setup>
import {ref,defineProps,onMounted} from 'vue'
import PayPalService from '@/services/PayPalService'
import router from '@/router';

const service = new PayPalService()
const loading = ref(false)

const props = defineProps<{
    idToValidate: string
}>()

onMounted(async () => {
    loading.value = true
    const response = await service.checkSubFromId(props.idToValidate)
    if(response.status != 'ACTIVE') {
        router.push({name: 'register-free'})
    }
    loading.value = false
})






</script>

<style scoped></style>