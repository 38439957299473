<template>
    <div class="li-menu">
   <a :href="props.bannerLink" target="_blank">   
      <img :src="props.imageURL " alt="Publicidad" style="height: 110px;">
    </a>
</div>
</template>

<script  lang="ts" setup>
import { defineProps } from 'vue';

const props = defineProps(['imageURL', 'bannerLink'])
</script>

<style scoped>
.li-menu{
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
}
.li-menu:hover {
    background-color: #57afc4;
    color: white;
}
.li-menu {
    margin-bottom: 5px;
}
</style>