<template>
    <DefaultLayout>
        <EditChannelHeader />
        <div class="channel-description" v-if="!loading">
            <div v-if="showEditDescription" class="animate modal">
                <div class="modal-inner">
                    <div style="float: right; cursor: pointer;" @click="showEditDescription = false"><v-icon
                            name="ri-close-circle-fill" fill="lightcoral"></v-icon></div>
                    <h3>Editar Descripción</h3>
                    <p>Cuéntanos un poco sobre tí. (Máximo: 255 caracteres)</p>
                    <input class="input-text" type="text" v-model="description" placeholder="Nueva Descripción"
                        maxlength="255" max="255" />
                    <button class="new-question" @click="handleDescription">Actualizar Descripción</button>
                </div>
            </div>
            <h3>Sobre {{ user.name }} <v-icon class="pencil" name="bi-pencil-fill" style="overflow-y: hidden;"
                    @click="showEditDescription = true"></v-icon></h3>
            <p v-if="user.description">{{ user.description }}</p>
            <p v-else>Bienvenido al canal de {{ user.name }} en Ademass</p>
        </div>
        <div class="latest-messages-container" v-if="!loading">
            <h3>Últimos CodeBites de {{ user.name }} <RouterLink :to="{ name: 'my-codebites' }" style="text-decoration: none;">
                    <small>Ver todos</small></RouterLink> <button class="new-question"
                    @click="router.push({ name: 'new-codebite' })">Crear un nuevo CodeBite</button></h3>
            <div class="latest-messages">
                <CodeBite v-for="codebite in codebites" :key="codebite.id" :codebite="codebite" :user="user" @refresh="refresh" />
                <p v-if="codebites.length == 0" style="margin-top: 10px;">{{ user.name }} no ha publicado CodeBites</p>
            </div>
        </div>
        <div class="latest-messages-container" v-if="!loading">
            <h3>Últimos CodePosts de {{ user.name }} <RouterLink :to="{ name: 'my-codeposts' }" style="text-decoration: none;">
                    <small>Ver todos</small></RouterLink> <button class="new-question"
                    @click="router.push({ name: 'new-codepost' })">Crear un nuevo CodePost</button></h3>
            <div class="latest-messages">
                <CodePost v-for="codepost in codeposts" :key="codepost.id" :codepost="codepost" :user="user" @refresh="refresh" />
                <p v-if="codeposts.length == 0" style="margin-top: 10px;">{{ user.name }} no ha publicado CodePosts</p>
            </div>
        </div>
    </DefaultLayout>
</template>

<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import EditChannelHeader from '@/components/channel/EditChannelHeader.vue'
import CodeBite from '@/components/cards/CodeBite.vue'
import CodePost from '@/components/cards/CodePost.vue'
import ProfileService from '@/services/ProfileService'
import ChannelService from '@/services/ChannelService'
import { ref, onMounted, Ref } from 'vue'
import { useAuth } from '@/store/useAuth'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import router from '@/router'
import CodeI from '@/interfaces/CodeBiteInterface'

const profileService = new ProfileService()
const user = ref()
const loading = ref(true)
const store = useAuth()
const showEditDescription = ref(false)
const description = ref('')
const toast = useToast()
const codebites:Ref<CodeI[]> = ref([])
const codeposts:Ref<CodeI[]>  = ref([])
const channelService = new ChannelService()

onMounted(async () => {
    if (!store.token) { return }
    loading.value = true
    user.value = await profileService.MyUser(store.token)
    codebites.value = await channelService.GetLatestCodeBitesFromAuthUser(store.token, 4)
    codeposts.value = await channelService.GetLatestCodePostsFromAuthUser(store.token, 2)
    loading.value = false
})

const refresh = async () => {
    if (!store.token) { return }
    codebites.value = await channelService.GetLatestCodeBitesFromAuthUser(store.token, 4)
    codeposts.value = await channelService.GetLatestCodePostsFromAuthUser(store.token, 2)
}

const handleDescription = async () => {
    if (!store.token) { return }
    if (description.value.length < 3 || description.value.length > 255) {
        toast.showToast("El tamaño de la descripción no es válido", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    const response = await profileService.UpdateDescription(store.token, description.value)
    if (response == true) {
        toast.showToast("Perfil Actualizado.", Toast.SUCCESS)
        setTimeout(function () { toast.hideToast() }, 4000);
        user.value = await profileService.MyUser(store.token)
        showEditDescription.value = false
    } else {
        toast.showToast("Error al actualizar.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
    }
}
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;

    h3 {
        margin-bottom: 15px;
    }

    p {
        font-size: 12px;
    }
}

.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 5px;
    margin-top: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.input-text {
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid $secondary-color;
    margin: 5px;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 5px;
    outline: none;
}

.new-question:hover {
    background-color: $secondary-color;
    color: white;

    svg {
        fill: white;
    }
}

.pencil {
    margin-left: 5px;
    cursor: pointer;
}

.pencil:hover {
    fill: $secondary-color;
}

.channel-description {
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
}

.latest-messages-container {
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 50px;
}

.latest-messages {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}</style>