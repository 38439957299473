<template>
  <ProdevValidatorPortFolio :idToValidate="route.params.id" ></ProdevValidatorPortFolio>
  <div class="bookcontainer">
    <div class="info">
      <h2>¡Hola!</h2>
      <p>Te doy la bienvenida a mi Ademass Developer Passport.
        <br />Puedes compartirlo o capturarlo usando el siguiente QR:
      </p>
      <iframe id="qrcode" src="" width="250" height="250" style="border: 0;"></iframe>
      <p>También puedes visitarlo siempre que quieras con<br />el siguiente enlace: <a :href="val"><p class="advice">{{ val }}</p></a></p>
      <hr style="color: #131313; margin-top: 5px;" />
      <p class="advice">INSTRUCCIONES: Pulsa y arrastra sobre las esquinas<br />del documento para pasar de página.</p>
    </div>
    <div ref="flipbook" class="flipbook" id="flipbook">
      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.15;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.15; bottom: 0; left: 0;" />
        <div class="cover">
          <img src="/img/logo.png" alt="ademass logo" width="256" />
          <v-icon name="gi-world" fill="white" scale="20" />
          <div class="cover-text">
            <h1>{{ candidate?.user?.name }}</h1>
            <h5><span>&lt;</span>{{ candidate?.user?.professional_profile?.slogan }} <span>/&gt;</span></h5>
          </div>
          <div class="seal">
            <v-icon name="fa-passport" fill="white" scale="4" />
            <p>Ademass Developer Passport</p>
          </div>
        </div>
      </div>


      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <div class="userdataframe">
            <img :src="candidate?.user?.profile_picture_uri" alt="foto de perfil" style="max-width: 150px; max-height: 150px;">
            <div class="personal_information">
              <p>
                <span>Apellidos / Family Name:</span> {{ apellidos }}
              </p>
              <p>
                <span>Nombre / Name:</span> {{ nombre }}
              </p>
              <p>
                <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }}
              </p>
              <p>
                <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }}
              </p>
              <p>
                <span>Email:</span> {{ candidate?.user?.email }}
              </p>
              <p>
                <span>Teléfono:</span> {{ candidate?.user?.telephone }}
              </p>
            </div>
          </div>
          <p>SOBRE MI / ABOUT ME</p>
          <div class="about">
            <p>{{ candidate?.user?.professional_profile?.profile_description }}</p><br />
          </div>
        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">EXPERIENCIA / EXPERIENCE</p>
          <hr>
          <div class="experiences">
            <br>
            <div class="experience_education" v-for="(job, index) in partExperience1" :key="index">
              <div class="content_education">
                <p>
                  <span>Empresa: </span><span class="education_title"> {{ job?.company }}</span>
                </p>
                <p>
                  <span>Puesto: </span><span class="education_title">{{ job?.name }} </span>
                </p>
                <p>
                  <span>Descripción: </span><br>
                  <span class="education_title">{{ job?.description }}</span>
                </p>
                <p>
                  <span>Años en el puesto: </span><span class="education_title">{{ job?.years }}</span>
                </p>

              </div>
              <div class="img_sello">
                <img src="/img/portfolio/sello.png" alt="sello" width="100" :style="{'transform': `rotate(${Math.random(1,100)})`}">
              </div>
            </div>

            <br>
          </div>

        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">EXPERIENCIA / EXPERIENCE</p>
          <hr>
          <div class="experiences">
            <br>
            <div class="experience_education" v-for="(job, index) in partExperience2" :key="index">
              <div class="content_education">
                <p>
                  <span>Empresa: </span><span class="education_title"> {{ job?.company }}</span>
                </p>
                <p>
                  <span>Puesto: </span><span class="education_title">{{ job?.name }} </span>
                </p>
                <p>
                  <span>Descripción: </span><br>
                  <span class="education_title">{{ job?.description }}</span>
                </p>
                <p>
                  <span>Años en el puesto: </span><span class="education_title">{{ job?.years }}</span>
                </p>

              </div>
              <div class="img_sello">
                <img src="/img/portfolio/sello.png" alt="sello" width="100">
              </div>
            </div>

            <br>
          </div>

        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">EXPERIENCIA / EXPERIENCE</p>
          <hr>
          <div class="experiences">
            <br>
            <div class="experience_education" v-for="(job, index) in partExperience3" :key="index">
              <div class="content_education">
                <p>
                  <span>Empresa: </span><span class="education_title"> {{ job?.company }}</span>
                </p>
                <p>
                  <span>Puesto: </span><span class="education_title">{{ job?.name }} </span>
                </p>
                <p>
                  <span>Descripción: </span><br>
                  <span class="education_title">{{ job?.description }}</span>
                </p>
                <p>
                  <span>Años en el puesto: </span><span class="education_title">{{ job?.years }}</span>
                </p>

              </div>
              <div class="img_sello">
                <img src="/img/portfolio/sello.png" alt="sello" width="100">
              </div>
            </div>

            <br>
          </div>

        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">FORMACIÓN / EDUCATION</p>
          <hr>
          <div class="experiences">
            <div class="experience_education" v-for="(study, index) in partStudy1" :key="index">
              <div class="content_education">
                <p>
                  <span>Título: </span><span class="education_title"> {{ study?.name }}</span>
                </p>
                <p>
                  <span>Centro: </span><span class="education_title">{{ study?.organism }} </span>
                </p>
                <p>
                  <span>Descripción: </span><br>
                  <span class="education_title">{{ study?.description }}</span>
                </p>
              </div>
              <div class="img_sello">
                <img src="/img/portfolio/sello.png" alt="sello" width="100">
              </div>
            </div>

            <br>
          </div>
        </div>
      </div>

      <div class="page" >
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">FORMACIÓN / EDUCATION</p>
          <hr>
          <div class="experiences">
            <div class="experience_education" v-for="(study, index) in partStudy2" :key="index">
              <div class="content_education">
                <p>
                  <span>Título: </span><span class="education_title"> {{ study?.name }}</span>
                </p>
                <p>
                  <span>Centro: </span><span class="education_title">{{ study?.organism }} </span>
                </p>
                <p>
                  <span>Descripción: </span><br>
                  <span class="education_title">{{ study?.description }}</span>
                </p>
              </div>
              <div class="img_sello">
                <img src="/img/portfolio/sello.png" alt="sello" width="100">
              </div>
            </div>

            <br>
          </div>
        </div>
      </div>
     

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> Málaga |
            <span>País / Country:</span> España |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">HABILIDADES / SKILLS</p>
          <hr>
          <div class="skills">
            <br>
            <div class="content_skills">
              <table>
                <tbody>
                  <tr v-for="(elm, index ) in part1Skills" :key="index">
                    <td>
                      <p>{{ elm?.name }}</p> 
                      <img src="/img/portfolio/check.png" alt="icon" width="30" v-if="isSkillApproved(elm.id)">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br>
          </div>
        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> Málaga |
            <span>País / Country:</span> España |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">HABILIDADES / SKILLS</p>
          <hr>
          <div class="skills">
            <br>
            <div class="content_skills">
              <table>
                <tbody>
                  <tr v-for="(elm, index ) in part2Skills" :key="index">
                    <td>
                      <p>{{ elm?.name }}</p>
                      <img src="/img/portfolio/check.png" alt="icon" width="30" v-if="isSkillApproved(elm.id)">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br>
          </div>
        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">CERTIFICATIONS / CERTIFICACIONES</p>
          <hr>
          <div class="skills">
            <br>
            <div class="content_skills">
              <div class="skill" v-for="(elm, index) in evaluationsPart1" :key="index">
                <p>{{ elm?.name }}</p>
                <img src="/img/portfolio/Valido.png" alt="sello" width="80" v-if="isEvaluationApproved(elm.course_id)">
                <img src="/img/portfolio/invalid.png" alt="icon" width="80" v-else>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">CERTIFICATIONS / CERTIFICACIONES</p>
          <hr>
          <div class="skills">
            <br>
            <div class="content_skills">
              <div class="skill" v-for="(elm, index) in evaluationsPart2" :key="index">
                <p>{{ elm?.name }}</p>
                <img src="/img/portfolio/Valido.png" alt="sello" width="80" v-if="isEvaluationApproved(elm.course_id)">
                <img src="/img/portfolio/invalid.png" alt="icon" width="80" v-else>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">CERTIFICATIONS / CERTIFICACIONES</p>
          <hr>
          <div class="skills">
            <br>
            <div class="content_skills">
              <div class="skill" v-for="(elm, index) in evaluationsPart3" :key="index">
                <p>{{ elm?.name }}</p>
                <img src="/img/portfolio/Valido.png" alt="sello" width="80" v-if="isEvaluationApproved(elm.course_id)">
                <img src="/img/portfolio/invalid.png" alt="icon" width="80" v-else>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">CERTIFICATIONS / CERTIFICACIONES</p>
          <hr>
          <div class="skills">
            <br>
            <div class="content_skills">
              <div class="skill" v-for="(elm, index) in evaluationsPart4" :key="index">
                <p>{{ elm?.name }}</p>
                <img src="/img/portfolio/Valido.png" alt="sello" width="80" v-if="isEvaluationApproved(elm.course_id)">
                <img src="/img/portfolio/invalid.png" alt="icon" width="80" v-else>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">CERTIFICATIONS / CERTIFICACIONES</p>
          <hr>
          <div class="skills">
            <br>
            <div class="content_skills">
              <div class="skill" v-for="(elm, index) in evaluationsPart5" :key="index">
                <p>{{ elm?.name }}</p>
                <img src="/img/portfolio/Valido.png" alt="sello" width="80" v-if="isEvaluationApproved(elm.course_id)">
                <img src="/img/portfolio/invalid.png" alt="icon" width="80" v-else>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">CERTIFICATIONS / CERTIFICACIONES</p>
          <hr>
          <div class="skills">
            <br>
            <div class="content_skills">
              <div class="skill" v-for="(elm, index) in evaluationsPart6" :key="index">
                <p>{{ elm?.name }}</p>
                <img src="/img/portfolio/Valido.png" alt="sello" width="80" v-if="isEvaluationApproved(elm.course_id)">
                <img src="/img/portfolio/invalid.png" alt="icon" width="80" v-else>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>
      
      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">CERTIFICATIONS / CERTIFICACIONES</p>
          <hr>
          <div class="skills">
            <br>
            <div class="content_skills">
              <div class="skill" v-for="(elm, index) in evaluationsPart7" :key="index">
                <p>{{ elm?.name }}</p>
                <img src="/img/portfolio/Valido.png" alt="sello" width="80" v-if="isEvaluationApproved(elm.course_id)">
                <img src="/img/portfolio/invalid.png" alt="icon" width="80" v-else>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>
      
      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">CERTIFICATIONS / CERTIFICACIONES</p>
          <hr>
          <div class="skills">
            <br>
            <div class="content_skills">
              <div class="skill" v-for="(elm, index) in evaluationsPart8" :key="index">
                <p>{{ elm?.name }}</p>
                <img src="/img/portfolio/Valido.png" alt="sello" width="80" v-if="isEvaluationApproved(elm.course_id)">
                <img src="/img/portfolio/invalid.png" alt="icon" width="80" v-else>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>

      <div class="page">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="userdata">
          <p style="font-size: 18px;">ADEMASS INTERNATIONAL DEVELOPER PASSPORT</p>
          <p>
            <span>Ciudad / City:</span> {{ candidate?.user?.professional_profile?.location?.name }} |
            <span>País / Country:</span> {{ candidate?.user?.professional_profile?.location?.country?.name }} |
            <span>Movilidad / Movility:</span> Internacional / International
          </p>
          <p style="margin-top: 8px;margin-bottom: 8px;">CERTIFICATIONS / CERTIFICACIONES</p>
          <hr>
          <div class="skills">
            <br>
            <div class="content_skills">
              <div class="skill" v-for="(elm, index) in evaluationsPart9" :key="index">
                <p>{{ elm?.name }}</p>
                <img src="/img/portfolio/Valido.png" alt="sello" width="80" v-if="isEvaluationApproved(elm.course_id)">
                <img src="/img/portfolio/invalid.png" alt="icon" width="80" v-else>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>

      <div class="page back-cover" style="overflow: visible;">
        <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.05;" />
        <img src="/img/dirty.webp"
          style="position: absolute; width: 100%; height: 50%; opacity: 0.05; bottom: 0; left: 0; " />
        <div class="infocard"
          style="position: absolute; top: 20px; right: 15px; background-color: white; width: 400px; height: 280px; background-image: url('/img/portfolio/notebook.jpg'); background-size: cover; transform: rotate(-3deg)">
          <div class="contact">
            <h3>¿ HABLAMOS ? / LET'S TALK? </h3>
            <p>
              <span class="final_cover">Nombre / Name <b>:</b></span>&nbsp;&nbsp;<b>{{ nombre }}</b>
            </p>
            <p>
              <span class="final_cover">Apellidos / Family Name <b>:</b></span>&nbsp;&nbsp;<b>{{ apellidos }}</b>
            </p>
            <p>
              <span class="final_cover">Email <b>:</b></span>&nbsp;&nbsp;<b>{{ candidate?.user?.email }}</b>
            </p>
            <p>
              <span class="final_cover">Teléfono <b>:</b></span>&nbsp;&nbsp;<b>{{ candidate?.user?.telephone }}</b>
            </p>
          </div>
        </div>
        <img src="/img/portfolio/clip.png"
          style="position: absolute; top: -30px; right: 40px; z-index: 2; overflow: visible;" width="80" />
        <img :src="candidate?.user?.profile_picture_uri" alt="foto de perfil"
          style="position: absolute; top: 20px; right: 40px; overflow: visible;transform: rotate(-3deg);" width="80">


      </div>
      <div class="page back-cover">
        <div class="final">
          <img src="/img/dirty.webp" style="position: absolute; width: 100%; height: 50%; opacity: 0.15;" />
          <img src="/img/dirty.webp"
            style="position: absolute; width: 100%; height: 50%; opacity: 0.15; bottom: 0; left: 0;" />
          <img src="/img/logo.png" alt="ademass logo" width="256" />
        </div>
      </div>

    </div>
    <div class="pozis">
    <button @click="turnPage(3)" class="pag">Experiencia</button>
    <button @click="turnPage(6)" class="pag">Formación</button>
    <button @click="turnPage(8)" class="pag">Habilidades</button>
    <button @click="turnPage(10)" class="pag">Certificaciones</button>
    <button @click="turnPage(19)" class="pag">¿Hablamos?</button>
    <a :href="`https://campus-ademass.com/portfolio2/${candidate?.user?.id}`" target="_blank" media="print" class="print">Abrir página para imprimir</a>
  </div>
  </div>

</template>



<script setup>
import $ from 'jquery';
import 'turn.js';
import { ref, onMounted } from 'vue'
import PortFolio from '@/services/PortFolioService';
import SkillServive from '@/services/SkillService'
import ProfileService from '@/services/ProfileService'
import { useRoute } from 'vue-router';
import EvaluationService from '@/services/EvaluationService'
import ProdevValidatorPortFolio from '@/components/auth/ProdevValidatorPortFolio.vue'

const loading = ref(false);
const flipbook = ref(null);
const val = ref("https://ademass.com")
const profileService = new ProfileService()
const skillService = new SkillServive()
const portFolioService = new PortFolio()
const profile = ref('')
const candidate = ref({})
const route = useRoute()
const partes = ref('')
const nombre = ref('')
const apellidos = ref('')
const evaluationService = new EvaluationService()
//evaluaciones(certificaciones)
const evaluations = ref([])
const evaluationsPart1 = ref([])
const evaluationsPart2 = ref([])
const evaluationsPart3 = ref([])
const evaluationsPart4 = ref([])
const evaluationsPart5 = ref([])
const evaluationsPart6 = ref([])
const evaluationsPart7 = ref([])
const evaluationsPart8 = ref([])
const evaluationsPart9 = ref([])
//estudios
const studies = ref([])
const partStudy1 = ref([])
const partStudy2 = ref([])
const partStudy3 = ref([])
const pag2 = ref(false)
const pag3 = ref(false)
//experiencia
const experience = ref([])
const partExperience1 = ref([])
const partExperience2 = ref([])
const partExperience3 = ref([])
const pag2_experience = ref(false)
const pag3_experience = ref(false)
//Skills
const allSkills = ref([])
const part1Skills = ref([])
const part2Skills = ref([])

const indexPageFormation = ref(5)
const indexPageSkills = ref(6)
const indexPageCertification = ref(8)
const indexFinal = ref(12)

const turnPage = (i) => {
  $(".flipbook").turn("page", i)
}


const isSkillApproved = (skillId) => {
  if (candidate.value && candidate.value.user && candidate.value.user.skills) {
    return candidate.value.user.skills.some(skill => skill.id === skillId);
  }
  return false;
}

const isEvaluationApproved = (evaluationId) => {
  if (candidate.value && candidate.value.user && candidate.value.user.certifications) {
    return candidate.value.user.certifications.some(evaluation => evaluation.course_id === evaluationId);
  }
  return false;
}

onMounted(async () => {
  loading.value = true;
  profile.value = await profileService.UserInfo(route.params.id)
  allSkills.value = await skillService.GetAllSkills()
  candidate.value = await portFolioService.GetCandidateInfo(route.params.id)
  candidate.value.user.previous_jobs = candidate.value.user.previous_jobs.reverse()
  candidate.value.user.studies = candidate.value.user.studies.reverse()
  partes.value = candidate.value.user.name.split(" ");
  nombre.value = partes.value[0]
  apellidos.value = partes.value.slice(1).join(" ")
  evaluations.value = await evaluationService.getAllEvaluations()
  studies.value = await candidate.value.user.studies
  experience.value = await candidate.value.user.previous_jobs
  const dividerSkills = () => {
    part1Skills.value = allSkills.value.slice(0, 10)
    part2Skills.value = allSkills.value.slice(10)
  }
  dividerSkills()
  const dividerExperiences = () => {
    if (experience.value.length > 3 && experience.value.length <= 6) {
      partExperience1.value = experience.value.slice(0, 3)
      partExperience2.value = experience.value.slice(3)
      pag2_experience.value = true;
      indexPageFormation.value = 5;
    }
    else if (experience.value.length > 6) {
      partExperience1.value = experience.value.slice(0, 3)
      partExperience2.value = experience.value.slice(3, 6)
      partExperience3.value = experience.value.slice(6)
      pag3_experience.value = true;
      indexPageFormation.value = 6;
    } else {
      partExperience1.value = experience.value;
      indexPageFormation.value = 5;
    }
  }
  dividerExperiences()
  const dividerStudies = () => {
    if (studies.value.length > 3 && studies.value.length < 6) {
      partStudy1.value = studies.value.slice(0, 3)
      partStudy2.value = studies.value.slice(3)
      pag2.value = true;
      if(indexPageFormation.value == 5){
      indexPageSkills.value = 7 
    }else if(indexPageFormation.value == 6){
      indexPageSkills.value = 8 
    }
    } else if (studies.value.length > 6) {
      partStudy1.value = studies.value.slice(0, 3)
      partStudy2.value = studies.value.slice(3, 6)
      partStudy3.value = studies.value.slice(6)
      pag3.value = true;
      if(indexPageFormation.value == 5){
      indexPageSkills.value = 8 
    }else if(indexPageFormation.value == 6){
      indexPageSkills.value = 9 
    }
    } else {
      partStudy1.value = studies.value;
    }
  }
  dividerStudies()
  const divider = () => {
    evaluationsPart1.value = evaluations.value.slice(0,6);
    evaluationsPart2.value = evaluations.value.slice(6, 12);
    evaluationsPart3.value = evaluations.value.slice(12, 18);
    evaluationsPart4.value = evaluations.value.slice(18, 24);
    evaluationsPart5.value = evaluations.value.slice(24, 30);
    evaluationsPart6.value = evaluations.value.slice(30, 36);
    evaluationsPart7.value = evaluations.value.slice(36, 42);
    evaluationsPart8.value = evaluations.value.slice(42, 48);
    evaluationsPart9.value = evaluations.value.slice(48);
  }
  divider()
  
  const calculate = () =>{
     indexPageSkills.value = (indexPageFormation.value + 1)
     indexPageCertification.value = (indexPageSkills.value + 2)
     indexFinal.value = ( indexPageCertification.value + 9)
  }
  calculate()
  val.value = `https://campus-ademass.com/portfolio3/${route.params.id}`;

  document.getElementById("qrcode").setAttribute("src", "https://api.mimfa.net/qrcode?value=" + encodeURIComponent(val.value) + "&as=value");

  $(".flipbook").turn();
  loading.value = false;
});


var images = document.querySelectorAll('.img_sello');
images.forEach(function (image) {
  var randomRotation = Math.floor(Math.random() * 41) - 20; // Generar un número aleatorio entre -20 y 20 grados
  image.style.transform = 'rotate(' + randomRotation + 'deg)'; // Aplicar la rotación
});


</script>

<style scoped lang="scss">
.contact p {
  margin-top: 10px;
  margin-bottom: 20px !important;
  color: #00007f;
  font-size: 18px;
  font-family: "note-text";
}
.pozis{
  display: flex;
  flex-direction: column;
}
table{
  width: 100%;
}
.pag{
  border: none;
  background-color: rgb(254, 254, 102);
  color: black;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  margin-bottom: 6px;
  padding: 8px;
}
.print{
  border: none;
  background-color:rgb(248, 112, 112);
  color: black;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  margin-bottom: 6px;
  padding: 8px;
}
tr{
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #aaa;
}
td{
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  text-align: start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #aaa;
}
.final {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

p b {
  color: #00007f;
  font-size: 18px;
  font-family: "note-text";
  font-weight: bold;
}

.details {
  width: 100%;
  color: #00007f;
  font-size: 18px;
  font-family: "note-text";
  display: inline-block;

}

.final_cover {
  width: 100%;
  color: #00007f;
  font-size: 18px;
  font-family: "note-text";
}

.final_cover b {
  font-size: 22px;
  font-weight: bold;
  color: #00007f;
  font-family: "note-text";
}

.contact {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px;
  min-height: 70vh;
  align-items: flex-start;
  justify-content: flex-start;
}

h3 {
  width: 100%;
  color: #00007f;
  font-family: "note-text";
}

.back-cover {
  background-color: #131313 !important;
}

.personal_information p {
  margin-bottom: 10px;
}

.desgastada {
  font-family: Arial, sans-serif;
  font-size: 36px;
  color: #333;
  -webkit-text-stroke: 1px white;
  /* Grosor del desgaste */
}

a {
  color: #131313;
  font-weight: bold;
  text-decoration: none;
  z-index: 30;
}

.content_skills {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  gap: 15px;
  align-items: flex-start;
}

.skill {
  width: 150px;
  height: 150px;
  border: 1px solid #dedede;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.skills {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  /* Ajusta según sea necesario */
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  min-height: 700px;
}

.education_title {
  border-bottom: 1px dotted black;
  /* Línea de puntos */
  display: inline-block;
  /* Para asegurar que el ancho de la línea se ajuste al texto */
  padding-left: 8px;
  padding-bottom: 0 !important;
  vertical-align: auto;
}

.experience_education {
  padding: 15px;
  padding-left: 45px;
  display: flex;
  text-align: left;
}

.content_education {
  width: 70%;
}

.img_sello {
  width: 25%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  transform: rotate(calc(-20deg + (40deg * random())));
}

.experience {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.personal_information {
  line-height: 0.60;
  text-align: left;
  padding-right: 20px;
}

.userdataframe {
  margin-top: 20px;
  width: 100%;
  display: flex;
  margin-left: 50px;
  gap: 20px;
  margin-bottom: 2em;
}

.about {
  padding: 40px;
  text-align: justify;
}

.advice {
  color: black;
  font-style: italic;
  font-weight: bold;
  font-size: large;
}
.pag:hover{
    cursor: pointer;
    color: white;
    background-color: $secondary-color;
}
a:hover {
  color: white;
}


* {
  overflow: hidden !important;
  overflow-y: hidden !important;
  color: white;
  line-height: normal !important;
}

.flipbook {
  width: 70%;
  height: 90%;

}

.info {
  position: absolute;
  left: 10%;
  background-color: rgba(193, 240, 40, 0.5);
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;
    margin-left: 5px;
    text-align: left;
  }

  h2 {
    margin-left: 5px;
    text-align: left;
  }
}

.page {
  background: white;
  border: 1px solid black;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  line-height: 300px;
  text-align: center;
}

.bookcontainer {
  width: 100vw;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-image: url('../../public/img/wood.jpg');
  background-size: cover;
}

.cover {
  background-color: #131313;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  height: 100%;
  border-top: 10px solid $third-color;
}


.cover-text {
  font-family: "Source Code Pro", 'Courier New', Courier, monospace !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: normal !important;
  margin-bottom: 25px;

  h5 {
    color: $secondary-color;

    span {
      color: white;
    }
  }
}

.seal {
  display: flex;
  border: 1px solid white;
  border-radius: 10px;
  padding: 0;
  line-height: normal;
  align-items: center;
  padding-left: 0;
  width: 260px;

  p {
    font-size: 13px;
  }
}

.seal svg {
  margin-left: -10px;
  margin-top: -8px;
  margin-bottom: -7px;
}


.userdata {
  color: #131313 !important;
  display: flex;
  flex-direction: column;
  line-height: normal;
  padding-top: 25px;

  p {
    color: #131313 !important;
    font-size: 12px;

    span {
      color: #131313;
      font-weight: bold;
    }
  }
}
</style>