export default class AuthorService {
    private base_url = 'https://gogodev.net/gogolearn/api/author'

    public async GetAllAuthors() {
        const uri = this.base_url
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetAuthor(author_id:string) {
        const uri = `${this.base_url}/detail/${author_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }
}