<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div class="container" v-else>
            <FullConversationChat v-if="showConversationBox" :friendId="friendId" @closeConversation="closeConversation" />
            <AddFriend v-if="showAddFriend" @close="showAddFriend = false" />
            <div class="title">
                <h1>Mensajería</h1>
                <button class="friend" style="float: right;" @click="showAddFriend = true"><v-icon
                        name="io-person-add-sharp" class="icon" hover="true" animation="wrench" fill="white" />
                    <p>Añadir amigos</p>
                </button>
            </div>
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Filtrar búsqueda" @keyup="handleSearch" />
            </form>
            <ul>
                <li v-for="friend in filteredFriends" :key="friend.id" style="margin: 2px;" class="liMessages" @click="showConversation(friend.id)">
                    <div style="display: flex; align-items: center;">
                        <div class="userMessage" style="margin-right: 10px;">
                            <div class="image">
                                <img v-if="friend?.profile_picture_uri" :src="friend.profile_picture_uri" />
                                <h3 v-else>{{ friend.name.substring(0,2) }}</h3>
                            </div>
                        </div>
                        <p>{{friend.name}}</p>
                    </div>
                   
                    <button style="float: right;" class="glass"> 
                        <ConversationNotification :friendId="friend.id" style="position: absolute;" />
                        <v-icon name="bi-chat-fill" scale="1.5" fill="white" />
                    </button>

                </li>
            </ul>
        </div>
    </DefaultLayout>
</template>

<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref, onMounted,Ref} from 'vue'
import { useAuth } from '@/store/useAuth';
import AddFriend from '@/components/widgets/AddFriend.vue';
import FriendshipService from '@/services/FriendshipService'
import FullConversationChat from '@/components/widgets/FullConversationChat.vue';
import ConversationNotification from '@/components/widgets/ConversationNotification.vue';
import { useRoute } from 'vue-router';

const loading = ref(true)
const store = useAuth()
const showAddFriend = ref(false)
const friends:Ref<Array<string>> = ref([])
const filteredFriends:Ref<Array<any>> = ref([])
const friendshipService = new FriendshipService()
const search = ref('')
const friendId = ref(0)
const showConversationBox = ref(false)
const router = useRoute()
const paramId = router.params.id

onMounted(async () => {
    if (!store.token) {
        loading.value = false
        return
    }
    loading.value = true
    friends.value = await friendshipService.listUserFriends(store.token)
    filteredFriends.value = friends.value
    if(paramId) {
        showConversation(paramId)
    }
    loading.value = false
})

const showConversation = async (id:any) => {
    if (!store.token) {
        return
    }
    friendId.value = parseInt(id);
    await friendshipService.markAsRead(store.token, id)
    showConversationBox.value = true
}

const closeConversation = () => {
    showConversationBox.value = false
}

const handleSearch = () => {
    loading.value = true
    filteredFriends.value = friends.value.filter((item:any) => item.name.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}
</script>

<style lang="scss" scoped>
.image {
    border: 3px solid $secondary-color;
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    background-color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
        color: $secondary-color;
    }
}

button {
    padding: 6px;
    border: 0;
    outline: 0;
    background-color: $secondary-color;
    color: white;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
}

ul {
    margin-top: 20px;
}

.liMessages {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid $primary-color;
}

.liMessages:hover {
    background-color: $secondary-color;

    p {
        color: white;
    }

    .glass {
        border: 2px solid white;
        padding: 8px;
    }
}

.userMessage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.friend {
    padding: 10px;
    border: 0;
    outline: 0;
    background-color: $third-color;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    p {
        color: white;
        margin-left: 5px;

    }
}

.friend:hover {
    transform: scale(0.9);
}
</style>