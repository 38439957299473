<template>
    <div class="box card" v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
    </div>
    <div class="animate" v-else>
        <div class="card" @click="handleRoute">
            <h4> {{ props.content.lesson }}</h4>
            <p><v-icon name="md-castforeducation"></v-icon>{{ props.content.course }}
            <br /><v-icon name="md-daterange"></v-icon>{{ new Date(props.content.date).getDate() }} / {{ new Date(props.content.date).getMonth()+1 }} / {{ new Date(props.content.date).getFullYear() }}</p>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, onMounted } from 'vue'
import router from '@/router'
import useWindowWidth from '@/composables/useWindowWidth'

const {windowWidth} = useWindowWidth()

const props = defineProps(['content', 'showOrder'])
const card = ref({
    'id': 0,
    'content': 'cargando...',
    'lesson': 'cargando...',
    'course': 'cargando...',
    'date': 'cargando...',
    'course_id': 0,
    'lesson_id': 0
})
const loading = ref(true)

onMounted(() => {
    loading.value = true
    card.value = props.content
    loading.value = false
})

const handleRoute = () => router.push({name: 'course', params: {id: card.value.course_id, lesson_id: card.value.lesson_id}})
</script>

<style lang="scss" scoped>
.card {
  border-radius: 10px;
  filter: drop-shadow(0 5px 10px 0 #ffffff);
  width: 340px;
  height: 110px;
  background-color: #ffffff;
  padding: 20px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: 0.6s ease-in;
  margin: 5px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
  cursor: pointer;
}

.card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -15px;
  right: -15px;
  background: $secondary-color;
  height:220px;
  width: 25px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card:hover::before{
    transition-delay:0.2s ;

  transform: scale(40);
}

svg {
    margin-right: 5px;
}

.card:hover{
    color: #ffffff;
    p {
        color: white;
        svg {
            fill: white;
        }
    }
    h4 {
        color: white;
    }

}

.card p{
    padding: 10px 0;
}
 
</style>
