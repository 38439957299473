<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div class="container" v-else>
            <AddFriend v-if="showAddFriend" @close="showAddFriend=false"/>
            <div class="title">
                <h1>Solicitudes Pendientes</h1>
                <div class="spacer"></div>
                <RouterLink :to="{name:'friends'}">
                <button class="friend" style="float: left;" @click="showAddFriend = true">
                    <p>Ir a amigos</p>
                </button>
            </RouterLink>
               
            </div>
            <ul v-if="requests.length >0">
                <li style="margin: 2px;" class="liMessages" v-for="request in requests" :key="request.id">
                    <div class="userMessage">
                        <div class="image">
                            <img v-if="request.sender?.profile_picture_uri" :src="request.sender.profile_picture_uri" width="128" />
                            <h3 v-else>{{ request.sender?.name?.substring(0,2) }}</h3>
                        </div>
                    </div>
                    <p><b>{{ request.sender?.name }}</b></p>
                    <div>
                        <button style="float: right;" class="glass red" @click="decline(request.id)"><v-icon
                                name="si-adblock" class="icon" hover="true" animation="wrench" fill="white" style="margin-right: 5px;" />Rechazar</button>
                        <button style="float: right;" class="glass" @click="accept(request.id)"><v-icon
                                name="io-person-add-sharp" class="icon" hover="true" animation="wrench" fill="white" style="margin-right: 5px;" />Aceptar</button>

                    </div>

                </li>
            </ul>
            <div v-else>
            <p><br />Sin solicitudes pendientes.</p>
            </div>
        </div>
    </DefaultLayout>
</template>





<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref, onMounted,Ref } from 'vue'
import { useAuth } from '@/store/useAuth'
import AddFriend from '@/components/widgets/AddFriend.vue'
import FriendshipService from '@/services/FriendshipService'
import { Toast } from '@/interfaces/ToastInterface'
import { useToast } from '@/store/useToast'
import RequestI from '@/interfaces/RequestInterface'

const loading = ref(true)
const store = useAuth()
const showAddFriend = ref(false)
const requests:Ref<RequestI[]> = ref([])
const service = new FriendshipService()
const toast = useToast()


onMounted(async () => {
    if (!store.token) {
        loading.value = false
        return
    }
    loading.value = true
    requests.value = await service.listPendingRequests(store.token)
    loading.value = false
})



const accept = async (id:string | undefined) => {
    loading.value = true
    if(store.token != null){
    const response = await service.acceptFriendshipRequest(store.token, id);
    toast.showToast(response.message, Toast.INFO)
    setTimeout(function () { toast.hideToast() }, 4000);
    requests.value = await service.listPendingRequests(store.token)
    }
    loading.value = false
}


const decline = async (id:string | undefined) => {
    loading.value = true
    if(store.token != null){
    const response = await service.declineFriendshipRequest(store.token, id);
    toast.showToast(response.message, Toast.INFO)
    setTimeout(function () { toast.hideToast() }, 4000);
    requests.value = await service.listPendingRequests(store.token)
    }
    loading.value = false
}

</script>






<style lang="scss" scoped>
.blue {
    background-color: $secondary-color !important;
}

.red {
    background-color: lightcoral;
}

.image {
    border: 3px solid $secondary-color;
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
        color: $secondary-color;
    }
}

button {
    padding: 6px;
    border: 0;
    outline: 0;
    background-color: $secondary-color;
    color: white;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
}

ul {
    margin-top: 20px;
}

.liMessages {
    width: 100%;
    background-color: $primary-color;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}

.glass:hover {
        border: 2px solid white;
        padding: 8px;
    }
.userMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.friend {
    padding: 10px;
    border: 0;
    outline: 0;
    background-color: $third-color;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    p {
        color: white;
        margin-left: 5px;

    }
}

.friend:hover {
    transform: scale(0.9);
}</style>