<template>
    <DefaultLayout>
        <div v-if="showFeedback" class="animate modal">
                <div class="modal-inner">
                    <h3>Respuesta enviada</h3>
                    <img v-if="windowWidth >= 1000" src="../../public/img/lms/devops.jpg" style="width: 100%;"/>
                    <p><b style="font-size: 14px;">¡Gracias por contribuir!</b><br /><br />Con aportes como el tuyo, mejoramos todos. ¡Gracias!</p>
                        <button class="new-question" @click="router.back()">Aceptar</button>
                </div>
            </div>
        <div class="animate form">
            <h1>Redactar Respuesta</h1>
            <p>Asegúrate de usar un formato legible. Usa la herramienta <b>&lt;/&gt;</b> para escribir código. Mejorará mucho la legibilidad de la pregunta</p>
            <div class="spacer"></div>
            <quill-editor ref="answerBox" theme="snow" toolbar="full" @update:content="handleAnswer"></quill-editor>
            <div class="spacer"></div>
            <button class="new-question" @click="storeAnswer">Enviar Respuesta <v-icon name="io-send"
                    style="margin-left: 10px;"></v-icon></button>
        </div>
    </DefaultLayout>
</template>

<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import ForumService from '@/services/ForumService'
import { useAuth } from '@/store/useAuth'
import useWindowWidth from '@/composables/useWindowWidth'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import { useRouter } from 'vue-router'

const toast = useToast()
const service = new ForumService()
const store = useAuth()
const name = ref('')
const answer = ref('')
const showFeedback = ref(false)
const {windowWidth} = useWindowWidth()
const answerBox = ref()
const route = useRoute()
const question_id = route.params.question_id
const router = useRouter()

const handleAnswer = () => {
    answer.value = answerBox.value.getHTML()
}

const storeAnswer = async () => {
    if (!store.token) { return; }
    const response = await service.WriteAnswer(store.token, question_id, answer.value)
    console.log("response", response)
    if (response != true) {
        toast.showToast("Upps... Algo salió mal.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    showFeedback.value = true
}
</script>

<style scoped lang="scss">

.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;
    h3 {
        margin-bottom: 15px;
    }
    p {
        font-size: 12px;
    }
}
.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    margin-top: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.new-question:hover {
    background-color: $secondary-color;
    color: white;
    svg {
        fill: white;
    }
}
h3 {
    color: $secondary-color;
}

.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    margin-top: 15px;
    margin-left: 0;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.new-question:hover {
    background-color: $secondary-color;
    color: white;

    svg {
        fill: white;
    }
}

.form {
    width: 100%;
}

.input {
    width: 100%;
    padding: 10px;
    outline: 0;
    border: 2px solid $primary-color;
    border-radius: 5px;
}

p {
    font-size: 12px;
    margin-bottom: 5px;
}
</style>