<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>
            <h1>Mis candidaturas</h1>
            <div class="spacer" />

            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Filtrar por puesto" @keyup="handleSearch"
                    class="input-job" />
            </form>
            <div class="spacer" />
            <div class="table-container">
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Puesto</th>
                            <th v-if="windowWidth > tableSmall">País/Ciudad</th>
                            <th v-if="windowWidth > tableSmall">Modalidad</th>
                            <th v-if="windowWidth > tableSmall">Salario</th>
                            <th>Estado</th>
                            <th>Ver detalles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="employ in filteredEmploys" :key="employ?.employ?.id">
                            <td> {{ employ?.employ?.name }} </td>
                            <td v-if="windowWidth > tableSmall"> {{ employ?.employ?.location?.country?.name }} ({{
            employ?.employ?.location?.name }}) </td>
                            <td v-if="windowWidth > tableSmall && employ?.employ?.is_remote">
                                <div>Remoto</div>
                            </td>
                            <td v-if="windowWidth > tableSmall && !employ?.employ?.is_remote">
                                <div>Presencial / Híbrido</div>
                            </td>

                            <td v-if="windowWidth > tableSmall && employ?.employ?.is_practicum == 0">
                                {{ employ?.employ?.salary_min_amount?.toLocaleString() }}€ - {{
            employ?.employ?.salary_max_amount?.toLocaleString() }}€
                            </td>

                            <td v-else-if="windowWidth > tableSmall && employ?.employ?.is_practicum != 0">Prácticas</td>
                            <td>{{ employ?.employ?.status?.description }}</td>
                            <td v-if="employ?.employ?.is_practicum == 0" @click="gotoEmploy(employ?.employ?.id)"
                                style="cursor: pointer;">
                                <v-icon name="md-fileopen-round" class="ver" fill="#57afc4" scale="2"></v-icon>
                            </td>
                            <td v-else @click="gotoPracticum(employ?.employ?.id)" style="cursor: pointer;">
                                <v-icon name="md-fileopen-round" class="ver" fill="#57afc4" scale="2"></v-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import useWindowWidth from '@/composables/useWindowWidth';
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CandidatureService from '@/services/CandidatureService'
import { onMounted, ref, Ref } from 'vue';
import { useAuth } from '@/store/useAuth';
import router from '@/router';
import EmployInterface from '@/interfaces/EmployInterface';

const candidatureService = new CandidatureService()
const employs:Ref<any> = ref({})
const filteredEmploys:Ref<any> = ref({})
const loading = ref(false)
const search = ref('')
const store = useAuth()
const { windowWidth } = useWindowWidth()
const tableSmall = ref(850);
const gotoEmploy = (id: number | undefined) => {
    router.push({ name: 'job-detail', params: { id: id } })
}
const gotoPracticum = (id: number | undefined) => {
    router.push({ name: 'project-detail', params: { id: id } })
}

onMounted(async () => {
    if (!store.token) {
        return
    }
    loading.value = true
    employs.value = await candidatureService.ListAllUserCandidatures(store.token)
    filteredEmploys.value = employs.value
    loading.value = false
})

const handleSearch = () => {
    loading.value = true;
    filteredEmploys.value = employs.value.filter((item:any) => {
        return item.employ?.name && item.employ.name.toLowerCase().includes(search.value.toLowerCase());
    });
    loading.value = false;
};

</script>

<style lang="scss" scoped>
.employ-card {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: $secondary-color;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.btn {
    text-decoration: none;
    margin-left: 20px;
}

.input-job {
    width: 100vw;
}

/* Estilos para la tabla */
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    color: $secondary-color !important;
}

.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}

.table-container {
    overflow-x: auto;
}

.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}
</style>