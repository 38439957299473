<!-- Tienda.vue -->
<template>
    <DefaultLayout>
      <div class="container" 
      style="background-image: url('/img/photos/social_front2.png');
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: 32%, 32%;" >
       
        <h1>Estado de mi cuenta ProDev</h1>
        <h3>Gracias por formar parte de nuestra comunidad,</h3>
          <h3 class="top">¡Eleva tu formación al próximo nivel!</h3>
        <div class="cards">
        <div class="card">
          <h3>Mi plan de suscripción</h3>
          <h5>Estado:</h5>
          <p>{{data.status}}</p>
          <h5>Tipo de suscripción:</h5>
          <p v-if="data.plan_id == 'P-96L394980W065412PMXOHYLI'">Mensual</p>
          <p v-else>Anual</p>
          <h5>Fecha de inicio:</h5>
         <p v-if="!loading">{{data.create_time?.slice(0,10)}}</p> 
          <h5 v-if="data.status == 'ACTIVE'">Próximo pago:</h5>
            <div  v-if="data.status == 'ACTIVE'">
            <p v-if="data.plan_id == 'P-96L394980W065412PMXOHYLI'">{{nextPayment}} (4,99€)</p>
            <p v-else>{{nextPayment}} (29,90€)</p>
            </div>
           <button class="close" v-if="data.status == 'ACTIVE'" @click="cancelSub"><p class="text-close">Cancelar Suscripción</p></button>
           <button class="info" v-else><p class="text-close"><a href="/prodev/registro">Activar Suscripción</a></p></button>
        </div>
        <p style="margin-top: 30px;">Tu apoyo ayuda a cubrir los costos asociados con el desarrollo y mantenimiento de recursos de aprendizaje de alta calidad, programas de apoyo, y una infraestructura sólida para facilitar el crecimiento y éxito de todos los miembros.</p>
      </div>

      </div>
    </DefaultLayout>
  </template>
  
  <script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import PaypPalService from '@/services/PayPalService'
import { onMounted, ref,Ref } from 'vue';
import { useAuth } from '@/store/useAuth';
import StatusI from '@/interfaces/StatusPaypal'

const service = new PaypPalService()
const data:Ref<StatusI> = ref({})
const store = useAuth()
const loading = ref(true)
const nextPayment = ref('')

onMounted(async() => {
  loading.value = true
  if(!store.token) {
    return
  }
  data.value = await service.checkSub(store.token)
  if(data.value.plan_id == 'P-96L394980W065412PMXOHYLI') {
    // mensual
    let start_year = data.value.create_time?.slice(0,4)
    let start_month = data.value.create_time?.slice(5,7)
    let start_day = data.value.create_time?.slice(8,10)

    if(start_month == undefined) {
      return
    }
    if(start_year == undefined) {
      return
    }
    let next_month = parseInt(start_month) +1;
    if(next_month >=13) {
      start_month = '01'
      let next_year = parseInt(start_year) +1
      start_year = next_year.toString()
    } else {
      start_month = next_month.toString()
    }

    nextPayment.value = start_year + '-' + start_month + '-' + start_day
    
  } else {
    // anual
    let start_year = data.value.create_time?.slice(0,4)
    let start_month = data.value.create_time?.slice(5,7)
    let start_day = data.value.create_time?.slice(8,10)
    if(start_year == undefined) {
      return
    }
    let next_year = parseInt(start_year) +1
    start_year = next_year.toString()

    nextPayment.value = start_year + '-' + start_month + '-' + start_day

  }
  loading.value = false
})


const cancelSub = async () => {
  if(!store.token) {
    return;
  }
  var reason = prompt("Lamentamos que te vayas :( . Por favor indícanos el motivo de tu cancelación. Este paso es irreversible.");
  if (reason != null) {
      const response = await service.cancelSub(store.token, reason)
      window.location.href = "/estado-cuenta-prodev"
  }
}

  </script>
  
  <style scoped lang="scss">

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
  }
  .top{
    color: $secondary-color;
  }
  .info{
    margin-top: 20px;
    padding: 12px;
    color: white;
    background-color: $third-color;
    border: none;
    border-radius: 8px;
  }
  a{
    color: white;
  }
  .close{
    margin-top: 20px;
    padding: 12px;
    color: white;
    background-color: red;
    border: none;
    border-radius: 8px;
  }
  .text-close{
    color: white;
    font-weight: bold;
  }
  .close:hover{
    cursor: pointer;
    scale: 1.02;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  .info:hover{
    cursor: pointer;
    scale: 1.02;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
 h1{
  margin-bottom: 15px;
 }

 .separator{
  width: 15px;
}
.cards{
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}
.card {
  margin-top: 20px;
  height: 380px;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
}


.card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.card h4,
.card h5 {
  padding: 10px;
  margin: 0;
}

.card h4 {
  font-size: 18px;
  color: $secondary-color;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}


.card h5 {
  font-size: 16px;
  color: $secondary-color;
}

h5:hover {
  cursor: pointer;
}
@media screen and (max-width:600px){
  .cards{
    flex-direction: column;
    width: 100%;
  }
  .container {
    background-image: none!important; 
  }
 

}
  </style>
  