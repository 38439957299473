<template>
    <div class="animate">
    <h4>Acceder</h4>
    <form> 
        <div class="form-group">
            <label class="label-space">Email</label>
            <input type="text" placeholder="ejemplo@ademass.com" required v-model="email" />
        </div>
        <div class="form-group">
            <label class="label-space">Contraseña</label>
            <input type="password" required v-model="password" />
        </div>
        <input type="submit" value="Entrar" class="go-btn" @click.prevent="handleLogin" />
        <p class="feedback">{{ feedback }}</p>
        <div class="spacer" v-if="feedback"/>
    </form>
</div> 
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useAuth } from '@/store/useAuth'
import router from '@/router'

const store = useAuth()
const email = ref('')
const password = ref('')
const feedback = ref('')

const handleLogin = async () => {
    feedback.value = "Validando credenciales..."
    const success = await store.login(email.value, password.value)
    if(success) {
        feedback.value = "Cargando tu panel de estudios..."
        router.push({name: 'home'})
        return
    }

    feedback.value = store.message
}

</script>

<style lang="scss" scoped>
h4 {
    margin-bottom: 10px;
}
.go-btn {
    color: white;
    font-family: $title-font;
}
</style>