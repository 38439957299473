<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>
            <div class="title">
                <h1>{{ roadmap.title }} </h1>
                <FollowRoadmapWidget :roadmapId="roadmap.id" class="widget" v-if="windowWidth > 950"></FollowRoadmapWidget>
            </div> 
            <div class="spacer"></div>
            <FollowRoadmapWidget :roadmapId="roadmap.id" class="widget" v-if="windowWidth <= 950" style="margin-left:0"></FollowRoadmapWidget>
            <div class="spacer" v-if="windowWidth <= 950"></div>
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Filtrar cursos."
                    @keyup="handleSearch" />
            </form>
            <div class="bar">
            <RoadmapProgressbar :roadmapId="roadmap.id" />
        </div>
            <div class="spacer" />
            <div v-if="loading">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
            </div>
            <component :is="layout" :content="filteredCourses" showOrder="true" v-else>
            </component>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import RoadmapInterface from '@/interfaces/RoadmapInterface'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import RoadmapService from '@/services/RoadmapService'
import CourseService from '@/services/CourseService'

import { onMounted, ref, Ref, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import RoadmapProgressbar from '@/components/widgets/RoadmapProgressbar.vue'
import FollowRoadmapWidget from '@/components/widgets/FollowRoadmapWidget.vue'
import useWindowWidth from '@/composables/useWindowWidth'

const { windowWidth } = useWindowWidth()

const router = useRoute()
const roadmapService = new RoadmapService()
const courseService = new CourseService()
const loading = ref(true)

const courses:Ref<Array<RoadmapInterface>> = ref([])
const filteredCourses:Ref<Array<RoadmapInterface>>  = ref([])

const roadmap: Ref<RoadmapInterface> = ref({
    id: 0,
    title: 'cargando...',
    description: 'cargando...',
    image_url: 'cargando...',
    created_at: 'cargando...',
    updated_at: 'cargando...'
})
const roadmap_id = ref(router.params.id)

const CourseCardLayout = defineAsyncComponent(() => import('@/layouts/CourseCardLayout.vue'))
const CourseTableLayout = defineAsyncComponent(() => import('@/layouts/CourseTableLayout.vue'))
const CourseListLayout = defineAsyncComponent(() => import('@/layouts/CourseListLayout.vue'))
const search: Ref<string> = ref('')
const layout = ref(CourseCardLayout)

const handleComponent = (cmp: any) => layout.value = cmp

onMounted(async () => {
    loading.value = true
    roadmap.value = await roadmapService.GetRoadmap(roadmap_id.value)
    courses.value = await courseService.GetCourseFromRoadmap(roadmap_id.value)
    filteredCourses.value = courses.value
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filteredCourses.value = courses.value.filter(item => item.name?.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}

</script>

<style lang="scss" scoped>
.title {
    display: flex;
    align-items: center;
}
.widget {
    width: fit-content;
    padding-right: 15px !important;
    margin-right: 0;
    margin-left: 10px;
    overflow-y: hidden;
}
.bar {
    width: 90%;
}
.img {
    border-radius: 10px;
}

.config {
    display: flex;
    align-items: center;
}
.icon {
    margin: 25px;
}
.search-form {
    display: flex;
    align-items: center;
    background-color: $primary-color;
    border-radius: 5px;
    border: 0;
    width: 100%;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    height: 37px;
    overflow-y: hidden;
}
.aux-btns {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.aux-btn {
    height: 37px;
    background-color: $third-color;
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
}
.last {
    border-radius: 0 5px 5px 0;
}

.divider {
    background-color: $secondary-color;
    width: 100%;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.container {
    width: 100%;
}

@media(max-width:950px) {
    .bar {
        width: 100%;
    }
    .search-form {
        width: 100%;
    }
}
</style>