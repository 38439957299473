<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>
        <div class="animate wrapper">
            <div v-if="showAdvice" class="animate modal">
                <div class="modal-inner">
                    <div style="float: right; cursor: pointer;" @click="showAdvice = false"><v-icon name="ri-close-circle-fill" fill="lightcoral"></v-icon></div>
                    <h3>Antes de responder...</h3>
                    <img v-if="windowWidth >= 1000" src="../../public/img/lms/frontend.jpg" style="width: 100%;"/>
                    <p><b style="font-size: 14px;">Se amable y constructivo</b><br /><br />Ademass es una comunidad de desarrolladores sana y educada. Muchos compañeros están empezando y tu experiencia seguro que les será de gran ayuda. ¡Mantengamos un buen ambiente!</p>
                    <RouterLink :to="{name: 'my-answer', params: {question_id:question?.id}}" style="text-decoration: none;">
                        <button class="new-question">Escribir Respuesta</button>
                    </RouterLink>
                </div>
            </div>
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <RouterLink :to="{name: 'forum-general-list', params: {topic_id: topic_id, topic_name: topic_name}}" style="text-decoration: none;">
                <button class="new-question"><v-icon name="bi-arrow-left-circle-fill"
                    style="margin-right: 10px;"></v-icon>Volver Atrás</button>
                </RouterLink>
                <h3 style="color: #57afc4;">{{ topic_name }}</h3>
                    <button v-if="question?.is_solved == '0'" class="new-question" @click="showAdvice = true">Escribir Respuesta <v-icon name="io-send"
                    style="margin-left: 10px;"></v-icon></button>
                     <p v-else-if="question?.is_inactive == '0'"><v-icon name="bi-check-circle-fill" fill="#BECE49"></v-icon>Esta pregunta ha sido marcada como solucionada por su autor</p>
                    <p v-else><v-icon name="bi-check-circle-fill" fill="#BECE49"></v-icon>Esta pregunta ha sido marcada como solucionada por inactividad.</p>
         
                </div>
            <div class="question">
                <div class="user">
                    <div class="profile">
                        <img v-if="question?.user.profile_picture_uri" :src="question.user.profile_picture_uri" />
                        <span v-else>{{ question?.user.name.substring(0, 2) }}</span>
                    </div>
                    <RouterLink :to="{ name: 'social-profile', params: { id: question?.user.id } }"
                        style="text-decoration: none;">
                        <p>{{ question?.user.name }}</p>
                        <div class="reputation"><ReputationCardUsers :user_id="question?.user.id" /></div>
                    </RouterLink>
                </div>
                <div class="question-content">
                    <h1>{{ question?.name }}</h1>
                    <div v-html="question?.description" class="description"></div>
                    <div class="spacer"></div>
                    <h3 v-if="question?.try">Para solucionarlo, {{ question.user.name }} ha intentado...</h3>
                    <div v-if="question?.try" class="spacer"></div>
                    <div v-if="question?.try" v-html="question.try" class="description"></div>
                    <div v-if="question?.repository" class="spacer"></div>
                    <h3 v-if="question?.repository">{{ question.user.name }} nos deja el siguiente repositorio para encontrar la solución</h3>
                    <div v-if="question?.repository" class="repository">
                        <a :href="question.repository" target="_blank">{{ question.repository }}</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- GOLDEN ANSWER-->
        <div class="animate wrapper" v-if="question?.is_solved == '1' && question.is_inactive == '0'">
            <div class="question">
                <div class="user">
                    <div class="profile">
                        <img v-if="goldenAnswer?.user.profile_picture_uri" :src="goldenAnswer.user.profile_picture_uri" />
                        <span v-else>{{ goldenAnswer?.user.name.substring(0, 2) }}</span>
                    </div>
                    <RouterLink :to="{ name: 'social-profile', params: { id: goldenAnswer?.user.id } }"
                        style="text-decoration: none;">
                        <p>{{ goldenAnswer?.user.name }}</p>
                        <div class="reputation"><ReputationCardUsers :user_id="goldenAnswer?.user.id" /></div>
                    </RouterLink>
                    <h3 style="color: #57afc4; font-size: 50px;">{{likeDislike(goldenAnswer?.likes)}}</h3>
                    <div class="likes" style="overflow: hidden;">
                        <v-icon v-if="checkIfIsLiked(goldenAnswer?.likes)" name="bi-hand-thumbs-up-fill" scale="2" animation="wrench" hover="true" fill="#57AFC4" style="cursor: pointer;" @click="undoLike(goldenAnswer?.id)"></v-icon>
                        <v-icon v-else name="bi-hand-thumbs-up-fill" scale="2" animation="wrench" hover="true" style="cursor: pointer;" @click="doLike(goldenAnswer.id)"></v-icon>

                        <v-icon  v-if="checkIfIsDisliked(goldenAnswer.likes)" name="bi-hand-thumbs-down-fill" scale="2" animation="wrench" hover="true" fill="#57AFC4" style="cursor: pointer;" @click="undoDislike(goldenAnswer?.id)"></v-icon>
                        <v-icon v-else name="bi-hand-thumbs-down-fill" scale="2" animation="wrench" hover="true" style="cursor: pointer;" @click="doDislike(goldenAnswer.id)"></v-icon>
                    </div>
                </div>
                <div class="question-content">
                    <div class="correct-answer"><v-icon name="bi-award-fill"></v-icon>Esta es la respuesta correcta según el autor de la pregunta</div>
                    <div v-html="goldenAnswer?.answer" class="description"></div>
                    <div class="spacer"></div>
                </div>
            </div>
        </div>

        <!-- Answers -->
        <div class="animate wrapper" v-for="(answer, index) in question?.answers" :key="answer.id">
            <div class="question">
                <div class="user">
                    <div class="profile">
                        <img v-if="answer.user.profile_picture_uri" :src="answer.user.profile_picture_uri" />
                        <span v-else>{{ answer.user.name.substring(0, 2) }}</span>
                    </div>
                    <RouterLink :to="{ name: 'social-profile', params: { id: answer.user.id } }"
                        style="text-decoration: none;">
                        <p>{{ answer.user.name }}</p>
                        <div class="reputation"><ReputationCardUsers :user_id="answer.user.id" /></div>
                    </RouterLink>
                    <h3 style="color: #57afc4; font-size: 50px;">{{likeDislike(answer.likes)}}</h3>
                    <div class="likes" style="overflow: hidden;">
                        <v-icon v-if="checkIfIsLiked(answer.likes)" name="bi-hand-thumbs-up-fill" scale="2" animation="wrench" hover="true" fill="#57AFC4" style="cursor: pointer;" @click="undoLike(answer.id)"></v-icon>
                        <v-icon v-else name="bi-hand-thumbs-up-fill" scale="2" animation="wrench" hover="true" style="cursor: pointer;" @click="doLike(answer.id)"></v-icon>

                        <v-icon  v-if="checkIfIsDisliked(answer.likes)" name="bi-hand-thumbs-down-fill" scale="2" animation="wrench" hover="true" fill="#57AFC4" style="cursor: pointer;" @click="undoDislike(answer.id)"></v-icon>
                        <v-icon v-else name="bi-hand-thumbs-down-fill" scale="2" animation="wrench" hover="true" style="cursor: pointer;" @click="doDislike(answer.id)"></v-icon>
                    </div>
                    <div v-if="(question?.is_solved == '0') && (question?.user_id == myUser?.id)" class="mark-as-solution" @click="markAsSolution(answer.id)">
                        <v-icon name="bi-award-fill"></v-icon>
                        Marcar como solución
                    </div>
                </div>
                <div class="question-content">
                    <div v-if="index == 0" class="most-voted"><v-icon name="gi-three-friends"></v-icon>Respuesta mejor valorada por la comunidad</div>
                    <div v-html="answer.answer" class="description"></div>
                    <div class="spacer"></div>
                </div>
            </div>
        </div>
    </div>
    </DefaultLayout>
</template>

<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { ref, onMounted } from 'vue'
import ForumService from '@/services/ForumService';
import { useRoute } from 'vue-router';
import useWindowWidth from '@/composables/useWindowWidth';
import { useAuth } from '@/store/useAuth';
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import ReputationCardUsers from '@/components/widgets/ReputationCardUsers.vue';
import {QuestionI, UserI} from '@/interfaces/ForumWriteInterface'

const toast = useToast()
const loading = ref(true)
const forumService = new ForumService()
const question = ref<QuestionI | null>(null)
const route = useRoute()
const topic_id = route.params.topic_id
const topic_name = route.params.topic_name
const showAdvice = ref(false)
const {windowWidth} = useWindowWidth()
const store = useAuth()
const myUser = ref<UserI | null>(null);
const goldenAnswer = ref<any>(null)

onMounted(async () => {
    loading.value = true
    if(store.token) {
        const rawResponse = await fetch('https://gogodev.net/gogolearn/api/my-user', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${store.token}`
            }
        })
        myUser.value = await rawResponse.json()
    }
  
    await updateInfo()
    loading.value = false
})

const updateInfo = async () => {
    question.value = await forumService.GetQuestionWithAnswers(route.params.question_id)
    goldenAnswer.value = question.value?.answers.find( (item:any) => item.is_correct == '1');
    question.value?.answers.sort((a:any, b:any) => likeDislikeNumber(b.likes) - likeDislikeNumber(a.likes));
}

const markAsSolution = async (answer_id:string) => {
    if(!store.token) { 
        toast.showToast("Debes registrarte o acceder para realizar esta acción.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return 
    }
    const answer = await forumService.MarkAsCorrect(store.token, answer_id)

    if(answer.success) {
        toast.showToast(answer.message, Toast.SUCCESS)
        setTimeout(function () { toast.hideToast() }, 4000);
        await updateInfo()
    } else {
        toast.showToast(answer.message, Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
    }
}

const likeDislikeNumber = (_likes:any):number => {
    let likes = 0;
    _likes.forEach((element:any) => {
        if(element.pivot.liked == '1') {
            likes++;
        } else {
            likes--;
        }
    });
    return likes;
}

const likeDislike = (_likes:any):string => {
    let likes = 0;
    _likes.forEach((element:any) => {
        if(element.pivot.liked == '1') {
            likes++;
        } else {
            likes--;
        }
    });
    return likes.toLocaleString();
}

const doLike = async (id:string | null) => {
    if(!store.token) {
        toast.showToast("Necesitas registrarte / loguearte para dar like.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    const response = await forumService.DoLike(store.token, id)
    if(response != true) {
        toast.showToast("Uups... Algo salió mal", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }

    toast.showToast("Has dado me gusta a la respuesta", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);

    await updateInfo()
}

const doDislike = async (id:string) => {
    if(!store.token) {
        toast.showToast("Necesitas registrarte / loguearte para dar dislike.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    const response = await forumService.DoDislike(store.token, id)
    if(response != true) {
        toast.showToast("Uups... Algo salió mal", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }

    toast.showToast("Has dado NO me gusta a la respuesta", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);

    await updateInfo()
}

const undoLike = async (id:any) => {
    if(!store.token) {
        toast.showToast("Necesitas registrarte / loguearte para hacer esta acción.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    const response = await forumService.UndoLike(store.token, id)
    if(response != true) {
        toast.showToast("Uups... Algo salió mal", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }

    toast.showToast("Ha dejado de gustarte la respuesta", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);

    await updateInfo()
}

const undoDislike = async (id:any) => {
    if(!store.token) {
        toast.showToast("Necesitas registrarte / loguearte para hacer esta acción.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    const response = await forumService.UndoDislike(store.token, id)
    if(response != true) {
        toast.showToast("Uups... Algo salió mal", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }

    toast.showToast("Ha dejado de NO gustarte la respuesta", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);

    await updateInfo()
}

const checkIfIsLiked = (likes:any):boolean => {
    if(!store.token) {
        return false
    }
    return likes.some((item:any) => item.id == myUser.value?.id && item.pivot.liked == '1' );
}

const checkIfIsDisliked = (likes:any):boolean => {
    if(!store.token) {
        return false
    }
    return likes.some((item:any) => item.id == myUser.value?.id && item.pivot.liked == '0' );
}

</script>

<style lang="scss" scoped>
.reputation {
    margin-top: 10px;
}
.mark-as-solution {
    width: 100%;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: orange;
    color: white;
    cursor: pointer;
    font-size: 14px;
    padding: 25px;
    max-width: 120px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
        fill: white;
        scale: 2;
        margin-bottom: 15px;
    }

}

.mark-as-solution:hover {
    background-color: $third-color;
}
.most-voted {
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    background-color: $third-color;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
    color: white;
    margin-bottom: 20px;
    svg {
        fill: white;
        margin-right: 25px;
        scale: 2;
    }
}

.correct-answer {
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    background-color: orange;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
    color: white;
    margin-bottom: 20px;
    svg {
        fill: white;
        margin-right: 25px;
        scale: 2;
    }
}
.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;
    h3 {
        margin-bottom: 15px;
    }
    p {
        font-size: 12px;
    }
}
.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 5px;
    margin-top: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.new-question:hover {
    background-color: $secondary-color;
    color: white;
    svg {
        fill: white;
    }
}
.wrapper {
    width: 100%;
    padding: 20px;
}

.question {
    width: 100%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    padding: 20px;
    display: flex;
    border-radius: 5px;

    .user {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-right: 3px solid $primary-color;
        padding-right: 20px;
        width: 160px;

        p {
            font-size: 12px;
            text-align: center;
        }

        .profile {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 3px solid $secondary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            span {
                color: $secondary-color;
                font-weight: bold;
                font-size: 18px;
            }
        }
    }

    .question-content {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        width: 85%;

        h1 {
            margin-bottom: 15px;
        }

        .description {
            pre {
                padding: 10px;
                border-radius: 5px;
                background-color: #333;
                color: white;
            }
        }
        .repository {
            margin-top: 20px;
            padding: 20px;
            border-radius: 10px;
            background-color: $secondary-color;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                color: white;
                text-decoration: none;
            }
        }
    }

    .question-actions {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 20px;
        margin-right: -50px !important;
        border: 2px solid $primary-color;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .likes {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}
</style>