<template>
    <DefaultLayout>
        <div v-if="sending" class="animate modal">
            <div v-if="!result">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
            </div>
            <div v-if="result.status">
                <h1 style="color:white;"><v-icon name="hi-emoji-happy" scale="5" fill="#fff"></v-icon> ¡Has superado la prueba!</h1>
                <p style="color:white;">Tu diploma estará disponible en unos minutos desde la sección Mis Diplomas.</p>
                <div class="answer"><h3>Total preguntas: {{ result.total_questions }}</h3></div>
                <div class="answer"><h3>Respuestas correctas: {{ result.good_answers }}</h3></div>
                <RouterLink :to="{name: 'my-diplomas'}" class="button mb5">Mis diplomas</RouterLink>
                <RouterLink :to="{name: 'home'}" class="button">Volver a inicio</RouterLink>
            </div>
            <div v-else>
                <h1 style="color:white;"><v-icon name="ri-emotion-unhappy-line" scale="5" fill="#fff"></v-icon> No has superado el test</h1>
                <div class="answer"><h3>Total preguntas: {{ result.total_questions }}</h3></div>
                <div class="answer"><h3>Respuestas correctas: {{ result.good_answers }}</h3></div>
                <RouterLink :to="{name: 'home'}" class="button">Aceptar</RouterLink>
            </div>
        </div>
    <div v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
    </div>
    <div class="container" v-else>
        <h1>{{ evaluation.name }}</h1>
        <div class="spacer"></div>
        <div v-for="(question, index) in questions" :key="question.id" class="question">
            <p class="question-title">{{index+1}}. {{ question.text }}</p>
            <div class="spacer"></div>
            <div class="answer" @click="handleAnswer(question.id, 1)">
                a) {{ question.answer_1 }} <span v-if="question.selected == 1"><v-icon name="bi-check-square-fill" fill="#57afc4"></v-icon></span>
            </div>
            <div class="answer" @click="handleAnswer(question.id, 2)">
                b) {{ question.answer_2 }} <span v-if="question.selected == 2"><v-icon name="bi-check-square-fill" fill="#57afc4"></v-icon></span>
            </div>
            <div class="answer" @click="handleAnswer(question.id, 3)">
                c) {{ question.answer_3 }} <span v-if="question.selected == 3"><v-icon name="bi-check-square-fill" fill="#57afc4"></v-icon></span>
            </div>
            <div class="answer" @click="handleAnswer(question.id, 4)">
                d) {{ question.answer_4 }} <span v-if="question.selected == 4"><v-icon name="bi-check-square-fill" fill="#57afc4"></v-icon></span>
            </div>
        </div>
        <button @click="handleSend" style="color:white;">Entregar respuestas y finalizar evaluación.</button>
    </div>
</DefaultLayout>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import EvaluationService from '@/services/EvaluationService'
import { useAuth } from '@/store/useAuth'
import { useRoute } from 'vue-router'
import router from '@/router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import QuestionInterface from '@/interfaces/QuestionInterface'
const evaluationService = new EvaluationService()
const store = useAuth()
const loading = ref(true)
const route = useRoute()
const id = route.params.id
const questions = ref()
const evaluation = ref()
const sending = ref(false)
const result = ref()

onMounted(async () => {
    loading.value = true
    if (!store.token) {
        router.push({ name: 'home' })
        return
    }
    const tries = await evaluationService.getUserTries(store.token, id)
    evaluation.value = await evaluationService.getEvaluationInfo(id)
    if (evaluation.value.max_tries <= tries) {
        router.push({ name: 'home' })
        return
    }
    questions.value = await evaluationService.generateEvaluation(store.token, id)
    loading.value = false
})

const handleAnswer = (question:number, answer:number) => {
    questions.value.forEach((item:any) => {
        if(item.id == question) {
            item.selected = answer
        }
    })
}

const handleSend = async () => {
    sending.value = true
    if (!store.token) {
        router.push({ name: 'home' })
        return
    }
    const answers:Array<QuestionInterface> = []
    questions.value.forEach((element:any) => {
        const item:QuestionInterface = {
            id: element.id,
            evaluation_id: element.evaluation_id,
            answer: element.selected
        }
        answers.push(item)
    });
    const response = await evaluationService.checkEvaluationResult(store.token, id, questions.value.length, answers)
    result.value = response
}
</script>

<style lang="scss" scoped>
.mb5 {
    margin-bottom: 5px;
}

.question-title {
    font-family: $title-font;
    color: #333 !important;
}
.button {
    background-color: $third-color;
    color: white;
    padding: 15px;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.button:hover {
    background-color: #333;
}
.container {
    min-width: 70vw;;
}
.question {
    border-radius: 5px;
    background-color: white;
    padding: 15px;
    margin: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
}
.answer {
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    background-color: $primary-color;
    cursor: pointer;
    //font-family: $text-font;
    color: #333;
}
.answer:hover {
    background-color: $third-color;
    color: white;
}

button {
    border: 0;
    border-radius: 5px;
    padding: 15px;
    width: 100%;
    text-align: center;
    background-color: lightcoral;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
}

button:hover {
    background-color: $secondary-color;
}
</style>