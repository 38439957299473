<template>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>
            <div class="container">
                <div class="table-container">
                <table class="modern-table">
                        <thead>
                            <tr>
                                <th>Alumno</th>
                                <th>País / Ciudad</th>
                                <th>Reputación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="candidate in candidates" :key="candidate.id">
                                <td>
                                    <div >
                                        
                                        <div v-if="candidate.user?.name != null">
                                            <h6>{{ candidate.user?.name }}</h6>
                                            <p>{{ candidate.user?.email }}</p>
                                        </div>
                                        <div v-else>
                                            <p>Sin rellenar</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="candidate.user?.professional_profile?.location?.country?.flag_svg != null && candidate.user?.professional_profile?.location?.country?.name != null && candidate.user?.professional_profile?.location?.name != null">
                                        <p>{{ candidate.user?.professional_profile?.location?.country?.name }}</p>
                                        <p >{{ candidate.user?.professional_profile?.location?.name }}</p>
                                    </div>
                                    <div v-else>
                                        <p>Sin rellenar</p>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="candidate.user?.reputation != null">
                                        <p>{{ candidate.user?.reputation }} pts</p>
                                    </div>
                                    <div v-else>
                                        <p class="_null">Sin rellenar</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, defineProps } from 'vue';
import JobPracticumService from '../../services/JobPracticumService'
import { useAuth } from '../../store/useAuth'

const loading = ref(true);
const candidates = ref([])
const service = new JobPracticumService()
const props = defineProps(["jobId"])
const store = useAuth()

onMounted(async () => {
    loading.value = true
    candidates.value = await service.getCandidate(store.token, props.jobId)

    loading.value = false
})


</script>

<style lang="scss" scoped>
.go {
    margin-top: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.separator {
    width: 10px;
}
td{
    text-align: center
}

/* Estilos para la tabla */
.modern-table {
    width: 100%;
    border-collapse: collapse;
}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    color: $secondary-color !important;
}

.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}

.table-container {
    overflow-x: auto;
}

.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}


</style>
