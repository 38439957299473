<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>
            <h1>CodeBites Guardados</h1>
                <div class="spacer"></div>
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar por título." @keyup="handleSearch"
                    class="input-job" />
            </form>
            <div class="spacer" />
            <div class="codebites-wrapper">
                <CodeBite v-for="item in filtered" :key="item.id" :codebite="item.codebite" :user="item.user" @refresh="refresh" />
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ChannelService from '@/services/ChannelService'
import { onMounted, ref,Ref } from 'vue';
import { useAuth } from '@/store/useAuth';
import CodeBite from '@/components/cards/CodeBite.vue'
import ProfileService from '@/services/ProfileService'
import CodeI from '@/interfaces/CodeBiteInterface';

const service = new ChannelService()
const items:Ref<CodeI[]> = ref([])
const filtered:Ref<CodeI[]> = ref([])
const loading = ref(true)
const search = ref('')
const store = useAuth()
const profileService = new ProfileService()
const user = ref()

onMounted(async () => {
    if(!store.token) { return }
    loading.value = true
    items.value = await service.GetSavedCodeBites(store.token)
    user.value = await profileService.MyUser(store.token)
    filtered.value = items.value
    loading.value = false
})

const refresh = async () => {
    if(!store.token) { return }
    items.value = await service.GetSavedCodeBites(store.token)
    filtered.value = items.value
}

const handleSearch = () => {
    loading.value = true
    filtered.value = items.value.filter(item => item.codebite.title.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}
</script>

<style lang="scss" scoped>
.codebites-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.employ-card {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: $secondary-color;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.btn {
    text-decoration: none;
    margin-left: 20px;
}

.input-job {
    width: 100vw;
}

/* Estilos para la tabla */
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
th{
    color: $secondary-color!important;
}
.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}
.table-container {
  overflow-x: auto;
}
.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}
</style>