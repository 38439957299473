import StudyInterface from '@/interfaces/StudyInterface'

export default class StudyService {
    private base_url = 'https://gogodev.net/gogolearn/api/study'

    public async GetAllStudies(token: string) {
        const uri = this.base_url
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetStudyFromUser(token: string, id: number) {
        const uri = `${this.base_url}/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async DeleteJobFromUser(token: string, id: number) {
        const uri = `${this.base_url}/delete/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CreateStudy(token: string, study: StudyInterface) {
        const uri = `${this.base_url}/create`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: study.name,
                description: study.description,
                organism: study.organism,
                is_official: false,
                location_id: study.location_id
            })
        })
        const response = await rawResponse.json()
        return response
    }

    public async UpdateStudyToUser(token: string, study: StudyInterface, id: number) {
        const uri = `${this.base_url}/update/${id}`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: study.name,
                description: study.description,
                organism: study.organism,
                is_official: false,
                location_id: study.location_id
            })
        })
        const response = await rawResponse.json()
        return response
    }
}