export default class NotificationService {
    private base_url = 'https://gogodev.net/gogolearn/api/notification'


    public async CountUnreadNotification(token: string) {
        const uri = `${this.base_url}/count-unread`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async MarkNotificationAsRead(token: string, notification_id: string) {
        const uri = `${this.base_url}/read/${notification_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async MarkNotificationAsUnread(token: string, notification_id: string) {
        const uri = `${this.base_url}/unread/${notification_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async ReadAllNotes(token: string) {
        const uri = `${this.base_url}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

}