<template>
    <DefaultLayout>
        <div class="animate modal modal-pdf" v-if="sending">
            <v-icon name="md-downloading" scale="5" animation="flash" fill="#fff"></v-icon>
            <h1 style="color: white;">Estamos generando tus apuntes.</h1> 
            <h3 style="color: white;">No cierres esta ventana hasta que se inicie la descarga.</h3>
            <button @click="sending = false" style="color:white;">Cancelar</button>
        </div>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div class="container" v-else>
            <h1 style="margin-bottom: 20px;">Imprimir apuntes en PDF</h1>
            <RouterLink :to="{name: 'my-notes'}" class="print">Volver a mis apuntes</RouterLink>
             <div class="spacer" />
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar." @keyup="handleSearch" />
            </form>
            <div class="spacer"></div>
            <h5>Tienes apuntes tomados en los siguientes cursos:</h5>
            <div class="spacer"></div>
            <div v-if="loading">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
            </div>
            <div class="container-items" v-else>
                <div v-for="course in filteredDiplomas" :key="course.id" class="animate card">
                    <div class="list">
                        <h4 class="clickable" @click="handleGenerate(course.id)">{{ course.name.substring(0, 40) }}</h4>
                    </div>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref, Ref, onMounted } from 'vue'
import DiplomaService from '@/services/DiplomaService'
import { useAuth } from '@/store/useAuth'
import router from '@/router'
import SharedService from '@/services/SharedService'

const loading = ref(true)
const sharedService = new SharedService()
const courses:Ref<any> = ref()
const filteredDiplomas:Ref<any> = ref()
const store = useAuth()
const search: Ref<string> = ref('')
const sending = ref(false)

const handleGenerate = async (courseID: number) => {
    if (!store.token) {
        return;
    }

    sending.value = true;

    try {
        const pdf: Blob | undefined = await sharedService.printNotes(store.token, courseID);

        if (!pdf) {
            console.error("Failed to generate the notes PDF.");
            sending.value = false;
            return;
        }

        // Verificar si msSaveOrOpenBlob está disponible
        const navigatorAny = window.navigator as any;
        if (navigatorAny.msSaveOrOpenBlob) {
            navigatorAny.msSaveOrOpenBlob(pdf);
        } else {
            const objUrl = window.URL.createObjectURL(pdf);
            const link = document.createElement("a");
            link.href = objUrl;
            link.download = "apuntes";
            link.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(objUrl);
            }, 250);
        }
    } catch (error) {
        console.error("An error occurred while generating the PDF:", error);
    } finally {
        sending.value = false;
    }
};

onMounted(async () => {
    if (!store.token) {
        router.push({ name: 'home' })
        return
    }
    loading.value = true
    courses.value = await sharedService.getAllCoursesWithNotes(store.token)
    filteredDiplomas.value = courses.value
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filteredDiplomas.value = courses.value.filter((item:any) => item.name.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}

</script>

<style lang="scss" scoped>
.print {
    margin-top: 70px;
    padding: 10px;
    background-color: $third-color;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    color: white;
    text-decoration: none;
}
.modal-pdf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-items {
    display: flex;
    flex-wrap: wrap;
}
.container {
    min-width: 70vw;
}
* {
    overflow-y: hidden;
}
.hand {
    margin-left: 5px;
    transform: rotate(306deg) !important;
}
.list {
    width: 100%;
    position: relative;
    margin: 10px;
}

.list h3 {
    overflow-y: hidden;
}

.list h3 {
    margin-bottom: 10px;
}

.list h3 span {
    margin-right: 5px;
}

.card {
    width: 340px;
    height: 55px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    //background-color: $secondary-color;
    position: relative;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.follow {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -5px;
    right: 0;
}

.img {
    width: 150px;
    height: 100px;
    padding: 0;
    overflow-y: hidden;

}

button {
    border: 0;
    padding: 15px;
    background-color: lightcoral;
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
}
button:hover{
    background-color: $primary-color;
}
</style>