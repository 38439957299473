<template>
    <DefaultLayout>
        <div class="animate modal modal-diploma" v-if="sending">
            <v-icon name="md-downloading" scale="5" animation="flash" fill="#fff"></v-icon>
            <h1 style="color: white;">Estamos generando tu diploma.</h1> 
            <h3 style="color: white;">No cierres esta ventana hasta que se inicie la descarga.</h3>
            <button @click="sending = false" style="color:white;">Cancelar</button>
        </div>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div class="container" v-else>
            <h1>Mis diplomas</h1>
            <div class="spacer" />
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar." @keyup="handleSearch" />
            </form>
            <div class="spacer" />
            <div v-if="loading">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
            </div>
            <div class="container-items" v-else>
                <div v-for="diploma in filteredDiplomas" :key="diploma.id" class="animate card">
                    <div class="list">
                        <h4 class="clickable" @click="handleGenerate(diploma.id)">{{ diploma.name.substring(0, 40) }}</h4>
                    </div>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref, Ref, onMounted } from 'vue'
import DiplomaService from '@/services/DiplomaService'
import { useAuth } from '@/store/useAuth'
import router from '@/router'
import SharedService from '@/services/SharedService'

const loading = ref(true)
const diplomaService = new DiplomaService()
const sharedService = new SharedService()
const diplomas = ref()
const filteredDiplomas = ref()
const store = useAuth()
const search: Ref<string> = ref('')
const sending = ref(false)

const handleGenerate = async (diplomaID: number) => {
    if (!store.token) {
        return;
    }
    sending.value = true;

    const pdf: Blob | undefined = await sharedService.printDiploma(store.token, diplomaID);
    
    if (!pdf) {
        console.error("Failed to generate the diploma PDF.");
        sending.value = false;
        return;
    }

    if (window.navigator && 'msSaveOrOpenBlob' in window.navigator) {
        (window.navigator as any).msSaveOrOpenBlob(pdf); // Casting to any to bypass TypeScript error
    } else {
        const objUrl = window.URL.createObjectURL(pdf);
        const link = document.createElement("a");
        link.href = objUrl;
        link.download = "diploma";
        link.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
        }, 250);
    }

    sending.value = false;
};


onMounted(async () => {
    if (!store.token) {
        router.push({ name: 'home' })
        return
    }
    loading.value = true
    diplomas.value = await diplomaService.getAllDiplomasFromUser(store.token)
    filteredDiplomas.value = diplomas.value
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filteredDiplomas.value = diplomas.value.filter((item:any) => item.name.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}

</script>

<style lang="scss" scoped>
.modal-diploma {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-items {
    display: flex;
    flex-wrap: wrap;
}
.container {
    min-width: 70vw;
}
* {
    overflow-y: hidden;
}
.hand {
    margin-left: 5px;
    transform: rotate(306deg) !important;
}
.list {
    width: 100%;
    position: relative;
    margin: 10px;
}

.list h3 {
    overflow-y: hidden;
}

.list h3 {
    margin-bottom: 10px;
}

.list h3 span {
    margin-right: 5px;
}

.card {
    width: 340px;
    height: 55px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    //background-color: $secondary-color;
    position: relative;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.follow {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -5px;
    right: 0;
}

.img {
    width: 150px;
    height: 100px;
    padding: 0;
    overflow-y: hidden;

}

button {
    border: 0;
    padding: 15px;
    background-color: lightcoral;
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
}
button:hover{
    background-color: $primary-color;
}
</style>