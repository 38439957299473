<template>
     <div class="no-auth"  style="margin-right: 10px;">
            <button><a href="https://campus-ademass.com/mi-muro">Volver atrás</a></button>
        </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.no-auth {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    margin-right: 0;
}
button {
    padding: 10px;
    background-color: $secondary-color;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-family: $title-font;
}
a{
    text-decoration: none;
    color: white;
}

button:hover {
    background-color: $third-color;
}
</style>