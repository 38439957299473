<template>
    <div v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
    </div>
    <div v-else class="recovery-container">
        <div v-if="!isValid">
            <p>El enlace de recuperación no es válido o ha expirado.</p>
        </div>
        <div v-else>
            <img :src="logo" class="logo" width="352">

            <div class="spacer" />
            <div>
                <div class="animate">
                    <form>
                        <h4>Introduce tu nueva contraseña</h4>

                        <div class="form-group">
                            <label class="label-space">Nueva contraseña</label>
                            <input type="password" required v-model="password" />
                        </div>
                        <div class="form-group">
                            <label class="label-space">Repetir contraseña</label>
                            <input type="password" required v-model="new_password" />
                        </div>
                        <input type="submit" value="Actualizar" class="go-btn" @click.prevent="handleUpdatePassword" />
                        <p class="feedback">{{ feedback }}</p>
                        <div class="spacer" v-if="feedback" />

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script  lang="ts" setup>
import logo from '@/assets/svg/ademass_slogan.svg'
import { onMounted, ref } from 'vue'
import RecoveryService from '@/services/RecoveryService'
import { useRoute } from 'vue-router'
import router from '@/router'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface'

const service = new RecoveryService()
const route = useRoute()
const loading = ref(false)
const token = ref(route.params.id)
const isValid = ref(true)
const password =ref('')
const new_password =ref('')
const feedback = ref('')
const toast = useToast()

onMounted(async () => {
    loading.value = true
    isValid.value = await service.checkIfValid(token.value)
    loading.value = false
})
 
const handleUpdatePassword =async()=>{
    loading.value= true
    if(password.value!= new_password.value){
        feedback.value ="Las contraseñas no coinciden."
        loading.value = false
        return
    }
    if(password.value.length<6){
        feedback.value = "La contraseña debe tener al menos 6 caracteres."
        loading.value = false
        return
    }
      const response = await service.updatePassword(token.value,password.value,new_password.value)
      if(response){
        toast.showToast("Contraseña actualizada correctamente.", Toast.SUCCESS)
        setTimeout(function () { toast.hideToast() }, 4000);
        router.push({name:'auth'})
      }
      else{
        feedback.value="Ocurrió un error, inténtalo de nuevo más tarde."
      }
      loading.value = false
}
</script>

<style lang="scss" scoped>
h4 {
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
}

.auth-box {
    width: 600px;
    min-height: 280px;
    position: relative;
}

.go-btn {
    color: white;
    font-family: $title-font;
}

.recovery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100svh;

}

.no-underscore {
    margin-left: 10px;
    text-decoration: none;
}

.no-underscore:hover {
    color: $title-color;
}</style>