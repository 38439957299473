<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>
            <div class="container">
                <div class="ofered">
                 <div class="buttons-see">
                <h1>Ofertas de Empleo Activas</h1>
                <div class="go">
                <a href="https://ademass-prodev.netlify.app/" target="_blank">
                        <button class="btn-function2">Información ProDev</button>
                    </a>
                    <div class="separator"></div>
                    <a href="/prodev/registro" >
                        <button class="btn-function">Primer Mes Gratis</button>
                    </a>
                </div>
            </div>
                <div class="ofered-body">
                    <h2><a href="/prodev/registro" target='_blank' class="title-ofered">Tendrás que ser usuario ProDev para poder aplicar a más de una oferta de empleo, pulsa aquí para ser usuario ProDev y disfruta de un mes GRATIS.</a></h2>
                </div>
            </div>
            <div class="spacer" />
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar." @keyup="handleSearch"
                    class="input-job" />
            </form>
            <div class="spacer" />
            <div class="table-container">
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Puesto</th>
                            <th>País/Ciudad</th>
                            <th v-show="windowWidth>tableSmall">Modalidad</th>
                            <th v-show="windowWidth>tableSmall">Salario</th>
                            <th>Ver detalles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="employ in filteredEmploys" :key="employ.id">
                            <td> {{ employ.name }} </td>
                            <td> <div v-if="!employ.is_remote">{{ employ?.location?.country?.name }} ({{ employ?.location?.name }})</div> 
                                 <div v-else>Remoto</div></td>

                            
                            <td v-show="employ?.is_remote && windowWidth>tableSmall">Remoto</td>
                            <td v-show="!employ.is_remote && windowWidth>tableSmall">Presencial / Híbrido</td>
                           
                            <td v-show="windowWidth>tableSmall">{{ employ.salary_min_amount?.toLocaleString() }}€ - {{
                                employ.salary_max_amount?.toLocaleString() }}€</td>
                            <td>
                                <RouterLink :to="{ name: 'job-detail', params: { id: employ.id } }"><v-icon
                                        name="md-fileopen-round" class="ver" fill="#57afc4" scale="2"></v-icon></RouterLink>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import EmployService from '@/services/EmployService'
import { onMounted, ref, Ref } from 'vue';
import useWindowWidth from '@/composables/useWindowWidth';
import EmployInterface from '@/interfaces/EmployInterface';

const employService = new EmployService()
const employs:Ref<EmployInterface[]> = ref([])
const filteredEmploys:Ref<EmployInterface[]>  = ref([])
const loading = ref(false)
const search = ref('')
const {windowWidth} = useWindowWidth()
const tableSmall= ref(850);

onMounted(async () => {
    loading.value = true
    employs.value = await employService.ListAllEmploys()
    filteredEmploys.value = employs.value
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filteredEmploys.value = employs.value.filter(item => item.name.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}
</script>

<style lang="scss" scoped>
.ofered {
    display: flex;
    justify-content: space-between;
    min-width: 130px;
}

.ofered-body {
    background-color: $third-color;
    padding: 10px;
    border-radius: 12px;
    min-height: 100px;
    max-width:60% ;
    display: flex;
    align-items:flex-start;
    justify-content: center;
    text-align: center;
    margin-right: 15px;
}
.ofered-body:hover{
    cursor: pointer;
    background-color: $secondary-color;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.title-ofered {
    color: white;
    text-decoration: none;
    border-radius: 8px;
    height: 100%;
}
.go{
    margin-top: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.separator{
    width: 10px;
}
.buttons-see{
    display: flex;
    flex-direction: column;
}
.employ-card {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: $secondary-color;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.title-div{
    width: 100%;
}
a{
    text-decoration: none!important;
}



.btn {
    text-decoration: none;
    margin-left: 20px;
}

.input-job {
    width: 100vw;
}

/* Estilos para la tabla */
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
th{
    color: $secondary-color!important;
}
.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}
.table-container {
  overflow-x: auto;
}
.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}
.btn-function {
    padding: 10px;
    background-color: $third-color;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-family: $title-font;
}

.btn-function:hover {
    background-color: $secondary-color;
}
.btn-function2 {
    padding: 10px;
    background-color:$secondary-color ;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-family: $title-font;
}

.btn-function2:hover {
    background-color:$third-color ;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    width: 100%;

}
@media screen and (max-width:890px){
    .ofered{
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .ofered-body{
      margin-top: 20px;
      min-width: 90%;
      align-items: center;
      justify-content: center;
      padding: 15px;
    }
    .title-ofered{
        width: 100%;
    }
    .header{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btn-function{
        margin-top: 15px;
        min-width: 200px;
    } .btn-function2{
        min-width: 200px!important;
    }
    .go{
        flex-direction: column;
    }
  
}
</style>