export default class BookService{
    private base_url = 'https://gogodev.net/gogolearn/api/lms_books'

    public async GetBooks() {
        const uri = `${this.base_url}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetBookDetail(id:string | string[]) {
        const uri = `${this.base_url}/detail/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }
}
