<template>
    <div class="animate widget-container">
        <div class="is-auth" v-if="store.token">
            <RouterLink :to="{ name: 'friend-request' }">
                <div style="display: flex; justify-self: right; overflow: visible;">
                    <div class="notification">
                        <div class="alert-notification" v-if="friendshipRequests.length > 0">{{ friendshipRequests.length }}
                        </div>
                        <v-icon name="fa-user-friends" scale="1.2" style="margin-right: 5px;" />
                    </div>
                </div>
            </RouterLink>
            <div style="display: flex; justify-self: right; overflow: visible;">
                <div class="notification" style="margin-left: 5px;">
                    <div class="alert-notification" v-if="allUnreads > 0">{{ allUnreads }}</div>
                    <v-icon name="ri-message-2-fill" @click="toggleMenu" style="cursor: pointer;" scale="1.2" />
                </div>
            </div>

            <div style="width: 24px; height: 24px;display: flex; justify-self: right; overflow: hidden; margin-left: 15px; margin-right: 0px;">
                    <ProfileImage />
            </div>
            <div style="height: 24px;display: flex; justify-self: right; overflow: hidden; margin-left: 10px; margin-right: 10px;">
            <ReputationCard />
            </div>
            <p style="text-decoration: none !important; text-decoration-color: rgba(0,0,0,0);">{{ store.email }}</p>
            <v-icon name="la-power-off-solid" class="logout-btn" hover="true" animation="wrench" @click="handleLogout" />
        </div>
        <AuthBtn v-else />
    </div>
    <div class="animate context-menu" v-if="showContextMenu">
        <RouterLink :to="{ name: 'messages' }" style="text-decoration: none; font-size: 12px;">Ir a mensajería.</RouterLink>
        <RouterLink :to="{ name: 'friends' }" style="text-decoration: none; font-size: 12px; margin-left: 5px;">Añadir
            amigos.</RouterLink>
        <div class="close" @click="toggleMenu"><v-icon name="ri-close-circle-fill" /></div>
        <form class="search-form">
            <v-icon name="fa-filter"></v-icon>
            <input type="text" placeholder="Buscar amigo..." v-model="search" @keyup="handleSearch" />
        </form>
        <div class="friend-list">
            <div v-for="friend in filteredFriends" :key="friend.id">
                <div class="friend" @click="showConversation(friend.id)">
                    <img class="profile" v-if="friend.profile_picture_uri" :src="friend.profile_picture_uri" width="28" />
                    <div class="profile" v-else>
                        {{ friend.name.substring(0, 2) }}
                    </div>
                    <h5 style="font-size: 12px;">{{ friend.name }}</h5>
                    <div style="position: absolute;">
                        <ConversationNotification :friendId="friend.id" style="position: relative; top: 0;" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useAuth } from '@/store/useAuth'
import router from '@/router'
import AuthBtn from '../buttons/AuthBtn.vue'
import { ref, onMounted, onUnmounted } from 'vue'
import useWindowWidth from '@/composables/useWindowWidth'
import { useConversations } from '@/store/useConversations'
import { Toast } from '@/interfaces/ToastInterface'
import { useToast } from '@/store/useToast'
import FriendshipService from '@/services/FriendshipService'
import ConversationNotification from './ConversationNotification.vue'
import ProfileImage from '@/components/widgets/ProfileImage.vue'
import ReputationCard from './ReputationCard.vue'

const search = ref('')
const store = useAuth()
const showContextMenu = ref(false)
const { windowWidth } = useWindowWidth()
const storeConversations = useConversations()
const toast = useToast()
const friendshipRequests = ref([])
const service = new FriendshipService()
const friends = ref([])
const filteredFriends = ref([])
const allUnreads = ref(0)
const intervalId = window.setInterval(async () => {
    if (!store.token) {
        return
    }
    friendshipRequests.value = await service.listPendingRequests(store.token)
    friends.value = await service.listUserFriends(store.token)
    allUnreads.value = await service.getAllUnreadMessagesSentToYou(store.token)
    filteredFriends.value = friends.value
}, 10000)

onMounted(async () => {
    if (!store.token) {
        return
    }
    friendshipRequests.value = await service.listPendingRequests(store.token)
    friends.value = await service.listUserFriends(store.token)
    allUnreads.value = await service.getAllUnreadMessagesSentToYou(store.token)
    filteredFriends.value = friends.value
})


onUnmounted(() => {
    clearInterval(intervalId);
})

const handleSearch = () => {
    filteredFriends.value = friends.value.filter(item => item.name.toLowerCase().includes(search.value.toLowerCase()))
}


const toggleMenu = () => {
    if (windowWidth.value < 900) {
        return;
    }
    showContextMenu.value = !showContextMenu.value
}

const handleLogout = () => {
    store.logout()
    router.push({ name: 'auth' })
}

const showConversation = async (id: number) => {
    if (!store.token) {
        return
    }
    const success = await storeConversations.addConversation(store.token, id)
    if (!success) {
        toast.showToast("No puedes abrir más pop-ups de conversación simultáneos. Visita la sección de mensajería para acceder a todas las capacidades.", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
    }
    toggleMenu()
}

</script>

<style lang="scss" scoped>
.alert-notification-inside {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: lightcoral;
    top: 0px;
    right: 0;
    z-index: 10000000;
    overflow: visible;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    margin-left: 10px;
}

.alert-notification {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: crimson;
    top: 0px;
    right: 0;
    z-index: 10000000;
    overflow: visible;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.notification {
    position: relative;
}


.close {
    top: -10px;
    float: right;
    cursor: pointer;
    font-size: 12px;
}

.close:hover {
    color: $secondary-color;
}

.profile {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: $primary-color;
    margin-right: 10px;
    border: 2px solid $secondary-color;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: $secondary-color;
    font-size: 12px;
}

.friend {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    color: $text-color;
    border: 1px solid $primary-color;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 2px;
    cursor: pointer;

    h5 {
        color: $secondary-color;
    }
}

.friend:hover {
    background-color: $primary-color;

}

.friend-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0;
}

.search-form {
    display: flex;
    align-items: center;
    background-color: $primary-color;
    border-radius: 5px;
    border: 0;
    width: 100%;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    height: 37px;
    overflow-y: hidden;
}

.context-menu {
    position: fixed;
    top: 50px;
    right: 300px;
    background-color: white;
    z-index: 10;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    border: 2px solid #efefef;
    padding: 20px;
    padding-top: 5px;
}

.widget-container {
    display: flex;
    align-items: center;
    overflow-y: hidden;
}

.is-auth {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    position: relative;
}

.is-auth p {
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
}

.logout-btn {
    margin-right: 10px;
    cursor: pointer;
}

button {
    padding: 10px;
    background-color: $third-color;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: $secondary-color;
}
</style>