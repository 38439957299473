<template>
    <DefaultLayout>
        <ProdevValidator />
    <div class="container">
            <h1>Ejercicios</h1>
              <!--         <div class="activity">
         <a :href="pdf" download="Ejercicios_Respuestas.pdf" class="link-pdf"><v-icon name="md-fileopen-round" class="ver"  fill="#bece49"
                                        scale="3"></v-icon></a>
                                        <p>Descargar Ejercicios en PDF con las Soluciones</p>
                                    </div>--> 
    
            <div class="table-container">
                
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Nº de Ejercicio</th>
                            <th>Enunciado</th>
                            <th>Ver respuestas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="elm in questions" :key="elm?.id">
                            <td>{{ elm?.order }}</td>
                            <td class="question">{{ elm?.title }}</td>
                            <td>
                                <a :href="`/respuesta/${elm?.id}`" target="_blank"><v-icon name="md-fileopen-round" class="ver" fill="#57afc4"
                                        scale="2"></v-icon></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
</DefaultLayout>
  </template>
  
  <script setup lang="ts">
  import {ref,onMounted,Ref} from 'vue'
  import DefaultLayout from '@/layouts/DefaultLayout.vue';
  import ExercisesService from '@/services/ExerciseService';
  import ProdevValidator from '@/components/auth/ProdevValidator.vue';
  import { useRoute } from 'vue-router';
  import ActivityInterface from '@/interfaces/ActivityInterface';
   
  const route = useRoute()
  const loading = ref(false)
  const service = new ExercisesService()
  const pdf = ref('')
  const questions: Ref<ActivityInterface[]> = ref([]);

  onMounted(async()=>{
    loading.value = true;
      questions.value = await service.GetExercisesDetail(route.params.id)
      pdf.value = await service.GetPdf(route.params.id)
      loading.value = false;
  })

  
  </script>
  
  <style lang="scss" scoped>
  h1{
    margin-bottom: 20px;
}
p{
    margin-bottom: 12px;
}
.question{
  white-space: pre-line;
  color: #333;
  overflow-x: auto; /* Habilita el desbordamiento horizontal si es necesario */
}
.container {
    padding: 15px;
}


.modern-table {
    width: 100%;
    border-collapse: collapse;

}
.activity{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    color: $secondary-color !important;
}

.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}

.table-container {
    overflow-x: auto;
}

.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}
  </style>
  