export default class CandidatureService {
    private base_url = 'https://gogodev.net/gogolearn/api/candidature'

    public async AddCandidatureToEmploy(token:string, employ_id:string | undefined) {
        const uri = `${this.base_url}/candidature/add/${employ_id}`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async DeleteCandidatureToEmploy(token:string, candidature_id:number) {
        // TODO
    }

    public async ListAllUserCandidatures(token:string) {
        const uri = `${this.base_url}/candidature/all`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async GetCandidatureStatusInfo(candidature_status_id:number) {
        // TODO
    }
}