<template>
  <DefaultLayout>
    <ProdevValidator />
    <div class="container">
      <h1 class="title">¡Sección de Webinars!</h1>
      <h3>Esta sección está destinada a resolver dudas sobre los Bootcamps</h3>
      <p style="margin-bottom: 15px; width: 60%;margin-top: 0;"> Para mejorar tu formación, es esencial abordar las diferentes dudas que puedan surgir durante el proceso de aprendizaje. Por ello, dos días después de cada Sesión de Bootcamp, estaremos resolviendo las principales inquietudes sobre dicha sesión.</p>
      <div class="video-container">
        <iframe src="https://player.vimeo.com/video/XXXXXXXXX" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="session-cards">
        <div class="session-card" @click="toogleDescription(0)">
          <h2>Sesión 0</h2>
        </div>
        <div class="session-card"  @click="toogleDescription(1)">
          <h2>Sesión 1</h2>
        </div>
        <div class="session-card" @click="toogleDescription(2)">
          <h2>Sesión 2</h2>
        </div>
        <div class="session-card" @click="toogleDescription(3)">
          <h2>Sesión 3</h2>
        </div>
        <div class="session-card" @click="toogleDescription(4)">
          <h2>Sesión 4</h2>
        </div>
        <div class="session-card" @click="toogleDescription(5)">
          <h2>Sesión 5</h2>
        </div>
      </div>

      <div class="descriptions" v-if="description == true">
        <br>
        <h2>{{ titles[selectedSessionIndex] }}</h2>
        <br>
        <p>{{ bodySession[selectedSessionIndex] }}</p>
      </div>
    </div>
  </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ProdevValidator from '@/components/auth/ProdevValidator.vue';
import { ref,Ref } from 'vue';


let selectedSessionIndex = ref(-1);

const toogleDescription = (index: number) => {
  description.value = !description.value;
  selectedSessionIndex.value = index;
};


const titles:Ref<Array<string>> = ref(['Descripción sesión 0', 'Descripción sesión 1','Descripción sesión 2',
  'Descripción sesión 3','Descripción sesión 4' ,'Descripción sesión 5'])

const bodySession:Ref<Array<string>> = ref([
  'Descripción 0',
  'Descripción 1',
  'Descripción 2',
  'Descripción 3',
  'Descripción 4',
  'Descripción 5',

])  
const description = ref(false)



</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}
h2{
  color: $secondary-color;
}
h3{
  color: $secondary-color;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333; /* Color de texto más oscuro */
}
.descriptions{
  width: 100%;
  min-height: 300px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}
.video-container {
  background-color:rgb(64, 58, 58); 
  border-radius: 10px;
  border: 4px solid rgb(64, 58, 58);
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  width: 100%; 
  max-width: 1000px; 
}

iframe {
  width: 100%;
  min-height: 400px;
  border: none; 
  border-radius: 10px;
  margin-bottom: 30px;
}

.session-cards {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  width: 100%;
  margin-top: 20px;
}

.session-card {
  background-color: $secondary-color;
  border-radius: 12px;
  color: white;
  padding: 20px;
  text-align: center;
  max-height: 80px;
}

.session-card h2 {
  color: white;
  margin-top: 0;
}

.session-card:hover {
  transform: scale(1.03);
  cursor: pointer;
  background-color: $third-color;
}
</style>
