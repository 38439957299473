export default class BootCampService{
    private base_url = 'https://gogodev.net/gogolearn/api/lms_bootcamp'

    public async GetBootCamp() {
        const uri = `${this.base_url}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }
    public async GetBootCampDetail(id:string | string[]) {
        const uri = `${this.base_url}/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }
}