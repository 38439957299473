<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>
            <div class="container">
                <h1>Candidatos</h1>
                 <br>
                <h3>Cuando la oferta llegue a 5 candidatos el grupo se cerrará automaticamente y se abrirá uno nuevo</h3>
                <p>Una vez cerrado el grupo, recibirás un Email con el enlace del grupo privado de Discord donde recibiréis las especificaciones del proyecto.</p>
                <br>
                <CandidatesTable  :jobId="jobId"/>
           
        </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import { onMounted, ref, defineProps } from 'vue';
import JobPracticumService from '../services/JobPracticumService'
import { useAuth } from '../store/useAuth'
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useRoute } from 'vue-router'
import CandidatesTable from '@/components/table/CandidatesTable.vue'

const loading = ref(true);
const candidates = ref([])
const service = new JobPracticumService()
const props = defineProps(["jobId"])
const store = useAuth()
const route = useRoute()
const jobId = route.params.id

onMounted(async () => {
    loading.value = true
    candidates.value = await service.getCandidate(store.token, props.jobId)
    loading.value = false
})


</script>

<style lang="scss" scoped>
.buttons{
    width: 100%;
    gap: 15px;
    display: flex;
    padding: 8px;
}
.btn{
    border: none;
    background-color: $secondary-color;
    color: white;
    width: 120px;
    border-radius: 5px;
    padding: 8px;
}
.btn:hover{
    cursor: pointer;
}
.separator{
    width: 10px;
}
a:hover{
    cursor: pointer;
}
</style>
