<template>
    <DefaultLayout>
        <div class="container" style=" background-image: url('/img/photos/certificate_front2.png'), url('/img/photos/courses_front2.png');
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: 32%, 32%;">

            <h1>¡Activa Tu Cuenta ProDev y disfruta de tu primer mes gratis!</h1>
            <div class="title">Activa ahora tu suscripción y podrás acceder a todos los servicios ProDev de forma
                <strong>GRATUITA</strong> durante el primer mes.<br> Somos transparentes. Podrás dar de baja tu suscripción en cualquier momento de forma sencilla.<br> Solo tienes que pulsar el botón de <strong>CANCELAR</strong> en la sección de "Mi Suscripción ProDev".
            </div>
            <br>
            <h2><strong>¡Aprovecha la oferta anual con el 40% de descuento para los 1.000 primeros!</strong></h2>
            <p></p>
            <div class="cards">
                <div class="card">
                    <form class="inputProfile">
                        <h4>ProDev Mensual</h4>
                        <br>
                        <v-icon name="md-workspacepremium-sharp" class="icon" hover="true" animation="wrench"
                            style="scale: 4; margin-top: 10%;margin-bottom: 15%; fill:#28a3bf" />
                        <br>
                        <h5>Suscripción Mensual</h5>
                        <br>
                        <p class="price-discount">4,99€ </p>
                    </form>
                    <br>
                    <div id="paypal-button-container-month"></div> 
                </div>
                <div class="separator"></div>
                <div class="card">
                    <form class="inputProfile">
                        <h4>ProDev Anual</h4>
                        <br>
                        <v-icon name="md-workspacepremium-sharp" class="icon" hover="true" animation="wrench"
                            style="scale: 4; margin-top: 10%;margin-bottom: 15%; fill:#28a3bf" />
                        <br>
                        <h5>Suscripción Anual</h5>
                        <br>
                        <div class="price">
                            <p class="price-discount">49,90€</p>
                        </div>
                    </form>
                    <br>
                    <div id="paypal-button-container-year"></div>
                </div>
            </div>
            <br>
            <div class="title">Con Ademass ProDev, no solo disfrutas de beneficios exclusivos,<br> también construyes la
                comunidad que amamos.<br> Tu apoyo es clave para no dejar atrás a nadie. </div>
        </div>
    </DefaultLayout>
</template>

<script  lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { onMounted,ref,Ref } from 'vue';
import PaypPalService from '@/services/PayPalService'
import ProfileService from '@/services/ProfileService';
import { loadScript } from '@paypal/paypal-js';
import { useAuth } from '@/store/useAuth';
import router from '@/router';

const service = new PaypPalService()
const userService = new ProfileService() 
const store = useAuth()
const data:Ref<any> = ref({})
const user:Ref<any> = ref({})

/*onMounted(() => {
    setTimeout(() => {
        const paypalBTN = document.getElementById("paypal-button-container-P-19W68076RC250891AMXOHZ6Q");
        if (paypalBTN.childNodes.length == 0) {
            window.location.reload()
        }
    }, 1000)

})*/



onMounted(async () => {
  if(!store.token) {
    return
  }
 user.value = await userService.MyUser(store.token)
  const paypalSdk = await loadScript({  
    clientId: 'AQfjLjOxuhpsNWYSik8beXjqgJOWm-Q9_-V8JfKGdSg_5ow6WT1ZCtSf6KHsGledxGP9vlUEfPb_lO6U',
    currency: 'EUR',
    vault: true
  });

  if(paypalSdk == null) { return }
  if(paypalSdk == undefined) { return }
  if(paypalSdk.Buttons == null) { return }

  paypalSdk.Buttons({

        createSubscription: function(data, actions) {
            if(user?.value?.paypal_id != 'null'){
          return actions.subscription.create({
           'plan_id': 'P-7F343062YS375561DMYB7LWY'
           });}else{
            return actions.subscription.create({
           'plan_id': 'P-96L394980W065412PMXOHYLI'
           });
           }
         },
         onApprove: function(data, actions):any {
            window.location.assign(`prodev/bienvenida/${data.subscriptionID}`);
         }

    }).render('#paypal-button-container-month');

    paypalSdk.Buttons({

createSubscription: function(data, actions) {
    if(user?.value.paypal_id != 'null'){
        return actions.subscription.create({
   
   'plan_id': 'P-19W68076RC250891AMXOHZ6Q'
   });
   }else{
    return actions.subscription.create({
   
   'plan_id': 'P-6N2884890V550730XMYB7OMI'
   });
   }

 },
 onApprove: function(data, actions):any {
    window.location.assign(`prodev/bienvenida/${data.subscriptionID}`);
 }

}).render('#paypal-button-container-year');
data.value = await service.checkSub(store.token)
    if(data.value.status == 'ACTIVE'){
        router.push({name: 'count-prodev'})
    }
    
})
</script>

<style lang="scss" scoped>
.buttons {
    display: flex;

}

.price {
    display: flex;
}

.price-original {
    color: red;
    text-decoration: line-through;
}

.price-discount {
    color: #29c682;
}

.separator {
    width: 10px;
}

.cards {
    display: flex;
}

h1 {
    margin-top: 20px;
    margin-bottom: 20px;
}

strong {
    color: #28a3bf !important;
}

h4 {
    margin-bottom: 10px;
}

.inputView {
    margin-bottom: 15px;
}

.imgFound {
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
}

.container {
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;

}

.inputProfile {
    flex-direction: column;
    width: 60%;
    display: flex;
    align-items: center;
}

.card {
    padding: 15px;
    min-height: 300px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 15px;
    margin-top: 60px;
    background-color: white;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.1);

}


.btn-details {
    margin-top: 15px;
    padding: 10px;
    color: white;
    background-color: $secondary-color;
    font-size: 13px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    margin-bottom: 5px;
    font-family: $main-font;
    min-width: 100px;
}

b {
    color: white;
}

.btn-details:hover {
    background-color: $third-color;
    cursor: pointer;
    scale: 1.1;
}

@media screen and (max-width: 600px) {
    .inputProfile {
        width: 100%;
    }

    .container {
        background-image: none !important;
        /* Oculta las imágenes de fondo en dispositivos móviles */
    }

    .card {
        width: 100%;
        margin-left: 0;

    }

    .cards {
        flex-direction: column;
    }
}</style>