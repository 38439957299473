export default class CompletedService {
    private base_url = 'https://gogodev.net/gogolearn/api/completed'

    async getAllFromUser(token:string) {
        const uri = `${this.base_url}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    async checkCompletedStatus(token:string, lesson_id:number) {
        const uri = `${this.base_url}/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    async doCompleted(token:string, lesson_id:number) {
        const uri = `${this.base_url}/do-completed/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    async doNoCompleted(token:string, lesson_id:number) {
        const uri = `${this.base_url}/do-no-completed/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }
}
