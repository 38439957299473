<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else class="animate container">
            <div class="title">
                <h1>{{ course.name }}</h1>
                <div v-if="heartLoading" class="heart-container">
                    <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2" />
                </div>
                <div v-else-if="store.token">
                    <div v-if="windowWidth > 950">
                        <div class="button-follow-inner" v-if="follow" @click="handleUnfollowCourse">
                            <v-icon name="bi-heart-fill" fill="#bece49" hover="true" animation="wrench" class="heart"
                                scale="1" />
                            <span>Siguiendo</span>
                        </div>
                        <div class="button-follow-inner" v-else @click="handleFollowCourse">
                            <v-icon name="bi-heart-fill" fill="#ccc" hover="true" animation="wrench" class="heart"
                                scale="1" />
                            <span>Seguir</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spacer"></div>
            <CourseProgressbar :course-id="course.id" ref="progressBar" class="bar" />
            <div class="spacer"></div>
            <div v-if="windowWidth <= 950">
                        <div class="button-follow-inner-mobile" v-if="follow" @click="handleUnfollowCourse">
                            <v-icon name="bi-heart-fill" fill="#bece49" hover="true" animation="wrench" class="heart"
                                scale="1" />
                            <span>Siguiendo</span>
                        </div>
                        <div class="button-follow-inner-mobile" v-else @click="handleFollowCourse">
                            <v-icon name="bi-heart-fill" fill="#ccc" hover="true" animation="wrench" class="heart"
                                scale="1" />
                            <span>Seguir</span>
                        </div>
                    </div>
                   <!--<BookBar></BookBar>  <div class="spacer"></div>--> 
                    
                    <div class="spacer" v-if="windowWidth <= 950"></div>
            <LessonViewer :courseId="course.id" :authorInfo="authorInfo" :courseDescription="course.description" @refreshStatus="refreshStatus" />       </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import CourseProgressbar from '@/components/widgets/CourseProgressbar.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CourseService from '@/services/CourseService'
import { onMounted, ref, Ref} from 'vue'
import { useRoute } from 'vue-router'
import LessonViewer from '@/components/sections/LessonViewer.vue'
import { useAuth } from '@/store/useAuth'
import FollowCourseService from '@/services/FollowCourseService'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import AuthorService from '@/services/AuthorService'
import useWindowWidth from '@/composables/useWindowWidth'
//import BookBar from '@/components/layouts/BookBar.vue'

const { windowWidth } = useWindowWidth()
const store = useAuth()
const toast = useToast()
const follow = ref(false)
const followCourseService = new FollowCourseService()
const progressBar:Ref<any> = ref(null)
const loading = ref(true)
const heartLoading = ref(true)
const courseService = new CourseService()
const course = ref()
const route = useRoute()
const course_id = route.params.id
const authorService = new AuthorService()
const authorInfo = ref()

onMounted(async () => {
    loading.value = true
    course.value = await courseService.GetCourse(course_id)
    loading.value = false
    authorInfo.value = await authorService.GetAuthor(course.value.author_id)
    if (!store.token) {
        heartLoading.value = false
        return;
    }
    heartLoading.value = true
    follow.value = await followCourseService.checkFollowCourseStatus(store.token, course_id)
    heartLoading.value = false

})

const handleFollowCourse = async () => {
    if (!store.token) {
        return;
    }
    heartLoading.value = true
    const response = await followCourseService.doFollowToCourse(store.token, course_id)
    toast.showToast("Has comenzado a seguir el curso.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    follow.value = true
    heartLoading.value = false
}

const handleUnfollowCourse = async () => {
    if (!store.token) {
        return;
    }
    heartLoading.value = true
    const response = await followCourseService.doUnfollowToCourse(store.token, course_id)
    toast.showToast("Has dejado de seguir el curso.", Toast.WARNING)
    setTimeout(function () { toast.hideToast() }, 4000);
    follow.value = false
    heartLoading.value = false
}

const refreshStatus = async () => {
    if (progressBar.value == null) {
        return
    }
   await progressBar.value.updateStatus()
}
</script>

<style lang="scss" scoped>
span {
    font-size: 14px;
}


.button-follow-inner {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    height: 35px;
    margin-top: 5px;
}

.button-follow-inner:hover {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: white;

    span {
        color: white;
    }
}

.button-follow-inner-mobile {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 0;
    height: 35px;
    width: 100px;
}

.button-follow-inner-mobile:hover {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: white;

    span {
        color: white;
    }
}

.bar {
    width: 87%;
    text-align: justify;
}

.heart-container {
    overflow-y: hidden;
}

.title {
    display: flex;
    width: 100%;
    overflow-y: hidden;
    align-items: center;
    justify-content: space-between;
}

.description p {
    margin-bottom: 15px;
}

.img {
    border-radius: 10px;
}

.container {

    display: flex;
    flex-direction: column;
}

.heart {
    cursor: pointer;
}

@media(max-width:950px) {
    .bar {
        width: 100%;
    }
}</style>