<template>
      <DefaultLayout>
        <div class="container">
    <div class="card">
      <h1>Respuesta del ejercicio {{ answer?.order }}</h1>
      <h2>Respuesta</h2>
      <div class="answer" v-if="answer.answer"><p>{{answer?.answer}}</p></div>
      <h2 style="margin-top: 15px;" v-if="answer.answer_image_uri">Vista en HTML:</h2>
      <img v-if="answer.answer_image_uri" :src="answer?.answer_image_uri" alt="Imagen en HTML">
      </div>
  </div>
  </DefaultLayout>
  </template>
  
  <script setup lang="ts">
  import {ref,onMounted, Ref } from 'vue'
  import DefaultLayout from '@/layouts/DefaultLayout.vue';
  import ExercisesService from '@/services/ExerciseService';
  import { useRoute } from 'vue-router';
import AnswerInterface from '@/interfaces/AnswerInterface';


   const loading =ref(false)
   const route = useRoute()
   const service = new ExercisesService()
   const answer:Ref<AnswerInterface> = ref({})

onMounted(async()=>{ 
     loading.value = true;
     answer.value = await service.GetExercisesAnswerDetail(route.params.index)
     loading.value = false;
})


  
  </script>
  
  <style lang="scss" scoped>
  h1{
    margin-bottom: 20px;
}
p{
    margin-bottom: 12px;
}
img{
   min-width: 300px;
   max-width: 500px;
}
.container {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.card h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.card h2 {
  color: $secondary-color;
  font-size: 20px;
  margin-bottom: 10px;
}

.answer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  max-width: 100%; 
  box-sizing: border-box;
  
}

p {
  font-family: 'Courier New', monospace;
  white-space: pre-line;
  color: #333;
  font-size: 1em; /* Ajusta el tamaño de fuente según sea necesario */
  max-width: 85%;
}

  </style>
  