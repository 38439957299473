<template>
    <v-icon name="RiLoader2Fill" />
    <div v-if="loading">
        <v-icon name="RiLoader2Fill" />
    </div>
    <div v-else>
        <div v-if="roadmaps.length > 0" class="content">
            <h1>Continuar mis hojas de ruta</h1>
            <div class="container">
                <RoadmapList v-for="item in roadmapsContent" :key="item.id" :content="item" />
              <AdvertisementMain AdType="card"/> 
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, Ref } from 'vue'
import FollowRoadmapService from '@/services/FollowRoadmapService'
import { useAuth } from '@/store/useAuth'
import RoadmapList from '@/components/cards/RoadmapList.vue'
import RoadmapService from '@/services/RoadmapService'
import AdvertisementMain from '@/components/advertisement/AdevertisementMain.vue'

const store = useAuth()
const followRoadMapService = new FollowRoadmapService()
const roadmapService = new RoadmapService()
const roadmaps = ref([])
const roadmapsContent = ref([])
const loading = ref(false)

onMounted(async () => {
    loading.value = true
    if (store.token) {
        roadmaps.value = await followRoadMapService.getAllRoadmapsFromUser(store.token)
    }
    console.log(roadmaps.value)
    roadmaps.value.forEach(async roadmap => {
        const item = await roadmapService.GetRoadmap(roadmap.roadmap_id)
        roadmapsContent.value.push(item)
    });
    Promise.all(roadmapsContent.value)
    loading.value = false
})
</script>

<style lang="scss" scoped>
.content {
    margin-bottom: 20px;
}

.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

h1 {
    margin-bottom: 10px;
}

.publish {
    margin-top: 9px;
}

@media(max-width: 950px) {
    .container {
        width: 100%;
    }
}
</style>