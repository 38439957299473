<template>
   <div class="container">
    <div class="btns">
    <a class="button acc" href="https://store.steampowered.com/app/2200410/Tales_from_Candleforth/"  target="_blank">IR A STEAM</a>
   </div>
</div>
</template>

<script  lang="ts" setup>
import { onMounted,ref } from 'vue';
import InformationService from '@/services/InformationService'

const service = new InformationService()
const information = ref({})

onMounted(async()=>{
  information.value = await service.GetInformation()
  console.log(window.innerWidth);
})



</script>

<style lang="scss" scoped>
.acc {
   background-color: rgb(56, 53, 53) !important;
}
.btns {
   display: flex;
   flex-direction: row;
   gap: 10px;
   padding-top: 25px;
   z-index: 9999;
}
.acc:hover{
  background-color: $secondary-color!important;
}

.btns a {
   cursor: pointer;
   pointer-events: auto;
   z-index: 2147483647 !important;
   position: relative;
}
.video-fondo__video {
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 object-fit: cover; /* Ajusta el video al tamaño del contenedor */
 z-index: -1; /* Coloca el video detrás del contenido */
}
h1, h3 {
    color: white;
    width: 100%;
    text-transform: uppercase;
}

h1 {
   font-size: 100px;
   text-transform: uppercase;
}

h3 {
   font-size: 40px;
}



.container {
   margin-top: 100px;
    height: 65vh;
    flex-direction: column;
    padding: 15px;
    margin-top: 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100vw;
    overflow-x: visible;
    position: absolute;
    left: 0px;
    top: 0px;
    background-image: url('../../../public/img/banners/banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid black;
    background-color: black;
}


.button {
   text-decoration: none;
    padding: 10px;
    background-color:black;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-family: $title-font;
    font-size: 20px;
}
.button:hover {
    background-color: $third-color;
}
.space{
    height: 20px;
}
@media screen and (max-width:1300px){
  .btns{
   z-index: 0!important;
  }
  h1 {
     font-size: 30px;
  }
  h3 {
     font-size: 20px;
  }
.button{
   font-size: 14px;
}
.container{
   text-align: center;
}
.btns a{
   z-index: 0 !important;
}
}
@media screen and (max-width:550px){
.container{
   margin-top: 55px;
  background-image: url('../../../public/img/banners/banner2.png');
}
}
@media screen and (max-width:1000px){
.container{
   margin-top: 55px;
}
}


</style>
