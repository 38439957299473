import QuestionInterface from "@/interfaces/QuestionInterface"

export default class EvaluationService {
    private base_url = 'https://gogodev.net/gogolearn/api/evaluation'

    public async getAllEvaluations() {
        const uri = `${this.base_url}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }

    public async getEvaluationInfo(evaluation_id:string | string[]) {
        const uri = `${this.base_url}/detail/${evaluation_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }

    public async getUserTries(token:string, evaluation_id:string | string[]) {
        const uri = `${this.base_url}/tries/${evaluation_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async generateEvaluation(token:string, evaluation_id:string | string[]) {
        const uri = `${this.base_url}/generate/${evaluation_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async checkEvaluationResult(token:string, evaluation_id:string | string[], size:number, questions:Array<QuestionInterface>) {
        const uri = `${this.base_url}/validate`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'evaluation_id': evaluation_id,
                'size': size,
                'questions': questions
            })
        })
        const response = await rawResponse.json()
        return response
    }
}