<template>
    <div class="animate top-bar">
        <div class="logo" v-if="windowWidth > 950">
            <RouterLink :to="{name:'home'}" style="cursor: pointer;"><img :src="logo"></RouterLink>
        </div>
        <RouterLink :to="{name:'home'}" style="cursor: pointer;" v-else>
            <img src="../../../public/img/lms/logo_sticker.png" class="logo" style="width: 80px;" />
        </RouterLink>
        
        <div style="display: flex;">
        <RouterLink :to="{name: 'notifications'}">
        <div style="display: flex; justify-self: right; overflow: visible;">
            <div class="notification">
                <div class="alert-notification" v-if="pendingNotifications > 0 && $route.name != 'notifications'">{{ pendingNotifications }}</div>
                <v-icon name="bi-bell-fill" scale="1.2" style="margin-right: 5px;" v-if="auth.token"></v-icon>
            </div>
        </div>
    </RouterLink>
    <UserInfoWidget class="widget" v-if="windowWidth >= 950" />
    </div>
        <v-icon name="hi-menu-alt-3" scale="2" class="menu-btn" v-if="windowWidth <= 950" @click="handleMenu" />
    </div>
</template>

<script lang="ts" setup>
import UserInfoWidget from '@/components/widgets/UserInfoWidget.vue'
import useWindowWidth from '@/composables/useWindowWidth'
import { useMenu } from '@/store/useMenu'
import { ref, onMounted} from 'vue'
import logo from '@/assets/svg/ademass.svg'
import { useAuth } from '@/store/useAuth'
import NotificationService from '@/services/NotificationService'


const auth = useAuth()
const { windowWidth } = useWindowWidth()
const store = useMenu()
const pendingNotifications = ref(0)
const notificationService = new NotificationService()


//const search = ref('')

onMounted(async () => {
    if(!auth.token)
    {
        return;
    }
  pendingNotifications.value = await notificationService.CountUnreadNotification(auth.token)  
})

const handleMenu = () => {
    store.toggleMenu()
    window.scrollTo(0, 0);
}
</script>

<style lang="scss" scoped>

.alert-notification {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: crimson;
    top: 0px;
    right: 0;
    z-index: 10000000;
    overflow: visible;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.notification {
    position: relative;
}

.menu-btn {
    margin-right: 20px;
    cursor: pointer;
}

.widget {
    margin-right: 20px;
}

.top-bar {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 99999;
    background-color: white;
    box-shadow: 2px 2px 5px #ccc;
}

.logo {
    font-family: $logo-font;
    padding-left: 15px;
    width: 10%;
}

.search-form {
    width: 50%;
    display: flex;
    align-items: center;
    background-color: $primary-color;
    border-radius: 5px;
    border: 0;
    width: 50%;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    height: 37px;
    overflow-y: hidden;
}

.search-bar {
    background: none !important;
}

.search-btn {
    background-color: $third-color;
    height: 37px;
    margin: 0;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 5px 5px 0;
}

.search-btn:hover {
    background-color: $secondary-color;
}

@media(max-width:950px) {
    .logo {
        width: 20%;
    }
}
</style>