<template>
    <DefaultLayout>
        <!-- Modal Experience -->
        <div class="animate modal" v-if="showAddExperienceModal">



            <div class="form-style-10">
                <div class="inner-wrap">
                    <h5>Añadir Experiencia</h5>
                    <br />
                    <label>Cargo / Puesto <input type="text" name="field1" v-model="newExperience.name" /></label>
                    <label>Compañía <input type="text" name="field1" v-model="newExperience.company" /></label>
                    <label>Años en el puesto <input type="number" name="field1" v-model="newExperience.years" /></label>
                    <label>En años en el puesto solo números enteros</label>
                    <label>Descripción <textarea name="field2" v-model="newExperience.description"></textarea></label>
                    <label>Categoría
                        <select v-model="newExperience.job_category_id">
                            <option v-for="jobCategory in jobCategories" :key="jobCategory.id" :value="jobCategory.id">
                                {{ jobCategory.name }}</option>
                        </select>
                    </label>
                    <input type="submit" @click="OnClickStoreNewExperience" />
                    <button @click="showAddExperienceModal = false" class="close"><v-icon name="io-close-circle-sharp"
                            fill="#c93c3c" scale="1.5" /></button>
                    <p>{{ feedbackAddExperience }}</p>
                </div>
            </div>



        </div>
        <!-- End Modal Experience -->

        <!-- Modal Update Experience -->
        <div class="animate modal" v-if="showUpdateExperienceModal">



            <div class="form-style-10">
                <div class="inner-wrap">
                    <h5>Editar Experiencia</h5>
                    <br />
                    <label>Cargo / Puesto <input type="text" name="field1" v-model="newExperience.name" /></label>
                    <label>Compañía <input type="text" name="field1" v-model="newExperience.company" /></label>
                    <label>Años en el puesto <input type="number" name="field1" v-model="newExperience.years" /></label>



                    <!-- <label>Años en el puesto <input type="number" name="field1" v-model="newExperience.years" /></label>-->
                    <label>Descripción <textarea name="field2" v-model="newExperience.description"></textarea></label>
                    <label>Categoría
                        <select v-model="newExperience.job_category_id">
                            <option v-for="jobCategory in jobCategories" :key="jobCategory.id" :value="jobCategory.id">
                                {{ jobCategory.name }}</option>
                        </select>
                    </label>
                    <input type="submit" @click="OnClickUpdateExperience" />
                    <input type="submit" @click="OnClickDeleteExperience" value="Eliminar" class="delete-btn" />
                    <button @click="showUpdateExperienceModal = false" class="close"><v-icon name="io-close-circle-sharp"
                            fill="#c93c3c" scale="1.5" /></button>
                    <p>{{ feedbackUpdateExperience }}</p>
                </div>
            </div>



        </div>
        <!-- End Modal Update Experience -->



        <!-- Modal Add Study -->
        <div class="animate modal" v-if="showAddStudyModal">
            <div class="form-style-10">
                <div class="inner-wrap">
                    <h5>Añadir Estudios Previos</h5>
                    <br />
                    <label>Título <input type="text" name="field1" v-model="newStudy.name" /></label>
                    <label>Centro de estudios <input type="text" name="field1" v-model="newStudy.organism" /></label>
                    <label>Descripción <textarea name="field2" v-model="newStudy.description"></textarea></label>
                    <label>Ubicación
                        <select class="part" v-model="countryStudy" @change="LoadLocationsStudy" placeholder="País"
                            style="margin-bottom: 5px;">
                            <option disabled>País</option>
                            <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}
                            </option>
                        </select> <select class="part" v-model="newStudy.location_id" placeholder="Región">
                            <option>Región</option>
                            <option v-for="location in locationsStudy" :key="location.id" :value="location.id">{{
                                location.name
                            }}
                            </option>
                        </select>
                    </label>
                    <input type="submit" @click="OnClickStoreNewStudy" />
                    <button @click="showAddStudyModal = false" class="close"><v-icon name="io-close-circle-sharp"
                            fill="#c93c3c" scale="1.5" /></button>
                    <p>{{ feedbackAddStudy }}</p>
                </div>
            </div>
        </div>
        <!-- End Modal Add Study -->


        <!-- Modal Edit Study -->
        <div class="animate modal" v-if="showUpdateStudyModal">
            <div class="form-style-10">
                <div class="inner-wrap">
                    <h5>Editar Estudio Previo</h5>
                    <br />
                    <label>Título <input type="text" name="field1" v-model="newStudy.name" /></label>
                    <label>Centro de estudios <input type="text" name="field1" v-model="newStudy.organism" /></label>
                    <label>Descripción <textarea name="field2" v-model="newStudy.description"></textarea></label>
                    <label>Ubicación
                        <select class="part" v-model="countryStudy" @change="LoadLocationsStudy" placeholder="País"
                            style="margin-bottom: 5px;">
                            <option disabled>País</option>
                            <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}
                            </option>
                        </select> <select class="part" v-model="newStudy.location_id" placeholder="Región">
                            <option>Región</option>
                            <option v-for="location in locationsStudy" :key="location.id" :value="location.id">{{
                                location.name
                            }}
                            </option>
                        </select>
                    </label>
                    <input type="submit" @click="OnClickUpdateStudy" />
                    <input type="submit" @click="OnClickDeleteStudy" value="Eliminar" class="delete-btn" />
                    <button @click="showUpdateStudyModal = false" class="close"><v-icon name="io-close-circle-sharp"
                            fill="#c93c3c" scale="1.5" /></button>
                    <p>{{ feedbackUpdateStudy }}</p>
                </div>
            </div>
        </div>
        <!-- End Modal Edit Study -->



        <h1>Mi perfil de empleo</h1>
        <div class="header">
            <p>Aporta la mayor información posible. Mientras más completo sea tu perfil, más fácil nos será encontrarte un
                empleo adecuado a tus competencias.</p>
            <!-- <a href="#" class="cv-button"><v-icon name="fa-file-pdf" fill="#fff" class="icon"></v-icon>Descargar mi Ademass
                CV Pass</a> -->
        </div>

        <div class="spacer"></div>
        <div class="main-info-container">
            <h3>Información General</h3>
            <div class="animate main-info" v-if="!loading">
                <div class="form-check ">
                    <input class="form-check-input " type="checkbox" value="" id="flexCheckDefault"
                        v-model="profile.is_searching" @change="UpdateIsSearching" style="background-color:#bece49;">
                    <label class="form-check-label" for="flexCheckDefault">
                        En búsqueda activa de empleo.
                    </label>
                </div>
                <br />
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                        v-model="profile.automatic_inscription" @change="UpdateAutomaticInscription">
                    <label class="form-check-label" for="flexCheckDefault">
                        Autoinscribirme en candidaturas adecuadas.
                    </label>
                </div>
                <br>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                        v-model="profile.available_remote" @change="UpdateAvailableRemote">
                    <label class="form-check-label" for="flexCheckDefault">
                        Estoy dispuesto a trabajar en remoto.
                    </label>
                </div>
                <br />
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                        v-model="profile.available_to_move" @change="UpdateAvailableToMove">
                    <label class="form-check-label" for="flexCheckDefault">
                        Estoy dispuesto a desplazarme.
                    </label>
                </div>
                <div class="spacer"></div>
                <div v-if="profile.available_to_move">
                <label>Mi rango para desplazarme es:</label>
                <br/>
                <select class="form-select move" aria-label="Default select example" v-model="profile.movility_type" @change="UpdateMovilityType" >
                    <option value="local">Local</option>
                    <option value="region">Regional</option>
                    <option value="country">Nacional</option>
                    <option value="international">Internacional</option>
                </select>
            </div>
                <br />
                <label>Actualmente resido en:</label>
                <div class="selects">
                    <select class="part" v-model="country" @change="LoadLocations" placeholder="País">
                        <option disabled>País</option>
                        <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}
                        </option>
                    </select> <select class="part" v-model="profile.location_id" placeholder="Región"
                        @change="UpdateLocationId">
                        <option>Región</option>
                        <option v-for="location in locations" :key="location.id" :value="location.id">{{ location.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-else>
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2" />
            </div>
        </div>
        <br />
        <div class="main-info-container">
            <h3>Sobre mí</h3>
            <div class="animate main-info" v-if="!loading">
                <div class="social-check-container2">
                    <div class="left2">
                <div class="social-check ">
                    
                    <label class="form-check-label" for="imputSocialSlogan">
                        Slogan
                    </label>
                    <input class="input-social" type="text"  id="imputSocialSlogan" placeholder="Ejemplo : Desarrollador Web" v-model="slogan" >                 
                </div>
                <br />
                <div class="social-check ">
                    <label class="form-check-label" for="imputSocialGitHub">
                       Descripción
                    </label>
                    <textarea class="input-social" type="text"  id="imputSocialGitHub" cols="30" rows="10" placeholder="Soy un apasionado del desarrollo web ... máximo 4.000 carácteres" v-model="profile_description" ></textarea>                
                </div>
                <br>
                <button class="btn-info" @click="OnClickAbout()"><b>Actualizar</b> </button>
            </div>
        </div>
            </div>
            <div v-else>
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2" />
            </div>
        </div>
        <div class="main-info-container">
            <h3>Redes sociales</h3>
            <div class="animate main-info" v-if="!loading">
                <div class="social-check-container">
                    <div class="left">
                <div class="social-check ">
                    
                    <label class="form-check-label" for="imputSocialInstagram" >
                        Instagram
                    </label>
                    <input class="input-social" type="text"  id="imputSocialInstagram" v-model="instagram"  placeholder="Si no tienes, dejar vacío">                 
                </div>
                <br />
                <div class="social-check ">
                    <label class="form-check-label" for="imputSocialGitHub" >
                        GitHub
                    </label>
                    <input class="input-social" type="text" id="imputSocialGitHub" v-model="github"  placeholder="Si no tienes, dejar vacío">                 
                </div>
                <br>
                <div class="social-check ">
                    <label class="form-check-label" for="imputSocialLinkedink" >
                        Linkedin
                    </label>
                    <input class="input-social" type="text"  id="imputSocialLinkedink" v-model="linkedin" placeholder="Si no tienes, dejar vacío">                 
                </div>
                <br />
                <div class="social-check ">
                    <label class="form-check-label" for="imputSocialFacebook">
                        Facebook
                    </label>
                    <input class="input-social" type="text"  id="imputSocialFacebook" v-model="facebook"  placeholder="Si no tienes, dejar vacío">                 
                </div>
            </div>
                <br>
                <div class="right">
                <div class="social-check ">
                    <label class="form-check-label" for="imputSocialYoutube">
                        Youtube
                    </label>
                    <input class="input-social" type="text"  id="imputSocialYoutube" v-model="youtube"   placeholder="Si no tienes, dejar vacío">                 
                </div>
                <br />
                <div class="social-check ">
                    <label class="form-check-label" for="imputSocialTikTok">
                        TikTok
                    </label>
                    <input class="input-social" type="text"  id="imputSocialTikTok"  v-model="tiktok"  placeholder="Si no tienes, dejar vacío">                 
                </div>
                <br/>
                <div class="social-check ">
                    <label class="form-check-label" for="imputSocialWeb">
                     Sitio Web
                    </label>
                    <input class="input-social" type="text"  id="imputSocialWeb" v-model="web"  placeholder="Si no tienes, dejar vacío" >                 
                </div>
                <br/>
                <div  class="social-check ">
                    <label class="form-check-label" for="imputSocialOtros">
                        Otros
                    </label>
                    <input class="input-social" type="text"  id="imputSocialOtros" v-model="other_sn"   placeholder="Si no tienes, dejar vacío">                 
                </div>
            </div>
            
        </div>
    <br>
        <button class="btn-info" @click="OnClickSocial()"><b> Actualizar</b></button>
            </div>
            <div v-else>
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2" />
            </div> 
        </div>
        <br/>
        <div class="experience-container">
            <h3>Experiencia Laboral</h3>
            <div class="experience" v-if="!loadingExperience">
                <div class="experience-card-plus" @click="OnClickShowAddExperience">
                    <v-icon name="bi-plus-square-dotted" scale="3" fill="#bece49" />
                </div>
                <div class="experience-card" v-for="experience in experiences" :key="experience.id"
                    @click="OnClickShowEditExperience(experience.id)">
                    <h5>{{ experience.years }} años como {{ experience.name }} en {{ experience.company }}</h5>
                    <small>Pulsa para editar / ver más</small>
                </div>
            </div>
            <div v-else>
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2" />
            </div>
        </div>
        <br />
        <div class="education-container">
            <h3>Estudios Previos</h3>
            <div class="experience" v-if="!loadingStudies">
                <div class="animate experience-card-plus" @click="OnClickShowAddStudy">
                    <v-icon name="bi-plus-square-dotted" scale="3" fill="#bece49" />
                </div>
                <div class="animate experience-card" v-for="study in studies" :key="study.id"
                    @click="OnClickShowEditStudy(study.id)">
                    <h5>{{ study.name }} en {{ study.organism }}</h5>
                    <small>Pulsa para editar / ver más</small>
                </div>
            </div>
            <div v-else>
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2" />
            </div>
        </div>
        <br />
        <div class="soft-skills-container">
            <h3>Habilidades</h3>
            <div class="experience" v-if="!loadingSkills">
                <div class="animate skill-card-plus">
                    <select v-model="selectedSkill" class="addSkillSelect">
                        <option value="0" disabled>Añadir Habilidad</option>
                        <option v-for="skill in allSkills" :key="skill.id" :value="skill.id">
                            {{ skill.name }}</option>
                    </select>
                    <input type="submit" @click="OnClickAddSkill" value="Añadir" class="add-skill" />
                </div>
                <div class="animate experience-card" v-for="skill in skills" :key="skill.id"
                    @click="OnClickDeleteSkill(skill.id)">
                    <h5>{{ skill.name }}</h5>
                    <small>Pulsa para Eliminar</small>
                </div>
            </div>
            <div v-else>
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2" />
            </div>
        </div>
        <br />
        <div class="your-certificates">
            <h3 v-if="certifications.length >= 1">Certificaciones Ademass</h3>
            <div class="experience" v-if="!loadingCertifications">
                <div class="animate experience-card-green" v-for="certification in certifications" :key="certification.id"
                    @click="router.push({
                        name: 'my-diplomas'
                    })">
                    <h5>{{ certification.name }}</h5>
                </div>
            </div>
            <div v-else>
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2" />
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref, onMounted , Ref} from 'vue'
import EmployProfileService from '@/services/EmployProfileService'
import LocationService from '@/services/LocationService'
import SkillServive from '@/services/SkillService'
import JobCategoryService from '@/services/JobCategoryService'
import PreviousJobService from '@/services/PreviousJobService'
import DiplomaService from '@/services/DiplomaService'
import StudyService from '@/services/StudyService'
import { useAuth } from '@/store/useAuth'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import router from '@/router'
import PortFolio from '@/services/PortFolioService'
import ProfileI from '@/interfaces/ProfileInterface'

const portFolioService = new PortFolio()
const github =ref('')
const instagram = ref('')
const linkedin = ref('')
const facebook = ref('')
const youtube = ref('')
const tiktok = ref('')
const web = ref('')
const other_sn = ref('') 
const slogan = ref('')
const profile_description = ref('')

const selectedSkill = ref(0)
const showAddExperienceModal = ref(false)
const showUpdateExperienceModal = ref(false)
const showUpdateStudyModal = ref(false)
const showAddStudyModal = ref(false)
const loading = ref(true)
const loadingExperience = ref(false)
const loadingStudies = ref(false)
const loadingSkills = ref(false)
const loadingCertifications = ref(false)
const allSkills:Ref<ProfileI[]> = ref([])
const skills:Ref<any> = ref([])
const studies:Ref<any> = ref([])
const experiences:Ref<any> = ref([])
const countries:Ref<ProfileI[]> = ref([])
const certifications:Ref<ProfileI[]> = ref([])
const country = ref(1)
const countryStudy = ref(1)
const locations:Ref<ProfileI[]> = ref([])
const locationsStudy:Ref<ProfileI[]> = ref([])
const jobCategories:Ref<ProfileI[]> = ref([])
const updateExperienceId = ref(0)
const updateStudyId = ref(0)
const profile:Ref<any> = ref({
    is_searching: false,
    automatic_inscription: false,
    available_remote: false,
    available_to_move: false,
    location_id: 32,
    movility_type: 'local'
})
const newExperience:Ref<any> = ref({
    years: 0,
    name: null,
    company: null,
    description: null,
    job_category_id: 0
})
const newStudy:Ref<any> = ref({
    name: null,
    description: null,
    organism: null,
    location_id: 0
})
const feedbackAddExperience = ref("")
const feedbackAddStudy = ref("")
const feedbackUpdateExperience = ref("")
const feedbackUpdateStudy = ref("")
const employProfileService = new EmployProfileService()
const locationService = new LocationService()
const jobCategoryService = new JobCategoryService()
const previousJobService = new PreviousJobService()
const skillService = new SkillServive()
const studyService = new StudyService()
const diplomaService = new DiplomaService()
const toast = useToast()
const store = useAuth()
onMounted(() => LoadProfileInfo())

const LoadProfileInfo = async () => {

    if (store.token == null) {
        loading.value = false
        return
    }

    loading.value = true
    jobCategories.value = await jobCategoryService.getAllJobCategories()
    profile.value = await employProfileService.GetEmployProfileInfo(store.token)
    ProfileParser()
    countries.value = await locationService.GetAllCountries()
    allSkills.value = await skillService.GetAllSkills()
    await SetCountry()
    await LoadLocations()
    await LoadExperience()
    await LoadStudies()
    await LoadSkills()
    await LoadCertificates()
    await LoadLocationsStudy()

    slogan.value =  profile.value.slogan,
    profile_description.value = profile.value.profile_description,
    github.value =profile.value.github,
    instagram.value = profile.value.instagram,
    linkedin.value = profile.value.linkedin,
    facebook.value = profile.value.facebook,
    youtube.value = profile.value.youtube,
    tiktok.value = profile.value.tiktok,
    web.value = profile.value.web,
    other_sn.value = profile.value.other_sn

    loading.value = false
}

const SetCountry = async () => {
    const countryTemp = await locationService.GetCountryByLocationId(profile.value.location_id)
    country.value = countryTemp.id
}

const ProfileParser = () => {

    if (profile.value.is_searching == 1) {
        profile.value.is_searching = true
    } else {
        profile.value.is_searching = false
    }

    if (profile.value.automatic_inscription == 1) {
        profile.value.automatic_inscription = true
    } else {
        profile.value.automatic_inscription = false
    }

    if (profile.value.available_remote == 1) {
        profile.value.available_remote = true
    } else {
        profile.value.available_remote = false
    }

    if (profile.value.available_to_move == 1) {
        profile.value.available_to_move = true
    } else {
        profile.value.available_to_move = false
    }
}

const LoadLocations = async () => {
    locations.value = await locationService.GetLocationsFromCountry(country.value)
}

const LoadLocationsStudy = async () => {
    locationsStudy.value = await locationService.GetLocationsFromCountry(countryStudy.value)
}


const UpdateIsSearching = async () => {
    if(!store.token) { return }
    if (profile.value.is_searching) {
        await employProfileService.SetSearchEmploy(store.token)
    } else {
        await employProfileService.SetNoSearchEmploy(store.token)
    }
    toast.showToast("Perfil actualizado.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
}

const UpdateAutomaticInscription = async () => {
    if(!store.token) { return }
    if (profile.value.automatic_inscription) {
        await employProfileService.SetAutoSearchEmploy(store.token)
    } else {
        await employProfileService.SetNoAutoSearchEmploy(store.token)
    }
    toast.showToast("Perfil actualizado.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
}

const UpdateAvailableRemote = async () => {
    if(!store.token) { return }
    if (profile.value.available_remote) {
        await employProfileService.SetAvailableRemote(store.token)
    } else {
        await employProfileService.SetNoAvailableRemote(store.token)
    }
    toast.showToast("Perfil actualizado.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
}

const UpdateAvailableToMove = async () => {
    if(!store.token) { return }
    if (profile.value.available_to_move) {
        await employProfileService.SetAvailableToMove(store.token)
    } else {
        await employProfileService.SetAvailableToMove(store.token)
    }
    toast.showToast("Perfil actualizado.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
}

const UpdateLocationId = async () => {
    if(!store.token) { return }
    await employProfileService.SetLocation(store.token, profile.value.location_id)
    toast.showToast("Perfil actualizado.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000)
}


const UpdateMovilityType = async () => {
    if(!store.token) { return }
    await employProfileService.SetMovilityType(store.token, profile.value.movility_type)
    toast.showToast("Perfil actualizado.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000)
}

const OnClickSocial = async () => {
    try {
        if (!store.token) {
            return;
        }
        alert('¡Se han añadido correctamente las redes sociales!')

        await portFolioService.SetSocialStudy(
                store.token,
                github.value,
                instagram.value,
                linkedin.value,
                facebook.value,
                youtube.value,
                tiktok.value,
                web.value,
                other_sn.value);

    } catch (error) {
        console.error('Error al guardar redes sociales', error);
        feedbackAddExperience.value = "Error al guardar redes sociales.";
    }
}
const OnClickAbout = async () => {
    try {
        if (!store.token) {
            return;
        }
        alert('¡Se ha añadido correctamente el apartado Sobre Mí!')
        await portFolioService.SetAboutMe(
            store.token,
            slogan.value, 
            profile_description.value);

    } catch (error) {
        console.error('Error al guardar sobre mí', error);
    }
}

const OnClickStoreNewExperience = async () => {
    try {
        if (!store.token) {
            return;
        }

        feedbackAddExperience.value = "Guardando..."

        await previousJobService.CreateJobToUser(store.token, newExperience.value);

        newExperience.value = {
            years: 0,
            name: null,
            company: null,
            description: null,
            job_category_id: 0
        }
        await LoadExperience()
        feedbackAddExperience.value = ""
        showAddExperienceModal.value = false
    } catch (error) {
        console.error('Error al guardar nueva experiencia:', error);
        feedbackAddExperience.value = "Error al guardar la experiencia.";
    }
}
const OnClickAddSkill = async () => {
    if (!store.token) {
        return;
    }
    if (selectedSkill.value == 0) {
        toast.showToast("Por favor, selecciona una skill.", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    await skillService.AddSkillToUser(store.token, selectedSkill.value)
    selectedSkill.value = 0;
    LoadSkills()
}

const OnClickStoreNewStudy = async () => {
    try {
        if (!store.token) {
            return;
        }

        feedbackAddStudy.value = "Guardando..."

        await studyService.CreateStudy(store.token, newStudy.value);

        newStudy.value = {
            name: null,
            description: null,
            organism: null,
            location_id: 0
        }

        await LoadStudies()
        feedbackAddStudy.value = ""
        showAddStudyModal.value = false
    } catch (error) {
        console.error('Error al guardar nuevo estudio:', error);
        feedbackAddStudy.value = "Error al guardar el estudio.";
    }
}

const OnClickShowEditExperience = async (id: number) => {
    if (!store.token) {
        return
    }
    showUpdateExperienceModal.value = true
    updateExperienceId.value = id
    newExperience.value = await previousJobService.GetJobFromUser(store.token, id)
}

const OnClickShowEditStudy = async (id: number) => {
    if (!store.token) {
        return
    }
    showUpdateStudyModal.value = true
    updateStudyId.value = id
    newStudy.value = await studyService.GetStudyFromUser(store.token, id)
}

const OnClickShowAddExperience = () => {
    newExperience.value = {
        years: 0,
        name: null,
        company: null,
        description: null,
        job_category_id: 0
    }
    feedbackAddExperience.value = ""
    showAddExperienceModal.value = true
}

const OnClickShowAddStudy = () => {
    newStudy.value = {
        name: null,
        description: null,
        organism: null,
        location_id: 0
    }
    feedbackAddStudy.value = ""
    showAddStudyModal.value = true
}
const OnClickUpdateExperience = async () => {
    try {
        if (!store.token) {
            return;
        }

        feedbackUpdateExperience.value = "Actualizando..."

        await previousJobService.UpdateJobToUser(store.token, newExperience.value, updateExperienceId.value);

        newExperience.value = {
            years: 0,
            name: null,
            company: null,
            description: null,
            job_category_id: 0
        }
        await LoadExperience()
        feedbackUpdateExperience.value = ""
        showUpdateExperienceModal.value = false
    } catch (error) {
        console.error('Error al actualizar experiencia:', error);
        feedbackUpdateExperience.value = "Error al actualizar la experiencia.";
    }
}

const OnClickUpdateStudy = async () => {
    try {
        if (!store.token) {
            return;
        }

        feedbackUpdateStudy.value = "Actualizando..."

        await studyService.UpdateStudyToUser(store.token, newStudy.value, updateStudyId.value);

        newStudy.value = {
            name: null,
            description: null,
            organism: null,
            location_id: 0
        }
        await LoadStudies()
        feedbackUpdateStudy.value = ""
        showUpdateStudyModal.value = false
    } catch (error) {
        console.error('Error al actualizar estudio:', error);
        feedbackUpdateStudy.value = "Error al actualizar el estudio.";
    }
}

const OnClickDeleteExperience = async () => {
    if (!store.token) {
        return;
    }

    feedbackUpdateExperience.value = "Borrando..."

    await previousJobService.DeleteJobFromUser(store.token, updateExperienceId.value)

    newExperience.value = {
        years: 0,
        name: null,
        company: null,
        description: null,
        job_category_id: 0
    }
    await LoadExperience()
    feedbackUpdateExperience.value = ""
    showUpdateExperienceModal.value = false
}

const OnClickDeleteSkill = async (id: number) => {
    if (!store.token) {
        return;
    }

    await skillService.DeleteSkillToUser(store.token, id)
    LoadSkills()
}

const OnClickDeleteStudy = async () => {
    if (!store.token) {
        return;
    }

    feedbackUpdateStudy.value = "Borrando..."

    await studyService.DeleteJobFromUser(store.token, updateStudyId.value)

    newStudy.value = {
        name: null,
        description: null,
        organism: null,
        location_id: 0
    }
    await LoadStudies()
    feedbackUpdateStudy.value = ""
    showUpdateStudyModal.value = false
}

const LoadExperience = async () => {
    if (!store.token) {
        return;
    }
    loadingExperience.value = true
    experiences.value = await previousJobService.GetAllJobsFromUser(store.token)
    loadingExperience.value = false
}
const LoadStudies = async () => {
    if (!store.token) {
        return;
    }
    loadingStudies.value = true
    studies.value = await studyService.GetAllStudies(store.token)
    loadingStudies.value = false
}
const LoadSkills = async () => {
    if (!store.token) {
        return;
    }
    loadingSkills.value = true
    skills.value = await skillService.GetAllSkillsFromUser(store.token)
    loadingSkills.value = false
}
const LoadCertificates = async () => {
    if (!store.token) {
        return;
    }
    loadingCertifications.value = true
    certifications.value = await diplomaService.getAllDiplomasFromUser(store.token)
    loadingCertifications.value = false
}

</script>

<style lang="scss" scoped>
b{
    color: white;
}
.btn-info:hover{
   background-color: $third-color;
   cursor: pointer;
}
.btn-info{
    padding: 8px;
    color: white;
    background-color: $secondary-color;
    border: none;
    border-radius: 6px;
    width: 150px;
}
.social-check-container{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.social-check{
    display: flex;
    flex-direction: column;
}
.left{
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-right: 3%;
}
.left2{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-right: 3%;
}
.right{
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-left: 3%;
}
.input-social{
    background-color: white;
    border: 1px solid #ccc;
}
textarea{
    padding: 8px;
    border-radius: 6px;
}
.move{
    width: 20%;
}
@media screen and (max-width: 800px) {
    .move {
        width: 40%;
    }
    .social-check-container{
    flex-direction: column;
}
.left{
    width: 90%;
}
.left2{
    width: 90%;
}
.right{
    width: 90%;
}
}

.delete-btn {
    background-color: $danger-color !important;
    cursor: pointer;
    margin-left: 5px;
}

.modal {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: $secondary-color;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

input {
    padding: 10px;
    border-radius: 5px;
    outline: 0;
    border: 0;
    background-color: #dedede;
    color: $text-color;
}

.experience-card-plus {
    border-radius: 10px;
    border: 1px solid $text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    cursor: pointer;
    margin-top: 5px;
    margin-left: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.skill-card-plus {
    border-radius: 10px;
    border: 1px solid $text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    margin-top: 5px;
    margin-left: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.experience-card {
    border-radius: 10px;
    border: 1px solid $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    cursor: pointer;
    background-color: $secondary-color;
    color: white;
    margin-left: 5px;
    margin-top: 5px;
    padding: 10px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.experience-card h5 {
    text-align: center;
    color: white;
}

.experience-card-green {
    border-radius: 10px;
    border: 1px solid $third-color;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    cursor: pointer;
    background-color: $third-color;
    color: white;
    margin-left: 5px;
    margin-top: 5px;
    padding: 10px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.experience-card-green h5 {
    text-align: center;
    color: white;
}

.experience-card small {
    position: absolute;
    bottom: 10px;
    color: white;
}

.experience-card-plus:hover {
    background-color: $secondary-color;
}

.experience {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    padding-left: 0;
    padding-right: 0;

}

.selects {
    display: flex;
}

.part {
    margin-right: 5px;
    width: 20%;
    display: inline;
}

.icon {
    margin-right: 5px;
}

.header {
    padding: 20px;
    /*  border-radius: 10px;
    border: 1px solid $text-color;*/
    margin: 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 0;
    padding-left: 0;
}

.cv-button {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: $secondary-color;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: white;
    font-family: $title-font;
    text-decoration: none;
    width: fit-content;
}

.cv-button:hover {
    background-color: $third-color;
}

.main-info {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid $text-color;
    margin: 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}





.checkbox-wrapper-28 .promoted-input-checkbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}



select {
    height: 40px;
    border: 1px solid ;
    border-radius: 5px;
    margin-top: 10px;
}
.addSkillSelect{
    margin-top: 0;
    border-radius: 0;
    width: 180px;
    height: 40px;
    border: 1px solid #4D4D4D;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}
.close {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
}

.form-style-10 {
    width: 450px;
    padding: 0;
    margin: 40px auto;
    background: #F8F8F8;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
}

.form-style-10 .inner-wrap {
    padding: 30px;
    background: #F8F8F8;
    border-radius: 6px;
    position: relative;
    margin-bottom: 15px;
}

.form-style-10 h1 {
    background: #2A88AD;
    padding: 20px 30px 15px 30px;
    margin: -30px -30px 30px -30px;
    border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
    font: normal 30px $main-font, serif;
    -moz-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    border: 1px solid #257C9E;
}

.form-style-10 h1>span {
    display: block;
    margin-top: 2px;
    font: 13px $main-font, $main-font, sans-serif;
}

.form-style-10 label {
    display: block;
    font: 13px $main-font, $main-font, sans-serif;
    color: #888;
    margin-bottom: 15px;
}

.form-style-10 input[type="text"],
.form-style-10 input[type="date"],
.form-style-10 input[type="datetime"],
.form-style-10 input[type="email"],
.form-style-10 input[type="number"],
.form-style-10 input[type="search"],
.form-style-10 input[type="time"],
.form-style-10 input[type="url"],
.form-style-10 input[type="password"],
.form-style-10 textarea,
.form-style-10 select {
    display: block;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border: 2px solid #fff;
    // box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
    // -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
    // -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
}

.form-style-10 .section {
    font: normal 20px $main-font, serif;
    color: #2A88AD;
    margin-bottom: 5px;
}

.form-style-10 .section span {
    background: #2A88AD;
    padding: 5px 10px 5px 10px;
    position: absolute;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border: 4px solid #fff;
    font-size: 14px;
    margin-left: -45px;
    color: #fff;
    margin-top: -3px;
}

.form-style-10 input[type="button"],
.form-style-10 input[type="submit"] {
    background: $third-color;
    padding: 8px 20px 8px 20px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
    font: normal 30px $main-font, serif;
    /*  -moz-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);*/
    // border: 1px solid $third-color;
    font-size: 15px;
    cursor: pointer;
}

.form-style-10 input[type="button"]:hover,
.form-style-10 input[type="submit"]:hover {
    background: $secondary-color;
    /*  -moz-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.28);
    -webkit-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.28);
    box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.28);*/
}

.form-style-10 .privacy-policy {
    float: right;
    width: 250px;
    font: 12px $main-font, $main-font, sans-serif;
    color: #4D4D4D;
    margin-top: 10px;
    text-align: right;
}

.add-skill {
    border-radius: 0;
    height: 40px;
    border: 1px solid #4D4D4D;
    border-left: 0;
    cursor: pointer;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.add-skill:hover {
    background-color: $secondary-color;
    color: white;
}</style>