<template>
<DefaultLayout>
    <h1>Profesores Disponibles</h1>
    <div class="spacer"></div>
    <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar." 
                    class="input-job" />
            </form>
            <div class="spacer"></div>
            <div class="table-container">
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>País/Ciudad</th>
                            <th>Especialidad</th>
                            <th>Tarifa</th>
                            <th>Ver detalles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Juanjo</td>
                            <td>España (Málaga)</td>
                            <td>Vue / Laravel</td>
                            <td>10€</td>
                            <td class="link-add">
                               <a href="/profesores-detalles">
                               <v-icon name="md-fileopen-round" class="ver" fill="#57afc4" scale="2"></v-icon>
                            </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
</DefaultLayout>
</template>

<script  lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import {ref} from 'vue'


const search = ref('')
</script>

<style lang="scss" scoped>
.link-add::hover{
   cursor: pointer;
}
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
th{
    color: $secondary-color!important;
}
.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}
.table-container {
  overflow-x: auto;
}
.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}
</style>