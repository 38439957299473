export default class FollowCourseService {
    private base_url = 'https://gogodev.net/gogolearn/api/follow_course'

    public async getAllCoursesFromUser(token:string) {
        const uri = this.base_url
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async checkFollowCourseStatus(token:string, course_id:string | string[]) {
        const uri = `${this.base_url}/${course_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async doFollowToCourse(token:string, course_id:string | string[]) {
        const uri = `${this.base_url}/do-follow/${course_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async doUnfollowToCourse(token:string, course_id:string | string[]) {
        const uri = `${this.base_url}/do-unfollow/${course_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }
}