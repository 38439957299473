<template>
        <div class="container">
            <div>
                <button @click="OnGoBack">Volver</button>
                <button @click="OnMarkAsRead">Marcar como leído</button>
                <button @click="OnMarkAsUnread">Marcar como NO leído</button>
            </div>
            <br />
            <h1>{{props.title}}</h1>
            <br />
            <div v-html="props.content"></div> 
        </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue'
import NotificationService from '@/services/NotificationService';
import { useAuth } from '@/store/useAuth';
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';

const notificationService = new NotificationService()
const notifications = ref([])
const store = useAuth()
const toast = useToast()

const props = defineProps(['title', 'content', 'id']);
const emit = defineEmits(['goBack'])

const OnMarkAsRead = async () => {
    if(!store.token){return}
    await notificationService.MarkNotificationAsRead(store.token, props.id)
    notifications.value = await notificationService.ReadAllNotes(store.token)
    toast.showToast("Notificación leída.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
}

const OnMarkAsUnread = async () => {
    if(!store.token){return}
    await notificationService.MarkNotificationAsUnread(store.token, props.id)
    notifications.value = await notificationService.ReadAllNotes(store.token)
    toast.showToast("Notificación marcada como no leída.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
}

const OnGoBack = () => {
    emit('goBack')
}
</script>

<style lang="scss" scoped>
button {
    padding: 10px;
    border: 0;
    outline: 0;
    background-color: $secondary-color;
    color: white;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
}

button:hover {
    background-color: $third-color;
}
ul {
    margin-top: 20px;
}
li {
    width: 70vw;
    background-color: $primary-color;
    padding: 20px;
    border-radius: 10px;
}
</style>