<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else class="animate">
            <h1>Selecciona el Lenguaje</h1>
            <RouterLink :to="{name:'forum'}" class="breadcrumbs">Dudas y Preguntas</RouterLink> / General
            <div class="spacer"></div>
            <p>¡Importante! Es el lenguaje, no el framework. Por ejemplo, si quieres ir a las preguntas de Laravel, debes seleccionar PHP. Por último, si tu lenguaje no se encuentra en la lista, o no es sobre un lenguaje en concreto, puedes seleccionar 'Otros'</p>
            <div class="spacer"></div>
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar." @keyup="handleSearch"
                    class="input-job" />
            </form>
            <div class="spacer" />
            <div class="table-container">
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Lenguaje</th>
                            <th>Seleccionar</th>
                        </tr>
                    </thead>
                    <tbody>
                       <!-- <tr><td colspan="2"><AdvertisementMain AdType="table"/></td></tr>--> 
                        <tr v-for="item in filtered" :key="item.id">
                            <td> {{ item.name }} </td>                         
                            <td>
                                <RouterLink :to="{ name: 'forum-general-list', params: { topic_id: item.id, topic_name: item.name} }"><v-icon
                                        name="md-fileopen-round" class="ver" fill="#57afc4" scale="2"></v-icon></RouterLink>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ForumService from '@/services/ForumService'
import { onMounted, ref ,Ref} from 'vue';
import ForumI from '@/interfaces/ForumInterface';
//import useWindowWidth from '@/composables/useWindowWidth';
//import AdvertisementMain from '@/components/advertisement/AdevertisementMain.vue'

const service = new ForumService()
const items:Ref<ForumI[]> = ref([])
const filtered:Ref<ForumI[]>  = ref([])
const loading = ref(false)
const search = ref('')
//const {windowWidth} = useWindowWidth()
//const tableSmall= ref(850);

onMounted(async () => {
    loading.value = true
    items.value = await service.GetAllTopics()
    filtered.value = items.value
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filtered.value = items.value.filter(item => item.name?.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
    text-decoration: none;
    color: $secondary-color;
}

.breadcrumbs:hover {
    color: $third-color;
}
.employ-card {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: $secondary-color;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.btn {
    text-decoration: none;
    margin-left: 20px;
}

.input-job {
    width: 100vw;
}

/* Estilos para la tabla */
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
th{
    color: $secondary-color!important;
}
.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}
.table-container {
  overflow-x: auto;
}
.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}
</style>