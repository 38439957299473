export default class LocationService {
    private base_url = 'https://gogodev.net/gogolearn/api/location'

  
    public async GetAllLocations()
    {
        const uri = `${this.base_url}/location/all`
        const rawResponse = await fetch(uri,  {
            method: 'GET'
          })
        const response = await rawResponse.json()
        return response
    }

    public async GetLocationsFromCountry(country:number)
    {
        const uri = `${this.base_url}/location/${country}`
        const rawResponse = await fetch(uri,  {
            method: 'GET'
          })
        const response = await rawResponse.json()
        return response
    }

    public async GetAllCountries()
    {
        const uri = `${this.base_url}/country/all`
        const rawResponse = await fetch(uri,  {
            method: 'GET'
          })
        const response = await rawResponse.json()
        return response
    }

    public async GetCountryByLocationId(location_id:number)
    {
      const uri = `${this.base_url}/country/${location_id}`
      const rawResponse = await fetch(uri,  {
          method: 'GET'
        })
      const response = await rawResponse.json()
      return response
    }
}
