export default class {
    private base_url = 'https://gogodev.net/gogolearn/api/later'

    async getAllFromUser(token:string) {
        const uri = `${this.base_url}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    async checkLaterStatus(token:string, lesson_id:number) {
        const uri = `${this.base_url}/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    async doLater(token:string, lesson_id:number) {
        const uri = `${this.base_url}/do-later/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    async doNoLater(token:string, lesson_id:number) {
        const uri = `${this.base_url}/do-no-later/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }
}