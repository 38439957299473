<!-- Tienda.vue -->
<template>
    <DefaultLayout>
      <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
      <div class="container"
      style="background-image: url('/img/photos/friends_front2.png'), url('/img/photos/forum_front2.png');
    background-repeat: no-repeat;
    background-position: left bottom, right center;
    background-size: 32%, 32%;">
       
        <h1>¡Bienvenido a nuestra sección de Webinar!</h1>
        <h2>Esta sección es exclusiva para usuarios ProDev, <br><a href="/prodev/registro" target='_blank'>pulsa aquí para ser usuario ProDev y disfruta de un mes GRATIS.</a></h2>
        <br>
        <p><b>¡Estamos encantados de presentarles esta nueva experiencia en el módulo de webinars!</b><br> Confiamos en que encontrarán este enfoque innovador enriquecedor y estimulante.<br> ¡Esperamos que  lo disfruten! </p>
        <div class="cards">
        <div class="card">
          <a :href="webinars.src" target="_blank">
            <img :src="webinars.image_url" alt="webinar">
            <h4>Fecha Inicio: {{date}}</h4>
            <h4> {{webinars.title}}</h4>
            <h5> <a :href="webinars.src" target="_blank">Acceder</a></h5>
          </a>
        </div>
      </div>
      </div>
    </DefaultLayout>
  </template>
  
  <script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import WebinarService from '@/services/WebinarService';
import WebinarI from '@/interfaces/WebinarInterface';
import { onMounted,ref,Ref } from 'vue';

const service = new WebinarService()
const webinars:Ref<WebinarI> = ref({})
const loading:Ref<boolean> = ref(false)
const date = ref('')

onMounted(async()=>{
  loading.value = true;
  webinars.value = await service.GetLatestWebinar()
  if(webinars.value.datetime == null) { loading.value = false; return}
  const timestamp = new Date(webinars.value.datetime);
// Get local date and time components
const year = timestamp.getFullYear();
const month = timestamp.getMonth() + 1; // Months are 0-based, so January is 0
const day = timestamp.getDate();
const hours = timestamp.getHours();
const minutes = timestamp.getMinutes();
const seconds = timestamp.getSeconds();

// Format the local datetime string
date.value = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${addLeadingZero(day)} ${addLeadingZero(hours)}:${addLeadingZero(minutes)}`;

  loading.value = false
})

function addLeadingZero(number:number) {
    return number < 10 ? '0' + number : number;
}
  </script>
  
  <style scoped lang="scss">

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
  }
 h1{
  margin-bottom: 15px;
 }

 .separator{
  width: 15px;
}
.cards{
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

a {
  text-decoration: none;
}
a:hover{
  color: $secondary-color;
}
.card {
  margin-top: 20px;
  height: 410px;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
}
.card:hover{
  scale: 1.01;
}

.card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.card h4,
.card h5 {
  padding: 10px;
  margin: 0;
}

.card h4 {
  font-size: 18px;
  color: $secondary-color;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}


.card h5 {
  font-size: 16px;
  color: $secondary-color;
}

h5:hover {
  cursor: pointer;
}
@media screen and (max-width:600px){
  .cards{
    flex-direction: column;
  }
  .container {
    background-image: none!important; 
  }

}
  </style>
  