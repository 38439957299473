<template>
    <div class="text-container">
      <p>Publicidad</p>
      <a href="mailto:nacho.acedo@gaudiumsoluciones.com">
      <img :src="props.imageURL" alt="Publicidad">
      </a>
    </div>
  </template>
  
  <script lang="ts" setup>
  import { defineProps } from 'vue';

  const props = defineProps(['imageURL', 'bannerLink'])
  </script>
  
  <style scoped>
  .text-container{
    height: 350px;
    padding: 10px;
    display: inline-block;
    width: 320px;
    margin: 5px;
    text-align: center;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);

  }
  
  .text-container img {
    width: 100%;
    height: auto;
   
  }
  .text-container:hover {

  transform: scale(1.01);
}
a{
     text-decoration: none;
}
  </style>
  