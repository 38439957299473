<template>
    <div class="modal-container">
        <div class="animate  send-friendship">
            <div class="close" @click="close"><v-icon name="ri-close-circle-fill" fill="lightcoral" scale="1.5" /></div>
            <img :src="logo" width="256">
            <div class="spacer"></div>
            <h3>Eliminar amigo dev</h3>
            <div class="spacer"></div>
            <p>Ya no podréis mensajearos dentro de la plataforma.<br/>Esta acción es permanente. ¿Estás seguro?</p>
            <div class="spacer"></div>
            <div class="input-box">
                <button @click="deleteFriend" class="delete">Estoy seguro, eliminar de mis amigos</button>
                <button @click="close">Cancelar</button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import logo from '@/assets/svg/ademass.svg'
import { defineEmits, ref, defineProps } from 'vue'
import { Toast } from '@/interfaces/ToastInterface'
import { useToast } from '@/store/useToast'
import FriendshipService from '@/services/FriendshipService'
import { useAuth } from '@/store/useAuth'

const emit = defineEmits(['close'])
const toast = useToast()
const friendshipService = new FriendshipService()
const store = useAuth()
const props = defineProps(['id'])
const deleteFriend = async () => {
    if(!store.token) {
        return
    }
    const response = await friendshipService.deleteFriend(store.token, props.id)
    toast.showToast(response, Toast.INFO)
    setTimeout(function () { toast.hideToast() }, 4000);
    emit('close')
}

const close = () => {
    emit('close')
}
</script>

<style lang="scss" scoped>
.close {
    top: -10px;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    font-size: 12px;
}
.input-box {
    display: flex;
    width: 100%;

    button {
        border: 1px solid #ccc;
        border-radius: 10px;
        margin: 5px;
        cursor: pointer;
        padding: 20px;
    }
    button:hover {
        background-color: $secondary-color;
        color: white;
        border: 1px solid $secondary-color;
    }
}
.delete {
    background-color: lightcoral;
    color: white;
}
input {
    border: 1px solid #ccc;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 10px;
    width: 80%;
}

.modal-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999999999999999999999;
}

.send-friendship {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    border-radius: 10px;
    border: 1px solid $primary-color;
    margin-bottom: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    max-width: 600px;
}
</style>