import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'

interface Conversation {
  id: number,
  username: string,
  messages: Array<string>
}

interface ConversationResponse {
  user: {
    name: string
  },
  messages: Array<string>
}

interface ConversationState {
  conversations: Ref<Array<Conversation>>,
  maxConversations: Ref<number>,
  base_url: Ref<string>,
  max_amount_messages: Ref<number>
}

export const useConversations = defineStore('useConversations', {
  state: (): ConversationState => ({
    conversations: ref([]),
    maxConversations: ref(3),
    base_url: ref('https://gogodev.net/gogolearn/api/friendship/message'),
    max_amount_messages: ref(20)
  }),
  actions: {
    async addConversation(token:string, friendId:number):Promise<boolean> {
        if(this.conversations.length >= this.maxConversations) {
          return false
        }

        const uri = `${this.base_url}/get-latest/${this.max_amount_messages}/${friendId}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response:ConversationResponse = await rawResponse.json()

        this.conversations.push({
          id: friendId,
          username: response.user.name,
          messages: response.messages
        })

        this.markAsRead(token, friendId)

        return true
    },
    async markAsRead(token:string, friendId:number) {
      const uri = `${this.base_url}/mark-as-read/${friendId}`
      const rawResponse = await fetch(uri, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
    },
    async updateConversation(token:string, friendId:number) {
      const uri = `${this.base_url}/get-latest/${this.max_amount_messages}/${friendId}`
      const rawResponse = await fetch(uri, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`
          }
      })
      const response:ConversationResponse = await rawResponse.json()

      this.conversations = this.conversations.map((conversation) => {
        if(conversation.id != friendId) {
          return conversation
        } else {
          return {
            id: friendId,
            username: response.user.name,
            messages: response.messages
          }
        }
      })
    },
    async sendMessage(token:string, receiver:number, message:string) {
      const uri = `${this.base_url}/send`
      const rawResponse = await fetch(uri, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'receiver': receiver,
          'message': message
        })
      })
      const response = rawResponse.json()
      this.updateConversation(token, receiver)
      this.markAsRead(token, receiver)
    },
    removeConversation(id:number):boolean {
        
        if(this.conversations.length <= 0) {
          return false
        }

        this.conversations = this.conversations.filter((conversation) => conversation.id != id)
        return true

    }
  },
  persist: true,
})