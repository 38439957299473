<template>
    <div class="animate codebite">
        <div v-if="showDelete" class="animate modal">
                <div class="modal-inner">
                    <div style="float: right; cursor: pointer;" @click="showDelete = false"><v-icon
                            name="ri-close-circle-fill" fill="lightcoral"></v-icon></div>
                    <h3>¿Estás seguro de que deseas borrar este CodePost?</h3>
                    <p>Esta acción no se puede deshacer</p>
                    <div style="display: flex; align-items: center;">
                    <button class="new-question" @click="handleDelete" style="background-color: lightcoral; color: white;">Borrar CodePost</button>
                    <button class="new-question" @click="showDelete = false">Cancelar</button>
                </div>
                </div>
            </div>
        <div class="codebite-title">
            <h3>{{props.codepost.title}} <span v-if="isOwner" class="delete-icon" @click="showDelete = true"> <v-icon name="md-delete" class="ver" fill="lightcoral" scale="1"></v-icon></span></h3>
        </div>
        <div class="codebite-content">
        <img :src="props.codepost.image_uri" style="width: 100%;" />
        <div class="spacer"></div>
          <div v-html="props.codepost.short_description"></div>
          <div class="spacer"></div>
            <button class="read-more-codepost" @click="readMore">Leer CodePost</button>
        </div>
        <div class="social-container">
            <hr />
            <div class="social">
                <div class="profile" v-if="props.codepost.user">
                    <img v-if="props.codepost.user.profile_picture_uri" :src="props.codepost.user.profile_picture_uri" />
                    <span v-else>{{ props.codepost.user.name.substring(0, 2) }}</span>
                </div>
                <div class="profile" v-else>
                    <span>...</span>
                </div>
                <RouterLink :to="{name:'social-profile', params: {id: props.user.id}}" style="text-decoration: none;">
                <h5 class="user-name" v-if="props.user.nickname">@{{props.user.nickname}}</h5>
                <h5 class="user-name" v-else>@{{props.user.name.substring(0,3)}}{{ props.user.id }}</h5>
            </RouterLink>
                <h5 style="font-size: 18px;">{{likes}}</h5>
                <v-icon v-if="!liked" class="code-bite-icon" name="bi-hand-thumbs-up-fill" @click="handleDoCodePostLike" />
                <v-icon v-else class="code-bite-icon-marked" name="bi-hand-thumbs-up-fill" @click="handleUndoCodePostLike" />
                <v-icon v-if="!isSaved" class="code-bite-icon" name="md-save-sharp" @click="handleStoreCodePost" />
                <v-icon v-else class="code-bite-icon-marked" name="md-save-sharp" @click="handleUndoStoreCodePost" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, onMounted, defineEmits } from 'vue'
import ChannelService from '@/services/ChannelService'
import { useAuth } from '@/store/useAuth'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import router from '@/router';
import ProfileService from '@/services/ProfileService'

const toast = useToast()
const props = defineProps(['codepost', 'user'])
const emit = defineEmits(['refresh'])
const likes = ref(0)
const service = new ChannelService()
const isSaved = ref(false)
const liked = ref(false)
const store = useAuth()
const showDelete = ref(false)
const profileService = new ProfileService()
const isOwner = ref(false)

onMounted(async () => {
    likes.value = await service.CountCodePostLikes(props.codepost.id)

    if(!store.token) { return}
    isSaved.value = await service.CheckIfCodePostIsStored(store.token, props.codepost.id)
    liked.value = await service.CheckIfCodePostIsLiked(store.token, props.codepost.id)
    const user = await profileService.MyUser(store.token)
    if(user.id == props.user.id) { isOwner.value = true}
})

const readMore = () => {
    router.push({name: 'codepost-view', params: {id: props.codepost.id}})
}

const handleDelete = async () => {
    if(!store.token) {
        toast.showToast("Debes acceder con tu cuenta o registrarte para realizar esta acción", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    const response = await service.DeleteCodePost(store.token, props.codepost.id)
    if(response.success) {
        toast.showToast("CodePost Borrado", Toast.SUCCESS)
        setTimeout(function () { toast.hideToast() }, 4000)
        emit('refresh')
        showDelete.value = false
        return
    } else {
        toast.showToast(response.message, Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        showDelete.value = false
    }
}

const handleStoreCodePost = async () => {
    if(!store.token) {
        toast.showToast("Debes acceder con tu cuenta o registrarte para realizar esta acción", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    await service.MarkCodePostAsSaved(store.token, props.codepost.id)
    toast.showToast("CodePost guardado. Puedes ver tus CodePosts guardados en la sección del menú CodePosts guardados (Mi Sección)", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 8000);
    isSaved.value = true
}

const handleUndoStoreCodePost = async () => {
    if(!store.token) {
        toast.showToast("Debes acceder con tu cuenta o registrarte para realizar esta acción", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    await service.UndoCodePostAsSaved(store.token, props.codepost.id)
    toast.showToast("Eliminado de tus CodePosts guardados", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    isSaved.value = false
}

const handleDoCodePostLike = async () => {
    if(!store.token) {
        toast.showToast("Debes acceder con tu cuenta o registrarte para realizar esta acción", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    await service.DoCodePostLike(store.token, props.codepost.id)
    toast.showToast("Te gusta este CodePost", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    likes.value++
    liked.value = true;
}

const handleUndoCodePostLike = async () => {
    if(!store.token) {
        toast.showToast("Debes acceder con tu cuenta o registrarte para realizar esta acción", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    await service.UndoCodePostLike(store.token, props.codepost.id)
    toast.showToast("Ya no te gusta este CodePost", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    likes.value--
    liked.value = false;
}
</script>

<style lang="scss" scoped>
.delete-icon {
    float: right;
    cursor: pointer;
}
.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;

    h3 {
        margin-bottom: 15px;
    }

    p {
        font-size: 12px;
    }
}
.read-more-codepost {
    padding: 10px;
    width: 100%;
    text-align: center;
    color: white;
    background-color: $third-color;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    outline: none;
    border: none;
    cursor: pointer;
}
.read-more-codepost:hover {
    background-color: $secondary-color;
}
.codebite-content {
    margin-top: 5px;
    padding-bottom: 50px;
}
.code-bite-icon {
    fill: #aaa;
    cursor: pointer;
}
.code-bite-icon:hover {
    fill: $third-color;
}
.code-bite-icon-marked {
    fill: $secondary-color;
    cursor: pointer;
}

.profile {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    span {
        color: $secondary-color;
        font-weight: bold;
        font-size: 18px;
    }
}

.codebite {
    position: relative;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    padding: 15px;
    border-radius: 10px;
    margin: 5px;
    width: 610px;

    p {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .codebite-title {
        margin-top: -15px;
        margin-left: -15px;
        margin-right: -15px;
        padding: 10px;
        background-color: #333;

        h3 {
            color: white;
            font-size: 14px;
            text-align: center;
        }
    }
}

.social-container {
    position: absolute;
    bottom: 0;
}

.social {
    margin-top: 5px;
    display: flex;
    align-items: center;

    .user-name {
        color: #999;
        margin-left: 5px;
        margin-right: 5px;
    }

    h5 {
        color: $secondary-color;
    }
}

@media(max-width: 950px) {
.codebite {
    width: 100%;
}
}
</style>