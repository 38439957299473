import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueLazyLoad from 'vue3-lazyload'
import { OhVueIcon, addIcons } from "oh-vue-icons";
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import myUpload from 'vue-image-crop-upload/upload-3.vue';



import {BiPuzzleFill,FaPassport, GiWorld, BiBookHalf,HiSolidTemplate,BiCast,CoYoutube,BiTelephoneFill,BiGraphUpArrow,MdWorkRound,BiGithub,BiTwitter,FaPortrait,BiInfoCircle,BiCalendarDateFill,FaShoppingCart,MdLibrarybooksRound,MdDevelopermode,FaLaptopCode,MdWorkspacepremiumSharp,HiShoppingCart,BiBookFill,MdSupportagentTwotone,IoLogoTwitter,CoTiktok,IoLogoYoutube,CoInstagram,BiLinkedin,BiFacebook,IoBook,BiHandThumbsUpFill,MdLineweightTwotone, SiCoderwall, MdStarsRound, BiFilePost, MdDelete, BiFileCodeFill, FaUserEdit, RiMedalFill, BiHandThumbsDownFill, BiCameraFill, GiThreeFriends, BiAwardFill, MdQuestionanswerRound, SiDialogflow, MdFibernewRound,MdReplay,BiPatchQuestionFill,RiUserFollowFill, BiMenuAppFill, IoSend, BiPlusCircleFill, RiLockPasswordFill,MdMarkemailread,MdEmail,FaUserAlt,IoListCircleSharp, BiPencilFill, MdDescription,MdSummarize, MdFileopenRound,MdCastforeducation, BiQuestionCircleFill, BiArrowLeftCircleFill, MdDaterange, BiArrowRightShort, RiMenuUnfoldFill, GiTeacher, BiEyeFill, AiAds, SiAdblock, FaRegularWindowMaximize, LaWindowMinimizeSolid,MdSaveSharp, RiMessage2Fill, IoPersonAddSharp, GiMagnifyingGlass,RiCloseCircleFill, BiChatFill, FaUserFriends, BiBriefcaseFill, HiSolidLocationMarker, SiExpensify, RiMoneyEuroCircleFill, FaFilePdf, MdNewreleasesRound, BiBellFill, BiDiscord,  BiPlusSquareDotted, GiTabletopPlayers, IoCloseCircleSharp, BiPersonWorkspace, MdWorkhistory, MdDownloading, BiArrowRightCircleFill, HiEmojiHappy, RiEmotionUnhappyLine, BiCheckSquareFill, FaHandPointUp, LaChalkboardTeacherSolid, HiSolidVideoCamera,RiForbidFill, BiCheckCircleFill, BiCheckCircle, MdWatchlaterOutlined, MdWatchlaterTwotone, BiCircle, BiHeartFill, BiHeart, BiCardHeading, BiCardList, FaTable, FaFilter, MdHomeSharp, PrSearch, MdEmailRound, LaPowerOffSolid, LaUserCircle, MdRouteTwotone, CoEducation, GiSkills, GiDiploma, MdPlaylessonRound, MdWatchlater, MdNotes, RiLoader2Fill, HiMenuAlt3 } from "oh-vue-icons/icons";
addIcons(BiPuzzleFill,FaPassport, GiWorld, BiBookHalf,HiSolidTemplate,BiCast,CoYoutube,BiTelephoneFill,BiGraphUpArrow,MdWorkRound,BiGithub,BiTwitter,FaPortrait,BiInfoCircle,BiCalendarDateFill,FaShoppingCart,MdLibrarybooksRound,MdDevelopermode,FaLaptopCode,MdWorkspacepremiumSharp,HiShoppingCart,BiBookFill,MdSupportagentTwotone,IoLogoTwitter,CoTiktok,IoLogoYoutube,CoInstagram,BiLinkedin,BiFacebook,IoBook,BiHandThumbsUpFill, MdLineweightTwotone, SiCoderwall, MdStarsRound, BiFilePost, MdDelete, BiFileCodeFill, FaUserEdit, RiMedalFill, BiHandThumbsDownFill, BiCameraFill, GiThreeFriends, BiAwardFill, MdQuestionanswerRound, SiDialogflow, MdFibernewRound,MdReplay,BiPatchQuestionFill,RiUserFollowFill, BiMenuAppFill, IoSend, BiPlusCircleFill, RiLockPasswordFill,MdMarkemailread,MdEmail,FaUserAlt,IoListCircleSharp, BiPencilFill, MdDescription, MdSummarize, MdFileopenRound,MdCastforeducation, BiQuestionCircleFill, MdDaterange,BiArrowLeftCircleFill, BiArrowRightCircleFill, BiArrowRightShort, RiMenuUnfoldFill, AiAds, GiTeacher, BiEyeFill, SiAdblock, FaRegularWindowMaximize,MdSaveSharp, LaWindowMinimizeSolid, RiMessage2Fill, IoPersonAddSharp, GiMagnifyingGlass, RiCloseCircleFill, BiChatFill, FaUserFriends, BiBriefcaseFill, HiSolidLocationMarker, SiExpensify, RiMoneyEuroCircleFill, FaFilePdf, MdNewreleasesRound, BiBellFill, BiDiscord, BiPlusSquareDotted, GiTabletopPlayers, IoCloseCircleSharp, BiPersonWorkspace, MdWorkhistory, MdDownloading, BiArrowRightCircleFill, HiEmojiHappy, RiEmotionUnhappyLine, BiCheckSquareFill, FaHandPointUp, LaChalkboardTeacherSolid,RiForbidFill, HiSolidVideoCamera, BiCheckCircleFill, BiCheckCircle, MdWatchlaterOutlined, MdWatchlaterTwotone, BiCircle, BiHeartFill, BiHeart, BiCardHeading, BiCardList, FaTable, FaFilter, MdHomeSharp, PrSearch, MdEmailRound, LaPowerOffSolid, LaUserCircle, MdRouteTwotone, CoEducation, GiSkills, GiDiploma, MdPlaylessonRound, MdWatchlater, MdNotes, RiLoader2Fill, HiMenuAlt3);

const app = createApp(App)
const pinia = createPinia()
app.component("v-icon", OhVueIcon);
app.component('QuillEditor', QuillEditor);
app.component('myUpload', myUpload)
pinia.use(piniaPluginPersistedstate)
app.use(pinia).use(router).use(VueLazyLoad,{}).mount('#app')
