<template>
    <transition name="slide-up"  v-if="activity" style="margin-top: 25px;">
        <div class="container " v-if="isHidden">
            <div>
                <h1 style="text-align: center;">Conéctate con el Éxito</h1>
                <p>En Ademass, estamos comprometidos con tu crecimiento profesional y queremos que alcances tus metas en el
                    mundo del desarrollo. Nos enorgullece ofrecer una plataforma de aprendizaje integral, con
                    certificaciones y oportunidades de empleo, todo de forma completamente gratuita.</p>
                <p>Queremos estar aún más cerca de ti, compartir experiencias, noticias y recursos exclusivos que impulsen
                    tu carrera. ¿Cómo? ¡A través de nuestras redes sociales! 🌐 Por ello, te animamos a seguirnos. Es una
                    forma genial y gratuita de ayudar a nuestro crecimiento. <b>Nos ayuda mucho, más de lo que pueda
                        parecer</b>, y esto se traduce en, sobre todo, mejores ofertas de empleo para la comunidad. ¡Te lo
                    agrademos mucho!</p>
                <p>Tu éxito es nuestra prioridad, y queremos celebrar cada logro contigo. ¡Construyamos juntos un futuro
                    lleno de oportunidades!</p>
            </div>
            <div class="icons">
                <a href="https://www.facebook.com/profile.php?id=100092274511050" target="_blank"> <v-icon
                        name="bi-facebook" class="icon" /></a>
                <a href="https://twitter.com/Ademass_TD" target="_blank"> <v-icon name="io-logo-twitter" class="icon" /></a>
                <a href="https://www.linkedin.com/company/ademass-training-development/" target="_blank"><v-icon
                        name="bi-linkedin" class="icon" /></a>
                <a href="https://www.instagram.com/ademass_td/" target="_blank"><v-icon name="co-instagram"
                        class="icon" /></a>
                <a href="https://www.youtube.com/channel/UCmviPiZcwo8FduTBC8CYRsg" target="_blank"><v-icon
                        name="io-logo-youtube" class="icon" /></a>
                <a href="https://www.tiktok.com/@ademass_td" target="_blank"><v-icon name="co-tiktok" class="icon" /></a>
            </div>
            <div class="buttons">
                <button class="btn" @click="hideParent(false)">Cerrar</button>
                <div class="spacer"></div>
                <button @click="hideParent(true)" v-if="store.token" class="btn-ok">¡Hecho! No mostrar más</button>
            </div>
        </div>
    </transition>
</template>

<script  lang="ts" setup>
import { Ref, ref } from 'vue';
import { useAuth } from '@/store/useAuth';
import { onMounted } from 'vue';
import ActivityService from '@/services/ActivityService';

const loading = ref(true)
const store = useAuth()
const isHidden: Ref<boolean> = ref(true);
const service = new ActivityService()
const activity = ref(false)

onMounted(async () => {
    if (store.token === null) {
        return;
    }
    loading.value = true
    try {
        activity.value = await service.deleteBanner(store.token)
    }
    catch (error) {
        console.error("Error al eliminar el banner:", error);
    }
    finally {
        loading.value = false
    }
});

async function hideParent(forever: boolean) {
    if (store.token === null) {
        return;
    }
    isHidden.value = false;
    if (forever) {
        await service.hiddenBanner(store.token)
    }

}
</script>

<style lang="scss" scoped>
.h1 {
    width: 100%;
}

.container {
    border: 2px solid #57afc4;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

p {
    margin-top: 10px;
}

.icon {
    width: 35px;
    height: 35px;
    margin: 8px;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;
}

.icon:hover {
  transform: scale(1.1);
  color: #57afc4; 
}

.icons {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.btn {
    padding: 10px;
    background-color:red;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-family: $title-font;
}
.btn:hover {
    background-color: rgb(247, 176, 176);
}

.btn-ok {
    padding: 10px;
    background-color: $third-color;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-family: $title-font;
}
.btn-ok:hover {
    background-color: $secondary-color;
}

.buttons {
    display: flex;
    flex-direction: row;
}

.spacer {
    width: 10px;
}

.hidden {
    display: none;
}

.slide-up-enter-active,
.slide-up-leave-active {
    transition: max-height 2s ease-in-out, opacity 1s ease-in-out;
    overflow: hidden;
}

.slide-up-enter-from,
.slide-up-leave-to {
    max-height: 0;
    opacity: 0;
}

.slide-up-enter-to,
.slide-up-leave-from {
    max-height: 500px;
    opacity: 1;
}</style>