<template>
    <div v-if="loading">
        <v-icon name="RiLoader2Fill" />
    </div>
    <div v-else>
        <AdvertisementFull v-if="props.AdType == 'full'" :imageURL="imageURL" :bannerLink="bannerLink" />
        <AdvertisementMenu v-if="props.AdType == 'menu'" :imageURL="imageURL" :bannerLink="bannerLink" />
        <AdvertisementCard v-if="props.AdType == 'card'" :imageURL="imageURL" :bannerLink="bannerLink" />
        <AdvertisementList v-if="props.AdType == 'list'" :imageURL="imageURL" :bannerLink="bannerLink" />
        <AdvertisementTable v-if="props.AdType == 'table'" :imageURL="imageURL" :bannerLink="bannerLink" />
    </div>
</template>

<script  lang="ts" setup>
import AdvertisementFull from './AdevertisementFull.vue'
import AdvertisementMenu from './AdevertisementMenu.vue'
import AdvertisementCard from './AdvertisementCard.vue'
import AdvertisementList from './AdvertisementList.vue'
import AdvertisementTable from './AdvertisementTable.vue'
import AdService from '@/services/AdService'
import { defineProps, onMounted, ref } from 'vue'

const props = defineProps(['AdType'])
const imageURL = ref('')
const bannerLink = ref('')
const loading = ref(true)
const service = new AdService()

onMounted(async () => {
    const response = await service.GetAdvertisement()
    bannerLink.value = response.bannerLink
    switch (props.AdType) {
        case 'full':
            imageURL.value = response.image_url_full
            break;
        case 'card':
            imageURL.value = response.image_url_card
            break;
        case 'table':
            imageURL.value = response.image_url_table
            break;
        case 'list':
            imageURL.value = response.image_url_list
            break;
        case 'menu':
            imageURL.value = response.image_url_menu
            break;
        default:
            console.error('advertisement component does not have a valid AdType');
            break;
    }
    loading.value = false
})

</script> 

<style scoped></style>