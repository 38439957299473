<template>
    <DefaultLayout>
        <div v-if="showFeedback" class="animate modal">
                <div class="modal-inner">
                    <h3>Pregunta enviada</h3>
                    <img v-if="windowWidth >= 1000" src="../../public/img/lms/fullstack.jpg" style="width: 100%;"/>
                    <p><b style="font-size: 14px;">¡La comunidad se pone en marcha!</b><br /><br />Puedes llevar un seguimiento de las respuestas recibidas desde la sección 'Mis Preguntas' del menú (sección Foro) Una vez recibida una respuesta satisfactoria, no olvides marcar la pregunta como resuelta. ¡Gracias!</p>
                    <RouterLink :to="{name: 'my-questions'}" style="text-decoration: none;">
                        <button class="new-question">Aceptar</button>
                    </RouterLink>
                </div>
            </div>
        <div class="animate form">
            <h1>Redactar Pregunta</h1>
            <div class="spacer"></div>
            <h3>Título de la Pregunta</h3>
            <p>Debe ser conciso y no extenderse más allá de 150 caracteres</p>
            <input class="input" v-model="name" type="text" max="150" maxlength="150" required
                placeholder="Ejemplo: Error - No se reconoce el comando npm cuando trato de lanzar el servidor de pruebas" />
            <div class="spacer"></div>
            <h3>Explicación del problema</h3>
            <p>Detalla cuál es el problema al que te estás enfrentando que no logras solucionar. Asegúrate de darle un formato legible. Usa la herramienta <b>&lt;/&gt;</b> para escribir código. Mejorará mucho la legibilidad de la pregunta</p>
            <quill-editor ref="descriptionBox" theme="snow" v-model="description" toolbar="full" @update:content="hanldeDescription"></quill-editor>
            <div class="spacer"></div>
            <h3>Lo que he intentado hacer hasta ahora</h3>
            <p>Explica a la comunidad qué has tratado de realizar para solucionarlo</p>
            <quill-editor ref="triesBox" theme="snow" v-model="tries" toolbar="full"  @update:content="hanldeTries"></quill-editor>
            <div class="spacer"></div>
            <h3>Repositorio (opcional)</h3>
            <p>Prové un enlace al repositorio con tu código para ayudar a reproducir el error</p>
            <input class="input" v-model="repository" type="text"
                placeholder="Ejemplo: https://github.com/ademass/ejemplo" />
            <div class="spacer"></div>
            <button class="new-question" @click="storeQuestion">Enviar Pregunta <v-icon name="io-send"
                    style="margin-left: 10px;"></v-icon></button>
        </div>
    </DefaultLayout>
</template>

<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref ,Ref} from 'vue'
import { useRoute } from 'vue-router'
import ForumService from '@/services/ForumService'
import { useAuth } from '@/store/useAuth'
import useWindowWidth from '@/composables/useWindowWidth'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';


const toast = useToast()
const service = new ForumService()
const store = useAuth()
const route = useRoute()
const name = ref('')
const description = ref('')
const tries = ref('')
const repository = ref('')
const course_id:Ref<string | string[]> =ref( route.params.course_id)
const lesson_id:Ref<string | string[]> =ref(route.params.lesson_id) 
const showFeedback = ref(false)
const {windowWidth} = useWindowWidth()
const descriptionBox = ref()
const triesBox = ref()
const course_id_str = Array.isArray(course_id.value) ? course_id.value[0] : course_id.value;
const lesson_id_str = Array.isArray(lesson_id.value) ? lesson_id.value[0] : lesson_id.value;
const hanldeDescription = () => {
    description.value = descriptionBox.value.getHTML()
}

const hanldeTries = () => {
    tries.value = triesBox.value.getHTML()
}

const storeQuestion = async () => {
    if (!store.token) { return; }
    const response = await service.WriteQuestion(store.token, true, name.value, description.value,  parseInt(course_id_str), undefined, parseInt(lesson_id_str), tries.value, repository.value)
    console.log("response", response)
    if (response != true) {
        toast.showToast("Upps... Algo salió mal.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    showFeedback.value = true
}
</script>

<style scoped lang="scss">

.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;
    h3 {
        margin-bottom: 15px;
    }
    p {
        font-size: 12px;
    }
}
.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    margin-top: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.new-question:hover {
    background-color: $secondary-color;
    color: white;
    svg {
        fill: white;
    }
}
h3 {
    color: $secondary-color;
}

.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    margin-top: 15px;
    margin-left: 0;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.new-question:hover {
    background-color: $secondary-color;
    color: white;

    svg {
        fill: white;
    }
}

.form {
    width: 100%;
}

.input {
    width: 100%;
    padding: 10px;
    outline: 0;
    border: 2px solid $primary-color;
    border-radius: 5px;
}

p {
    font-size: 12px;
    margin-bottom: 5px;
}
</style>