<template>
    <div class="advertisement" >
      <a :href="props.bannerLink" target="_blank">   
      <img :src="props.imageURL " alt="Publicidad" style="height: 110px;">
    </a>
    </div>
  </template>
  
  <script lang="ts" setup>
   import { defineProps } from 'vue';

   const props = defineProps(['imageURL', 'bannerLink'])

  </script>
  
  <style scoped lang="scss">
  .advertisement {
    display: flex;
    justify-content:space-around;
     width: 100%;
     border: 2px solid $secondary-color ;
     background-color: $secondary-color ;
    text-align: center;
    max-height: 120px;
    border-radius: 8px;

  }
  p{
    margin-top: 10px;
  }
  @media only screen and (max-width: 750px) {
  .advertisement{
    max-height: 500px;
  }
}

  
  </style>
  