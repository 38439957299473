<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div class="container" v-else>
            <h1 style="margin-bottom: 20px;">Mis apuntes</h1>
            <RouterLink :to="{name: 'my-notes-pdf'}" class="print">Imprimir apuntes en PDF</RouterLink>
            <div class="spacer" />
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Filtrar por contenido."
                    @keyup="handleSearchByContent" v-if="activeSearch == 2" />
                    <input type="text" v-model="search" placeholder="Filtrar por curso."
                    @keyup="handleSearchByCourse" v-if="activeSearch == 0" />
                    <input type="text" v-model="search" placeholder="Filtrar por lección."
                    @keyup="handleSearchByLesson" v-if="activeSearch == 1" />
                    <div class="aux-btns">
                <button @click.prevent="handleActiveSearch(0)" class="aux-btn"><v-icon name="la-chalkboard-teacher-solid"
                        title="Filtrar por curso" fill="#fff"/></button>
                <button @click.prevent="handleActiveSearch(1)" class="aux-btn"><v-icon name="hi-solid-video-camera"
                        title="Filtrar por lección" fill="#fff"/></button>
                <button @click.prevent="handleActiveSearch(2)" class="aux-btn last"><v-icon name="fa-table"
                        title="Filtrar por nota (Contenido del apunte)" fill="#fff"/></button>
            </div>
            </form>
            <div class="spacer" />
            <div v-if="loading">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
            </div>
            <div class="container-items" v-else>
                <NoteRow v-for="note in filteredNotes" :key="note.id" :content="note"/>
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import NoteService from '@/services/NoteService'
import { onMounted, ref, Ref } from 'vue'
import {useAuth} from '@/store/useAuth'
import NoteRow from '@/components/cards/NoteRow.vue'
import LessonService from '@/services/LessonService'
import CourseService from '@/services/CourseService'

const noteService = new NoteService()
const lessonService = new LessonService()
const courseService = new CourseService()
const loading = ref(true)
const store = useAuth()
const notes:Ref<any> = ref({})
const fullNotes:Ref<any> = ref({})
const filteredNotes:Ref<any> = ref([])
const activeSearch = ref(0)
const search: Ref<string> = ref('')


onMounted(async () => {
    loading.value = true
    if (store.token) {
        notes.value = await noteService.GetAllNotesFromUser(store.token)
    }
    notes.value.forEach(async (note:any) => {
        const lesson = await lessonService.getLesson(note.lesson_id)
        const course = await courseService.GetCourse(lesson.course_id)
        fullNotes.value.push({
            'id': note.id,
            'content': note.note_content,
            'lesson': lesson.name,
            'course': course.name,
            'date': note.updated_at,
            'course_id': lesson.course_id,
            'lesson_id': note.lesson_id
        })
    });
    Promise.all(fullNotes.value)
    filteredNotes.value = fullNotes.value
    loading.value = false
})

const handleActiveSearch = (active:number) => {
    activeSearch.value = active
    search.value = ''
    filteredNotes.value = fullNotes.value
}

const handleSearchByContent = () => {
    loading.value = true
    filteredNotes.value = fullNotes.value.filter((item:any) => item.content.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}

const handleSearchByLesson = () => {
    loading.value = true
    filteredNotes.value = fullNotes.value.filter((item:any) => item.lesson.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}

const handleSearchByCourse = () => {
    loading.value = true
    filteredNotes.value = fullNotes.value.filter((item:any) => item.course.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}

</script>

<style lang="scss" scoped>
.print {
    margin-top: 70px;
    padding: 10px;
    background-color: $third-color;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    color: white;
    text-decoration: none;
}
.print:hover {
    background-color: $secondary-color;
}
.container-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.container {
    min-width: 70vw;
}
.img {
    border-radius: 10px;
}

.config {
    display: flex;
    align-items: center;
}
.icon {
    margin: 25px;
}
.search-form {
    width: 50%;
    display: flex;
    align-items: center;
    background-color: $primary-color;
    border-radius: 5px;
    border: 0;
    width: 100%;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    height: 37px;
    overflow-y: hidden;
}
.aux-btns {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.aux-btn {
    height: 37px;
    background-color: $secondary-color;
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.aux-btn:hover {
    background-color: $third-color;
}
.last {
    border-radius: 0 5px 5px 0;
}

.divider {
    background-color: $secondary-color;
    width: 100%;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.container {
    width: 100%;
}
</style>