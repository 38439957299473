<template>
    <div class="container">
        <img :src="logo" v-if="showLogin" class="logo">
        <div class="box auth-box">
            <LoginForm v-if="showLogin" />
            <RegisterForm v-else />
            <footer>
                <small @click="toggleShowLogin" v-if="showLogin" class="animate">Aún no tengo cuenta, registrarme.</small>
                <small @click="toggleShowLogin" v-else class="animate">Ya tengo cuenta, acceder.</small>
                <router-link :to="{name: 'home'}" class="animate no-underscore"><small class="animate">Volver a inicio.</small></router-link>
                 
                <router-link :to="{name: 'recovery'}" class="animate no-underscore"><small class="animate">Olvidé mi contraseña.</small></router-link>
            </footer>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import LoginForm from '@/components/auth/LoginForm.vue'
import RegisterForm from '@/components/auth/RegisterForm.vue'
import logo from '@/assets/svg/ademass_slogan.svg'

const showLogin = ref(true)

const toggleShowLogin = () => showLogin.value = !showLogin.value
</script>
 
<style lang="scss" scoped>
.logo {
    width: 30%;
    margin-bottom: 20px;
}
.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
}

.container h3 {
    color: white;
    font-size: 60px;
    font-family: $logo-font;
    width: 100%;
    text-align: center;
}
.auth-box {
    width: 600px;
    min-height: 280px;
    position: relative;
}

.auth-box footer {
    position: absolute;
    bottom: 15px;
    width: 95%;
    padding-top: 5px;
    border-top: 1px solid $primary-color;
}

.auth-box footer small {
    cursor: pointer;
}

.auth-box footer small:hover {
    color: $title-color;
}

.no-underscore {
    margin-left: 10px;
    text-decoration: none;
}

.no-underscore:hover {
    color: $title-color;
}

@media(max-width: 900px) {
    .container {
        flex-wrap: wrap;
        margin-top: 25x;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: auto;
    }

    .container h3 {
        font-size: 40px;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .logo {
        margin-top: 50px;
    }
    .auth-box {
        width: 100%;
        min-height: 350px;
        height: auto;
    }
}
</style>