export default class PortFolio {
    /* eslint-disable */
    private baseURI = 'https://gogodev.net/gogolearn/api';


    async GetCandidateInfo(id:string) {
        const uri: string = `${this.baseURI}/lms_portfolio/${id}`;
        const rawResponse = await fetch(uri, {
            method: 'GET',
            cache: 'no-store',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const response = await rawResponse.json();
        return response;
    }
    public async SetSocialStudy(token: string, github:string, instagram:string, linkedin:string, facebook:string,youtube:string,tiktok:string, web:string,other_sn:string) {
        const uri:string = `${this.baseURI}/employ_profile/set-social-networks`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'github': github,
                'instagram': instagram,
                'linkedin': linkedin,
                'facebook': facebook,
                'youtube': youtube,
                'tiktok': tiktok,
                'web':web,
                'other_sn':other_sn
            })
        })
        const response = await rawResponse.json()
        return response
    }
    public async SetAboutMe(token: string, slogan:string, profile_description:string) {
        const uri:string = `${this.baseURI}/employ_profile/set-portfolio`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'slogan': slogan,
                'profile_description': profile_description
               
            })
        })
        const response = await rawResponse.json()
        return response
    }

}
