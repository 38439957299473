export default class RecoveryService {
    private base_url = 'https://gogodev.net/gogolearn/api/recovery'

    public async requestRecovery(email:string) {
        const uri = `${this.base_url}`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               'email':email
            })
        })
        const response = await rawResponse.json()
        return response
    }

    public async checkIfValid(token:string|string[]) {
        const uri = `${this.base_url}/check-if-valid/${token}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }

    public async updatePassword(token:string|string[],password:string,new_password:string) {
        const uri = `${this.base_url}/update-password`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               'token':token,
               'password':password,
                'new_password':new_password
            })
        
        })
        const response = await rawResponse.json()
        return response
    }
}