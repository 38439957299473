<!-- Tienda.vue -->
<template>
    <DefaultLayout>
      <ProdevValidator />
      <div class="container"
      style="background-image: url('/img/challens/Challenge.png');
    background-repeat: no-repeat;
    background-position: left bottom, right center;
    background-size: 32%, 32%;">
       
        <h1>¡Realiza el reto de la semana!</h1>
        <h2>Esta sección es exclusiva para usuarios ProDev, <br><a href="/prodev/registro" target='_blank'>pulsa aquí para ser usuario ProDev y disfruta de un mes GRATIS.</a></h2>
        <br>
        <div class="cards">
        <div class="card">
          <a href="#">
            <img :src="challenge?.image_url" alt="challenge"  class="img-challens">
            <h4>{{ challenge?.title }}</h4>
            <h4>Hashtag del reto: {{ challenge?.sn_hashtag }}</h4>
            <h4>RRSS del reto: {{ challenge?.sn_target }}</h4>
            <div v-html="challenge?.description"></div>
            <h5> </h5>
          </a>
        </div>
      </div>
      </div>
    </DefaultLayout>
  </template>
  
  <script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ProdevValidator from '@/components/auth/ProdevValidator.vue';
import ChallengeService from '@/services/ChallengeService';
import ChallengeI from '@/interfaces/ChallengeInterface'
import { onMounted,ref,Ref } from 'vue';

const loading= ref(false)
const service  = new ChallengeService()
const challenge:Ref<ChallengeI> = ref({})
onMounted(async()=>{
  loading.value = true; 
  challenge.value = await service.GetChallenge()
  loading.value =false;
})
  </script>
  
  <style scoped lang="scss">
  .img-challens{
    max-height:65% ;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
  }
 h1{
  margin-bottom: 15px;
 }

 .separator{
  width: 15px;
}
.cards{
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

a {
  text-decoration: none;
}
a:hover{
  color: $secondary-color;
}
.card {
  margin-top: 20px;
  width: 700px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  z-index: 2;
  background-color: white;
}
.card:hover{
  scale: 1.01;
}

.card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.card h4,
.card h5 {
  padding: 10px;
  margin: 0;
}

.card h4 {
  font-size: 18px;
  color: $secondary-color;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}


.card h5 {
  font-size: 16px;
  color: $secondary-color;
}

h5:hover {
  cursor: pointer;
}
@media screen and (max-width:600px){
  .cards{
    flex-direction: column;
  }
  .container {
    background-image: none!important; 
  }
  .card {
    width: 95%;
    padding: 10px;
  }

}
  </style>
  