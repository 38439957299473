<template>
    <DefaultLayout>
        <ChannelHeader :user="user" />
        <div class="main-area">
            <div class="buttons">
                <button class="new-question" @click="router.push({name: 'social-profile-edit'})"><v-icon name="bi-pencil-fill" style="margin-right: 5px;"></v-icon>Editar Cabecera</button>
                <button class="new-question" @click="router.push({name: 'search-channels'})"><v-icon name="ri-user-follow-fill" style="margin-right: 5px;"></v-icon>A Quién Seguir</button>
                <button :class="{'new-question': true, 'marked': showCodeBites}" @click="handleShowCodeBites"><v-icon name="bi-file-code-fill" style="margin-right: 5px;"></v-icon>Ver CodeBites</button>
                <button :class="{'new-question': true, 'marked': !showCodeBites}" @click="handleShowCodePosts"><v-icon name="bi-file-post" style="margin-right: 5px;"></v-icon>Ver CodePosts</button>
            </div>
            <div class="social-area">
                <div class="left">
                     <!-- HALL OF FAME -->
                     <h4 class="title">Salón de la Fama</h4>
                    <div class="animate context-menu">
                        <div class="friend-list">
                            <div v-for="(friend, index) in mostReputated" :key="friend.id">
                                <div class="friend" @click="router.push({name:'social-profile', params: {id: friend.id}})" target="_blank">
                                    <img class="profile" v-if="friend.profile_picture_uri" :src="friend.profile_picture_uri"
                                        width="28" />
                                    <div class="profile" v-else>
                                        {{ friend.name.substring(0, 2) }}
                                    </div>
                                    <h5 style="font-size: 12px;" v-if="friend.name.length < 22">{{index+1}}. {{ friend.name }}</h5>
                                    <h5 style="font-size: 12px;" v-else>{{index+1}}. {{ friend.name.substring(0,22) }}...</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END HALL OF FAME -->
                    <div class="spacer"></div>
                      <!-- CODEBITES GUARDADOS -->
                      <h4 class="title" v-if="showCodeBites">CodeBites Guardados</h4>
                    <div class="animate context-menu" v-if="showCodeBites">
                        <form class="search-form">
                            <v-icon name="fa-filter"></v-icon>
                            <input type="text" placeholder="Buscar CodeBite..." v-model="searchCodeBitesSaved" @keyup="handleSearchCodeBitesSaved" />
                        </form>
                        <div class="friend-list">
                            <div v-for="friend in filteredCodeBitesSaved" :key="friend.id">
                                <div class="friend" style="justify-content: flex-end;" @click="router.push({name: 'codebites-saved'})">
                                    <h5 style="font-size: 12px;" v-if="friend.codebite.title.length < 28">{{ friend.codebite.title }}</h5>
                                    <h5 style="font-size: 12px;" v-else>{{ friend.codebite.title.substring(0,28) }}...</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END CODEBITES GUARDADOS -->
                      <!-- CODEPOSTS GUARDADOS -->
                      <h4 class="title" v-if="!showCodeBites">CodePosts Guardados</h4>
                      <div class="animate context-menu" v-if="!showCodeBites">
                        <form class="search-form">
                            <v-icon name="fa-filter"></v-icon>
                            <input type="text" placeholder="Buscar CodePost..." v-model="searchCodePostsSaved" @keyup="handleSearchCodePostsSaved" />
                        </form>
                        <div class="friend-list">
                            <div v-for="friend in filteredCodePostsSaved" :key="friend.id">
                                <div class="friend" style="justify-content: flex-end;" @click="router.push({name: 'codepost-view', params: {id: friend.codepost.id}})">
                                    <h5 style="font-size: 12px;" v-if="friend.codepost.title.length < 35">{{ friend.codepost.title }}</h5>
                                    <h5 style="font-size: 12px;" v-else>{{ friend.codepost.title.substring(0,35) }}...</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END CODEPOSTS GUARDADOS -->
                </div>
                <div class="main codebite-container" v-if="showCodeBites">
                  <CodeBite v-for="codebite in codebites" :key="codebite.id" :codebite="codebite" :user="codebite.user" @refresh="refresh" />
                </div>
                <div class="main codepost-container" v-else>
                    <CodePost v-for="codepost in codeposts" :key="codepost.id" :codepost="codepost" :user="codepost.user" @refresh="refresh" />
                </div>
                <div class="right">
                    <!-- CONVERSATIONS -->
                    <h4 class="title">Mensajería Rápida</h4>
                    <div class="animate context-menu">
                        <RouterLink :to="{ name: 'messages' }" style="text-decoration: none; font-size: 12px;">Ir a
                            mensajería.</RouterLink>
                        <RouterLink :to="{ name: 'friends' }"
                            style="text-decoration: none; font-size: 12px; margin-left: 5px;">Añadir
                            amigos.</RouterLink>
                        <form class="search-form">
                            <v-icon name="fa-filter"></v-icon>
                            <input type="text" placeholder="Buscar amigo..." v-model="search" @keyup="handleSearch" />
                        </form>
                        <div class="friend-list">
                            <div v-for="friend in filteredFriends" :key="friend.id">
                                <div class="friend" @click="showConversation(friend.id)">
                                    <img class="profile" v-if="friend.profile_picture_uri" :src="friend.profile_picture_uri"
                                        width="28" />
                                    <div class="profile" v-else>
                                        {{ friend.name.substring(0, 2) }}
                                    </div>
                                    <h5 style="font-size: 12px;">{{ friend.name }}</h5>
                                    <div style="position: absolute;">
                                        <ConversationNotification :friendId="friend.id"
                                            style="position: relative; top: 0;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END CONVERSATIONS -->
                    <div class="spacer"></div>
                     <!-- LATEST QUESTIONS -->
                     <h4 class="title">Últimas Preguntas</h4>
                    <div class="animate context-menu">
                        <form class="search-form">
                            <v-icon name="fa-filter"></v-icon>
                            <input type="text" placeholder="Buscar pregunta..." v-model="searchQuestion" @keyup="handleSearchQuestions" />
                        </form>
                        <div class="friend-list">
                            <div v-for="friend in filteredQuestions" :key="friend.id">
                                <div class="friend" style="justify-content: right;" v-if="friend.lesson" @click="router.push({ name: 'detail-question-lessons-course', params: { course_id: friend.lesson.course.id, lesson_id: friend.lesson.id, question_id: friend.id } })">
                                    <h5 style="font-size: 12px;" v-if="friend.name.length < 35">{{ friend.name }}</h5>
                                    <h5 style="font-size: 12px;" v-else>{{ friend.name.substring(0,35) }}...</h5>
                                </div>
                                <div class="friend" style="justify-content: right;" v-else @click="router.push({ name: 'detail-question-general', params: { topic_id: friend.topic.id, question_id: friend.id, topic_name: friend.topic.name } })">
                                    <h5 style="font-size: 12px;" v-if="friend.name.length < 35">{{ friend.name }}</h5>
                                    <h5 style="font-size: 12px;" v-else>{{ friend.name.substring(0,35) }}...</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END LATEST QUESTIONS -->
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>
   
<script  lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ChannelHeader from '@/components/channel/ChannelHeader.vue';
import ProfileService from '@/services/ProfileService';
import FriendshipService from '@/services/FriendshipService';
import { ref, onMounted, onUnmounted,Ref } from 'vue'
import { useAuth } from '@/store/useAuth';
import { Toast } from '@/interfaces/ToastInterface'
import { useToast } from '@/store/useToast'
import { useConversations } from '@/store/useConversations'
import router from '@/router'
import ChannelService from '@/services/ChannelService';
import ForumService from '@/services/ForumService';
import CodeBite from '@/components/cards/CodeBite.vue';
import CodePost from '@/components/cards/CodePost.vue';

const codebites = ref()
const codeposts = ref()
const service = new ProfileService()
const store = useAuth()
const user = ref()
const friends:Ref<any> = ref({})
const filteredFriends:Ref<any> = ref({})
const friendshipService = new FriendshipService()
const search = ref('')
const toast = useToast()
const storeConversations = useConversations()
const showCodeBites = ref(false)
const mostReputated = ref()
const codebitesSaved = ref()
const filteredCodeBitesSaved = ref()
const codepostsSaved = ref()
const searchCodeBitesSaved = ref('')
const searchCodePostsSaved = ref('')
const searchQuestion = ref('')
const filteredCodePostsSaved = ref()
const channelService = new ChannelService()
const forumService = new ForumService()
const questions = ref()
const filteredQuestions = ref()
const intervalId = window.setInterval(async () => {
    if (!store.token) {
        return
    }
    friends.value = await friendshipService.listUserFriends(store.token)
    filteredFriends.value = friends.value
}, 10000)

onMounted(async () => {
    if (!store.token) { return }
    user.value = await service.MyUser(store.token)
    friends.value = await friendshipService.listUserFriends(store.token)
    filteredFriends.value = friends.value
    mostReputated.value = await channelService.GetMostReputatedChannels()
    codebitesSaved.value = await channelService.GetSavedCodeBites(store.token)
    filteredCodeBitesSaved.value = codebitesSaved.value
    codepostsSaved.value = await channelService.GetSavedCodePosts(store.token)
    filteredCodePostsSaved.value = codepostsSaved.value
    questions.value = await forumService.LatestQuestions()
    filteredQuestions.value = questions.value
    codebites.value = await channelService.CodeBiteFeeder(store.token)
    codeposts.value = await channelService.CodePostFeeder(store.token)
})

onUnmounted(() => {
    clearInterval(intervalId);
})

const showConversation = async (id: number) => {
    if (!store.token) {
        return
    }
    const success = await storeConversations.addConversation(store.token, id)
    if (!success) {
        toast.showToast("No puedes abrir más pop-ups de conversación simultáneos. Visita la sección de mensajería para acceder a todas las capacidades.", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
    }
}

const handleSearchQuestions = () => {
    filteredQuestions.value = questions.value.filter((item:any)=> item.name.toLowerCase().includes(searchQuestion.value.toLowerCase()))
}

const handleSearchCodeBitesSaved = () => {
    filteredCodeBitesSaved.value = codebitesSaved.value.filter((item:any) => item.codebite.title.toLowerCase().includes(searchCodeBitesSaved.value.toLowerCase()))
}

const handleSearchCodePostsSaved = () => {
    filteredCodePostsSaved.value = codepostsSaved.value.filter((item:any)=> item.codepost.title.toLowerCase().includes(searchCodePostsSaved.value.toLowerCase()))
}

const handleSearch = () => {
    filteredFriends.value = friends.value.filter((item:any) => item.name.toLowerCase().includes(search.value.toLowerCase()))
}
const handleShowCodeBites = async () => {
    if(!store.token) { return }
    showCodeBites.value = true
    codebites.value = await channelService.CodeBiteFeeder(store.token)
   
}
const handleShowCodePosts = async () => {
    if(!store.token) { return }
    showCodeBites.value = false
    codeposts.value = await channelService.CodePostFeeder(store.token)
}

const refresh = async () => {
    if(!store.token) { return }
    codebites.value = await channelService.CodeBiteFeeder(store.token)
    codeposts.value = await channelService.CodePostFeeder(store.token)
}
</script>
   
<style scoped lang="scss">
.codebite-container {
    justify-content: center;
    flex-direction: row !important;
    flex-wrap: wrap;
}

.codepost-container {
    justify-content: center;
    align-items: center;
}
.marked {
    background-color: $secondary-color;
    color: white;
    svg {
        fill: white;
    }
}
.title {
    color: $secondary-color;
    margin-bottom: 10px;
}
.profile {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: $primary-color;
    margin-right: 10px;
    border: 2px solid $secondary-color;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: $secondary-color;
    font-size: 12px;
}
.friend {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    color: $text-color;
    border: 1px solid $primary-color;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 2px;
    cursor: pointer;

    h5 {
        color: $secondary-color;
    }
}

.friend:hover {
    background-color: $primary-color;

}

.friend-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0;
}


.close:hover {
    color: $secondary-color;
}
.search-form {
    display: flex;
    align-items: center;
    background-color: $primary-color;
    border-radius: 5px;
    border: 0;
    width: 100%;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    height: 37px;
    overflow-y: hidden;
}

.context-menu {
    background-color: white;
    z-index: 10;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    border: 2px solid #efefef;
    padding: 20px;
    padding-top: 5px;
}
.buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.main-area {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    min-height: 100svh;
    height: fit-content;
}

.social-area {
    display: flex;

    .left {
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .right {
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .main {
        width: 60%;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

@media(max-width: 955px) {
    .buttons {
        flex-wrap: wrap;
    }
    .social-area {
        display: flex;

        .left {
            display: none;
        }

        .right {
            display: none;
        }

        .main {
            width: 100vw;
            display: flex;
            flex-direction: column;
        }
    }
}</style>