<template>
        <div class="animate reputation-container" v-if="store.token && !loading" @click="goto" :style="{backgroundColor:reputation.color}">
            <v-icon name="ri-medal-fill" fill="white" style="margin-right: 5px;"></v-icon>
            ({{user.reputation.toLocaleString()}}) {{reputation.name}}
        </div>
</template>

<script lang="ts" setup>
import ProfileService from '@/services/ProfileService'
import {ref, onMounted} from 'vue'
import { useAuth } from '@/store/useAuth'
import router from '@/router'

const service = new ProfileService()
const user = ref()
const store = useAuth()
const reputation = ref()
const loading = ref(true)

onMounted(async () => {
    if(!store.token) { 
        loading.value = false
        return 
    }
    user.value = await service.MyUser(store.token)
    reputation.value = await service.ReputationInfo(store.token)
    loading.value = false
})

const goto = () => {
    router.push({name:'my-reputation'})
}
</script>

<style lang="scss" scoped>
.reputation-container {
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    background-color: $secondary-color;
    color: white;
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 11px;
}
.reputation-container:hover {
    background-color: $third-color;
}
</style>