<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>
            <div class="job-header" :style="{ 'background-image': img }">
                <h1>{{ employ?.name }}</h1>
            </div>
            <div class="job-info">
                <div class="job-info-item">
                    <v-icon name="bi-briefcase-fill" fill="white" scale="3"></v-icon>
                    <p style="color:white; text-align: center;">{{ employ?.category?.name }}</p>
                </div>
                <div class="job-info-item">
                    <v-icon name="ri-money-euro-circle-fill" fill="white" scale="3"></v-icon>
                    <p style="color: white;">de {{ employ.salary_min_amount?.toLocaleString() }} a {{
                        employ.salary_max_amount?.toLocaleString() }}
                    </p>
                    <small style="color: white;">euros anuales</small>
                </div>
                <div class="job-info-item">
                    <v-icon name="si-expensify" fill="white" scale="3"></v-icon>
                    <p style="color: white;">{{ employ?.years_of_experience_in_job_category }} años
                    </p>
                    <small style="color: white;">de experiencia</small>
                </div>
                <div class="job-info-item">
                    <v-icon name="la-user-circle" fill="white" scale="3"></v-icon>
                    <p style="color: white;">{{ employ?.amount }} puesto/s</p>
                    <small style="color: white;">vacante/s</small>
                </div>
                <div class="job-info-item">
                    <v-icon name="hi-solid-location-marker" fill="white" scale="3"></v-icon>
                    <p style="color: white;" v-if="employ.is_remote">Remoto</p>
                    <div style="text-align: center;" v-else>
                        <p style="color: white;">{{ employ?.location?.name }}</p>
                        <small style="color: white;">{{ employ?.location?.country?.name
                        }}</small>
                    </div>
                </div>
            </div>
            <div class="section">
                <h3> <v-icon name="gi-diploma" fill="#bece49" scale="1.5" style="margin-right: 5px;"></v-icon>Certificaciones Ademass y <v-icon name="gi-skills" fill="#bece49" scale="1.2" style="margin-right: 5px;"></v-icon>Soft-Skills Recomendadas</h3>
                <div class="job-info">
                    <div class="job-info-item" v-for="certification in employ.certifications" :key="certification.id">
                        <v-icon name="gi-diploma" fill="white" scale="3"></v-icon>
                        <p style="color:white; text-align: center;">{{ certification.name }}</p>
                    </div>
                    <div class="job-info-item" v-for="skill in employ.skills" :key="skill.id">
                        <v-icon name="gi-skills" fill="white" scale="3"></v-icon>
                        <p style="color:white; text-align: center;">{{ skill.name }}</p>
                    </div>
                </div>
            </div>
            <div class="spacer"></div>
            <div class="spacer"></div>
            <div class="divider"></div>
            <div v-html="employ?.description">
            </div>
            <div class="spacer"></div>
            <div class="spacer"></div>
            <div class="divider"></div>

            <div class="candidature" v-if="store.token">
                <button @click="addCandidature">Presentar mi candidatura</button>
                <small>Recuerda tener tu perfil de empleo debidamente cumplimentado y actualizado.</small>
            </div>
            <div class="candidature" v-else>
                <p>Necesitas registrarte o acceder con tu cuenta para poder presentar tu candidatura a esta oferta. ¡Es gratis!</p>
            </div>
        </div>
    </DefaultLayout>
</template>
<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { Ref, ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router';
import EmployService from '@/services/EmployService';
import CandidatureService from '@/services/CandidatureService';
import { useAuth } from '@/store/useAuth';
import { useToast } from '@/store/useToast';
import { Toast } from '@/interfaces/ToastInterface';
import router from '@/router';

const store = useAuth()
const toast = useToast()
const loading = ref(false)
const loadingCandidature = ref(false)
const route = useRoute()
const employService = new EmployService()
const candidatureService = new CandidatureService()
const employ: Ref<any> = ref(
    {    
        id: 0,
        name: "",
        description: "",
        image_url: "",
        amount: 0,
        job_category_id: 0,
        years_of_experience_in_job_category: 0,
        salary_min_amount: 0,
        salary_max_amount: 0,
        is_remote: false,
        status: "",
        location_id: 0,
        created_at: "",
        updated_at: "",
    }
)
const img = computed(() => 'url(' + employ.value.image_url + ')')
const id = ref()

onMounted(async () => {
    loading.value = true
    id.value = route.params.id

    employ.value = await employService.GetEmploy(id.value)
    loading.value = false
})

const addCandidature = async () => {
    if(!store.token)
    {
        return
    }
    loadingCandidature.value = true
    await candidatureService.AddCandidatureToEmploy(store.token,employ.value.id)
    toast.showToast("Tu candidatura se ha añadido. Puedes consultar su estado en la sección 'MIS CANDIDATURAS'", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    router.push({name: 'my-candidatures'});
    loadingCandidature.value = false
}
</script>
<style scoped lang="scss">
.candidature {
    display: flex;
    flex-direction: column;
    text-align: center;
    button {
        background: $third-color;
        padding: 15px;
        color: white;
        border: 0;
        border-radius: 10px;
        outline: 0;
        font-size: 18px;
        cursor: pointer;
    }
    button:hover {
        background: $secondary-color;
    }
}
.section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        color: $third-color;
    }
}

.job-info-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $secondary-color;
    padding: 15px;
    margin: 5px;
    border-radius: 10px;
    height: 180px;
    width: 180px;
}

.job-header h1 {
    padding: 15px;
    border-radius: 10px;
    background-color: rgba(#fff, 0.7);
}

.job-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    border-radius: 10px;
    background-size: cover;
    background-color: $secondary-color;
}

.job-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    border-radius: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
}
</style>

