export default class ExercisesService{
    private base_url = 'https://gogodev.net/gogolearn/api'

    public async GetExercises() {
        const uri = `${this.base_url}/lms_exercise`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await rawResponse.json()
        return response
    }
    public async GetExercisesDetail(id:string | string [] ) {
        const uri = `${this.base_url}/lms_exercise/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await rawResponse.json()
        return response
    }
    public async GetExercisesAnswerDetail(id:string | string [] ) {
        const uri = `${this.base_url}/lms_exercise/detail/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await rawResponse.json()
        return response
    }
    public async GetPdf(id:string | string [] ) {
        const uri = `${this.base_url}/lms_exercise/pdf/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await rawResponse.json()
        return response
    }
}
