export default class EmployService {
    private base_url = 'https://gogodev.net/gogolearn/api/employ'

    public async ListAllEmploys() {
        const uri = this.base_url
        const rawResponse = await fetch(uri,  {
            method: 'GET'
          })
        const response = await rawResponse.json()
        return response
    }
    
    public async ListAllPracticums() {
      const uri = `${this.base_url}/practicum`
      const rawResponse = await fetch(uri,  {
          method: 'GET'
        })
      const response = await rawResponse.json()
      return response
  }

    public async GetEmploy(id: number) {
        const uri = `${this.base_url}/detail/${id}`
        const rawResponse = await fetch(uri,  {
            method: 'GET'
          })
        const response = await rawResponse.json()
        return response
    }
}