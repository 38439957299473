<template>
    <DefaultLayout>
        <div v-if="showFeedback" class="animate modal">
            <div class="modal-inner">
                <h3>CodePost Creado</h3>
                <img v-if="windowWidth >= 1000" src="../../public/img/lms/fullstack.jpg" style="width: 100%;" />
                <RouterLink :to="{ name: 'social-profile-edit' }" style="text-decoration: none;">
                    <button class="new-question">Gestionar Mi Canal</button>
                </RouterLink>
            </div>
        </div>
        <div class="animate form">
            <h1>Redactar CodePost</h1>
            <div class="spacer"></div>
            <RouterLink :to="{ name: 'social-profile-edit' }" style="text-decoration: none;">
                <button class="new-question">Volver a Gestionar Mi Canal</button>
            </RouterLink>
            <div class="spacer"></div>
            <h3>Título del CodePost</h3>
            <p>Debe ser conciso y no extenderse más allá de 255 caracteres</p>
            <input class="input" v-model="title" type="text" max="255" maxlength="255" required />
            <div class="spacer"></div>
            <h3>Imagen destacada del CodePost</h3>
            <p>Se mostrará en la preview del CodePost. Debe tener un tamaño máximo de 4Mb. La resolución ideal es 1280x720 píxeles</p>
            <input class="input" type="file" @change="onFileChange" required />
            <div class="spacer"></div>
            <h3>Descripción Corta del CodePost</h3>
            <p>Se muestra en la preview del CodePost, por lo que debe ser llamativa. No debe extenderse más allá de 255 caracteres</p>
            <input class="input" v-model="short" type="text" max="255" maxlength="255" required />
            <div class="spacer"></div>
            <h3>Contenido del CodePost</h3>
            <p>Usa la herramienta <b>&lt;/&gt;</b> para escribir código. Mejorará mucho su legibilidad. Al contrario que la <RouterLink
                    :to=" { name: 'new-codebite' } "> creación de un CodeBite</RouterLink>, los CodePosts son extensos y detallados artículos para aquellos que desean ir más allá
            </p>
            <quill-editor ref="descriptionBox" theme="snow" toolbar="full"
                @update:content="hanldeDescription"></quill-editor>
            <div class="spacer"></div>
            <button class="new-question" @click="storeCodepost">Crear CodePost <v-icon name="io-send"
                    style="margin-left: 10px;"></v-icon></button>
                    <small>{{ feedback }}</small>
        </div>
    </DefaultLayout>
</template>

<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import ChannelService from '@/services/ChannelService'
import { useAuth } from '@/store/useAuth'
import useWindowWidth from '@/composables/useWindowWidth'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import DOMPurify from 'dompurify';

const toast = useToast()
const service = new ChannelService()
const store = useAuth()
const title = ref('')
const short = ref('')
const description = ref('')
const showFeedback = ref(false)
const { windowWidth } = useWindowWidth()
const descriptionBox = ref()
const image = ref()
const feedback = ref('')

const hanldeDescription = () => {
    description.value = DOMPurify.sanitize(descriptionBox.value.getHTML())
    console.log(description.value)
}

const onFileChange = (event:any) => {
    const file = event.target.files[0];
    image.value = file;
};

const storeCodepost = async () => {
    if (!store.token) { return; }

    if(title.value.length < 3 || title.value.length > 255) {
        toast.showToast("La extensión del título no es válida.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    if(short.value.length < 3 || short.value.length > 255) {
        toast.showToast("La extensión de la descripción corta no es válida.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    if(description.value.length < 10) {
        toast.showToast("La extensión del contenido es demasiado corta.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    if(!image.value) {
        toast.showToast("La imagen es un campo requerido.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }


    feedback.value = "Subiendo...";
    const response = await service.CreateCodePost(store.token, title.value, description.value, short.value, image.value)

    if (response.success != true) {
        toast.showToast(response.message, Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        feedback.value = ''
        return
    }

    feedback.value = ''
    showFeedback.value = true
}
</script>

<style scoped lang="scss">
.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;

    h3 {
        margin-bottom: 15px;
    }

    p {
        font-size: 12px;
    }
}

.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    margin-top: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.new-question:hover {
    background-color: $secondary-color;
    color: white;

    svg {
        fill: white;
    }
}

h3 {
    color: $secondary-color;
}

.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    margin-top: 15px;
    margin-left: 0;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.new-question:hover {
    background-color: $secondary-color;
    color: white;

    svg {
        fill: white;
    }
}

.form {
    width: 100%;
}

.input {
    width: 100%;
    padding: 10px;
    outline: 0;
    border: 2px solid $primary-color;
    border-radius: 5px;
}

p {
    font-size: 12px;
    margin-bottom: 5px;
}</style>