<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div class="container" v-else>
            <h1>Ver más tarde</h1>
            <div class="spacer" />
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar."
                    @keyup="handleSearch" />
            </form>
            <div class="spacer" />
            <div v-if="loading">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
            </div>
            <div class="container-items" v-else>
                <LessonList v-for="lesson in filteredLessons" :key="lesson.id" :content="lesson"/>
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import LessonService from '@/services/LessonService'
import { onMounted, ref, Ref } from 'vue'
import {useAuth} from '@/store/useAuth'
import LaterService from '@/services/LaterService'
import LessonList from '@/components/cards/LessonList.vue'

const lessonService = new LessonService()
const loading = ref(true)
const store = useAuth()
const lessons:Ref<any> = ref([])
const filteredLessons:Ref<any> = ref([])
const lessonFollows:Ref<any> = ref([])
const laterService = new LaterService()

const search: Ref<string> = ref('')


onMounted(async () => {
    loading.value = true
    if (store.token) {
        lessonFollows.value = await laterService.getAllFromUser(store.token)
    }
    lessonFollows.value.forEach(async (follow:any) => {
        const item:any = await lessonService.getLesson(follow.lesson_id)
        lessons.value.push(item)
    });
    Promise.all(lessons.value)
    filteredLessons.value = lessons.value
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filteredLessons.value = lessons.value.filter((item:any) => item.name.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}

</script>

<style lang="scss" scoped>
.container-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.container {
    min-width: 70vw;
}
.img {
    border-radius: 10px;
}

.config {
    display: flex;
    align-items: center;
}
.icon {
    margin: 25px;
}
.search-form {
    display: flex;
    align-items: center;
    background-color: $primary-color;
    border-radius: 5px;
    border: 0;
    width: 100%;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    height: 37px;
    overflow-y: hidden;
}
.aux-btns {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.aux-btn {
    height: 37px;
    background-color: $third-color;
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
}
.last {
    border-radius: 0 5px 5px 0;
}

.divider {
    background-color: $secondary-color;
    width: 100%;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.container {
    width: 100%;
}
@media(max-width: 950px) {
    .search-form {
        width: 100%;
    }
}
</style>