<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div class="container">
            <h1>¡Bienvenido a nuestra sección de Ejercicios!</h1>
            <h2>Esta sección es exclusiva para usuarios ProDev, <br><a href="/prodev/registro" target='_blank'>pulsa aquí para ser usuario ProDev y disfruta de un mes GRATIS.</a></h2>
            <br>
            <p>
                ¡Bienvenido a nuestra sección de ejercicios de programación! Aquí encontrarás desafíos diseñados para
                mejorar tus habilidades en programación, desde principiantes hasta expertos. ¡Prepárate para desafiar tu
                mente y afianzar los conceptos aprendidos!</p>
            <div class="table-container">
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Lenguaje</th>
                            <th>Nº de Ejercicios</th>
                            <th>Fecha Inicio</th>
                            <th>Ver ejercicios</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="elm in activities"  :key="elm?.id">
                            <td>{{ elm?.language }}</td>
                            <td>{{ elm?.number_of_execercises }}</td>
                            <td>{{ elm?.date }}</td>
                            <td>
                                <RouterLink :to="{ name: 'viewActivity', params:{id : elm?.id} }"><v-icon name="md-fileopen-round" class="ver" fill="#bece49"
                                        scale="2"></v-icon></RouterLink>
                            </td>                    
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </DefaultLayout>
</template>

<script  lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ExercisesService from '@/services/ExerciseService';
import ActivityInterface from '@/interfaces/ActivityInterface';
import { onMounted,ref,Ref } from 'vue';

const loading:Ref<boolean> = ref(false)
const service = new ExercisesService()
const activities:Ref<ActivityInterface[]> = ref([])

onMounted(async()=>{
    loading.value = true
  activities.value = await service.GetExercises()
  loading.value = false
})
 


</script>

<style scoped lang="scss">

h1{
    margin-bottom: 20px;
}
p{
    margin-bottom: 12px;
}
.container {
    padding: 15px;
    text-align: center;
}
a{
    text-decoration: none;
}
.modern-table {
    width: 100%;
    border-collapse: collapse;

}
a:hover{
    color: $secondary-color;
}
.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    color: $secondary-color !important;
}

.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}

.table-container {
    overflow-x: auto;
}

.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}</style>