export default class SharedService {
    private base_url = 'https://gogodev.net/gogolearn/api/shared'

    public async getAllCoursesWithNotes(token:string) {
        const uri = `${this.base_url}/pdf/notes/get/get-courses-with-notes`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async countLessonsInRoadmap(token:string, roadmap_id:number) {
        const uri = `${this.base_url}/count/course/all/${roadmap_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async countLessonsCompletedInRoadmap(token:string, roadmap_id:number) {
        const uri = `${this.base_url}/count/course/${roadmap_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async countLessonsCompletedInCourse(token:string, course_id:number) {
        const uri = `${this.base_url}/count/lesson/${course_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async printDiploma(token:string, diploma_id:number) {
        try {
        const uri = `${this.base_url}/pdf/${diploma_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.blob()
        return response
    } catch(e) {
        console.log(e)
    }
    }

    public async printNotes(token:string, course_id:number) {
        try {
        const uri = `${this.base_url}/pdf/notes/print/${course_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.blob()
        return response
    } catch(e) {
        console.log(e)
    }
    }

    public async verifyDiplomaOwner(diploma_id:string | string[]) {
        const uri = `${this.base_url}/certificate/${diploma_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }
}