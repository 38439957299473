<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>

            <br>
            <div class="ofered">
                 <div class="buttons-see">
                <h1>Ofertas de Prácticas</h1>
                <div class="go">
                <a href="https://ademass-prodev.netlify.app/" target="_blank">
                        <button class="btn-function2">Información ProDev</button>
                    </a>
                    <div class="separator"></div>
                    <a href="/prodev/registro" >
                        <button class="btn-function">Primer Mes Gratis</button>
                    </a>
                    <div class="separator"></div>
                    <a href="https://www.youtube.com/watch?v=aJwcHRSnBmU" target="_blank">
                        <button class="btn-function">Explicame cómo funciona</button>
                    </a>
                </div>
            </div>
            </div>
            <div class="spacer" />

            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar." @keyup="handleSearch"
                    class="input-job" />
            </form>
         
            <div class="spacer" />
            <div class="table-container">
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Empresa / Puesto Empresa</th>
                            <th v-if="windowWidth > tableSmall">Perfil</th>
                            <th v-if="windowWidth > tableSmall">Modalidad</th>
                            <th v-if="windowWidth > tableSmall">Plazas</th>
                            <th>Ver detalles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="elm in filteredEmploys" :key="elm.id">
                            <td>{{ elm.company_name }} / {{ elm.name }}</td>
                            <td v-if="windowWidth > tableSmall">{{ elm.category?.name }}</td>
                            <td v-if="windowWidth > tableSmall">Teletrabajo 100%</td>
                            <td v-if="windowWidth > tableSmall">{{ elm?.amount }}</td>
                            <td>
                                <router-link :to="{ name: 'project-detail', params: { id: elm.id } }">
                                    <v-icon name="md-fileopen-round" class="ver" fill="#57afc4" scale="2"></v-icon>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import EmployService from '@/services/EmployService'
import { onMounted, ref,Ref } from 'vue';
import useWindowWidth from '@/composables/useWindowWidth';
import EmployInterface from '@/interfaces/EmployInterface';

const employService = new EmployService()
const employs:Ref<EmployInterface[]>  = ref([])
const filteredEmploys:Ref<EmployInterface[]> = ref([])
const loading = ref(false)
const search = ref('')
const { windowWidth } = useWindowWidth()
const tableSmall = ref(850);
onMounted(async () => {
    loading.value = true
    employs.value = await employService.ListAllPracticums()
    filteredEmploys.value = employs.value
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filteredEmploys.value = employs.value.filter(item => item.company_name?.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}
</script>

<style lang="scss" scoped>
.go{
    margin-top: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.separator{
    width: 10px;
}
.buttons-see{
    display: flex;
    flex-direction: column;
}
.employ-card {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: $secondary-color;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ofered {
    display: flex;
    justify-content: space-between;
    min-width: 130px;
}

.ofered-body {
    background-color: $third-color;
    padding: 10px;
    border-radius: 12px;
    min-height: 100px;
    max-width:60% ;
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
    margin-right: 15px;
}
.ofered-body:hover{
    cursor: pointer;
    background-color: $secondary-color;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.title-ofered {
    color: white;
    text-decoration: none;
    border-radius: 8px;
    height: 100%;
}



.btn {
    text-decoration: none;
    margin-left: 20px;
}

.input-job {
    width: 100vw;
}

/* Estilos para la tabla */
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    color: $secondary-color !important;
}

.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}

.table-container {
    overflow-x: auto;
}

.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}

.ver {
    cursor: pointer;
}

.btn-function {
    padding: 10px;
    background-color: $third-color;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-family: $title-font;
}

.btn-function:hover {
    background-color: $secondary-color;
}
.btn-function2 {
    padding: 10px;
    background-color:$secondary-color ;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-family: $title-font;
}

.btn-function2:hover {
    background-color:$third-color ;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    width: 100%;

}
@media screen and (max-width:890px){
    .ofered{
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .ofered-body{
      margin-top: 20px;
      min-width: 90%;
      align-items: center;
      justify-content: center;
      padding: 15px;
    }
    .title-ofered{
        width: 100%;
    }
    .header{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btn-function{
        margin-top: 15px;
        min-width: 200px;
    } .btn-function2{
        min-width: 200px!important;
    }
    .go{
        flex-direction: column;
    }
  
}</style>