export default class {
    baseURI = 'https://gogodev.net/gogolearn/api/stepper';

    async getJobs(token: string): Promise<any> {
        const uri = `${this.baseURI}/jobs`;
        const rawResponse = await fetch(uri, {
            method: 'GET',
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const response = await rawResponse.json();
        return response;
    }

    async getJobDetail(token: string, id: string): Promise<any> {
        const uri = `${this.baseURI}/jobs/${id}`;
        const rawResponse = await fetch(uri, {
            method: 'GET',
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const response = await rawResponse.json();
        return response;
    }

    async updateDescription(token: string, id: string, definition: string): Promise<any> {
        const uri = `${this.baseURI}/jobs/update-description/${id}`;

        const rawResponse = await fetch(uri, {
            method: "POST",
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "definition": definition
            })
        });
        const response = await rawResponse.json();

        return response;
    }

    async updateStatus(token: string, id: string, status: string): Promise<any> {
        const uri = `${this.baseURI}/jobs/update-status/${id}`;

        const rawResponse = await fetch(uri, {
            method: "POST",
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "status": status
            })
        });
        const response = await rawResponse.json();

        return response;
    }

    async updateStatusWithEmail(token: string, id: string, status: string): Promise<any> {
        const uri = `${this.baseURI}/jobs/update-status-with-email/${id}}`;

        const rawResponse = await fetch(uri, {
            method: "POST",
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "status": status
            })
        });
        const response = await rawResponse.json();

        return response;
    }

    async getCandidate(token: string | null, id: string): Promise<any> {
        const uri = `${this.baseURI}/candidates/${id}`;

        const rawResponse = await fetch(uri, {
            method: "GET",
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const response = await rawResponse.json();
        console.log(response);
        return response;
    }
}