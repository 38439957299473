<template>
    <div :class="{animate:true, toastcontainer:true, info:props.type == Toast.INFO, success:props.type == Toast.SUCCESS, warning:props.type == Toast.WARNING, danger:props.type == Toast.DANGER}">
        <p style="color:white;">{{props.message}}</p>
    </div>
</template>

<script lang="ts" setup>
import { Toast } from '@/interfaces/ToastInterface';
import { defineProps } from 'vue'
const props = defineProps(['message', 'type'])
</script>

<style lang="scss" scoped>
.toastcontainer {
    background-color: $primary-color;
    z-index: 99999999999999999999999999;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 10px;
    padding: 15px;
}

.info {
    background-color: $info-color;
    color: white;
    z-index: 99999999999999999999999999;
}

.success {
    background-color: $success-color;
    color: white;
    z-index: 99999999999999999999999999;
}

.warning {
    background-color: $warning-color;
    color: white;
    z-index: 99999999999999999999999999;
}

.danger {
    background-color: $danger-color;
    z-index: 99999999999999999999999999;
}
</style>