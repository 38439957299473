import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AuthView from '@/views/AuthView.vue'
import LegalView from '@/views/LegalView.vue'
import TermsView from '@/views/TermsView.vue'
import RoadmapsView from '@/views/RoadmapsView.vue'
import RoadmapView from '@/views/RoadmapView.vue'
import MyRoadmapsView from '@/views/MyRoadmapsView.vue'
import CoursesView from '@/views/CoursesView.vue'
import CourseView from '@/views/CourseView.vue'
import { useAuth } from '@/store/useAuth'
import MyCoursesView from '@/views/MyCoursesView.vue'
import SearchCoursesView from '@/views/SearchCoursesView.vue'
import MyFavsView from '@/views/MyFavsView.vue'
import MyLatersView from '@/views/MyLatersView.vue'
import MyNotesView from '@/views/MyNotesView.vue'
import CertificationsView from '@/views/CertificationsView.vue'
import CertificationView from '@/views/CertificationView.vue'
import EvaluationView from '@/views/EvaluationView.vue'
import MyDiplomasView from '@/views/MyDiplomasView.vue'
import CheckDiplomaView from '@/views/CheckDiplomaView.vue'
import ProfileView from '@/views/ProfileView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import MyNotesPdfView from '@/views/MyNotesPdfView.vue'
import JobOfferingView from '@/views/JobOfferingView.vue'
import JobDetailView from '@/views/JobDetailView.vue'
import JobProfileView from '@/views/JobProfileView.vue'
import CandidatureView from '@/views/CandidatureView.vue'
import NotificationView from '@/views/NotificationView.vue'
import RecoveryPass from '@/views/RecoveryPass.vue'
import RecoveryNewPass from '@/views/RecoveryNewPass.vue'
import MessagesView from '@/views/MessagesView.vue'
import MyAnswerView from '@/views/MyAnswerView.vue'
import MyWallView from '@/views/MyWallView.vue'
import MyQuestionsView from '@/views/MyQuestionsView.vue'
import FriendsView from '@/views/FriendsView.vue'
import FriendRequestView from '@/views/FriendRequestView.vue'
import ForumDetailView from '@/views/ForumDetailView.vue'
import ForumDetailQuestionView from '@/views/ForumDetailQuestionView.vue'
import ForumGeneralWriteView from '@/views/ForumGeneralWriteView.vue'
import ForumChooseView from '@/views/ForumChooseView.vue'
import ForumCoursesView from '@/views/ForumCoursesView.vue'
import ForumGeneralView from '@/views/ForumGeneralView.vue'
import ForumCourseListView from '@/views/ForumCourseListView.vue'
import ForumGeneralListView from '@/views/ForumGeneralListView.vue'
import ForumDetailGeneralView from '@/views/ForumDetailGeneralView.vue'
import ForumCourseWriteView from '@/views/ForumCourseWriteView.vue'
import SocialProfileView from '@/views/SocialProfileView.vue'
import SocialProfileEditView from '@/views/SocialProfileEditView.vue'
import ReputationView from '@/views/ReputationView.vue'
import CodeBiteWriteVue from '@/views/CodeBiteWrite.vue'
import CodePostWriteVue from '@/views/CodePostWrite.vue'
import CodeBiteAllAuthVue from '@/views/CodeBiteAllAuth.vue'
import CodePostAllAuthVue from '@/views/CodePostAllAuth.vue'
import CodeBiteSaved from '@/views/CodeBiteSaved.vue'
import CodePostSaved from '@/views/CodePostSaved.vue'
import CodePostView from '@/views/CodePostView.vue'
import ChannelRedirectView from '@/views/ChannelRedirectView.vue'
import CodePostAll from '@/views/CodePostAll.vue'
import CodeBiteAll from '@/views/CodeBiteAll.vue'
import FollowersListView from '@/views/FollowersListView.vue'
import FollowingListView from '@/views/FollowingListView.vue'
import SearchChannelsView from '@/views/SearchChannelsView.vue'
import HallOfFame from '@/views/HallOfFame.vue'
import ForumLatestQuestionsView from '@/views/ForumLatestQuestionsView.vue'
import ProjectOfferingView from '@/views/ProjectOfferingView.vue'
import ProjectDetailView from '@/views/ProjectDetailView.vue'
import BookShopView from '@/views/BookShopView.vue'
import BookShopDetailView from '@/views/BookShopDetailView.vue'
import GeneralShopView from '@/views/GeneralShopView.vue'
import ShopDetailView from '@/views/ShopDetailView.vue'
import TeachersView from '@/views/TeachersView.vue'
import TeachersDetailView from '@/views/TeachersDetailView.vue'
import BootCampsView from '@/views/BootCampsView.vue'
import BootCampsDetailsView from '@/views/BootCampsDetailsView.vue'
import WebinarView from '@/views/WebinarView.vue'
import WebinarDetailsView from '@/views/WebinarDetailsView.vue'
import ActivityView from '@/views/ActivityView.vue'
import ActivityQuestionsView from '@/views/ActivityQuestionsView.vue'
import AnswerView from '@/views/AnswerView.vue'
import FreeProDevView from '@/views/FreeProDevView.vue'
import ProDevWelcomeView from '@/views/ProDevWelcomeView.vue'
import PortFolioShow3View from '@/views/PortFolioShow3View.vue'
import PortFolioShow2View from '@/views/PortFolioShow2View.vue'
import StatusCountView from '@/views/StatusCountView.vue'
import ChallengeView from '@/views/ChallengeView.vue'
import PdfView from '@/views/PdfView.vue'
import SeeCandidatesView from '@/views/SeeCandidatesView.vue'



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/acceso',
    name: 'auth',
    component: AuthView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/hojas-de-ruta',
    name: 'roadmaps',
    component: RoadmapsView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/mis-hojas-de-ruta',
    name: 'my-roadmaps',
    component: MyRoadmapsView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/hojas-de-ruta/:id',
    name: 'roadmap',
    component: RoadmapView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/cursos',
    name: 'courses',
    component: CoursesView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/curso/:id/:lesson_id?',
    name: 'course',
    component: CourseView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mis-cursos',
    name: 'my-courses',
    component: MyCoursesView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mis-lecciones-favoritas',
    name: 'my-favs',
    component: MyFavsView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mis-temas-pendientes',
    name: 'my-laters',
    component: MyLatersView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mis-apuntes',
    name: 'my-notes',
    component: MyNotesView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mis-apuntes/imprimir-pdf',
    name: 'my-notes-pdf',
    component: MyNotesPdfView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/buscar/:search',
    name: 'search',
    component: SearchCoursesView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/certificaciones',
    name: 'certifications',
    component: CertificationsView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/certificaciones/:id',
    name: 'certification',
    component: CertificationView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/evaluacion/:id',
    name: 'evaluation',
    component: EvaluationView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mis-diplomas',
    name: 'my-diplomas',
    component: MyDiplomasView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/aut/:id',
    name: 'check-diploma',
    component: CheckDiplomaView,
    meta: {
      requreAuth: false
    }
  },
  {
    path: '/mi-perfil',
    name: 'profile',
    component: ProfileView,
    meta: {
      requreAuth: true
    }
  },
  {
    path: '/terminos-legales',
    name: 'terms',
    component: TermsView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/aviso-legal-y-politica-de-privacidad',
    name: 'legal',
    component: LegalView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/ofertas-de-empleo',
    name: 'job-offering',
    component: JobOfferingView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/ofertas-de-empleo/:id',
    name: 'job-detail',
    component: JobDetailView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/mi-perfil-de-empleo',
    name: 'job-profile',
    component: JobProfileView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mis-candidaturas',
    name: 'my-candidatures',
    component: CandidatureView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/notificaciones',
    name: 'notifications',
    component: NotificationView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/recuperar-contrasena',
    name: 'recovery',
    component: RecoveryPass,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/nueva-contrasena/:id',
    name: 'newPassword',
    component: RecoveryNewPass,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/mensajeria/:id?',
    name: 'messages',
    component: MessagesView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mis-amigos',
    name: 'friends',
    component: FriendsView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mis-amigos/solicitudes',
    name: 'friend-request',
    component: FriendRequestView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mi-muro',
    name: 'my-wall',
    component: MyWallView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/canal/:id',
    name: 'social-profile',
    component: ChannelRedirectView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/c/:nickname',
    name: 'social-profile-nickname',
    component: SocialProfileView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/c/:nickname/codeposts',
    name: 'social-codeposts',
    component: CodePostAll,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/c/:nickname/codebites',
    name: 'social-codebites',
    component: CodeBiteAll,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/social/buscar/canales',
    name: 'search-channels',
    component: SearchChannelsView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/salon-de-la-fama',
    name: 'hall-of-fame',
    component: HallOfFame,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/c/:nickname/seguidores',
    name: 'followers-list',
    component: FollowersListView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/c/:nickname/siguiendo',
    name: 'following-list',
    component: FollowingListView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/mi-canal',
    name: 'social-profile-edit',
    component: SocialProfileEditView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/foro',
    name: 'forum',
    component: ForumChooseView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/foro/cursos',
    name: 'forum-course',
    component: ForumCoursesView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/foro/general',
    name: 'forum-general',
    component: ForumGeneralView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/foro/cursos/:course_id',
    name: 'forum-course-list',
    component: ForumCourseListView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/foro/general/:topic_id/:topic_name',
    name: 'forum-general-list',
    component: ForumGeneralListView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/foro/ultimas-preguntas-sin-responder',
    name: 'forum-latest-questions',
    component: ForumLatestQuestionsView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/foro/general/pregunta/:topic_id/:question_id/:topic_name',
    name: 'detail-question-general',
    component: ForumDetailGeneralView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/foro/cursos/:course_id/:lesson_id',
    name: 'detail-question-course',
    component: ForumDetailView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/foro/cursos/:course_id/:lesson_id/:question_id',
    name: 'detail-question-lessons-course',
    component: ForumDetailQuestionView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/foro/cursos/escribir-pregunta/:course_id/:lesson_id',
    name: 'write-question-course',
    component: ForumCourseWriteView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/foro/general/escribir-pregunta/:topic_id',
    name: 'write-question-general',
    component: ForumGeneralWriteView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mis-dudas-y-preguntas',
    name: 'my-questions',
    component: MyQuestionsView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/escribir-respuesta/:question_id',
    name: 'my-answer',
    component: MyAnswerView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mi-reputacion-dev',
    name: 'my-reputation',
    component: ReputationView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mi-canal/crear-codebite',
    name: 'new-codebite',
    component: CodeBiteWriteVue,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mi-canal/crear-codepost',
    name: 'new-codepost',
    component: CodePostWriteVue,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mi-canal/codeposts',
    name: 'my-codeposts',
    component: CodePostAllAuthVue,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mi-canal/codebites',
    name: 'my-codebites',
    component: CodeBiteAllAuthVue,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/codebites/guardados',
    name: 'codebites-saved',
    component: CodeBiteSaved,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/codeposts/guardados',
    name: 'codeposts-saved',
    component: CodePostSaved,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/codeposts/ver/:id',
    name: 'codepost-view',
    component: CodePostView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/oferta-proyectos',
    name: 'project-offering',
    component: ProjectOfferingView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/detalles-oferta/:id',
    name: 'project-detail',
    component: ProjectDetailView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/libreria',
    name: 'bookshop',
    component: BookShopView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/detalles-libro/:id',
    name: 'bookshopDetail',
    component: BookShopDetailView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/tienda',
    name: 'shop',
    component: GeneralShopView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/articulo/:id',
    name: 'shop-detail',
    component:ShopDetailView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/profesores',
    name: 'teacher',
    component:TeachersView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/profesores-detalles',
    name: 'teacher-detail',
    component:TeachersDetailView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bootcamp',
    name: 'bootcamp',
    component:BootCampsView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/ejercicios',
    name: 'ejercicios',
    component:ActivityView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/ver-ejercicios/:id',
    name: 'viewActivity',
    component:ActivityQuestionsView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/respuesta/:index',
    name: 'response-question',
    component:AnswerView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/webinar',
    name: 'webinar',
    component:WebinarView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/prodev/registro',
    name: 'register-free',
    component:FreeProDevView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/prodev/prodev/bienvenida/:subscriptionId',
    name: 'prodev-welcome',
    component:ProDevWelcomeView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bootcamp-visor/:id',
    name: 'bootcamp-details',
    component:BootCampsDetailsView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/webinar-visor',
    name: 'webinar-details',
    component:WebinarDetailsView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/estado-cuenta-prodev',
    name: 'count-prodev',
    component:StatusCountView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/retos',
    name: 'challens',
    component:ChallengeView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/portfolio3/:id',
    name: 'portfolio3',
    component:PortFolioShow3View,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/portfolio2/:id',
    name: 'portfolio2',
    component:PortFolioShow2View,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/equipo/:id',
    name: 'team',
    component:SeeCandidatesView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/manuales',
    name: 'bookspdf',
    component:PdfView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error404',
    component: NotFoundView,
    meta: {
      requireAuth: false
    }
  }

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const store = useAuth()

  // Evaluation control
  if (to.name == 'evaluation' && from.name != 'certification') {
    next({name:'auth'})
  }

  // Auth control
  if ((to.meta.requireAuth) && (store.token == null)) {
    next({name:'auth'})
  }
  next()
})

export default router
