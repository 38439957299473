<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <ProdevValidator></ProdevValidator>
        <div class="container">
            <h1>¡Bienvenido a nuestra sección de Manuales!</h1>
            <h2>Esta sección es exclusiva para usuarios ProDev, <br><a href="/prodev/registro" target='_blank'>pulsa aquí para ser usuario ProDev y disfruta de un mes GRATIS.</a></h2>
            <br>
            <p>
                En esta sección podréis ir descargando las guías y manuales de tu tecnología favorita.</p>
            <div class="table-container">
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Lenguaje</th>
                            <th>Autor</th>
                            <th>Descargar PDF</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="elm in pdf"  :key="elm.id">
                            <td>{{ elm?.category }}</td>
                            <td>{{ elm?.author }}</td>
                            <td>
                                <a :href="elm?.pdf_uri" download="manual.pdf" class="link-pdf" target="_blank"><v-icon name="md-fileopen-round" class="ver"  fill="#bece49"
                                        scale="3"></v-icon></a>
                            </td>                    
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </DefaultLayout>
</template>

<script  lang="ts" setup>
import ProdevValidator from '@/components/auth/ProdevValidator.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BookService from '@/services/BookService';
import { onMounted,ref,Ref } from 'vue';

const service = new BookService()
const loading:Ref<boolean> = ref(false)
const pdf:Ref<any> = ref({})

onMounted(async()=>{
    loading.value = true
    pdf.value = await service.GetBooks()
    loading.value = false
})
 


</script>

<style scoped lang="scss">

h1{
    margin-bottom: 20px;
}
p{
    margin-bottom: 12px;
}
.container {
    padding: 15px;
    text-align: center;
}
a{
    text-decoration: none;
}
.modern-table {
    width: 100%;
    border-collapse: collapse;

}
a:hover{
    color: $secondary-color;
}
.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    color: $secondary-color !important;
}

.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}

.table-container {
    overflow-x: auto;
}

.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}</style>