<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div class="container" v-else>
            <h1>Certificaciones disponibles</h1>
            <div class="spacer"></div>
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar."
                    @keyup="handleSearch" />
            </form>
            <div class="divider"></div>
            <div class="spacer"></div>
            <div v-if="loading">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
            </div>
            <div v-else class="evaluations-container">
                <EvaluationList v-for="evaluation in filteredEvaluations" :key="evaluation.id" :content="evaluation" />
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import {ref, onMounted} from 'vue'
import EvaluationService from '@/services/EvaluationService'
import EvaluationList from '@/components/cards/EvaluationList.vue'

const evaluationService = new EvaluationService()
const loading = ref(true)
const evaluations = ref()
const filteredEvaluations = ref()
const search = ref('')

const handleSearch = () => {
    loading.value = true
    filteredEvaluations.value = evaluations.value.filter((item:any) => item.name.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}

onMounted(async () => {
    loading.value = true
    evaluations.value = await evaluationService.getAllEvaluations()
    filteredEvaluations.value = evaluations.value
    loading.value = false
})
</script>

<style lang="scss" scoped>
.evaluations-container {
    display: flex;
    flex-wrap: wrap;
}
.container {
    min-width: 70vw;
}
</style>