<template>
    <div v-if="loading" class="loading-container">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2" />
    </div>
    <div class="animate lesson-container" v-else>
        <div v-if="lesson" style="width: 100%;">
            <div v-if="lessonLoading" class="loading-container big">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="5" />
            </div>
            <div v-else style="width: 100%; background-color: #333; border-radius: 10px;">
                <div class="spacer" ></div>
                <div v-if="lesson.videoURL" style="display: flex; justify-content: center; align-items: center; position: relative; width: 100% !important;">
                    <YouTube v-if="windowWidth > 950" :src="lesson.videoURL" @ready="onReady" class="yt" id="ytube" style="width: 100% !important;" />
                    <YouTube v-else :src="lesson.videoURL" class="yt" height="180" width="280"  @ready="onReady" />
                </div>
                <div class="spacer"></div>
                <LessonsBtns :lessonId="lesson.id" @refreshStatus="refreshStatus" @goPrev="goPrevEvent" @goNext="goNextEvent" @showNotes="showPaperNotesEvent" @showDescription="showDescriptionEvent" @showLessonDescription="showLessonDescriptionEvent" @showSummary="showSummaryEvent" />
            </div>
        </div>
        <div class="animate" style="width: 100%;" v-else>
            <div v-if="lessonLoading" class="loading-container big">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
            </div>
            <div v-else>
                <button @click="showSummaryEvent" class="start">Acceder al contenido</button>
                <img v-if="windowWidth > 950" v-lazy="{ src: course.imageURL, loading: '../../img/lms/loading.png' }"
                    class="img" />
                <img v-else v-lazy="{ src: course.imageURL, loading: '../../img/lms/loading.png' }" class="img" />
            </div>
        </div>
       
        <div class="spacer"></div>


        <!-- SUMMARY -->
        <div class="summary" v-if="showSummary">
            <h4 class="topic-header">TEMARIO <button class="close-summary" @click="showSummary = false"><v-icon name="ri-close-circle-fill"></v-icon></button></h4>
            <ul class="animate">
                <!-- <li><AdvertisementMain AdType="menu"/></li> -->
                <li v-for="lesson in lessons" :key="lesson.id" @click="handleLesson(lesson.id)" class="lesson-link">
                    <LessonChecker :lessonId="lesson.id" ref="lessonChecker" />#{{ lesson.order }} {{ lesson.name }}
                </li>
            </ul>
        </div>
        

        <!-- NOTES -->
        <div v-if="lesson && showPaperNotes" class="notes-container">
            <div class="notes" v-if="store.token">
                <h3>Mis apuntes <button class="close-note" @click="showPaperNotes=false"><v-icon name="ri-close-circle-fill" fill="#00007f" scale="1.5" style="cursor: pointer;"></v-icon></button> <button class="save-note" @click="handleUpdateNote" @mouseenter="showSaveNoteAlt=true" @mouseleave="showSaveNoteAlt=false"><span v-if="showSaveNoteAlt" style="color: #00007f; font-family: 'note-text';">Guardar / Actualizar apuntes</span> <v-icon name="md-save-sharp" fill="#00007f" scale="1.5" style="cursor: pointer;"></v-icon></button></h3>
                <div v-if="!notesLoading" class="textarea-container">
                    <p class="advice">Toma aquí las notas que desees sobre la lección. Así podrás consultarlas siempre que
                        quieras, tanto desde aquí
                        como desde tu sección: Mis apuntes.</p>
                    <p><br /></p>
                    <textarea v-model="notes" class="note-content"></textarea>
                </div>
                <div v-else class="loading-container">
                    <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="5" />
                </div>
            </div>
            <div v-else>
                <p>Accede a tu cuenta para tomar apuntes de esta lección.</p>
            </div>
        </div>



        <!-- ABOUT THE COURSE-->
        <div v-if="lesson && showMainDescription" class="notes-container-course">
            <div class="notes-course">
                <h3>Sobre este Curso <button class="close-note" @click="showMainDescription=false"><v-icon name="ri-close-circle-fill" fill="#00007f" scale="1.5" style="cursor: pointer;"></v-icon></button></h3>
                <div class="textarea-container">
                    <p class="advice">Conoce más sobre esta fantástica formación.</p>
                    <p class="author-info"> <a :href="authorInfo.channelURL" target="_blank" class="author-link"><img
                        v-lazy="{ src: authorInfo.imageURL, loading: '../../../img/lms/loading.png' }" width="25"
                        class="author-img" /> Realizado por {{ authorInfo.name }}</a></p>
                    <p><br /></p>
                    
                    <div v-if="lesson" class="note-content" style="color: #00007f !important;" v-html="props.courseDescription"></div>
                </div>
            </div>
        </div>


        <!-- ABOUT THE LESSON-->
        <div v-if="lesson && showLessonDescription" class="notes-container-course">
            <div class="notes-course" style="margin-top: 40px;">
                <h3>#{{ lesson.order }} {{ lesson.name }}<button class="close-note" @click="showLessonDescription=false"><v-icon name="ri-close-circle-fill" fill="#00007f" scale="1.5" style="cursor: pointer;"></v-icon></button></h3>
                <div class="textarea-container">
                    <p class="advice">¿Qué vamos a aprender hoy?</p>
                    <p><br /></p>
                    <div v-if="lesson" v-html="lesson.description" class="note-content" style="color: #00007f !important;"></div>
                </div>
            </div>
        </div>


    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { defineProps, defineEmits } from 'vue'
import CourseService from '@/services/CourseService'
import LessonService from '@/services/LessonService'
import YouTube from 'vue3-youtube'
import LessonsBtns from '@/components/widgets/LessonBtns.vue'
import LessonChecker from '../widgets/LessonChecker.vue'
import { useAuth } from '@/store/useAuth'
import NoteService from '@/services/NoteService'
import { Toast } from '@/interfaces/ToastInterface'
import { useToast } from '@/store/useToast'
import { useRoute } from 'vue-router'
import useWindowWidth from '@/composables/useWindowWidth'
//import AdvertisementMain from '@/components/advertisement/AdevertisementMain.vue'


const { windowWidth } = useWindowWidth()
const props = defineProps(['courseId', 'authorInfo', 'courseDescription'])
const emit = defineEmits(['refreshStatus'])
const loading = ref(true)
const showSaveNoteAlt = ref(false)
const showPaperNotes = ref(false)
const store = useAuth()
const courseService = new CourseService()
const lessonService = new LessonService()
const noteService = new NoteService()
const toast = useToast()

const showMainDescription = ref(false)
const showLessonDescription = ref(false)

const notes = ref('')
const course = ref()
const lesson = ref()
const lessons = ref()
const lessonLoading = ref(false)
const lessonChecker = ref(null)
const lessonChecker0 = ref(null)
const lessonChecker1 = ref(null)
const lessonChecker2 = ref(null)
const lessonChecker3 = ref(null)
const lessonChecker4 = ref(null)
const lessonChecker5 = ref(null)
const notesLoading = ref(false)
const route = useRoute()
const ytLoaded = ref(false)
const showSummary = ref(false)
const currentLesson = ref(0)

const goNextEvent = () => {
    showLessonDescription.value = false
    showSummary.value = false
    showMainDescription.value = false
    showPaperNotes.value = false
    goNext()
}
const goPrevEvent = () => {
    showLessonDescription.value = false
    showSummary.value = false
    showMainDescription.value = false
    showPaperNotes.value = false
    goPrev()
}
const showLessonDescriptionEvent = () => {
    showLessonDescription.value = true
    showSummary.value = false
    showMainDescription.value = false
    showPaperNotes.value = false
}

const showDescriptionEvent = () => {
    showMainDescription.value = true
    showSummary.value = false
    showLessonDescription.value = false
    showPaperNotes.value = false
}

const showPaperNotesEvent = () => { 
    showPaperNotes.value = true
    showSummary.value = false
    showLessonDescription.value = false
    showMainDescription.value = false
}
const showSummaryEvent = () => {
    showSummary.value = true
    showLessonDescription.value = false
    showMainDescription.value = false
    showPaperNotes.value = false
}


onMounted(async () => {
    loading.value = true
    course.value = await courseService.GetCourse(props.courseId)
    lessons.value = await lessonService.getLessonsFromCourse(props.courseId)

    if (route.params.lesson_id) {
        handleLesson(parseInt(route.params.lesson_id.toString()))
    }
    loading.value = false
})

const onReady = () => {
    ytLoaded.value = true
}

const goPrev = async () => {
    showSummary.value = false
    lessonLoading.value = true

    const prev = await lessonService.getPrevLesson(currentLesson.value)
    if(prev.success == false) {
        lessonLoading.value = false
        notesLoading.value = false
        toast.showToast("No hay más clases en esa dirección.", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000)
        return
    }
    handleLesson(prev.id)
}

const goNext = async () => {
    showSummary.value = false
    lessonLoading.value = true

    const next = await lessonService.getNextLesson(currentLesson.value)
    if(next.success == false) {
        lessonLoading.value = false
        notesLoading.value = false
        toast.showToast("No hay más clases en esa dirección.", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000)
        return
    }
    handleLesson(next.id)
}

const handleLesson = async (lesson_id: number) => {
    showSummary.value = false
    lessonLoading.value = true
    notesLoading.value = true
    lesson.value = await lessonService.getLesson(lesson_id)
    currentLesson.value = lesson_id
    
    if (store.token) {
        const response = await noteService.GetNoteFromLesson(store.token, lesson.value.id)
        notes.value = response.note_content
    }
    window.scrollTo(0, 0);
    lessonLoading.value = false
    notesLoading.value = false

    setTimeout(async () => {
    if (!ytLoaded.value) {
        loading.value = true
        course.value = await courseService.GetCourse(props.courseId)
        lessons.value = await lessonService.getLessonsFromCourse(props.courseId)

        if (route.params.lesson_id) {
            handleLesson(parseInt(route.params.lesson_id.toString()))
        }
        loading.value = false
    }
}, 2000);
}

const handleUpdateNote = async () => {
    if (!store.token) {
        return
    }
    notesLoading.value = true
    const response = await noteService.CreateOrUpdateNote(store.token, lesson.value.id, notes.value)
    toast.showToast("Apuntes guardados.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000)
    notesLoading.value = false
}

const refreshStatus = async () => {
    emit('refreshStatus')
    if (lessonChecker0.value != null) {
        lessonChecker0.value.updateStatus()
    }
    if (lessonChecker1.value != null) {
        lessonChecker1.value.updateStatus()
    }
    if (lessonChecker2.value != null) {
        lessonChecker2.value.updateStatus()
    }
    if (lessonChecker3.value != null) {
        lessonChecker3.value.updateStatus()
    }
    if (lessonChecker4.value != null) {
        lessonChecker4.value.updateStatus()
    }
    if (lessonChecker5.value != null) {
        lessonChecker5.value.updateStatus()
    }
    if (lessonChecker.value != null) {
        lessonChecker.value.forEach(async (bullet) => {
            await bullet.updateStatus()
        });
    }
    Promise.all()
}
</script>

<style lang="scss" scoped>
@font-face {
    font-family: 'note-title';
    src: url('../../../src/assets/fonts/title.ttf');
}
@font-face {
    font-family: 'note-text';
    src: url('../../../src/assets/fonts/hand.ttf');
}

.close-summary {
    float: right;
    border: 0;
    outline: 0;
    cursor: pointer;
    background: none;
    color: white;
    svg {
        fill: lightcoral;
    }
}
.start {
    width: 100%;
    background-color: #333;
    color: white;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    outline: 0;
    margin-top: 0;
    margin-bottom: 30px;
    border: 0;
}
.start:hover {
    background-color: $secondary-color;
}

.summary {
    position: fixed;
    width: 35%;
    height: 100svh;
    top: 0;
    right: 0;
    z-index: 99999999;
    background-color: white;
    box-shadow: -2px 2px 5px rgba(0,0,0,0.5);
    padding: 15px;
    animation: 0.5s ease-out 0s 1 rightIn;
}


@keyframes rightIn {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.author-info {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
    padding-right: 15px;
    margin-top: 10px;
}

.author-link:hover {
    color:#00007f;
}

.author-img {
    border-radius: 50%;
    cursor: pointer;
    margin-left: 0;
    margin-right: 5px;
}

.author-info a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.save-note {
    float: right;
    background: none;
    outline: none;
    border: 0;
}

.close-note {
    float: right;
    background: none;
    outline: none;
    border: 0;
}

.note-content {
    font-family: 'note-text';
    background: none;
    font-size: 20px;
    height: 600px;
    h1, h2, h3, h4, h5, h6, p, a {
        color: #00007f !important;
    }
    * {
        color: #00007f !important;
    }
}
.show-more-less {
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    cursor: pointer;
}

.show-more-less:hover {
    text-decoration: underline;
    font-weight: bold;
}

.notes-container {
    width: 912px;
    height: 932px;
    position: fixed;
    top: 100px;
    left: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-image: url('../../../public/img/lms/note.png');
    background-size: cover;
    z-index: 999;
    animation: 0.5s ease-out 0s 1 upIn;
}
.notes-container-course {
    width: 912px;
    height: 932px;
    position: fixed;
    top: 100px;
    left: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    z-index: 999;
    animation: 0.5s ease-out 0s 1 upIn;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
}

@keyframes upIn {
  0% {
    transform: translateY(50%);
    //opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.advice {
    width: 100%;
    color: #00007f;
    font-size: 15px;
    font-family: 'note-text';
}

.notes-btn {
    padding: 15px;
    border-radius: 10px;
    background-color: $third-color;
    border: 0;
    cursor: pointer;
    width: 100%;
    color: white;
    font-family: $title-font;
}

.lesson-description {
    width: 85;
}

.notes-btn:hover {
    background-color: $secondary-color;
    color: white;
}

textarea {
    background-color: $primary-color;
    border: 0;
    border-radius: 10px;
    width: 100%;
    padding: 25px;
    height: 250px;
    color: #00007f;
    line-height: 24px;
    rotate: -0.3deg;
}

.notes {
    width: 85%;
    rotate: -3deg;
    margin-top: 50px;
    margin-left: 60px;
    h3{
      //  font-family: 'note-text';
        color: #00007f;
        font-size: 40px;
        text-transform: uppercase;
        font-family: 'note-title';
        margin-bottom: 10px;
    }
}

.notes-course {
    width: 85%;
    margin-top: 50px;
    margin-left: 60px;
    h3{
      //  font-family: 'note-text';
        color: #00007f;
        font-size: 40px;
        text-transform: uppercase;
        font-family: 'note-title';
        margin-bottom: 10px;
    }
}

.topic-header {
    margin-bottom: 6px;
    color: #333;
    width: 100%;
}

h5 {
    color: $text-color;
}

iframe {
    width: 100% !important;
}

.yt {
    overflow-y: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    iframe {
        width: 100% !important;
    }
}

#ytube {
    width: 100% !important;
    iframe {
        width: 100% !important;
    }
}

.lesson-link {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
}

.big {
    width: 640px;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
}

li {
    margin-bottom: 5px;
}

.lesson-link:hover {
    background-color: $secondary-color;
    color: white;
}

.init-img {
    width: 100%;
}

.img {
    border-radius: 10px;
    width: 100%;
}

.lesson-container {
    display: flex;
    flex-wrap: wrap;
}

.lesson-list-container {
    height: fit-content;
    min-height: 360px;
    background-color: $primary-color;
    padding: 10px;
    border-radius: 10px;
    min-width: 350px;
    width: 30%;
    margin-left: 10px;
}

@media(max-width: 950px) {
    .summary {
    position: fixed;
    width: 100%;
    height: 100svh;
    top: 0;
    right: 0;
    z-index: 99999999;
    background-color: white;
    box-shadow: -2px 2px 5px rgba(0,0,0,0.5);
    padding: 15px;
    animation: 0.5s ease-out 0s 1 rightIn;
}
.notes-container-course {
    z-index: 99999999;
    width: 100%;
    position: fixed;
    top: 50px;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    z-index: 999;
    animation: 0.5s ease-out 0s 1 upIn;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
}

.notes-container {
    width: 100%;
    position: fixed;
    top: 50px;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-image: url('../../../public/img/lms/note.png');
    background-size: cover;
    z-index: 999;
    animation: 0.5s ease-out 0s 1 upIn;
}

    .lesson-list-container {
        margin-top: 15px;
        width: 100%;
    }

    textarea {
        width: 100%;
    }

    .textarea-container {
        width: 100%;
    }

    .img {
        width: 100%;
    }

    .lesson-list-container {
        margin: 0;
        width: 100%;
        min-width: 0;
    }

    .btns {
        margin-bottom: 15px;
    }

    .notes {
        margin-right: 0;
        width: 100%;
    }
}
</style>