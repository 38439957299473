export default class WebinarService{
    private base_url = 'https://gogodev.net/gogolearn/api/lms_webinar'

    public async GetAllsWebinars() {
        const uri = `${this.base_url}/all`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
          }
        })
        const response = await rawResponse.json()
        return response
    }

    public async GetLatestWebinar() {
        const uri = `${this.base_url}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
          }
        })
        const response = await rawResponse.json()
        return response
    }
}