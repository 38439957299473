<template>
    <div class="animate channel-header-container" v-if="store.token && !loading">
        <img v-if="user.profile_bg_uri" :src="user.profile_bg_uri" class="bg-image" />
        <div v-if="showEditNickname" class="animate modal">
            <div class="modal-inner">
                <div style="float: right; cursor: pointer;" @click="showEditNickname = false"><v-icon
                        name="ri-close-circle-fill" fill="lightcoral"></v-icon></div>
                <h3>Editar Nickname</h3>
                <p>El Nickname debe ser único en la plataforma, y te permitirá disponer de una URL personalizada para tu
                    canal, que
                    será:<br /><br /><b>https://campus-ademass.com/c/{{ nickname.toLowerCase() }}</b><br /><br />para que
                    puedas compartirla más fácilmente. Evita usar espacios en blanco y caracteres especiales.</p>
                <input class="input-text" type="text" v-model="nickname" placeholder="Nuevo Nickname" />
                <button class="new-question" @click="handleNickname">Actualizar Nickname</button>
            </div>
        </div>
        <div v-if="showUpdateBG" class="animate modal">
            <div class="modal-inner">
                <div style="float: right; cursor: pointer;" @click="showUpdateBG = false"><v-icon
                        name="ri-close-circle-fill" fill="lightcoral"></v-icon></div>
                <h3>Editar Portada</h3>
                <p>La imagen de portada cubrirá toda la zona de presentación, escalando escalando en formato 'cover'
                    para cubrir el área. El tamaño máximo de imagen permitido es de 4Mbb.</p>
                <input class="input-text" type="file" @change="onFileChange" placeholder="Nueva Portada" />
                <button class="new-question" @click="handleBG">Actualizar Portada</button>
                <small>{{ feedback }}</small>
            </div>
        </div>
        <div class="edit-bg-image" @click="showUpdateBG = true"><v-icon class="pencil-2" name="bi-pencil-fill"
                style="overflow-y: hidden;"></v-icon></div>
        <div class="animate profile-name">
            <h3>{{ user.name }}<v-icon class="pencil-2" name="bi-pencil-fill"
                    @click="router.push({ name: 'profile' })"></v-icon></h3>
        </div>
        <div class="animate reputation-card">
            <ReputationCardUsers :user_id="user.id" />
        </div>
        <div class="animate profile-image-container">
            <div class="animate profile-image">
                <ProfileImage />
            </div>
            <div class="animate profile-data-container">
                <div class="animate profile-data" style="background-color: #333;">
                    <h5 v-if="user.nickname">@{{ user.nickname }} <v-icon class="pencil" name="bi-pencil-fill"
                            @click="showEditNickname = true"></v-icon></h5>
                    <h5 v-else>@{{ user.name.substring(0, 3) + user.id }} <v-icon class="pencil" name="bi-pencil-fill"
                            @click="showEditNickname = true"></v-icon></h5>
                </div>
                <div  v-if="user.nickname" class="animate profile-data" style="cursor: pointer;"  @click="router.push({name: 'followers-list', params: {nickname: user.nickname}})">
                    <h5>Seguidores: {{followers}}</h5>
                </div>
                <div  v-else class="animate profile-data" style="cursor: pointer;"  @click="router.push({name: 'followers-list', params: {nickname: user.name.substring(0, 3) + user.id}})">
                    <h5>Seguidores: {{followers}}</h5>
                </div>
                <div  v-if="user.nickname" class="animate profile-data" style="cursor: pointer;" @click="router.push({name: 'following-list', params: {nickname: user.nickname}})">
                    <h5>Siguiendo: {{following}}</h5>
                </div>
                <div  v-else class="animate profile-data"  style="cursor: pointer;" @click="router.push({name: 'following-list', params: {nickname: user.name.substring(0, 3) + user.id}})">
                    <h5>Siguiendo: {{following}}</h5>
                </div>
                <FollowChannelButton :user_id="user.id" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import ProfileImage from '@/components/widgets/ProfileImage.vue';
import ReputationCardUsers from '@/components/widgets/ReputationCardUsers.vue';
import ChannelService from '@/services/ChannelService';
import ProfileService from '@/services/ProfileService';
import { ref, onMounted } from 'vue'
import { useAuth } from '@/store/useAuth';
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import router from '@/router';
import FollowChannelButton from '@/components/widgets/FollowChannelButton.vue'

const channelService = new ChannelService()
const user = ref()
const loading = ref(true)
const profileService = new ProfileService()
const store = useAuth()
const showEditNickname = ref(false)
const nickname = ref('')
const toast = useToast()
const bg = ref()
const showUpdateBG = ref(false)
const feedback = ref('')
const followers = ref()
const following = ref()

onMounted(async () => {
    if (!store.token) { return }
    loading.value = true
    user.value = await profileService.MyUser(store.token)
    followers.value = await channelService.CountFollowers(user.value.id)
    following.value = await channelService.CountFollowing(user.value.id)
    loading.value = false
})

const onFileChange = (event) => {
    const file = event.target.files[0];
    bg.value = file;
};

const handleNickname = async () => {
    if (!store.token) { return }

    if ((nickname.value.length < 4) || (!validateNickname(nickname.value))) {
        toast.showToast("Nombre no válido. Utiliza caracteres no permitidos en el sistema.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    const response = await profileService.UpdateNickname(store.token, nickname.value.toLowerCase())
    if (response == true) {
        toast.showToast("Perfil actualizado.", Toast.SUCCESS)
        setTimeout(function () { toast.hideToast() }, 4000);
        showEditNickname.value = false
        user.value = await profileService.MyUser(store.token)
        return;
    }

    toast.showToast("El usuario ya existe en el sistema.", Toast.DANGER)
    setTimeout(function () { toast.hideToast() }, 4000);
}

const handleBG = async () => {
    if (!store.token) { return }
    feedback.value = "Subiendo..."
    const response = await profileService.UpdateBG(store.token, bg.value)
    if (response.success == true) {
        toast.showToast("Perfil Actualizado", Toast.SUCCESS)
        setTimeout(function () { toast.hideToast() }, 4000);
        user.value = await profileService.MyUser(store.token)
        showUpdateBG.value = false
        feedback.value = ""
    } else {
        toast.showToast(response.message, Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        feedback.value = ""
    }
}

const validateNickname = (n: string) => {
    const regex = /^[a-zA-Z0-9]+$/;
    return regex.test(n);
}
</script>

<style lang="scss" scoped>
.bg-image {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 13px;
}
.edit-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: fit-content;
    height: fit-content;
    overflow-y: hidden;
    background-color: $secondary-color;
    border-radius: 50%;
    padding: 15px;
    padding-left: 10px;
    margin: 5px;
}

.input-text {
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid $secondary-color;
    margin: 5px;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 5px;
    outline: none;
}

.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;

    h3 {
        margin-bottom: 15px;
    }

    p {
        font-size: 12px;
    }
}

.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 5px;
    margin-top: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.new-question:hover {
    background-color: $secondary-color;
    color: white;

    svg {
        fill: white;
    }
}

.pencil-2 {
    margin-left: 5px;
    cursor: pointer;
    fill: white;
    overflow: hidden;
    scale: 1.2;
}

.pencil-2:hover {
    fill: #333;
}

.pencil {
    margin-left: 5px;
    cursor: pointer;
}

.pencil:hover {
    fill: $secondary-color;
}

.channel-header-container {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #ccc;
    position: relative;
    background-image: url('https://ademass.com/wp-content/uploads/2023/05/ademass_imagen.jpg');
    background-size: cover;
    height: 400px;
    border-radius: 15px;
    overflow: visible;
    border: 3px solid $secondary-color;
    overflow: visible;
}

.profile-image {
    width: 200px;
    height: 200px;
}

.profile-image-container {
    position: absolute;
    bottom: -80px;
    left: 30px;
    display: flex;
    min-width: 400px;
    align-items: flex-end;
    overflow: visible;
}

.profile-name {
    padding: 10px;
    background-color: $secondary-color;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    right: 0;
    bottom: 50px;

    h3 {
        color: white;
        overflow-y: hidden;
        display: flex;
        align-items: center;
    }
}

.reputation-card {
    position: absolute;
    right: 10px;
    bottom: 90px;
}

.profile-data-container {
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    bottom: 0px;
    left: 200px;
    display: flex;
    flex-wrap: wrap;
}

.profile-data {
    padding: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $secondary-color;
    width: 200px;
    margin: 5px;

    svg {
        fill: white;
    }

    h5 {
        color: white;
    }
}

@media(max-width:950px) {
    .profile-name {

        top: 50px;
        bottom: auto;

    }

    .reputation-card {

        top: 90px;
        bottom: auto;
    }

}</style>