<template>
<DefaultLayout>
    <h1>Juanjo Ruiz</h1>
    <div class="spacer"></div>
    <div class="header">
        <div class="section">
     <h4>Especialidades:</h4><label>Vue / Laravel / Solid</label>
    </div>
     <div class="separator"></div>
     <div class="section">
     <h4>País:</h4><label>España</label>
    </div>
     <div class="separator"></div>
     <div class="section">
     <h4>Ciudad:</h4><label>Málaga</label>
    </div>
     <div class="separator"></div>
     <div class="section">
     <h4>Tarifa:</h4><label>50€</label>
    </div>
    </div>
    <div class="container is-light-mode">
    <Qalendar 
    :events="events"
    :config="config"
  />
</div>
     
</DefaultLayout>
</template>

<script  lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { Qalendar } from "qalendar";
import QEvent from '@/interfaces/QEvent'

const events: QEvent[] = [
  {
    title: "BBDD",
    with: "Juanjo Ruiz",
    time: { start: "2024-02-16 12:05", end: "2024-02-16 13:35" },
    color: "blue",
    isEditable: true,
    id: "753944708f0f",
    description: "Clase de base de datos en DIGITECH"
  },
  {
    title: "JavaScript",
    with: "Alejandro",
    time: { start: "2024-01-10", end: "2024-01-11" },
    color: "green",
    isEditable: true,
    id: "5602b6f589fc"
  }
  // ... otros eventos
];

const config = {
  // configuración del calendario
  
};

</script>

<style lang="scss" scoped>
@import "../../node_modules/qalendar/dist/style.css";

h4{
    margin-right: 8px;
}
.header{
    display: flex;
}
.separator{
    width: 50px;
} 
.section{
  display: flex;
  margin-bottom: 15px;
}
.container{
  width: 100%;
}

</style>