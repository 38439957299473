export default class {
    private base_url = 'https://gogodev.net/gogolearn/api/forum'

    public async WriteQuestion(token: string, is_from_course: boolean, name: string, description: string, course_id?: number,
        topic_id?: string | string[], lesson_id?: number, tries?: string, repository?: string) {

        const uri = `${this.base_url}/write-question`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'is_from_course': is_from_course,
                'name': name,
                'description': description,
                'course_id': course_id,
                'topic_id': topic_id,
                'lesson_id': lesson_id,
                'try': tries,
                'repository': repository
            })
        })
        const response = await (rawResponse).json()
        return response
    }

    public async WriteAnswer(token: string, question_id:string | string[], answer: string) {

        const uri = `${this.base_url}/write-answer`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'question_id': question_id,
                'answer': answer
            })
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetQuestionsByLessonId(lesson_id:string | string[]) {
        const uri = `${this.base_url}/questions-from-lesson/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        console.log("RESPONSE " + response)
        return response
    }

    public async GetQuestionWithAnswers(question_id: string | string[]) {
        const uri = `${this.base_url}/question/${question_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async LatestQuestions() {
        const uri = `${this.base_url}/latest-questions`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }


    public async UndoLike(token: string, id: string | string[]) {
        const uri = `${this.base_url}/undo-like/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UndoDislike(token: string, id: string | string[]) {
        const uri = `${this.base_url}/undo-dislike/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }


    public async DoLike(token: string, id: any) {
        const uri = `${this.base_url}/do-like/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async DoDislike(token: string, id: string | string[]) {
        const uri = `${this.base_url}/do-dislike/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async MarkAsCorrect(token: string, id: string | string[]) {
        const uri = `${this.base_url}/mark-as-correct/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetUserQuestions(token: string) {
        const uri = `${this.base_url}/user-questions`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetAllTopics() {
        const uri = `${this.base_url}/topics`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetQuestionsByTopicId(id:string|string[]) {
        const uri = `${this.base_url}/topics/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }
}