<template>
    <div>
      <div class="container" :style="{ 'background-image': `url(${currentBanner.bg_uri})`}">
        <div class="title"><h1>{{currentBanner.title}}</h1></div>
        <br>
        <div class="subtitle"><h3>{{currentBanner.description}}</h3></div>
        <div class="space"></div>
        <a class="button" v-if="currentBanner.action" :href="currentBanner.action">Acceder</a>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import InformationService from '@/services/InformationService';
  
  const service = new InformationService();
  const currentBanner = ref({});
  const informationMulti = ref([]);
  let currentBannerIndex = 0;
  
  const changeBanner = () => {
    currentBannerIndex = (currentBannerIndex + 1) % informationMulti.value.length;
    currentBanner.value = informationMulti.value[currentBannerIndex];
  };
  
  onMounted(async () => {
    informationMulti.value = await service.GetInformationMulti();
    currentBanner.value = informationMulti.value[currentBannerIndex];
    setInterval(changeBanner, 12000); // Cambiar cada dos minutos (120 segundos)
  });
  </script>
  
  <style lang="scss" scoped>
  h1,
  h3 {
    color: white;
    width: 100%;
  }
  .title{
    text-align: center;
  }
  .subtitle{
    text-align: center
  }
  
  .container {
    border: 2px solid #57afc4;
    min-height: 200px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-size: cover;
    background-position: center;
  }
  
  .button {
    padding: 10px;
    background-color: rgb(51, 47, 47);
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-family: $title-font;
  }
  
  .button:hover {
    background-color: rgb(83, 78, 78);
  }
  
  .space {
    height: 20px;
  }
  </style>
  