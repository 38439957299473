<template>
    <DefaultLayout>
        <NotificationDetail v-if="showNot" :title="notTitle" :content="notContent" :id="notId" @goBack="OnGoBack">

        </NotificationDetail>
        <div v-else>
            <div v-if="loading">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
            </div>
            <div class="container" v-else>
                <h1>Notificaciones y Alertas</h1>
                <ul>
                    <li v-for="notification in notifications" :key="notification.id" style="margin: 5px; width:100%;">
                        {{ notification.title }}
                        <v-icon name="md-newreleases-round" v-if="!notification.read && windowWidth>tableSmall " style="float: left;scale:1.8;"
                            fill="coral"></v-icon>
                        <button style="float: right;" @click="OnMarkAsRead(notification.id)" v-if="windowWidth>tableSmall">Marcar como leído</button>
                        <button style="float: right;" @click="OnShowMessage(notification.id, notification.title, notification.content)">Leer mensaje</button>
                    </li>
                </ul>
            </div>
        </div>
    </DefaultLayout>
</template>

<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref, onMounted,Ref } from 'vue'
import NotificationService from '@/services/NotificationService';
import { useAuth } from '@/store/useAuth';
import NotificationDetail from './NotificationDetail.vue';
import useWindowWidth from '@/composables/useWindowWidth';

const notificationService = new NotificationService()
const loading = ref(true)
const notifications:Ref<any> = ref({})
const store = useAuth()
const showNot = ref(false)
const notTitle = ref('')
const notContent = ref('')
const notId = ref(0)
const {windowWidth} = useWindowWidth()
const tableSmall= ref(850);

onMounted(async () => {
    if (!store.token) {
        loading.value = false
        return
    }
    loading.value = true
    notifications.value = await notificationService.ReadAllNotes(store.token)
    loading.value = false
})

const OnMarkAsRead = async (notification:string) => {
    if(!store.token){return}
    await notificationService.MarkNotificationAsRead(store.token, notification)
    loading.value = true
    notifications.value = await notificationService.ReadAllNotes(store.token)
    loading.value = false
}

const OnShowMessage = async (notification:number, title:string, content:string) => {
    notTitle.value = title
    notContent.value = content
    notId.value = notification
    showNot.value = true
}

const OnGoBack = async () => {
    if(!store.token){return}
    showNot.value = false
    loading.value = true 
    notifications.value = await notificationService.ReadAllNotes(store.token)
    loading.value = false
}
</script>

<style lang="scss" scoped>
button {
    padding: 5px;
    border: 0;
    outline: 0;
    background-color: $secondary-color;
    color: white;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
}

ul {
    margin-top: 20px;
    color: $secondary-color!important;
}

li {
    width: 100%;
    border-collapse: collapse;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    text-align: center;
    scale: 0.9;

}

li:hover{
   // scale: 1.00;
    color: black;
}
</style>