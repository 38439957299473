<template>
    <DefaultLayout>
        <div class="container">
            <div class="header">
                <h1>Librería</h1>
                
            </div>
            <div class="Tbody">
                <div class="card" v-for="elm in books" :key="elm.id">
                    <img :src="elm.image_uri" alt="imgh prueba" width="200" height="210">
                    <h5>{{ elm.title }}</h5>
                    <a :href="`/detalles-libro/${elm.id}`">                
                    <button class="btn-details">Ver detalles</button>
                    </a>
                </div>
            </div>
        </div>
        
    </DefaultLayout>
</template>

<script  lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BookService from '@/services/BookService'
import { onMounted } from 'vue';
import { ref,Ref } from 'vue';
import BookInterface from '@/interfaces/BookInterface'

const service = new BookService()
const loading = ref(false)
const books:Ref<Array<BookInterface>> = ref([])

onMounted(async()=>{
    loading.value = true
    books.value = await service.GetBooks()
    loading.value = false
})

</script>

<style scoped lang="scss">

.container {
    height: 100%;
    padding: 20px;
}
h5{
    margin-bottom: 5px;
    margin-top: 5px;
    color:$secondary-color;
}
.header {
    display: flex;
    align-items: center;
    justify-content: center;

}

.Tbody {
    
    height: 100%;
    margin: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 290px;
    width: 200px;
    margin: 15px;
    
}
.btn-details{
    margin-top: 5px;
    padding: 8px;
    color: white;
    background-color:$secondary-color;
    border-radius: 5px;
    border: none;
}
.btn-details::hover {
  background-color: $third-color;
  cursor: pointer;
}

</style>