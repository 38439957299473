<template>
    <v-icon name="RiLoader2Fill" />
    <div v-if="loading">
        <v-icon name="RiLoader2Fill" />
    </div>
    <div v-else>
        <div v-if="lessons.length > 0" class="content" style="margin-bottom: 100px;">
            <h1>Vuelve a ver contenido que te encanta</h1>
            <div class="container">
                <LessonList v-for="item in lessons" :key="item.id" :content="item" :alt="true" />
                <div class="spacer"></div>
            </div>
            <div v-if="moreThan21">
                <RouterLink :to="{name: 'my-favs'}">Ver todos</RouterLink>
            </div>
        </div>
    </div>
</template>
 
<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import FavService from '@/services/FavService'
import { useAuth } from '@/store/useAuth'
import LessonList from '@/components/cards/LessonList.vue'
import LessonService from '@/services/LessonService'

const store = useAuth()
const favService = new FavService()
const lessonService = new LessonService()
const allLessons = ref([])
const lessons = ref([])
const loading = ref(false)
const moreThan21 = ref(false)

onMounted(async () => {
    loading.value = true
    if (store.token) {
        allLessons.value = await favService.getAllFromUser(store.token)
    }

    let counter = 0;
    allLessons.value.forEach(async lesson => {
        counter++
        if(counter <= 9 ) {
            const item = await lessonService.getLesson(lesson.lesson_id)
            lessons.value.push(item)
        }
        else {
            moreThan21.value = true
        }
    });
    Promise.all(lessons.value)
    loading.value = false
})
</script>

<style lang="scss" scoped>
.content {
    margin-bottom: 20px;
}
.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
h1 {
    margin-bottom: 10px;
}

@media(max-width: 950px) {
    .container {
        width: 95vw;
    }
}
</style>