export default class AdService{
    private base_url = 'https://gogodev.net/gogolearn/api/lms_advertisement'

    public async GetAdvertisement() {
        const uri = `${this.base_url}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }


}