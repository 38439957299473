<template>
    <div v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
    </div>
    
</template>

<script  lang="ts" setup>
import { useAuth } from '@/store/useAuth'
import PayPalService from '@/services/PayPalService'
import { onMounted, ref } from 'vue'
import router from '@/router';

const loading = ref(true)
const service = new PayPalService()
const store = useAuth()

onMounted(async () => {
    if (!store.token) {
        router.push({name: 'auth'})
        return
    }
    loading.value = true

    const response = await service.checkSub(store.token)
    if(response.status != 'ACTIVE') {
        router.push({name: 'register-free'})
    }

    loading.value = false
})
</script>

<style scoped></style>