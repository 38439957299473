<template>
    <div style="position: relative;" v-if="unread > 0">
        <div class="alert-notification-inside">{{ unread }}</div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, onMounted, onUnmounted, ref } from 'vue'
import FriendshipService from '@/services/FriendshipService'
import { useAuth } from '@/store/useAuth'

const props = defineProps(['friendId'])
const service = new FriendshipService()
const unread = ref(0)
const store = useAuth()
const intervalId = window.setInterval(async () => {
    if(!store.token) {
        return
    }
    unread.value = await service.getUnreadMessagesSentByYourFriend(store.token, props.friendId)
}, 5000)

onMounted(async () => {
    if(!store.token) { return }
    unread.value = await service.getUnreadMessagesSentByYourFriend(store.token, props.friendId)
})

onUnmounted(() => {
    clearInterval(intervalId);
})
</script>

<style lang="scss" scoped>
.alert-notification-inside {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: lightcoral;
    top: 0px;
    right: 0;
    z-index: 1000000000;
    overflow: visible;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    margin-left: 10px;
}
</style>