<template>
    <div>
        <div v-if="!store.token">
            <h3>Accede a tu cuenta para activar la botonera de seguimiento de lecciones.</h3>
        </div> 
        <div class="inner-btns" v-else>
            <div class="play-bar-container">
                <button class="play-btn" @click="emit('goPrev')"><v-icon class="play-icon" name="bi-arrow-left-circle-fill"
                        scale="2" title="Anterior"></v-icon> Anterior</button>
                <button class="play-btn" @click="emit('showDescription')"><v-icon class="play-icon" name="md-summarize"
                        scale="2" title="Descripción del curso"></v-icon> Sobre el curso</button>
                <button class="play-btn" @click="emit('showLessonDescription')"><v-icon class="play-icon"
                        name="md-description" scale="2" title="Descripción de la clase"></v-icon> Descripción</button>
                <button class="play-btn" @click="emit('showNotes')"><v-icon class="play-icon" name="bi-pencil-fill"
                        scale="2" title="Tomar y leer mis apuntes"></v-icon> Mis apuntes</button>
                <button class="play-btn" @click="emit('showSummary')"><v-icon class="play-icon" name="io-list-circle-sharp"
                        scale="2" title="Ver y navegar por el temario"></v-icon> Temario</button>
                <RouterLink v-if="lesson" class="play-btn inner-btn" target='_blank'
                    style="margin:0; padding: 0;cursor: pointer; text-decoration: none;"
                    :to="{ name: 'detail-question-course', params: { course_id: lesson.course_id, lesson_id: lesson.id } }">
                    <button class="play-btn"
                        style="width: 100%; border: 0; text-decoration: none; overflow: hidden;"><v-icon class="play-icon"
                            name="bi-question-circle-fill" scale="2" title="Dudas y preguntas"></v-icon> Dudas</button>
                </RouterLink>
                <button v-else class="play-btn"
                        style="width: 100%; border: 0; text-decoration: none; overflow: hidden;"><v-icon class="play-icon"
                            name="ri-loader-2-fill"  animation="spin" scale="2" title="Dudas y preguntas"></v-icon> Dudas</button>
                <button class="play-btn" v-if="!isFav" @click="handleDoFav">
                    <v-icon class="play-icon" name="ri-loader-2-fill" animation="spin" scale="2" title="Añadir a favoritos"
                        v-if="favLoading"></v-icon>
                    <v-icon class="play-icon" name="bi-heart" scale="2" title="Añadir a favoritos" v-else></v-icon>
                    Favorito
                </button>
                <button class="play-btn play-btn-selected" v-else @click="handleDoNoFav">
                    <v-icon class="play-icon" name="ri-loader-2-fill" animation="spin" scale="2" title="Añadir a favoritos"
                        v-if="favLoading"></v-icon>
                    <v-icon class="play-icon" name="bi-heart-fill" scale="2" title="Eliminar de favoritos" v-else></v-icon>
                    Favorito
                </button>
                <button class="play-btn" v-if="!isLater" @click="handleDoLater">
                    <v-icon v-if="laterLoading" class="play-icon" name="ri-loader-2-fill" scale="2" animation="spin"
                        title="Ver más tarde"></v-icon>
                    <v-icon v-else class="play-icon" name="md-watchlater-outlined" scale="2" title="Ver más tarde"></v-icon>
                    Ver más tarde
                </button>
                <button class="play-btn  play-btn-selected" v-else @click="handleDoNoLater">
                    <v-icon v-if="laterLoading" class="play-icon" name="ri-loader-2-fill" scale="2" animation="spin"
                        title="Ver más tarde"></v-icon>
                    <v-icon v-else class="play-icon" name="md-watchlater-outlined" scale="2"
                        title="Eliminar de ver más tarde"></v-icon>
                    Ver más tarde
                </button>
                <button v-if="!isCompleted" @click="handleDoCompleted" class="play-btn">
                    <v-icon v-if="completedLoading" class="play-icon" name="ri-loader-2-fill" scale="2" animation="spin"
                        title="Marcar como completado"></v-icon>
                    <v-icon v-else class="play-icon" name="bi-check-circle" scale="2"
                        title="Marcar como completado"></v-icon>
                    Completado
                </button>
                <button v-else @click="handleDoNoCompleted" class="play-btn play-btn-selected">
                    <v-icon v-if="completedLoading" class="play-icon" name="ri-loader-2-fill" scale="2" animation="spin"
                        title="Eliminar de completados"></v-icon>
                    <v-icon v-else class="play-icon" name="bi-check-circle-fill" scale="2"
                        title="Eliminar de completados"></v-icon>
                    Completado
                </button>
                <button class="play-btn" @click="emit('goNext')"><v-icon class="play-icon" name="bi-arrow-right-circle-fill"
                        scale="2" title="Siguiente"></v-icon> Siguiente</button>
            </div>
            <!-- END NEW BAR-->
        </div>
    </div>
</template>


<style lang="scss" scoped>
.play-bar-container {
    display: flex;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.play-btn {
    width: 10%;
    height: 100px;
    background-color: #333;
    color: white;
    padding: 15px;
    outline: 0;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 10px;
    cursor: pointer;
    overflow-y: hidden;

    .play-icon {
        fill: white;
        overflow-y: hidden;
        margin-bottom: 10px;
    }
}

.play-btn-selected {
    background: linear-gradient(315deg, rgba(43, 139, 162, 1) 0%, rgba(87, 175, 196, 1) 100%);
}

.play-btn:hover {
    background-color: $third-color;

    button {
        background-color: $third-color !important;
    }
}

.play-btn-selected:hover {
    background-color: $third-color;
}

@media(max-width:995px) {
    .play-bar-container {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
        flex-wrap: wrap;
    }

    .play-btn {
        width: 100%;
    }
}
</style>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'
import { onMounted, ref } from 'vue'
import { useAuth } from '@/store/useAuth'
import CompletedService from '@/services/CompletedService'
import LaterService from '@/services/LaterService'
import FavService from '@/services/FavService'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface'
import LessonService from '@/services/LessonService'

const store = useAuth()
const completedService = new CompletedService()
const laterService = new LaterService()
const favService = new FavService()
const props = defineProps(['lessonId'])
const emit = defineEmits(['refreshStatus', 'showNotes', 'showDescription', 'showLessonDescription', 'showSummary', 'goPrev', 'goNext'])
const completedLoading = ref(true)
const favLoading = ref(true)
const laterLoading = ref(true)
const isFav = ref()
const isCompleted = ref()
const isLater = ref()
const toast = useToast()
const lesson = ref()
const lessonService = new LessonService()

onMounted(async () => {
    completedLoading.value = true
    favLoading.value = true
    laterLoading.value = true

    if (!store.token) {
        completedLoading.value = false
        favLoading.value = false
        laterLoading.value = false
        return
    }
    lesson.value = await lessonService.getLesson(props.lessonId)
    isFav.value = await favService.checkFavStatus(store.token, props.lessonId)
    isCompleted.value = await completedService.checkCompletedStatus(store.token, props.lessonId)
    isLater.value = await laterService.checkLaterStatus(store.token, props.lessonId)

    completedLoading.value = false
    favLoading.value = false
    laterLoading.value = false
})

const handleDoCompleted = async () => {
    if (!store.token) {
        return
    }
    completedLoading.value = true
    await completedService.doCompleted(store.token, props.lessonId)
    isCompleted.value = true
    toast.showToast("Marcado como completado.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    emit("refreshStatus")
    completedLoading.value = false
}
const handleDoFav = async () => {
    if (!store.token) {
        return
    }
    favLoading.value = true
    await favService.doFav(store.token, props.lessonId)
    isFav.value = true
    toast.showToast("Marcado como favorito.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    favLoading.value = false
}
const handleDoLater = async () => {
    if (!store.token) {
        return
    }
    laterLoading.value = true
    await laterService.doLater(store.token, props.lessonId)
    isLater.value = true
    toast.showToast("Añadido a ver más tarde.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    laterLoading.value = false
}
const handleDoNoCompleted = async () => {
    if (!store.token) {
        return
    }
    completedLoading.value = true
    await completedService.doNoCompleted(store.token, props.lessonId)
    isCompleted.value = false
    toast.showToast("Marcado como NO completado.", Toast.WARNING)
    setTimeout(function () { toast.hideToast() }, 4000);
    emit("refreshStatus")
    completedLoading.value = false
}
const handleDoNoFav = async () => {
    if (!store.token) {
        return
    }
    favLoading.value = true
    await favService.doNoFav(store.token, props.lessonId)
    isFav.value = false
    toast.showToast("Eliminado de favoritos.", Toast.WARNING)
    setTimeout(function () { toast.hideToast() }, 4000);
    favLoading.value = false
}
const handleDoNoLater = async () => {
    if (!store.token) {
        return
    }
    laterLoading.value = true
    const response = await laterService.doNoLater(store.token, props.lessonId)
    isLater.value = false
    toast.showToast("Eliminado de ver más tarde.", Toast.WARNING)
    setTimeout(function () { toast.hideToast() }, 4000);
    laterLoading.value = false
}
</script>


