<template>
    <div class="box card" v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
    </div>

    <div v-else class="animate-no-fade a-box">
        <div style="border-top-left-radius: 15px; border-top-right-radius: 15px;">
            <img v-lazy="{ src: props.content.image_url, loading: '../../../img/lms/loading.png' }" width="316">
        </div>
        <div class="text-container">
            <h3 @click="handleRoute">{{ props.content.title }}</h3>
            <div class="buttons-container">
                <div class="buttons-place">
                    <FollowRoadmapWidget :roadmapId="card.id" />
                    <button @click="handleRoute"><v-icon name="bi-arrow-right-circle-fill" fill="#bece49" hover="true"
                            animation="wrench" class="heart" scale="1"></v-icon>Acceder</button>
                </div>
            </div>
            <div class="pro">
                <RoadmapProgressbar :roadmapId="props.content.id" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, onMounted } from 'vue'
import FollowRoadmapWidget from '../widgets/FollowRoadmapWidget.vue'
import RoadmapProgressbar from '../widgets/RoadmapProgressbar.vue';
import router from '@/router'
import useWindowWidth from '@/composables/useWindowWidth'
const { windowWidth } = useWindowWidth()


const props = defineProps(['content'])
const card = ref({
    id: 0,
    title: 'cargando...',
    description: 'cargando...',
    image_url: 'img/lms/loading.png',
    created_at: '',
    updated_at: ''
})
const loading = ref(true)

onMounted(() => {
    loading.value = true
    card.value = props.content
    loading.value = false
})

const handleRoute = () => router.push({ name: 'roadmap', params: { id: card.value.id } })
</script>

<style lang="scss" scoped>
* {
    overflow-y: hidden;
}

.a-box {
    padding: 10px;
    display: inline-block;
    width: 320px;
    margin: 5px;
    text-align: center;
    transition: transform 0.3s ease;
}

.img-container {
    height: 210px;
    width: 318px;
    overflow: hidden;
    border-radius: 0px 0px 20px 20px;
    display: inline-block;
}

.img-container img {
   // transform: skew(0deg, -13deg);
    height: 210px;
    margin: -35px 0px 0px -70px;
    overflow-y: hidden;
    
}

.a-box:hover {
    animation: scaleAnimation 0.3s ease forwards;
}
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
.inner-skew {
    display: inline-block;
    border-radius: 20px;
    overflow: hidden;
    padding: 0px;
 //   transform: skew(0deg, 13deg);
    font-size: 0px;
    margin: 30px 0px 0px 0px;
    background: #c8c2c2;
    height: 210px;
    width: 318px;
}

.text-container {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 120px 20px 20px 20px;
    border-radius: 20px;
    background: #fff;
    margin: -120px 0px 0px 0px;
    line-height: 19px;
    font-size: 14px;
}

.text-container h3 {
    margin: 20px 0px 10px 0px;
    color: $secondary-color;
    font-size: 16px;
    cursor: pointer;
}

button {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
    background-color: white;
    font-size: 14px;
    font-family: $main-font;
}

button:hover {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: white;

    span {
        color: white;
    }
}

.heart {
    margin-right: 5px;
}

.buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    margin-bottom: 10px;
}

.buttons-place {
    display: flex;
    width: 100%;
    justify-content: center;
}

.desc {
    height: 150px;
}

.img {
    margin-top: -20px;
    padding-top: 0;
    margin-left: -15px;
    padding-left: 0;
    position: relative;
}

.card {
    width: 340px;
    height: 350px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid #ccc;
}

.titlecard {
    color: black;
    font-weight: bold;
    text-align: left;
}

.mobile-img {
    width: 100%;
}

.pro {
    width: 100%;
}

@keyframes hoverIn {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }

  100% {
    transform: translateY(-10%);
    opacity: 1;
  }
}

@media(max-width: 950px) {
    .card {
        height: 330px;
        width: 90% !important;
    }

    .box {
        padding-right: 0;
    }

    .pro {
        width: 95%;
    }

    h3 {
        margin-right: 10px !important;
    }
}
</style>
