export default class DiplomaService {
    private base_url = 'https://gogodev.net/gogolearn/api/diploma'

    public async getAllDiplomasFromUser(token:string) {
        const uri = `${this.base_url}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async getDiploma(token:string, id:number) {
        const uri = `${this.base_url}/detail/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }
}