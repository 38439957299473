<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>
            <h1>CodePosts de {{ user.name }}</h1>
            <button class="new-question" @click="goBack">Volver Atrás</button>
            <div class="spacer" />
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar." @keyup="handleSearch"
                    class="input-job" />
            </form>
            <div class="spacer" />
            <div class="table-container">
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Título</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in filtered" :key="item.id">
                            <td> <img :src="item.image_uri" width="100" /></td>
                            <td> {{item.title}} </td>
                            <td>
                                <v-icon name="md-fileopen-round" class="ver" fill="#57afc4" scale="2" @click="readMore(item.id)"></v-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ChannelService from '@/services/ChannelService'
import ProfileService from '@/services/ProfileService';
import { onMounted, ref,Ref } from 'vue';
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import router from '@/router';
import {useRoute} from 'vue-router'
import CodeI from '@/interfaces/CodeBiteInterface';

const service = new ChannelService()
const profileService = new ProfileService()
const items:Ref<CodeI[]> = ref([])
const filtered:Ref<CodeI[]> = ref([])
const loading = ref(true)
const search = ref('')
const toast = useToast()
const route = useRoute()
const nickname = route.params.nickname
const user = ref()


onMounted(async () => {
    loading.value = true
    user.value = await profileService.GetUserByNickname(nickname)
    items.value = await service.GetLatestCodePosts(user.value.id, 100000)
    filtered.value = items.value
    loading.value = false
})
const goBack = () => {
    router.back()
}
const readMore = (id:string | undefined) => {
    router.push({name: 'codepost-view', params: {id: id}})
}


const handleSearch = () => {
    loading.value = true
    filtered.value = items.value.filter(item => item.title?.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}

</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;

    h3 {
        margin-bottom: 15px;
    }

    p {
        font-size: 12px;
    }
}

.ver {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}
.employ-card {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: $secondary-color;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.btn {
    text-decoration: none;
    margin-left: 20px;
}

.input-job {
    width: 100vw;
}

/* Estilos para la tabla */
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
th{
    color: $secondary-color!important;
}
.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}
.table-container {
  overflow-x: auto;
}
.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}
</style>