<template>
    <DefaultLayout>
        <h1>Comprobación de autencidad.</h1>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>
            <div class="spacer"></div>
            <h3>{{ response.message }}</h3>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref, onMounted,Ref } from 'vue'
import SharedService from '@/services/SharedService'
import { useRoute } from 'vue-router'
import CheckI from '@/interfaces/CheckInterface'

const response:Ref<CheckI> = ref({})
const loading = ref(true)
const router = useRoute()

const sharedService = new SharedService()

onMounted(async () => {
    loading.value = true
    response.value = await sharedService.verifyDiplomaOwner(router.params.id)
    loading.value = false
})

</script>

<style lang="scss" scoped>

</style>