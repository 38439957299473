<template>
<DefaultLayout>
    <div class="head" v-if="!profile?.profile_picture_uri">
        <div class="photo" v-if=" windowWidth>tableSmall">
            <ProfileImage />
        </div>
        <h1>{{ profile.name }} </h1>
    </div>
    <div class="head" v-else>
        <div class="photo" v-if=" windowWidth>tableSmall">
            <ProfileImage />
        </div>
        <h1>{{ profile.name }} </h1>
    </div>
    
    <div class="spacer"></div>
    <div class="space">
        <h3>Usuario</h3>
        <form class="inputProfile">
        <v-icon name="fa-user-alt"></v-icon>
        <input type="text" placeholder="Nombre completo" v-model="profile.name" />
        </form>
        <form class="inputProfile">
        <v-icon name="bi-telephone-fill"></v-icon>
        <input type="text" placeholder="Número de telefono" v-model="profile.telephone" />
        </form>
        <button class="primary-button" @click="OnUpdateName">Actualizar</button>
    </div>
    <div class="space">
        <h3>Email</h3>
        <form class="inputProfile">
        <v-icon name="md-email"></v-icon>
        <input type="email" placeholder="Email actual" v-model="current_email" />
        </form>
        <form class="inputProfile">
            <v-icon name="md-fibernew-round" style="scale: 1.1;"></v-icon>
        <input type="email" placeholder="Nuevo email" v-model="new_email" />
        </form>
        <form class="inputProfile">
        <v-icon name="md-replay"></v-icon>
        <input type="email" placeholder="Repita nuevo email" v-model="repeat_email" />
        </form>
        <button class="primary-button" @click="OnUpdateEmail">Actualizar</button>
    </div>
    <div class="space">
        <h3>Contraseña</h3>
        <form class="inputProfile">
        <v-icon name="ri-lock-password-fill"></v-icon>
        <input type="password" placeholder="Contraseña actual" v-model="old_password"/>
        </form>
        <form class="inputProfile">
        <v-icon name="md-fibernew-round" style="scale: 1.1;"></v-icon>
        <input type="password" placeholder="Nueva contraseña" v-model="new_password" />
        </form>
        <form class="inputProfile">
        <v-icon name="md-replay"></v-icon>
        <input type="password" placeholder="Repita nueva contraseña" v-model="repeat_password" />
        </form>
        <button class="primary-button" @click="OnUpdatePassword">Actualizar</button>
    </div>
    <div class="space">
        <h3>Empleo</h3>
        <label>Perfil completado al {{percentage}}%</label>
        <button class="primary-button" @click="GoToJobProfile">Ir a mi perfil de empleo</button>
    </div>
</DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ProfileService from '@/services/ProfileService'
import { onMounted, ref,Ref } from 'vue';
import { useAuth } from '@/store/useAuth';
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import router from '@/router';
import useWindowWidth from '@/composables/useWindowWidth';
import ProfileImage from '@/components/widgets/ProfileImage.vue';
import ProfileI from '@/interfaces/ProfileInterface'

const profileService = new ProfileService()
const profile:Ref<ProfileI> = ref({})
const percentage = ref(0)
const store = useAuth()
const toast = useToast()
const {windowWidth} = useWindowWidth()
const tableSmall= ref(850);

const old_password = ref('')
const new_password = ref('')
const repeat_password = ref('')

const current_email = ref('')
const new_email = ref('')
const repeat_email = ref('')

onMounted(async () => {
    if(!store.token) {
        return null;
    }

    profile.value = await profileService.GetProfileInfo(store.token)
    percentage.value = await profileService.GetJobProfilePercentageAcomplishedInfo(store.token)
})


const OnUpdateName = async () => {
    if(!store.token) {
        return null;
    }
    await profileService.UpdateName(store.token, profile.value.name, profile.value.telephone);
    toast.showToast("Perfil actualizado.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
}

const OnUpdateEmail = async () => {
    if(!store.token) {
        return null;
    }

    if(new_email.value != repeat_email.value) {
        toast.showToast("Los emails no coincididen.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
    }

    if((new_email.value == "")||(new_email.value == null)||(new_email.value == undefined)||(new_email.value == " "))
    {
        toast.showToast("Campos vacíos.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }

    const response = await profileService.UpdateEmail(store.token, current_email.value, new_email.value, repeat_email.value)

    if(response == "error") {
        toast.showToast("Error al actualizar email.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }

    store.email = new_email.value
    current_email.value = ""
    new_email.value = ""
    repeat_email.value = ""

    toast.showToast("Perfil actualizado.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    
}

const OnUpdatePassword = async () => {
    if(!store.token) {
        return null;
    }
    if(new_password.value != repeat_password.value) {
        toast.showToast("Las contraseñas no coinciden.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    if((new_password.value == "")||(new_password.value == null)||(new_password.value == undefined)||(new_password.value == " "))
    {
        toast.showToast("Campos vacíos.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    const response = await profileService.UpdatePassword(store.token, old_password.value, new_password.value, repeat_password.value)

    if(response == "error") {
        toast.showToast("Error al actualizar la contraseña.", Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        return;
    }
    toast.showToast("Perfil actualizado.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
}

const GoToJobProfile = () => {
    router.push({name: 'job-profile'})
}
</script>

<style lang="scss" scoped>
.head{
    display: flex;
    align-items: center;
}
.photo{
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
        color: $secondary-color;
        text-transform: uppercase;
        font-size: 40px;
    }
}
.inputProfile {
    width: 50%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 0;
    width: 100%;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    height: 37px;
    overflow-y: hidden;

    input {
        padding: 5px;
        outline: 0;
        margin: 5px;
        margin-left: 0;
        margin-right: 0;
        box-shadow: 0;
        border: none;
        border-bottom: 1px solid #ccc;
        background-color: white !important;
        transition: border-bottom-color 1s ease;

        &:focus {
            border-bottom: 2px solid $secondary-color; 
        }
    }
}

.space {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    margin: 10px;
    margin-left: 0;
    width: 70vw;
    display: flex;
    flex-direction: column;
}

</style>