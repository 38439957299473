<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else class="animate">
            <h1>Últimas Preguntas Sin Responder</h1>
            <p>Listado de las últimas preguntas realizadas en la plataforma que aún no han sido marcadas como resueltas por
                su autor</p>
            <div class="spacer" />
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar por título."
                    @keyup="handleSearch" class="input-job" />
            </form>
            <div class="spacer" />
            <div class="table-container">
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Asunto</th>
                            <th>Título</th>
                            <th v-if="windowWidth>tableSmall">Seleccionar</th>
                            <th v-if="windowWidth<tableSmall">Ver</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in filtered" :key="item.id">
                            <td v-if="item.lesson">{{ item.lesson.name }} ({{ item.lesson.course?.name }})</td>
                            <td v-else>{{ item.topic?.name }}</td>
                            <td> {{ item.name }} </td>
                            <td>
                                <RouterLink v-if="item.lesson"
                                    :to="{ name: 'detail-question-lessons-course', params: { course_id: item.lesson.course?.id, lesson_id: item.lesson.id, question_id: item.id } }">
                                    <v-icon name="md-fileopen-round" class="ver" fill="#57afc4" scale="2"></v-icon>
                                </RouterLink>
                                <RouterLink v-else
                                    :to="{ name: 'detail-question-general', params: { topic_id: item.topic?.id, question_id: item.id, topic_name: item.topic?.name } }">
                                    <v-icon name="md-fileopen-round" class="ver" fill="#57afc4" scale="2"></v-icon>
                                </RouterLink>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ForumService from '@/services/ForumService'
import { onMounted, ref,Ref } from 'vue';
import { useRoute } from 'vue-router';
import useWindowWidth from '@/composables/useWindowWidth';
import LessonI from '@/interfaces/LessonInterface';


const items:Ref<LessonI[]> = ref([])
const filtered:Ref<LessonI[]> = ref([])
const loading = ref(false)
const search = ref('')
const service = new ForumService()
const route = useRoute()
const {windowWidth} = useWindowWidth()
const tableSmall= ref(600);

onMounted(async () => {
    loading.value = true
    items.value = await service.LatestQuestions()
    filtered.value = items.value
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filtered.value = items.value.filter(item => item.name?.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
    text-decoration: none;
    color: $secondary-color;
}

.breadcrumbs:hover {
    color: $third-color;
}

.employ-card {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: $secondary-color;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.btn {
    text-decoration: none;
    margin-left: 20px;
}

.input-job {
    width: 100vw;
}

/* Estilos para la tabla */
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    color: $secondary-color !important;
}

.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}

.table-container {
    overflow-x: auto;
}

.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}</style>