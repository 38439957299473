<template>
    <div class="modern-table">
            <div class="tr">
                <div class="td"> <a :href="props.bannerLink" target="_blank">
                    <img :src="props.imageURL" />
                </a> </div>
            </div>
    </div>
</template>

<script  lang="ts" setup>
import { defineProps } from 'vue'
const props = defineProps(['imageURL', 'bannerLink'])
</script>

<style scoped lang="scss">
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table .th,
.modern-table .td {
    padding: 10px;
    text-align: left;
}


.modern-table .td:first-child {
    padding-left: 20px;
}

.table-container {
    overflow-x: auto;
}

.modern-table .td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table  .tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table .tr:hover  {
    color: #333;
}

.modern-table  .tr:hover a {
    transition: color 0.3s ease;
}
</style>