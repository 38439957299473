<!-- Navbar.vue -->
<template>
    <nav class="navbar">
     
      <div  class="category">
      <div class="options" v-for=" elm in category" :key="elm.id">
       <p class="btn-link"> {{ elm.name }}</p>
      </div>
    </div>
    </nav>
  </template>
  
  <script lang="ts" setup>
  import {ref,Ref,onMounted} from 'vue'
  import ShopService from '@/services/ShopService'
  import Category from '@/interfaces/ShopInterface';

  const loading= ref(false)
  const category: Ref<Array<Category>> = ref([])
  const service = new ShopService()  
  onMounted(async()=>{
    loading.value = true
    category.value = await service.ListAllCategories()
    loading.value = false
})
  </script>
  
  <style scoped lang="scss">
  .navbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    overflow: hidden;
    transition: height 0.3s;
    background-color: white;
  }
  .category{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 25px;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  button {
    background-color: white;;
    border: none;
    cursor: pointer;
    margin-left: 15px;
    min-height:80px ;
    min-width:100px;
  }

  .btn-link{
    color: $secondary-color;
    
  }
  .btn-link:hover{
    cursor: pointer;
  }
  @media (max-width: 768px) {
  .category {
    flex-direction: column;
  }
  .navbar{
    flex-direction: column;

  }
  .options{
    background-color: #eee;
    padding: 8px;
  }
  .btn-link{
    color: #aaa;
  }
}
  </style>
  