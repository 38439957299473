<template>
    <div class="conversations" v-if="store.token">
        <ConversationChat v-for="(conversation,index) in storeConversation.conversations" :key="index" :conversation="conversation"  />
    </div>
</template>

<script setup lang="ts">
import ConversationChat from './ConversationChat.vue';
import { useAuth } from '@/store/useAuth';
import { useConversations } from '@/store/useConversations';

const storeConversation = useConversations()
const store = useAuth()

</script>

<style scoped lang="scss">
.conversations {
    position: fixed;
    left: 10px;
    bottom: 0px;
    display: flex;
    width: fit-content;
    align-items: flex-end;
    z-index: 10000000000;
}
</style>