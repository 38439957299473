<template>
    <div class="box card" v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
    </div>
    <div class="animate" v-else>
        <div :class="{'card':true, 'card-1': true, 'card-2': props.alt}"  @click="handleRoute">
            <h2 class="card__title">{{ props.content.name.substring(0,40) }}</h2>
            <p class="card__apply">
                <a class="card__link" href="#">Ver clase <v-icon name="bi-arrow-right-short" scale="1.5" fill="white"></v-icon></a>
            </p>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, onMounted } from 'vue'
import router from '@/router'

const props = defineProps(['content', 'alt'])
const card = ref({
    id: 0,
    name: 'cargando...',
    description: 'cargando...',
    image_url: 'img/lms/loading.png',
})
const loading = ref(true)

onMounted(() => {
    loading.value = true
    card.value = props.content
    loading.value = false
})

const handleRoute = () => router.push({ name: 'course', params: { id: card.value.course_id, lesson_id: card.value.id } })
</script>

<style lang="scss" scoped>
.main-container {
  padding: 30px;
}

/* HEADING */

.heading {
  text-align: center;
}

.heading__title {
  font-weight: 600;
}

.heading__credits {
  margin: 10px 0px;
  color: #888888;
  font-size: 25px;
  transition: all 0.5s;
}

.heading__link {
  text-decoration: none;
}

.heading__credits .heading__link {
  color: inherit;
}

/* CARDS */

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  margin: 5px;
  padding: 20px;
  width: 340px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}

.card__link,
.card__exit,
.card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
  
  display: flex;
  align-items: center;
}

.card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.card__link:hover::after {
  width: 100%;
}

.card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.card__icon {
  grid-row: 2/3;
  font-size: 30px;
}

.card__title {
  grid-row: 3/4;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* CARD BACKGROUNDS */

.card-1 {
  background: radial-gradient(#72cfe6, #56bcd5);
}

.card-2 {
  background: radial-gradient(#C4CF2C, #b5c108);
}

.card-3 {
  background: radial-gradient(#76b2fe, #b69efe);
}

.card-4 {
  background: radial-gradient(#60efbc, #58d5c9);
}

.card-5 {
  background: radial-gradient(#f588d8, #c0a3e5);
}

/* RESPONSIVE */

@media (max-width: 950px) {
    .card{

        width: 85vw;
    }
}

@media (max-width: 1600px) {
  .cards {
    justify-content: center;
  }
}

* {
    overflow-y: hidden;
}

.hand {
    margin-left: 5px;
    transform: rotate(306deg) !important;
}

.list {
    width: 100%;
    position: relative;
    margin: 10px;
}

.list h3 {
    overflow-y: hidden;
}

.list h3 {
    margin-bottom: 10px;
}

.list h3 span {
    margin-right: 5px;
}

.list h4 {
    color: #333;
}


.follow {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -5px;
    right: 0;
}

.img {
    width: 150px;
    height: 100px;
    padding: 0;
    overflow-y: hidden;

}

.clickable {
    color: black;
}

</style>
