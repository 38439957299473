<template>
  <router-view />
  <ConversationsBar />
  <ToastMessage :message="toast.message" :type="toast.type" v-if="toast.show" />
</template>

<script lang="ts" setup>
import ToastMessage from './components/layouts/ToastMessage.vue'
import ConversationsBar from './components/widgets/ConversationsBar.vue';
import { useToast } from './store/useToast'
const toast = useToast()
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@700&display=swap');
@import url('./assets/fonts/open-sans/style.css');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $text-color;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $title-color;
  font-family: $title-font;
}


.cover-text {
  font-family: "Source Code Pro", 'Courier New', Courier, monospace !important;
}
body {
  background: $bg-color-solid;
  background: linear-gradient(61deg, $bg-color-gradient-1 0%, $bg-color-gradient-2 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: $main-font;
}
.vicp-crop {
  overflow: hidden;
}
.vicp-preview-item {
  overflow: hidden;
}
.vicp-upload {
  overflow: hidden;
}

.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    margin-top: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.new-question:hover {
    background-color: $secondary-color;
    color: white;

    svg {
        fill: white;
    }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height:  100vh;
  background-color: $secondary-color;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divider {
    background-color: $secondary-color;
    width: 100%;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.clickable {
  cursor: pointer;
}

pre.ql-syntax {
    padding: 10px;
    border-radius: 5px;
    background-color: #333 !important;
    color: white !important;
}

.description {
    pre {
        padding: 10px;
        border-radius: 5px;
        background-color: #333;
        color: white;
    }
}
.search-form {
    width: 50%;
    display: flex;
    align-items: center;
    background-color: $primary-color;
    border-radius: 5px;
    border: 0;
    width: 100%;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    height: 37px;
    overflow-y: hidden;
}

.container {
  overflow-y: hidden;
}
.clickable:hover {
  color: $third-color !important;
}

.clickable:hover * {
  color: $third-color !important; 
}
.loading-container {
  overflow-y: hidden;
}
.bg-logo {
  position: fixed;
  bottom: -100px;
  right: -100px;
  z-index: -1;
  transform: rotate(-45deg);
  opacity: 0.25;
}
.primary-button {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: $third-color;
    border: 0;
    outline: 0;
    width: 100%;
    cursor: pointer;
    color: white;
    font-family: $title-font;
}
.primary-button:hover {
    background-color: $secondary-color;
}

.box {
  animation: 0.5s ease-out 0s 1 fadeIn;
  background: $box-bg-color-solid;
  background: linear-gradient(61deg, $box-bg-color-gradient-1 0%, $box-bg-color-gradient-2 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 10px;
  padding: 15px;
  overflow-y: hidden;
}

.box * {
  overflow-y: hidden;
}

.feedback {
    color: $third-color;
    font-weight: bold;
    font-size: 12px;
}

.animate {
  animation: 0.5s ease-out 0s 1 fadeIn;
}

.animate-no-fade {
  animation: 0.5s ease-out 0s 1 noFadeIn;
}

.spacer {
  margin: 15px;
}

.long-spacer {
  margin: 50px;
}

textarea:focus, input:focus{
    outline: none;
}

form input {
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: $primary-color;
  border-radius: 5px;
  border: 0;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

form input[type=submit] {
  cursor: pointer;
  margin-top: 10px;
  background-color: $secondary-color;
}

form input[type=submit]:hover {
  background-color: $third-color;
}

.label-space {
  width: 800px;
}

@keyframes noFadeIn {
  0% {
    transform: translateY(2%);
 
  }

  100% {
    transform: translateY(0);
  
  }
}
@keyframes fadeIn {
  0% {
    transform: translateY(2%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $primary-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $third-color;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary-color;
}
</style>