<template>
    <div class="bar-container" v-if="store.token">
        <div class="box card" v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
            <div class="progress" :style="{ width: percentage }" v-if="!loading" />
            <small v-if="!loading" class="pertext">{{ percentage }} completado</small>
        </div>
    <div v-else>
        <small>Accede a tu cuenta para visualizar tu progreso.</small>
    </div>
</template>

<script lang="ts" setup>
import { useAuth } from '@/store/useAuth'
import SharedService from '@/services/SharedService'
import { onMounted, defineProps, ref, computed } from 'vue';

const store = useAuth()
const sharedService = new SharedService()
const props = defineProps(['roadmapId'])
const totalLessons = ref(0)
const lessonsCompleted = ref(0)
const loading = ref(true)
const percentage = computed(() => {
    if ((lessonsCompleted.value == 0) || (totalLessons.value == 0)) {
        return '0%'
    }
    const value = (lessonsCompleted.value * 100) / totalLessons.value
    const round = value.toFixed(0)
    return `${round}%`
})

onMounted(async () => {
    if (!store.token) {
        return
    }
    lessonsCompleted.value = await sharedService.countLessonsCompletedInRoadmap(store.token, props.roadmapId)
    totalLessons.value = await sharedService.countLessonsInRoadmap(store.token, props.roadmapId)
    loading.value = false
})

</script>

<style lang="scss" scoped>

.pertext {
    width: 100%;
    text-align: center;
    z-index: 2;
}

.bar-container {
    background-color: #ccc;
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    position: relative;

    small {
        color: white;
        font-family: $title-font;
    }
}

.progress {
    background-color: $third-color;
    height: 20px;
    position: absolute;
    z-index: 1;
}
</style>