<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div class="container" v-else>
            <AddFriend v-if="showAddFriend" @close="showAddFriend = false" />
            <DeleteFriend v-if="showDeleteFriend" @close="handleFriendDeleted" :id="friendToDelete"/>
            <div class="title">
                <h1>Mis amigos</h1>
                <RouterLink :to="{ name: 'friend-request' }">
                    <button class="friend blue" style="float: right;">
                        <v-icon name="io-person-add-sharp" class="icon" hover="true" animation="wrench" fill="white" />
                        <p style="display: flex; align-items: center;">
                            Ver peticiones de amistad pendientes
                            <span class="notification" v-if="friendshipRequests.length > 0">{{ friendshipRequests.length
                            }}</span>
                        </p>
                    </button>
                </RouterLink>
                <button class="friend" style="float: right;" @click="showAddFriend = true"><v-icon
                        name="io-person-add-sharp" class="icon" hover="true" animation="wrench" fill="white" />
                    <p>Añadir amigos</p>
                </button>
            </div>
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Filtrar búsqueda" @keyup="handleSearch" />
            </form>
            <div style="width: 70vw; height: 2px;"></div>
            <ul>
                <li style="margin: 2px;" class="liMessages" v-for="friend in filteredFriends" :key="friend.id">
                    
                    <div class="image">
                        <img v-if="friend.profile_picture_uri" :src="friend.profile_picture_uri" width="128" />
                        <h3 v-else>{{ friend.name?.substring(0, 2) }}</h3>
                    </div>

                    <p>{{ friend.name }}</p>
                    <div>
                        <button style="float: right;" class="glass red"><v-icon name="si-adblock" class="icon" hover="true"
                                animation="wrench" fill="white" v-if= "(friend.id !== undefined)" @click="handleShowDeleteFriend(parseInt(friend.id))"/></button>
                        <button style="float: right;" class="glass">
                            <RouterLink :to="{ name: 'messages', params: { id: friend.id } }">
                                <v-icon name="ri-message-2-fill" class="icon" hover="true" animation="wrench"
                                    fill="white" />
                            </RouterLink>
                        </button>

                        <button style="float: right;" class="glass">
                            <RouterLink :to="{ name: 'social-profile', params: { id: friend.id } }">
                                <v-icon name="bi-eye-fill" class="icon" hover="true" animation="wrench" fill="white" />
                            </RouterLink>
                        </button>


                    </div>

                </li>
            </ul>
        </div>
    </DefaultLayout>
</template>





<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { ref, onMounted,Ref } from 'vue'
import { useAuth } from '@/store/useAuth'
import AddFriend from '@/components/widgets/AddFriend.vue'
import FriendshipService from '@/services/FriendshipService'
import DeleteFriend from '@/components/widgets/DeleteFriend.vue'
import RequestI from '@/interfaces/RequestInterface'

const loading = ref(true)
const store = useAuth()
const showAddFriend = ref(false)
const showDeleteFriend = ref(false)
const friends:Ref<RequestI[]> = ref([])
const filteredFriends:Ref<RequestI[]>  = ref([])
const service = new FriendshipService()
const search = ref('')
const friendshipRequests = ref([])
const friendToDelete = ref(0)

onMounted(async () => {
    if (!store.token) {
        loading.value = false
        return
    }
    loading.value = true
    friends.value = await service.listUserFriends(store.token)
    filteredFriends.value = friends.value
    friendshipRequests.value = await service.listPendingRequests(store.token)
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filteredFriends.value = friends.value.filter(item => item.name?.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}

const handleShowDeleteFriend = (id:number) => {
    friendToDelete.value = id
    showDeleteFriend.value = true
}

const handleFriendDeleted = async () => {
    if (!store.token) {
        loading.value = false
        return
    }
    loading.value = true
    showDeleteFriend.value = false
    friends.value = await service.listUserFriends(store.token)
    filteredFriends.value = friends.value
    search.value = ""
    loading.value = false
   
}

</script>






<style lang="scss" scoped>
.notification {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightcoral;
    margin-left: 5px;
    font-weight: bold;
}

.blue {
    background-color: $secondary-color !important;
}

.red {
    background-color: lightcoral;
}

.image {
    border: 3px solid $secondary-color;
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
        color: $secondary-color;
    }
}

button {
    padding: 6px;
    border: 0;
    outline: 0;
    background-color: $secondary-color;
    color: white;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
}

ul {
    margin-top: 20px;
}

.liMessages {
    width: 100%;
    background-color: $primary-color;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.liMessages:hover {
    background-color: $secondary-color;

    p {
        color: white;
    }

    .glass {
        border: 2px solid white;
        padding: 8px;
    }
}

.userMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.friend {
    height: 45px;
    padding: 10px;
    border: 0;
    outline: 0;
    background-color: $third-color;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    p {
        color: white;
        margin-left: 5px;

    }
}

.friend:hover {
    transform: scale(0.9);
}
</style>