import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useMenu = defineStore('useMenu', {
  state: () => {
    return {
     isOpen: ref(false)
    }
  },
  actions: {
    toggleMenu() {
      this.isOpen = !this.isOpen
    }
  }
})