<template>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else class="recovery-container">
            <img :src="logo"  class="logo" width="512">
           
            <div class="spacer" />
            <div>
                <div class="animate">
                    <form >
                        <h4>Recuperar contraseña</h4>
                       
                        <div class="form-group" v-if="showForm">
                            <label class="label-space">Introduce tu email. Te enviaremos un enlace de recuperación.</label>
                            <input type="text" placeholder="ejemplo@ademass.com" required v-model="email" class="test-email" />
                        </div>
                        <input  v-if="showForm" type="submit" value="Recuperar" class="go-btn" @click.prevent="handleRecovery" />
                        <p class="feedback">{{ feedback }}</p>
                        <div class="spacer" v-if="feedback" />
                        <footer>
                <router-link :to="{name: 'auth'}" class="animate no-underscore"><small class="animate">Ir a login.</small></router-link>
                <router-link :to="{name: 'home'}" class="animate no-underscore"><small class="animate">Volver a inicio.</small></router-link>
                 
                <router-link :to="{name: 'recovery'}" class="animate no-underscore"><small class="animate">Olvidé mi contraseña.</small></router-link>
            </footer>
                    </form>
                </div>
            </div>
        </div>
</template>

<script  lang="ts" setup>
import logo from '@/assets/svg/ademass_slogan.svg'
import { ref } from 'vue'
import RecoveryService from '@/services/RecoveryService'



const loading = ref(false)
const feedback = ref('')
const email = ref('')
const service = new RecoveryService()
const showForm =ref(true)

 const handleRecovery = async ()=>{
      loading.value= true
      await service.requestRecovery(email.value)
      feedback.value = "Petición recibida. Se ha enviado un email de recuperación."
      showForm.value =false
      loading.value = false
 }
</script>

<style lang="scss" scoped>
h4{
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
}
.auth-box {
    width: 600px;
    min-height: 280px;
    position: relative;
}
.go-btn {
    color: white;
    font-family: $title-font;
}
.recovery-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100svh;

}
.no-underscore {
    margin-left: 10px;
    text-decoration: none;
}

.no-underscore:hover {
    color: $title-color;
}

</style>