<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div class="container" v-else>
            <h1>{{ evaluation.name }}</h1>
            <div v-html="evaluation.description" class="desc"></div>
            <div class="spacer"></div>
            <div v-if="store.token" class="desc">
                <h3>Importante</h3>
                <p>En esta evaluación se te examinará del temario del curso: <span class="remark">{{
                    course.name
                }}</span>. Si aún no lo has realizado, te recomendamos darle un repaso antes de continuar.</p>
                <p>Esta evaluación es de tipo test, y contiene un total de <span class="remark">{{
                    evaluation.number_of_questions
                }}</span> preguntas que se generarán en el momento de comenzar la
                    prueba.
                    Deberás responder correctamente un mínimo de <span class="remark">{{
                        evaluation.questions_to_pass
                    }}</span> preguntas para aprobar.</p>
                <p>Dispones de <span class="remark">{{ evaluation.max_tries }}</span> intentos para obtener la
                    certificación. Actualmente has consumido <span class="remark">{{ tries }}</span> intentos.</p>
                <p>El tiempo estimado de realización es de 40 minutos. Asegúrate de disponer de espacio suficiente antes
                    de comenzar la prueba.</p>
                <div class="spacer"></div>
                <div v-if="!noMoreTries">
                    <p class="danger">Una vez pulses sobre el botón de comenzar se te contará como un intento, aunque no
                        entregues o finalices la prueba. No recargues ni abandones la página durante el examen, la cuenta será penalizada.</p>
                    <div class="spacer"></div>
                    <RouterLink class="button" :to="{ name: 'evaluation', params: { id: id } }">Ok, entendido. QUIERO COMENZAR
                        MI EXAMEN DE CERTIFICACIÓN.</RouterLink>
                </div>
                <div v-else>
                    <p class="danger">Has consumido todos los intentos para esta evaluación. Si quieres volver a intentarlo, escríbenos un correo a soporte para que podamos evaluar tu caso. Puedes encontrar la dirección en el footer de la página.</p>
                </div>
            </div>
            <div v-else>
                <p>Debes registrarte o acceder con tu cuenta para realizar el examen de certificación.</p>
            </div>

        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import EvaluationService from '@/services/EvaluationService'
import CourseService from '@/services/CourseService'
import { useAuth } from '@/store/useAuth'
const router = useRoute()
const id = router.params.id
const evaluationService = new EvaluationService()
const courseService = new CourseService()
const loading = ref(true)
const evaluation = ref()
const tries = ref()
const course = ref()
const store = useAuth()
const noMoreTries = ref(false)

onMounted(async () => {
    loading.value = true
    evaluation.value = await evaluationService.getEvaluationInfo(id)
    course.value = await courseService.GetCourse(evaluation.value.course_id)
    if (store.token) {
        tries.value = await evaluationService.getUserTries(store.token, id)
        if (evaluation.value.max_tries <= tries.value) {
            noMoreTries.value = true
        }
    }
    loading.value = false
})
</script>

<style lang="scss" scoped>
.desc {
    margin-left: 5px;
}
.remark {
    color: $third-color;
    font-weight: bold;
}

.danger {
    color: lightcoral;
}

.container {
    min-width: 70vw;
}

.button {
    background-color: $third-color;
    color: white;
    padding: 15px;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.button:hover {
    background-color: $secondary-color;
}
</style>