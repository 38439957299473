<template>
  <DefaultLayout>
    <ProdevValidator />
    <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
    <div class="container">
      <div class="video-container">
        <div class="video-wrapper" v-if="selectedObject?.src" >
          <iframe :src="selectedObject?.src" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Bootcamp Spring - Sesión 0"></iframe>
        </div>
        <div class="not_frame" v-else>
             <h2>Consulta la fecha de inicio<br> en nuestra sección de Bootcamps</h2>
        </div>
      </div>
      <br>
      <div class="session-cards">
        <div class="session-card-2">
   <a href="/bootcamp"><h2 class="btn-back">Volver Atrás</h2></a> 
  </div>
  <div class="session-card" v-for="elm in bootcamp_detail" :key="elm?.id" @click="toggleDescription(elm?.order)">
    <h2>{{ 'Sesión ' + elm?.order}}</h2>
  </div>
</div>

<div class="descriptions" v-if="description">
  <br>
  <h2>{{ 'Sesión ' + selectedSessionIndex }}</h2>
  <br>
  <p v-if="selectedSessionIndex != undefined">{{ Object.values(bootcamp_detail)[selectedSessionIndex]?.description }}</p>
  <br>
  <button @click="closeDescription" class="close">Cerrar</button>
</div>
    </div>
  </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ProdevValidator from '@/components/auth/ProdevValidator.vue';
import BootCampService from '@/services/BootCampService';
import { useRoute } from 'vue-router';
//import BootCampsDetailsI from '@/interfaces/BootCampDetailsInterface';
import { Ref,ref,onMounted } from 'vue';
import SelectedI from '@/interfaces/SelectedInterface';

const loading:Ref<boolean> =ref(false)
const route = useRoute()
const service = new BootCampService()
const bootcamp_detail:Ref<SelectedI[]> = ref([])
const selectedObject:Ref<SelectedI> = ref({})
const selectedSessionIndex:Ref<number | undefined> = ref(0)

onMounted(async()=>{
  loading.value = true;
  bootcamp_detail.value = await service.GetBootCampDetail(route?.params?.id)
  selectedSessionIndex.value = 0;
  selectedObject.value = bootcamp_detail.value[selectedSessionIndex.value];
  loading.value = false;

})

const description = ref(false);

const toggleDescription = (index: number | undefined) => {
  if (selectedSessionIndex.value !== index) {
    description.value = true;
    selectedSessionIndex.value = index;
    if(selectedSessionIndex.value != undefined) {
    selectedObject.value = bootcamp_detail.value[selectedSessionIndex.value];
    }
  } else {
    description.value = !description.value;
  }
};

const closeDescription = () => {
  description.value = false;
};

</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}
.not_frame {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $secondary-color;
  height: 300px;
  min-width: 290px;
  background-color: #f5f5f5; 
  
}
h2{
  color: $secondary-color;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333; /* Color de texto más oscuro */
}
.descriptions{
  width: 100%;
  padding: 25px;
  min-height: 200px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}
.video-container {
  width: 100%;
  margin-bottom: 0;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.session-cards {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  width: 100%;
  margin-top: 0;
}

.session-card {
  background-color: $secondary-color;
  border-radius: 12px;
  color: white;
  padding: 20px;
  text-align: center;
  max-height: 80px;
}
.session-card-2 {
  background-color: $third-color;
  border-radius: 12px;
  color: white;
  padding: 20px;
  text-align: center;
  max-height: 80px;

}
.session-card-2,a{
  text-decoration: none;
}
.btn-back{
  color: white;
}

.session-card-2:hover{
  scale: 1.001;
  cursor: pointer;
}
.close {
  background-color: red;
  border-radius: 12px;
  color: white;
  padding: 10px;
  text-align: center;
  max-height: 50px;
  border: none;
  font-size: 18px;
  width: 120px;
}
.close:hover{
  cursor: pointer;
  background-color: rgb(224, 157, 157);
}

.session-card h2 {
  color: white;
  margin-top: 0;
}

.session-card:hover {
  transform: scale(1.002);
  cursor: pointer;
  background-color: $third-color;
}
@media screen and (max-width:600px){
  .session-card-2{
    width: 100%;
  }
  .session-card{
    width: 100%;
  }
  .session-cards{
    min-height: 350px;
    min-width: 100%;
  }
}
</style>
