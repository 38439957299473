export default class CourseService {
    private base_url = 'https://gogodev.net/gogolearn/api/course'

    public async GetAllCourses() {
        const uri = this.base_url
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetCourseFromRoadmap(roadmap_id:string | string[]) {
        const uri = `${this.base_url}/${roadmap_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }

    public async GetCourse(course_id:string|string[]) {
        const uri = `${this.base_url}/detail/${course_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }

    public async SearchCoursesByName(search:string | string []) {
        const uri = `${this.base_url}/search/${search}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }

    public async CountCoursesInRoadmap(roadmap_id:string) {
        const uri = `${this.base_url}/count/${roadmap_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }
}