<template>
    <DefaultLayout>
        <div class="container">
            <div class="header">
                <h1>Detalles del Libro</h1>    
            </div>
            <div class="Tbody">
              <div class="img-book">
                <img src="../../public/img/lms/backend.png" alt="imgh prueba" :width="`${widthIMG}`" :height="`${heightIMG}`" style="border-radius: 8px;">
              </div>
              <div class="details">
                 <div>
                     <h4>Título</h4>
                     <p>{{ book.title }}</p>
                 </div>
                 <div>
                     <h4>Autor</h4>
                     <p>{{ book.author }}</p>
                 </div>
                 <div>
                     <h4>Categoría</h4>
                     <p>{{ book.category }}</p>
                 </div>
                 <div>
                     <h4>Editorial</h4>
                     <p>{{ book.publisher }}</p>
                 </div>
                 <div>
                     <h4>Año</h4>
                     <p>{{ book.updated_at?.slice(0,4) }}</p>
                 </div>
                 <div>
                     <h4>Descripción</h4>
                     <p>{{ book.description }}</p>
                 </div>
                 <div>
                    <div class="buttons">
                 <div v-if="book.epub_uri != undefined && book.epub_uri.length > 3 ">
                    <a :href="book.epub_uri" download >
                        <button class="btn-download">Descargar versión EPUB</button>
                    </a>
                 </div>
                 <div class="spacer-btn"></div>
                 <div v-if="book.pdf_uri != undefined && book.pdf_uri.length > 3 ">
                    <a :href="book.pdf_uri" download >
                        <button class="btn-download">Descargar versión PDF</button>
                    </a>
                 </div>
                 <div class="spacer-btn"></div>
                 <div v-if="book.paper_purchase_uri != undefined && book.paper_purchase_uri.length > 3 ">
                    <a :href="book.paper_purchase_uri" target="_blank">
                        <button class="btn-download" >Comprar en formato físico</button>
                    </a>
                 </div>  
                </div>
                 <p v-if="book.paper_purchase_uri != undefined && book.paper_purchase_uri.length > 3 ">Al comprar el formato físico a través de este botón, estás contribuyendo al mantenimiento de esta comunidad, y a que siga siendo gratis para todos, para siempre. ¡Muchas gracias!</p> 
                </div>           
              </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script  lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BookInterface from '@/interfaces/BookInterface';
import { Ref,ref,onMounted } from 'vue';
import BookService from '@/services/BookService';
import { useRoute } from 'vue-router';
const loading = ref(false)
const route = useRoute()

const widthIMG:Ref<number> = ref(600)
const heightIMG:Ref<number> = ref(400)

const book:Ref<BookInterface> = ref({
    id: 0,
    title: '',
    author:'',
    publisher:'',
    category:'',
    pdf_uri: '',
    epub_uri:'',
    paper_purchase_uri:'',
    order:0,
    description: '',
    image_uri: '',
    created_at:'',
    updated_at:''  
})
    const service = new BookService()


    onMounted(async()=>{
    loading.value = true
    book.value = await service.GetBookDetail(route.params.id)
    loading.value = false
})

</script>

<style scoped lang="scss">
.spacer-btn{
    width: 2.5px;
}
.container {
    padding: 20px;
}
h5{
    margin-bottom: 5px;
    color:$secondary-color;
}
.header {
    display: flex;
    align-items: center;
    justify-content: center;

}

.Tbody {  
    margin: 5px;
    display: flex;
    flex-direction: row;
}
.details{
    margin-left: 60px;
}
div{
    margin-bottom: 15px;
}
.btn-download{
    color: white;
    background-color: $secondary-color;
    padding: 8px;
    border: none;
    border-radius: 6px;
}
.buttons{
    display: flex;
    flex-direction: row;
}
@media only screen and (max-width: 750px) {
  /* Agrega aquí tus estilos para dispositivos móviles */
  .Tbody {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .details{
    margin-left: 2.5px;
    width: 95%;
}
.buttons{
    display: flex;
    flex-direction: column;
}
.btn-download{
    width: 100%;
}
:root{
    --widthIMG: 250px;
    --heightIMG: 200px;
}
 
}
</style>