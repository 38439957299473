<!-- Tienda.vue -->
<template>
    <DefaultLayout>
      <div class="container">
       
        <h1>Tienda</h1>
        <div>Recuerda que comprando a través de los enlaces de Ademass estas contribuyendo al mantenimiento de la plataforma.</div>
        <Navbar />
        <div class="tbody">
          <div class="card" v-for="elm in features" :key="elm.id">
                    <img :src="elm.image_uri" alt="imgh prueba" width="200" height="210">
                    <h5>{{ elm.title }}</h5>
                    <p>{{ elm.price }} € </p>
                    <a :href="`/articulo/${elm.id}`" class="btn-details">                
                    Ver Detalles
                    </a>
                </div>
                <div class="card" v-for="elm in features" :key="elm.id">
                    <img  :src="elm.image_uri" alt="imgh prueba" width="200" height="210">
                    <h5>{{ elm.title }}</h5>
                    <p>{{ elm.price }} € </p>
                    <a href="/articulo" class="btn-details">                
                    Ver Detalles
                    </a>
                </div>
                <div class="card" v-for="elm in features" :key="elm.id">
                    <img :src="elm.image_uri"  alt="imgh prueba" width="200" height="210">
                    <h5>{{ elm.title }}</h5>
                    <p>{{ elm.price }} € </p>
                    <a href="/articulo" class="btn-details">                
                    Ver Detalles
                    </a>
                </div>
        </div>
        
      </div>
    </DefaultLayout>
  </template>
  
  <script lang="ts" setup>
  import DefaultLayout from '@/layouts/DefaultLayout.vue';
  import Navbar from '@/components/layouts/NavBar.vue';
  import { Ref,ref,onMounted } from 'vue';
  import ShopService from '@/services/ShopService'
  import ShopInterface from '@/interfaces/ShopInterface';

  

  const loading: Ref<boolean> = ref(false)
  const service = new ShopService()
  const category = ref([])
  const features: Ref<Array<ShopInterface>> = ref([])

  onMounted(async()=>{
    loading.value = true
    category.value = await service.ListAllCategories()
    features.value = await service.ListAllFeaturedItems()
    loading.value = false
})

  </script>
  
  <style scoped lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .tbody { 
    
    margin: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
}
  .card{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 320px;
    width: 200px;
    margin: 15px;

    
}
.btn-details{
    margin-top: 5px;
    padding: 8px;
    color: white;
    background-color:$secondary-color;
    font-size: 13px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    margin-bottom: 5px;
}
.btn-details::hover {
  background-color: $third-color;
  cursor: pointer;
}
  </style>
  