<template>
    <DefaultLayout>
        <div class="container">
            <h1>Hojas de ruta</h1>
                <div class="spacer" />
                <div class="config">
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Filtrar búsqueda" @keyup="handleSearch" />
            </form>
            <!--<div class="aux-btns">
                <button @click="handleComponent(CardLayout)" class="aux-btn"><v-icon name="bi-card-heading" title="Ver en tarjetas" /></button>
                <button @click="handleComponent(ListLayout)" class="aux-btn"><v-icon name="bi-card-list" title="Ver en lista" /></button>
                <button @click="handleComponent(TableLayout)" class="aux-btn last"><v-icon name="fa-table" title="Ver en tabla" /></button>
            </div> -->
        </div>
            <div class="spacer" />
            <div v-if="loading">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon"/>
            </div>
            <component :is="layout" :content="filteredRoadmaps" v-else>
            </component>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import RoadmapService from '@/services/RoadmapService'
import RoadmapInterface from '@/interfaces/RoadmapInterface'

import { defineAsyncComponent, onMounted, ref, Ref } from 'vue'

const loading:Ref<boolean> = ref(true)
const search:Ref<string> = ref('')
const roadmaps:Ref<Array<RoadmapInterface>> = ref([])
const filteredRoadmaps:Ref<Array<RoadmapInterface>> = ref([])

const CardLayout = defineAsyncComponent(() => import('@/layouts/RoadmapCardLayout.vue'))
const TableLayout = defineAsyncComponent(() => import('@/layouts/RoadmapTableLayout.vue'))
const ListLayout = defineAsyncComponent(() => import('@/layouts/RoadmapListLayout.vue'))

const layout = ref(CardLayout)

const handleComponent = (cmp: any) => layout.value = cmp

const roadmapService = new RoadmapService()

onMounted(async () => {
    roadmaps.value = await roadmapService.GetAllRoadmaps()
    filteredRoadmaps.value = roadmaps.value
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filteredRoadmaps.value = roadmaps.value.filter(item => item.title?.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}
</script>

<style lang="scss" scoped>
.container {
    min-width: 70vw;
}
.config {
    display: flex;
    align-items: center;
}
.icon {
    margin: 25px;
}
.search-form {
    display: flex;
    align-items: center;
    background-color: $primary-color;
    border-radius: 5px;
    border: 0;
    width: 100%;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    height: 37px;
    overflow-y: hidden;
}
.aux-btns {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.aux-btn {
    height: 37px;
    background-color: $third-color;
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
}
.last {
    border-radius: 0 5px 5px 0;
}

.divider {
    background-color: $secondary-color;
    width: 100%;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media(max-width: 950px) {
    .search-form {
        width: 100%;
    }
}
</style>