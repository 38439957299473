<template>
   <div class="box card" v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
    </div>
    <div :class="{animate:true, card:true, isDone:done}" v-else>
        <div class="list">
            <h4 class="clickable" v-if="!done" @click="handleRoute">{{ props.content.name.substring(0,40) }}</h4>
            <h4 v-else>
                {{ props.content.name.substring(0,40) }}
                <v-icon name="bi-check-square-fill" fill="#fff" />
            </h4>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, onMounted } from 'vue'
import router from '@/router'
import DiplomaService from '@/services/DiplomaService'
import { useAuth } from '@/store/useAuth'

const store = useAuth()
const diplomaService = new DiplomaService()
const props = defineProps(['content'])
const card = ref({
    id:0,
    name:'cargando...',
    description: 'cargando...',
    image_url: 'cargando...',
    number_of_questions: 0,
    questions_to_pass: 0,
    course_id: 0,
    max_tries: 0,
    created_at: 'cargando...',
    updated_at: 'cargando...'
})
const loading = ref(false)
const done = ref(false)

onMounted(async () => {
    loading.value = true
    card.value = props.content
    if(store.token) {
        const diplomas = await diplomaService.getAllDiplomasFromUser(store.token)
        diplomas.forEach(element => {
            if(element.course_id == card.value.course_id) {
                done.value = true
            }
        })
    }
    loading.value = false
})

const handleRoute = () => router.push({name: 'certification', params: {id: card.value.id }})
</script>

<style lang="scss" scoped>
* {
    overflow-y: hidden;
}
.hand {
    margin-left: 5px;
    transform: rotate(306deg) !important;
}
.list {
    width: 100%;
    position: relative;
    margin: 10px;
    color: white !important;
}

.list h4 {
    color: white !important;
}

.list h3 {
    overflow-y: hidden;
}

.list h3 {
    margin-bottom: 10px;
}

.list h3 span {
    margin-right: 5px;
}

.isDone {
    background-color: $third-color !important;
}

.card {
    width: 340px;
    height: 55px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: $secondary-color;
    position: relative;
}

.follow {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -5px;
    right: 0;
}

.img {
    width: 150px;
    height: 100px;
    padding: 0;
    overflow-y: hidden;

}
</style>
