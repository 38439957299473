<template>
    <div class="layout-container">
        <TopBar /> 
        <div class="main-container">     
            <MenuBar />
            <main :class="{animate:true, main:windowWidth>950, mainmobile:windowWidth<=950}">
                <slot></slot>
            </main>
        </div>
        <FooterBar />
    </div>
</template>

<script lang="ts" setup>
import MenuBar from '@/components/layouts/MenuBar.vue'
import TopBar from '@/components/layouts/TopBar.vue'
import FooterBar from '@/components/layouts/FooterBar.vue'
import useWindowWidth from '@/composables/useWindowWidth'

const {windowWidth} = useWindowWidth()
</script>

<style lang="scss" scoped>
.layout-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main-container {
    margin-top: 70px;
    width: 100%;
    min-height: 850px;
    display: flex;
}

.main {
    margin: 20px;
    margin-left: 100px;
    width: 100%;
}

.mainmobile {
    margin: 20px;
}

</style>