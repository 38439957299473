import JobInterface from "@/interfaces/JobInterface"

export default class PreviousJobService {
    private base_url = 'https://gogodev.net/gogolearn/api/previous_job'

    public async GetAllJobsFromUser(token: string) {
        const uri = `${this.base_url}/job/all`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetJobFromUser(token: string, id: number) {
        const uri = `${this.base_url}/job/detail/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async DeleteJobFromUser(token: string, id: number) {
        const uri = `${this.base_url}/job/delete/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CreateJobToUser(token: string, job: JobInterface) {
        const uri = `${this.base_url}/job/create`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                years: job.years,
                name: job.name,
                company: job.company,
                description: job.description,
                job_category_id: job.job_category_id
            })
        })
        const response = await rawResponse.json()
        return response
    }

    public async UpdateJobToUser(token: string, job: JobInterface, id: number) {
        const uri = `${this.base_url}/job/edit/${id}`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                years: job.years,
                name: job.name,
                company: job.company,
                description: job.description,
                job_category_id: job.job_category_id
            })
        })
        const response = await rawResponse.json()
        return response
    }
}