<template>
    <div class="animate codebite">
        <div v-if="showDelete" class="animate modal">
                <div class="modal-inner">
                    <div style="float: right; cursor: pointer;" @click="showDelete = false"><v-icon
                            name="ri-close-circle-fill" fill="lightcoral"></v-icon></div>
                    <h3>¿Estás seguro de que deseas borrar este CodeBite?</h3>
                    <p>Esta acción no se puede deshacer</p>
                    <div style="display: flex; align-items: center;">
                    <button class="new-question" @click="handleDelete" style="background-color: lightcoral; color: white;">Borrar CodeBite</button>
                    <button class="new-question" @click="showDelete = false">Cancelar</button>
                </div>
                </div>
            </div>
        <div class="codebite-title">
            <h3 v-if="props.codebite.title.length < 60">{{props.codebite.title}} <span v-if="isOwner" class="delete-icon" @click="showDelete = true">  <v-icon name="md-delete" class="ver" fill="lightcoral" scale="1"></v-icon></span></h3>
            <h3 v-else>{{props.codebite.title.substring(0,60)}}... <span v-if="isOwner" class="delete-icon" @click="showDelete = true">  <v-icon name="md-delete" class="ver" fill="lightcoral" scale="1"></v-icon></span></h3>
        </div>
        <div class="codebite-content" v-html="props.codebite.content">
        </div>
        <div class="social-container">
            <hr />
            <div class="social">
                <div class="profile" v-if="props.user">
                    <img v-if="props.user.profile_picture_uri" :src="props.user.profile_picture_uri" />
                    <span v-else>{{ props.user.name.substring(0, 2) }}</span>
                </div>
                <div class="profile" v-else>
                    <span>...</span>
                </div>
                <RouterLink :to="{name:'social-profile', params: {id: props.user.id}}" style="text-decoration: none;">
                <h5 class="user-name" v-if="props.user.nickname">@{{props.user.nickname}}</h5>
                <h5 class="user-name" v-else>@{{props.user.name.substring(0,3)}}{{ props.user.id }}</h5>
            </RouterLink>
                <h5 style="font-size: 18px; margin-right: 5px;">{{likes}}</h5>
                <v-icon v-if="!liked" class="code-bite-icon" name="bi-hand-thumbs-up-fill" @click="handleDoLike" />
                <v-icon v-else class="code-bite-icon-marked" name="bi-hand-thumbs-up-fill" @click="handleUndoLike" />
                <v-icon v-if="!isSaved" class="code-bite-icon" name="md-save-sharp" @click="handleStoreCodeBite"/>
                <v-icon v-else class="code-bite-icon-marked" name="md-save-sharp" @click="handleUndoStoreCodeBite" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, onMounted, defineEmits } from 'vue'
import ChannelService from '@/services/ChannelService'
import ProfileService from '@/services/ProfileService'
import { useAuth } from '@/store/useAuth'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';

const toast = useToast()
const props = defineProps(['codebite', 'user'])
const emit = defineEmits(['refresh'])
const showDelete = ref(false)
const service = new ChannelService()
const loading = ref(false)
const isSaved = ref(false)
const store = useAuth()
const likes = ref(0)
const liked = ref(false)
const isOwner = ref(false)
const profileService = new ProfileService()

onMounted(async () => {
    if(!store.token) { return }
    loading.value = true
    isSaved.value = await service.CheckIfCodeByteIsStored(store.token, props.codebite.id)
    likes.value = await service.CountLikes(props.codebite.id)
    liked.value = await service.CheckIfIsLiked(store.token, props.codebite.id)
    const user = await profileService.MyUser(store.token)
    if(user.id == props.user.id) { isOwner.value = true}
    loading.value = false
})

const handleDelete = async () => {
    if(!store.token) {
        toast.showToast("Debes acceder con tu cuenta o registrarte para realizar esta acción", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    const response = await service.DeleteCodeBite(store.token, props.codebite.id)
    if(response.success) {
        toast.showToast("CodeBite Borrado", Toast.SUCCESS)
        setTimeout(function () { toast.hideToast() }, 4000)
        emit('refresh')
        showDelete.value = false
        return
    } else {
        toast.showToast(response.message, Toast.DANGER)
        setTimeout(function () { toast.hideToast() }, 4000);
        showDelete.value = false
    }
}

const handleStoreCodeBite = async () => {
    if(!store.token) {
        toast.showToast("Debes acceder con tu cuenta o registrarte para realizar esta acción", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    await service.MarkCodeBiteAsSaved(store.token, props.codebite.id)
    toast.showToast("CodeBite guardado. Puedes ver tus CodeBytes guardados en la sección del menú CodeBites guardados (Mi Sección)", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 8000);
    isSaved.value = true
}

const handleUndoStoreCodeBite = async () => {
    if(!store.token) {
        toast.showToast("Debes acceder con tu cuenta o registrarte para realizar esta acción", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    await service.UndoCodeBiteAsSaved(store.token, props.codebite.id)
    toast.showToast("Eliminado de tus CodeBites guardados", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    isSaved.value = false
}

const handleDoLike = async () => {
    if(!store.token) {
        toast.showToast("Debes acceder con tu cuenta o registrarte para realizar esta acción", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    await service.DoLike(store.token, props.codebite.id)
    toast.showToast("Te gusta este CodeBite", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    likes.value++
    liked.value = true;
}

const handleUndoLike = async () => {
    if(!store.token) {
        toast.showToast("Debes acceder con tu cuenta o registrarte para realizar esta acción", Toast.WARNING)
        setTimeout(function () { toast.hideToast() }, 4000);
        return
    }
    await service.UndoLike(store.token, props.codebite.id)
    toast.showToast("Ya no te gusta este CodeBite", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    likes.value--
    liked.value = false;
}
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;

    h3 {
        margin-bottom: 15px;
    }

    p {
        font-size: 12px;
    }
}
.delete-icon {
    float: right;
    cursor: pointer;
}
.codebite-content {
    margin-top: 5px;
    height: 300px;
}
.code-bite-icon {
    fill: #aaa;
    cursor: pointer;
    scale: 1.2;
    margin-right: 10px;
}

.code-bite-icon-marked {
    fill: $secondary-color;
    cursor: pointer;
    scale: 1.2;
    margin-right: 10px;
}
.code-bite-icon:hover {
    fill: $third-color;
}

.profile {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 5px;

    span {
        color: $secondary-color;
        font-weight: bold;
        font-size: 18px;
    }
}

.codebite {
    position: relative;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    padding: 15px;
    border-radius: 10px;
    margin: 5px;
    width: 600px;
    height: 400px;

    p {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .codebite-title {
        margin-top: -15px;
        margin-left: -15px;
        margin-right: -15px;
        padding: 10px;
        background-color: #333;

        h3 {
            color: white;
            font-size: 14px;
            text-align: center;
        }
    }
}

.social-container {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 90%;
    border-top: 1px solid #ccc;
}

.social {
    margin-top: 5px;
    display: flex;
    align-items: center;
    width: 100%;

    .user-name {
        color: #999;
        margin-left: 5px;
        margin-right: 15px;
    }

    h5 {
        color: $secondary-color;
    }
}

@media(max-width: 950px) {
.codebite {
    width: 100%;
}
}
</style>