<template>
    <div v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2" />
    </div>
    <div class="animate profile-data" v-else-if="!store.token" @click="router.push({ name: 'auth' })">
        <h5>Accede para seguir este canal</h5>
    </div>
    <div v-else>
        <div class="animate profile-data" v-if="!isFollowed" @click="DoFollow">
            <h5>Seguir</h5>
        </div>
        <div class="animate profile-data-green" v-else @click="DoUnfollow">
            <h5>Siguiendo</h5>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, onMounted } from 'vue'
import ChannelService from '@/services/ChannelService'
import { useAuth } from '@/store/useAuth'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import router from '@/router';

const service = new ChannelService()
const loading = ref(true)
const isFollowed = ref(false)
const store = useAuth()
const props = defineProps(['user_id'])
const toast = useToast()

onMounted(async () => {
    loading.value = true
    if (!store.token) {
        loading.value = false
        return
    }
    isFollowed.value = await service.CheckIfFollows(store.token, props.user_id)
    loading.value = false
})

const DoFollow = async () => {
    loading.value = true
    if (!store.token) {
        loading.value = false
        return
    }
    await service.DoFollow(store.token, props.user_id)
    toast.showToast("Has comenzado a seguir este canal. El número de seguidores se actualizará en breve.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    isFollowed.value = true
    loading.value = false
}

const DoUnfollow = async () => {
    loading.value = true
    if (!store.token) {
        loading.value = false
        return
    }
    await service.DoUnfollow(store.token, props.user_id)
    toast.showToast("Has dejado de seguir este canal. El número de seguidores se actualizará en breve.", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 4000);
    isFollowed.value = false
    loading.value = false
}
</script>

<style lang="scss" scoped>
.profile-data {
    padding: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $secondary-color;
    width: 200px;
    margin: 5px;
    cursor: pointer;

    svg {
        fill: white;
    }

    h5 {
        color: white;
    }
}

.profile-data:hover {
    background-color: #333;
}
.profile-data-green:hover {
    background-color: #333;
}
.profile-data-green {
    padding: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $third-color;
    width: 200px;
    margin: 5px;
    cursor: pointer;

    svg {
        fill: white;
    }

    h5 {
        color: white;
    }
}
@media only screen and (max-width: 600px) {
    .profile-data-green {
        width: 75px;
        font-size: 12px;
        text-align: center;
    }
    .profile-data{
        width: 75px;
        font-size: 12px;
        text-align: center;
    }
}
</style>