<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div class="container" v-else>
            <h1>Búsqueda de cursos que contienen: {{ searchOrigin }}</h1>
            <div class="spacer" />
            <form class="search-form">
                <v-icon name="fa-filter"></v-icon>
                <input type="text" v-model="search" placeholder="Escribe aquí para filtrar cursos."
                    @keyup="handleSearch" />
                    <div class="aux-btns">
                <button @click.prevent="handleComponent(CourseCardLayout)" class="aux-btn"><v-icon name="bi-card-heading"
                        title="Ver en tarjetas" /></button>
                <button @click.prevent="handleComponent(CourseListLayout)" class="aux-btn"><v-icon name="bi-card-list"
                        title="Ver en lista" /></button>
                <button @click.prevent="handleComponent(CourseTableLayout)" class="aux-btn last"><v-icon name="fa-table"
                        title="Ver en tabla" /></button>
            </div>
            </form>
            <div class="divider"></div>
            <div class="spacer" />
            <div v-if="loading">
                <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
            </div>
            <component :is="layout" :content="filteredCourses" showOrder="false" v-else>
            </component>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CourseService from '@/services/CourseService'
import { onMounted, ref, Ref, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import CourseI from '@/interfaces/CourseInterface'

const route = useRoute()
const searchOrigin = route.params.search

const courseService = new CourseService()
const loading = ref(true)

const courses:Ref<CourseI[]> = ref([])
const filteredCourses:Ref<CourseI[]> = ref([])


const CourseCardLayout = defineAsyncComponent(() => import('@/layouts/CourseCardLayout.vue'))
const CourseTableLayout = defineAsyncComponent(() => import('@/layouts/CourseTableLayout.vue'))
const CourseListLayout = defineAsyncComponent(() => import('@/layouts/CourseListLayout.vue'))
const search: Ref<string> = ref('')
const layout = ref(CourseCardLayout)

const handleComponent = (cmp: any) => layout.value = cmp

onMounted(async () => {
    loading.value = true
    courses.value = await courseService.SearchCoursesByName(searchOrigin)
    filteredCourses.value = courses.value
    loading.value = false
})

const handleSearch = () => {
    loading.value = true
    filteredCourses.value = courses.value.filter(item => item.name?.toLowerCase().includes(search.value.toLowerCase()))
    loading.value = false
}

</script>

<style lang="scss" scoped>
.container {
    min-width: 50vw;
}
.img {
    border-radius: 10px;
}

.config {
    display: flex;
    align-items: center;
}
.icon {
    margin: 25px;
}
.search-form {
    width: 50%;
    display: flex;
    align-items: center;
    background-color: $primary-color;
    border-radius: 5px;
    border: 0;
    width: 100%;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    height: 37px;
    overflow-y: hidden;
}
.aux-btns {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.aux-btn {
    height: 37px;
    background-color: $third-color;
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
}
.last {
    border-radius: 0 5px 5px 0;
}

.divider {
    background-color: $secondary-color;
    width: 100%;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.container {
    width: 100%;
}
</style>
