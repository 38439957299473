
<template>
    <div class="card">
      <a :href="props.bannerLink" target="_blank">   
      <img :src="props.imageURL " alt="Publicidad" style="height: 110px;">
    </a> 
    </div>
  </template>


<script  lang="ts" setup>
import { defineProps } from 'vue';

const props = defineProps(['imageURL', 'bannerLink'])
</script>

<style scoped lang="scss">
p{
    color: white;
}
a{
    color: white;
    text-decoration: none;
}
.card{
     background-color:#66c7df ;
    margin: 5px;
    padding: 10px;
    width: 340px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    cursor: pointer;
}
.card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}
</style>