<template>
    <a href="#" alt="Ademass" class="envelope-jj" @click.prevent="handleRoute">
        <div class="cardstart" @mouseenter="showInfo = true" @mouseleave="showInfo = false">
            <div class="wrapper">
                <img :src="props.img" class="cover-image" />
            </div>
            <!--<img src="https://ggayane.github.io/css-experiments/cards/dark_rider-title.png" class="title" /> -->
            <h3 class="title">{{props.title}}</h3>
            <img :src="props.front" class="character" />
        </div>
        <div v-if="showInfo" class="info advice-anim"><p>{{ props.description }}</p></div>
    </a>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'
import router from '@/router'
import useWindowWidth from '@/composables/useWindowWidth'


const props = defineProps(['title', 'description', 'img', 'route', 'front'])
const { windowWidth } = useWindowWidth()
const showInfo = ref(false)
const handleRoute = () => {
    router.push({ name: props.route })
}
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.advice-anim {
  transform: translate3d(0%, -50px, 100px);
  animation: 0.5s ease-out 0s 1 infoAnim;
}

@keyframes infoAnim {
  0% {
   // transform: translateX(-50%);
   scale:0;
    opacity: 0;
  }
  60% {
    scale: 1;
    opacity: 1;
  }

  100% {
   // transform: translateY(0);
   scale: 1;
    opacity: 1;
  }
}


.info {
    background-color: #333;
    margin: 10px;
    margin-top: 0;
    margin-left: 5px;
    width: 265px;
    text-decoration: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 15px;
    height: 130px;
    p{
        text-decoration: none;
        font-size: 14px;
        color: white;
    }
}
.envelope-jj {
    margin: 10px;
    margin-top: 0;
    overflow: visible;
    padding-top: 0;
    border-radius: 10px;
    text-decoration: none;
}


.cardstart {
  width: 266px; // height / 1.5
  height: 470px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 36px;
  perspective: 2500px;
  margin: 0 5px;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.wrapper {
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.cardstart:hover .wrapper {
  transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
  filter: blur(4px);

 // box-shadow: 2px 8px 10px -8px rgba(0, 0, 0, 0.4);
 /* -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);*/
}

.wrapper::before,
.wrapper::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 80px;
  transition: all 0.5s;
  position: absolute;
  left: 0;
}
.wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(
    to top,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 97%
  );
}
.wrapper::after {
  bottom: 0;
  opacity: 1;
  background-image: linear-gradient(
    to bottom,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 97%
  );
}

.cardstart:hover .wrapper::before,
.wrapper::after {
  opacity: 1;
}

.cardstart:hover .wrapper::after {
  height: 120px;
}
.title {
  width: 100%;
  transition: transform 0.5s;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: white;
  text-align: center;
  font-size: 26px;
  text-shadow: 2px 2px 5px rgba(0,0,0,0.7);
  line-height: 50px;
  overflow-y: hidden;
}
.cardstart:hover .title {
  transform: translate3d(0%, -50px, 100px);
}

.character {
  width: 100%;
  opacity: 0;
  border-radius: 10px;
  transition: all 0.5s;
  position: absolute;
  z-index: -1;
}

.cardstart:hover .character {
  opacity: 1;
  transform: translate3d(0%, -30%, 100px);
}
</style>