<template>
    <DefaultLayout>
        <div class="container">
            
                <h1>Detalles del artículo</h1>
                <Navbar />
            <div class="Tbody">
                <div class="img-book">
                    <img src="../../public/img/lms/backend.png" alt="imgh prueba" :width="`${widthIMG}`" :height="`${heightIMG}`" style="border-radius: 8px;">
                </div>
                <div class="details">
                   <div>
                    <h4>Categoría:</h4>
                    <p>{{ features.category?.name }}</p>
                   </div>
                   <div>
                    <h4>Empresa:</h4>
                    <p>{{ features?.publisher }}</p>
                   </div>
                   <div>
                    <h4>Producto:</h4>
                    <p>{{ features?.title }}</p>
                   </div>
                   <div>
                    <h4>Descripción del producto:</h4>
                    <p>{{ features?.description }}</p>
                   </div> 
                   <div>
                    <h4>Precio:</h4>
                    <p>{{ features?.price }}€</p>
                   </div>
                   <div>
                    <button class="btn-download">Comprar</button>
                   </div>
                   <div>Recuerda que comprando a través de los enlaces de Ademass estas contribuyendo al mantenimiento de la plataforma.</div>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script  lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import Navbar from '@/components/layouts/NavBar.vue';
import { Ref, ref, onMounted } from 'vue';
import ShopService from '@/services/ShopService'
import { useRoute } from 'vue-router';
import ShopInterface from '@/interfaces/ShopInterface';

const route = useRoute()
const loading: Ref<boolean> = ref(false)
const service = new ShopService()
const category = ref([])
const featuresCategory = ref([])
const features: Ref<ShopInterface> = ref({})
const widthIMG: Ref<number> = ref(600)
const heightIMG: Ref<number> = ref(400)


onMounted(async () => {
    try {
        loading.value = true;
        category.value = await service.ListAllCategories();
        features.value = await service.GetDetailItem(route.params.id);
        featuresCategory.value = await service.ListAllItemsFromCategory(route.params.id);
    } catch (error) {
        console.error('Error al cargar detalles del ítem:', error);
    } finally {
        loading.value = false;
    }
});


</script>

<style scoped lang="scss">
.spacer-btn {
    width: 2.5px;
}

.container {
    display: flex;
    flex-direction: column;

  }
h5 {
    margin-bottom: 5px;
    color: $secondary-color;
}



.Tbody {
    margin: 5px;
    display: flex;
    flex-direction: row;
}

.details {
    margin-left: 60px;
}


div {
    margin-bottom: 15px;
}

.btn-download {
    color: white;
    background-color: $secondary-color;
    padding: 8px;
    border: none;
    border-radius: 6px;
}

.buttons {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 750px) {

    /* Agrega aquí tus estilos para dispositivos móviles */
    .Tbody {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .details {
        margin-left: 2.5px;
        width: 95%;
    }

    .buttons {
        display: flex;
        flex-direction: column;
    }

    .btn-download {
        width: 100%;
    }

    :root {
        --widthIMG: 250px;
        --heightIMG: 200px;
    }

}</style>