export default class {
    private base_url = 'https://gogodev.net/gogolearn/api/profile'

    public async GetProfileInfo(token:string) {
        const uri = this.base_url
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }
    

    public async GetJobProfilePercentageAcomplishedInfo(token:string) {
        const uri = `${this.base_url}/job-percentage`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UpdateName(token:string, name:string | undefined, telephone:string | undefined) {
        const uri = `${this.base_url}/update-name`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'name': name,
                'telephone': telephone
            })
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UpdateNickname(token:string, name:string) {
        const uri = `${this.base_url}/social/nickname`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'nickname': name
            })
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UpdateDescription(token:string, description:string) {
        const uri = `${this.base_url}/social/description`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'description': description
            })
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UpdatePassword(token:string, old_password:string, new_password:string, repeat_password:string) {
        const uri = `${this.base_url}/update-password`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'old_password': old_password,
                'new_password': new_password,
                'repeat_password': repeat_password
            })
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UpdateEmail(token:string, current_email:string, new_email:string, repeat_email:string) {
        const uri = `${this.base_url}/update-email`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'current_email': current_email,
                'new_email': new_email,
                'repeat_email': repeat_email
            })
        })
        const response = await (rawResponse).json()
        return response
    }

    public async MyUser(token:string) {
        const uri = `https://gogodev.net/gogolearn/api/my-user`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UserInfo(id:string | string[]) {
        const uri = `https://gogodev.net/gogolearn/api/user/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin' : '*'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async ReputationUserInfo(id:string) {
        const uri = `${this.base_url}/reputation-user/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin' : '*'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetUserByNickname(nickname:string | string[]) {
        const uri = `${this.base_url}/user-by-nickname/${nickname}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin' : '*'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async ReputationInfo(token:string) {
        const uri = `${this.base_url}/reputation`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async ReputationLogs(token:string) {
        const uri = `${this.base_url}/reputation-logs`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UpdateBG(token:string, image:any) {
        const uri = `${this.base_url}/update-bg`
        const formData = new FormData();
        formData.append('image', image);
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${token}`
            },
            body: formData
        })
        const response = await (rawResponse).json()
        return response
    }
}