<template>
    <div class="animate img-container" v-if="store.token">
        <div v-if="loading" style="overflow: hidden;">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else class="img-container">
            <div class="animate img-profile" @mouseover="isHover = true" @mouseleave="isHover = false" @click="toggleShow">
                <img v-if="user.profile_picture_uri" :src="user.profile_picture_uri" />
                <div v-else class="profile-text">{{ user.name.substring(0,1) }}</div>
                <div v-if="isHover" class="photo">
                    <v-icon name="bi-camera-fill" fill="#57AFC4" stroke="white"></v-icon>
                </div>
            </div>
        </div>
    </div>


    <my-upload field="image" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail" v-model="show" :width="300" :height="300" url="https://gogodev.net/gogolearn/api/profile/update-picture" :params="params" :headers="headers" :lang-ext="langExt" img-format="png"></my-upload>

</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import ProfileService from '@/services/ProfileService'
import { useAuth } from '@/store/useAuth'
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface'

const store = useAuth()
const service = new ProfileService()
const loading = ref(true)
const user = ref()
const isHover = ref(false)
const toast = useToast()

const show = ref(false)
const params = {
				token: store.token,
				name: 'avatar'
}
const headers = {
				smail: '*_~',
                'Authorization': `Bearer ${store.token}`
}

const langExt = {
    hint: 'Pulsa aquí o arrastra la imagen para subirla',
    loading: 'Subiendo...',
    noSupported: 'Este navegador no está soporta,Por favor, usa IE10+ u otro navegador',
    success: 'Subida Exitosa',
    fail: 'Subida Fallida',
    preview: 'Previsualización',
    btn: {
        off: 'Cancelar',
        close: 'Cerrar',
        back: 'Atrás',
        save: 'Guardar'
    },
    error: {
        onlyImg: 'Tan solo se permiten archivos de formato imagen',
        outOfSize: 'La imagen excede el peso máximo: ',
        lowestPx: 'La imagen contiene una resolución myt baja. Se espera al menos: '
    }
}

const imgDataUrl = ref('')

onMounted(async () => {
    if(!store.token) {
        return
    }
    loading.value = true
    user.value = await service.MyUser(store.token)
    loading.value = false
})

const toggleShow = () => {
	show.value = !show.value;
}

const cropSuccess = async (_imgDataUrl:string, field:string|null) => {
    if(!store.token) {
        return
    }
    toast.showToast("Subida Exitosa. La imagen estará disponible en unos minutos. Continua navegando con tranquilidad", Toast.SUCCESS)
    setTimeout(function () { toast.hideToast() }, 8000);
    loading.value = true
    user.value = await service.MyUser(store.token)
    loading.value = false
}
			
const cropUploadSuccess = (jsonData:any, field:any) => {
	console.log('field: ' + field);
}
		
const cropUploadFail = (status:any, field:number) => {
    toast.showToast("Error: " + status, Toast.DANGER)
    setTimeout(function () { toast.hideToast() }, 4000);
}
</script>

<style lang="scss" scoped>
.photo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.img-profile {
    background-color: white;
    border-radius: 50%;
    width: 100%;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid $secondary-color;
    overflow: hidden;
}
.profile-text {
    font-size: 1em;
    color: $secondary-color;
    text-transform: uppercase;
}
</style>