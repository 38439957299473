<template>
    <div class="animate reputation-container" v-if="!loading" :style="{backgroundColor:reputation.color}">
        <v-icon name="ri-medal-fill" fill="white" style="margin-right: 5px;"></v-icon>
        ({{user.reputation.toLocaleString()}}) {{reputation.name}}
    </div>
</template>

<script lang="ts" setup>
import ProfileService from '@/services/ProfileService'
import {ref, onMounted, defineProps} from 'vue'

const service = new ProfileService()
const user = ref()
const reputation = ref()
const loading = ref(true)
const props = defineProps(['user_id'])

onMounted(async () => {
    user.value = await service.UserInfo(props.user_id)
    reputation.value = await service.ReputationUserInfo(props.user_id)
    loading.value = false
})

</script>

<style lang="scss" scoped>
.reputation-container {
border-radius: 10px;
padding: 10px;
background-color: $secondary-color;
color: white;
display: flex;
align-items: center;
overflow: hidden;
font-size: 11px;
}
.reputation-container:hover {
background-color: $third-color;
}
</style>