<template>
    <DefaultLayout>
        <h1>Mi Reputación Dev</h1>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-if="showAdvice" class="animate modal">
            <div class="modal-inner">
                <div style="float: right; cursor: pointer;" @click="showAdvice = false"><v-icon name="ri-close-circle-fill"
                        fill="lightcoral"></v-icon></div>
                <h3>Cómo Obtener Puntos DEV</h3>
                <p><b style="font-size: 14px;">Los puntos de reputación DEV se obtienen alcanzando metas en la plataforma.</b><br /><br />
                    Algunas de las acciones puntuadas son la obtención de nuevos certificados, responder preguntas que obtienen el apoyo de la comunidad o que son marcadas como respuesta correcta por su autor, escribir y difundir DEV Posts que gustan a la comunidad, indicar qué respuesta a solucionado una duda a una pregunta tuya, completar tu perfil de empleo...</p>
                    <br /><p>En definitiva: Se participativo, ayuda a los demás, comparte información de interés para todos, consigue tus metas de estudio, y la plataforma te recompensará. En esta sección podrás ver un desglose de todos los puntos obtenidos y su porqué.</p>
                    <br /><p><b style="font-size: 14px;">¿Para qué sirven los puntos de reputación DEV?</b></p>
                    <br /><p>Los puntos de reputación DEV de Ademass te ayudarán a posicionarte mejor en ofertas de empleo a las que te presentes con respecto a candidatos que cumplan el mismo perfil que tú. Además, desde la comunidad trabajamos a diario para ampliar los beneficios que estos aportan. ¡Tu reputación DEV te ayuda a destacar!</p>
               
                    <button class="new-question" @click="showAdvice = false">¡De acuerdo!</button>
                
            </div>
        </div>
        <div class="reputation-container" v-if="!loading">
            <div class="profile">
                <img v-if="user.profile_picture_uri" :src="user.profile_picture_uri" />
                <span v-else>{{ user.name.substring(0, 5) }}...</span>
            </div>
            <div class="animate reputation-card" v-if="!loading">
                <div style="position: absolute; right: 5px; top: 5px; cursor: pointer;" @click="showAdvice = true"><v-icon
                        name="bi-question-circle-fill"></v-icon></div>
                <v-icon name="ri-medal-fill" scale="7"></v-icon>
                <div class="reputation-text">
                    <div class="reputation-line"><span>Status:</span>
                        <h5>{{ reputation.name }}</h5>
                    </div>
                    <div class="reputation-line"><span>Puntos:</span>
                        <h5>{{ user.reputation.toLocaleString() }}</h5>
                    </div>
                    <div class="reputation-line"><span>Siguiente Nivel:</span>
                        <h5>{{ (reputation.max_points + 1).toLocaleString() }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer"></div>
        <div class="animate table-container" v-if="!loading && logs.length > 0">
            <table class="modern-table">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Motivo</th>
                        <th>Puntos DEV</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="log in logs" :key="log.id">
                        <td> {{ new Date(log.created_at).getDate() }}/{{ new Date(log.created_at).getMonth() + 1 }}/{{ new
                            Date(log.created_at).getFullYear() }} </td>
                        <td> {{ log.subject.name }} </td>
                        <td> {{ log.amount }} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ProfileService from '@/services/ProfileService'
import { ref, onMounted } from 'vue'
import { useAuth } from '@/store/useAuth'
import useWindowWidth from '@/composables/useWindowWidth';

const {windowWidth} = useWindowWidth()
const service = new ProfileService()
const user = ref()
const store = useAuth()
const reputation = ref()
const loading = ref(true)
const logs = ref()
const showAdvice = ref(false)

onMounted(async () => {
    if (!store.token) {
        loading.value = false
        return
    }
    user.value = await service.MyUser(store.token)
    reputation.value = await service.ReputationInfo(store.token)
    logs.value = await service.ReputationLogs(store.token)
    loading.value = false
})
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 700px;
    h3 {
        margin-bottom: 15px;
    }
    p {
        font-size: 12px;
    }
}
.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 5px;
    margin-top: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.new-question:hover {
    background-color: $secondary-color;
    color: white;
    svg {
        fill: white;
    }
}
.profile {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    border: 3px solid $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 30px;
    margin-right: 25px;

    span {
        color: $secondary-color;
        font-weight: bold;
        font-size: 18px;
    }
}

.reputation-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    align-items: center;
}

.reputation-card {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: fit-content;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    min-width: 400px;
    margin-right: 25px;
    display: flex;

    svg {
        fill: $secondary-color;
    }
}

.reputation-text {
    display: flex;
    flex-direction: column;
}


.btn {
    text-decoration: none;
    margin-left: 20px;
}

.input-job {
    width: 100vw;
}

/* Estilos para la tabla */
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    color: $secondary-color !important;
}

.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}

.table-container {
    overflow-x: auto;
}

.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}
@media only screen and (max-width: 600px) {
    .reputation-card {
        min-width: 350px;
    }
}

</style>