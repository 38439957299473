<template>
    <DefaultLayout>
        <ChannelHeader v-if="user" :user="user" />
        <div class="channel-description" v-if="!loading">
            <h3>Sobre {{ user.name }}</h3>
            <p v-if="user.description">{{ user.description }}</p>
            <p v-else>Bienvenido al canal de {{ user.name }} en Ademass</p>
        </div>
        <div class="latest-messages-container" v-if="!loading">
            <h3>Últimos CodeBites de {{ user.name }} <RouterLink :to="{ name: 'social-codebites', params: {nickname: nickname} }" style="text-decoration: none;">
                    <small>Ver todos</small></RouterLink></h3>
            <div class="latest-messages">
                <CodeBite v-for="codebite in codebites" :key="codebite.id" :codebite="codebite" :user="user" @refresh="refresh" />
                <p v-if="codebites.length == 0" style="margin-top: 10px;">{{ user.name }} no ha publicado CodeBites</p>
            </div>
        </div>
        <div class="latest-messages-container" v-if="!loading">
            <h3>Últimos CodePosts de {{ user.name }} <RouterLink :to="{ name: 'social-codeposts', params: {nickname: nickname} }" style="text-decoration: none;">
                    <small>Ver todos</small></RouterLink></h3>
            <div class="latest-messages">
                <CodePost v-for="codepost in codeposts" :key="codepost.id" :codepost="codepost" :user="user" @refresh="refresh" />
                <p v-if="codeposts.length == 0" style="margin-top: 10px;">{{ user.name }} no ha publicado CodePosts</p>
            </div>
        </div>
        <div class="latest-messages-container" v-if="!loading">
          <BackBtn></BackBtn>
        </div>
    </DefaultLayout>
</template>

<script setup lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ChannelHeader from '@/components/channel/ChannelHeader.vue'
import CodeBite from '@/components/cards/CodeBite.vue'
import CodePost from '@/components/cards/CodePost.vue'
import ProfileService from '@/services/ProfileService'
import ChannelService from '@/services/ChannelService'
import { ref, onMounted,Ref } from 'vue'
import { useAuth } from '@/store/useAuth'
import router from '@/router'
import {useRoute} from 'vue-router'
import BackBtn from '@/components/buttons/BackBtn.vue'
import CodeI from '@/interfaces/CodeBiteInterface'

const profileService = new ProfileService()
const user = ref()
const loading = ref(true)
const store = useAuth()
const codebites:Ref<CodeI[]> = ref([])
const codeposts:Ref<CodeI[]> = ref([])
const channelService = new ChannelService()
const route = useRoute()
const nickname = route.params.nickname

onMounted(async () => {
    loading.value = true
    user.value = await profileService.GetUserByNickname(nickname)
    if(isObjectEmpty(user.value)) {
        router.push({name:'error404'})
    }
    codebites.value = await channelService.GetLatestCodeBites(user.value.id, 4)
    codeposts.value = await channelService.GetLatestCodePosts(user.value.id, 2)
    loading.value = false
})

const refresh = async () => {
    if (!store.token) { return }
    codebites.value = await channelService.GetLatestCodeBites(store.token, 4)
    codeposts.value = await channelService.GetLatestCodePosts(store.token, 2)
}

function isObjectEmpty(obj:any) {
  return Object.keys(obj).length === 0;
}

</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}



.btn-function:hover {
    background-color: $secondary-color;
}
.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;

    h3 {
        margin-bottom: 15px;
    }

    p {
        font-size: 12px;
    }
}

.new-question {
    outline: 0;
    border: 3px solid $primary-color;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 5px;
    margin-top: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.input-text {
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid $secondary-color;
    margin: 5px;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 5px;
    outline: none;
}

.new-question:hover {
    background-color: $secondary-color;
    color: white;

    svg {
        fill: white;
    }
}

.pencil {
    margin-left: 5px;
    cursor: pointer;
}

.pencil:hover {
    fill: $secondary-color;
}

.channel-description {
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
}

.latest-messages-container {
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 50px;
}

.latest-messages {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}</style>