export default class FriendshipService {
    private base_url = 'https://gogodev.net/gogolearn/api/friendship'

    public async sendFriendshipRequest(token:string, email:string) {
        const uri = `${this.base_url}/send-friendship-request`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
               'email':email
            })
        })
        const response = await rawResponse.json()
        return response
    }

    public async listPendingRequests(token:string) {
        const uri = `${this.base_url}/pending`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async acceptFriendshipRequest(token:string, id:string | undefined) {
        const uri = `${this.base_url}/accept-request/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async declineFriendshipRequest(token:string, id:string | undefined) {
        const uri = `${this.base_url}/decline-request/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async listUserFriends(token:string) {
        const uri = `${this.base_url}/friends`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async deleteFriend(token:string, id:string | string[]) {
        await this.markAsRead(token, id)
        const uri = `${this.base_url}/delete-friend/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async getUnreadMessagesSentByYourFriend(token:string, id:number) {
        const uri = `${this.base_url}/message/count-unread/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async getAllUnreadMessagesSentToYou(token:string) {
        const uri = `${this.base_url}/message/count-all-unread`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }
    public async getConversation(token:string, friendId:number) {
        const uri = `${this.base_url}/message/read/${friendId}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }
    public async markAsRead(token:string, friendId:string | string[]) {
        const uri = `${this.base_url}/message/mark-as-read/${friendId}`
        const rawResponse = await fetch(uri, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
    }
    public async sendMessage(token:string, receiver:string | string[], message:string) {
        const uri = `${this.base_url}/message/send`
        const rawResponse = await fetch(uri, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'receiver': receiver,
            'message': message
          })
        })
        const response = rawResponse.json()
        this.markAsRead(token, receiver)
      }
}