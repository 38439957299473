<template>
    <div class="animate main-box">
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2" />
        </div>
        <div v-else>
            <div class="animate conversation-header">
                <h3 style="color: white;" v-if="conversation">{{ conversation.user.name }}</h3>
                <v-icon name="ri-close-circle-fill" scale="1.5" fill="white" animation="wrench" hover="true"
                    style="cursor: pointer;" @click="emit('closeConversation')"></v-icon>
            </div>
           
                <div style="margin-top: 60px;"></div>
                <div style="overflow-y: scroll;" ref="objDiv">
                    <div v-if="conversation">
                    <div v-for="message in conversation.messages" :key="message.id">
                        <div v-if="message.sender == props.friendId" class="friend-dialog-container">
                            <div class="animate friend-dialog">
                                <small style="color: #333; font-size: 10px;">{{ conversation.user.name }}:</small>
                                <p style="margin-top: 5px; margin-bottom: 5px;">{{ message.message }}</p>
                                <small style="color: #333; font-size: 10px;">{{ new Date(message.created_at).getHours() }}:{{ new Date(message.created_at).getMinutes() }} {{ new Date(message.created_at).getDate() }}/{{ new Date(message.created_at).getMonth()+1 }}/{{ new Date(message.created_at).getFullYear() }}</small>
                            </div>
                        </div>
                        <div v-else class="my-dialog-container">
                            <div class="animate my-dialog">
                                <small style="color: #333; font-size: 10px;">Yo:</small>
                                <p style="margin-top: 5px; margin-bottom: 5px;">{{ message.message }}</p>
                                <small style="color: #333; font-size: 10px;">{{ new Date(message.created_at).getHours() }}:{{ new Date(message.created_at).getMinutes() }} {{ new Date(message.created_at).getDate() }}/{{ new Date(message.created_at).getMonth()+1 }}/{{ new Date(message.created_at).getFullYear() }}</small>
                           </div>
                        </div>
                    </div>
                    <div style="margin-top: 200px;"></div>
                </div>
                </div>
                <div class="type-text">
                    <input type="text" placeholder="Escribir mensaje..." v-model="message" @keyup.enter="sendMessage"
                        @focus="markAsRead" max="255" /><button @click="sendMessage">Enviar</button>
                </div>
            
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, onMounted, onUnmounted, computed } from 'vue'
import FriendshipService from '@/services/FriendshipService'
import { useAuth } from '@/store/useAuth'


const props = defineProps(['friendId'])
const conversation = ref()
const friendshipService = new FriendshipService()
const store = useAuth()
const loading = ref(true)
const objDiv = ref()
const emit = defineEmits(['closeConversation'])
const message = ref('')
const firsTime = ref(true)

const intervalId = window.setInterval(async () => {
    if (!store.token) {
        return
    }
    friendshipService.getConversation(store.token, props.friendId)
    if(firsTime.value) {
        objDiv.value.scrollIntoView({ behavior: 'smooth', block: 'end' });
        firsTime.value=false;
    }
}, 2000)

onMounted(async () => {
    if (!store.token) { 
        loading.value = false
        return 
    }
    loading.value = true
    conversation.value = await friendshipService.getConversation(store.token, props.friendId)
    if (objDiv.value) {
        objDiv.value.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    loading.value = false
})

onUnmounted(() => {
    clearInterval(intervalId);
})

const sendMessage = async () => {
    if (!store.token) {
        return
    }
    await friendshipService.sendMessage(store.token, props.friendId, message.value)
    conversation.value = await friendshipService.getConversation(store.token, props.friendId)
    objDiv.value.scrollIntoView({ behavior: 'smooth', block: 'end' });
    message.value = ''
}

const markAsRead = async () => {
    if(!store.token) {
        return
    }
    friendshipService.markAsRead(store.token, props.friendId)
}
</script>

<style lang="scss" scoped>
.main-box {
    position: fixed;
    top: 75px;
    left: 100px;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: white;
    background-image: url('../../../public/img/lms/mosaic.png');
    border: 3px solid $primary-color;
    padding-right: 100px;
}

.type-text {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #d3d3d3;
    height: 40px;
    margin: 0;
    display: flex;

    input {
        border: 0;
        outline: 0;
        color: #999;
        overflow-y: hidden;
        padding-left: 10px;
        width: 100%;
    }

    button {
        background-color: $third-color;
        color: white;
        padding-left: 10px;
        padding-right: 10px;
        outline: 0;
        border: 0;
        cursor: pointer;
        position: absolute;
        right: 100px;
        height: 100%;
    }

    button:hover {
        background-color: $secondary-color;
    }
}

.conversation-header {
    padding: 10px;
    z-index: 100;
    background-color: #333;
    color: #fff;
    position: fixed;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding-right: 110px;
}

.friend-dialog {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    background-color: $secondary-color;
    margin: 5px;
    padding: 10px;

    p {
        color: white;
    }
}

.my-dialog {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
    background-color: $third-color;
    margin: 5px;
    padding: 10px;

    p {
        color: white;
    }
}

.my-dialog-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.friend-dialog-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

@media(max-width: 955px) {
    .main-box {
        position: fixed;
        left: 0;
        border-radius: 0;
        border: 0;
        padding-right: 5px;
        height: 100%;
    }

    .conversation-header {
        padding-right: 5px;
    }

    .type-text {
        button {
            right: 0;
        }
    }
}
</style>