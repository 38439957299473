export default class NoteService {
    private base_url = 'https://gogodev.net/gogolearn/api/note'

    public async GetAllNotesFromUser(token:string) {
        const uri = this.base_url
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CreateOrUpdateNote(token:string, lesson_id:number, note_content:string) {
        const uri = `${this.base_url}/create`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'lesson_id': lesson_id,
                'note_content': note_content
            })
        })
        const response = await rawResponse.json()
        return response
    }

    public async GetNoteFromLesson(token:string, lesson_id:string) {
        const uri = `${this.base_url}/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

}