export default class LessonService {
    private base_url = 'https://gogodev.net/gogolearn/api/lesson'

    public async countLessonsInCourse(course_id:number) {
        const uri = `${this.base_url}/count/${course_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }

    public async getLessonsFromCourse(course_id:string | string[]) {
        const uri = `${this.base_url}/${course_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }

    public async getLesson(lesson_id:string | string[]) {
        const uri = `${this.base_url}/detail/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }
    public async getPrevLesson(lesson_id:number) {
        const uri = `${this.base_url}/prev-lesson/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }
    public async getNextLesson(lesson_id:number) {
        const uri = `${this.base_url}/next-lesson/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await rawResponse.json()
        return response
    }
}