export default class EmployProfileService {
    private base_url = 'https://gogodev.net/gogolearn/api/employ_profile'

    public async GetEmployProfileInfo(token: string) {
        const uri = this.base_url
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async SetSearchEmploy(token: string) {
        const uri = `${this.base_url}/searching-employ`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async SetNoSearchEmploy(token: string) {
        const uri = `${this.base_url}/no-searching-employ`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async SetAutoSearchEmploy(token: string) {
        const uri = `${this.base_url}/auto-search`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async SetNoAutoSearchEmploy(token: string) {
        const uri = `${this.base_url}/no-auto-search`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async SetLocation(token: string, id: number) {
        const uri = `${this.base_url}/set-location/${id}`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async SetAvailableToMove(token: string) {
        const uri = `${this.base_url}/available-to-move`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async SetNoAvailableToMove(token: string) {
        const uri = `${this.base_url}/no-available-to-move`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async SetAvailableRemote(token: string) {
        const uri = `${this.base_url}/available-remote`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async SetNoAvailableRemote(token: string) {
        const uri = `${this.base_url}/no-available-remote`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async SetMovilityType(token: string, type: string) {
        const uri = `${this.base_url}/set-movility-type/${type}`
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

}