<template>
    <div class="conversation-box" v-if="maximize">
        <div class="animate conversation-header">
            <div class="notification" v-if="unreads > 0">{{ unreads }}</div>
            {{ props.conversation.username }}
            <v-icon name="ri-close-circle-fill" fill="white" style="float: right ; cursor: pointer" @click="close()" />
            <v-icon name="la-window-minimize-solid" fill="white" style="float: right ; cursor: pointer;  margin-right: 3px;"
                @click="maximize = false" />

        </div>
        <div style="overflow-y: scroll;" ref="objDiv">
            <div style="margin-top: 40px;"></div>
            <RouterLink :to="{name:'messages', params: {id: props.conversation.id}}" style="text-decoration: none !important;"><small style="text-decoration: none !important;">Para visualizar anteriores, ve a sección mensajería.</small></RouterLink>
            <div v-for="(message, index) in props.conversation.messages" :key="index">
                <div v-if="message.sender == props.conversation.id" class="friend-dialog-container">
                    <div class="friend-dialog">
                        <p>{{ message.message }}</p>
                    </div>
                </div>
                <div v-else class="my-dialog-container">
                    <div class="my-dialog">
                        <p>{{ message.message }}</p>
                    </div>
                </div>
            </div>

            <div style="margin-top: 100px;"></div>
        </div>
        <div style="margin-top: 40px;"></div>
        <div class="type-text">
            <input type="text" placeholder="Escribir mensaje..." v-model="message" @keyup.enter="sendMessage" @focus="markAsRead"
                max="255" /><button @click="sendMessage">Enviar</button>
        </div>
    </div>
    <div v-else style="margin-bottom: 0px; width: 350px;text-align: center; height: 40px;">
        <div class="conversation-header">
            <div class="notification" v-if="unreads > 0">{{ unreads }}</div>
            {{ props.conversation.username }}
            <v-icon name="ri-close-circle-fill" fill="white" style="float: right ; cursor: pointer" @click="close()" />
            <v-icon name="fa-regular-window-maximize" fill="white"
                style="float: right ; cursor: pointer; margin-right: 3px;" @click="maximize = true" />

        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, defineProps, onMounted, onUnmounted, computed } from 'vue'
import { useConversations } from '@/store/useConversations';
import { useAuth } from '@/store/useAuth';

const maximize = ref(true)
const storeConversations = useConversations()
const props = defineProps(['conversation'])
const message = ref('')
const store = useAuth()
const objDiv = ref()
const unreads = computed(() => {
    let count = 0;
    for (let i = 0; i <= storeConversations.max_amount_messages; i++) {
        if ((props.conversation.messages[i]) && (props.conversation.messages[i].sender == props.conversation.id) && (props.conversation.messages[i].is_read == 0)) {
            count++
        }
    }
    if(count > 99) {
        count = 99
    }
    return count
})

const intervalId = window.setInterval(async () => {
    if (!store.token) {
        return
    }
    storeConversations.updateConversation(store.token, props.conversation.id)
}, 5000)

const close = () => {
    storeConversations.removeConversation(props.conversation.id)
}

onUnmounted(() => {
    clearInterval(intervalId);
})

onMounted(() => {
    objDiv.value.scrollIntoView({ behavior: 'smooth', block: 'end' });
})

const markAsRead = async () => {
    if(!store.token) {
        return
    }
    storeConversations.markAsRead(store.token, props.conversation.id)
}

const sendMessage = async () => {
    if (!store.token) {
        return
    }
    const response = await storeConversations.sendMessage(store.token, props.conversation.id, message.value)
    objDiv.value.scrollIntoView({ behavior: 'smooth', block: 'end' });
    message.value = ''
}

</script>

<style scoped lang="scss">
.notification {
    float: left;
    cursor: pointer;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightcoral;
    color: white;
    margin-right: 3px;
}

.type-text {
    position: absolute;
    bottom: 0;
    width: 349px;
    background-color: white;
    border: 1px solid #d3d3d3;
    height: 40px;
    margin: 0;
    display: flex;

    input {
        border: 0;
        outline: 0;
        color: #999;
        overflow-y: hidden;
        padding-left: 10px;
        width: 290px;
    }

    button {
        background-color: $third-color;
        color: white;
        padding-left: 10px;
        padding-right: 10px;
        outline: 0;
        border: 0;
        cursor: pointer;
    }

    button:hover {
        background-color: $secondary-color;
    }
}

.my-dialog-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.friend-dialog-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.friend-dialog {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    background-color: $secondary-color;
    margin: 5px;
    padding: 10px;
    width: 65%;

    p {
        color: white;
    }
}

.my-dialog {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 10px;
    background-color: $third-color;
    width: 65%;
    margin: 5px;
    padding: 10px;

    p {
        color: white;
    }
}

.conversation-box {
    z-index: 99;
    background-color: #f1f1f1;
    background-image: url('../../../public/img/lms/mosaic.png');
    border: 1px solid #d3d3d3;
    text-align: center;
    border-radius: 10px;
    width: 350px;
    height: 400px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 2px 2px 5px #ccc;
}

.conversation-header {
    padding: 10px;
    z-index: 100;
    background-color: #333;
    color: #fff;
    position: fixed;
    width: 350px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
</style>