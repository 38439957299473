export default class FavService {

    private base_url = 'https://gogodev.net/gogolearn/api/fav'

    async getAllFromUser(token:string) {
        const uri = `${this.base_url}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    async checkFavStatus(token:string, lesson_id:number) {
        const uri = `${this.base_url}/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    async doFav(token:string, lesson_id:number) {
        const uri = `${this.base_url}/do-fav/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    async doNoFav(token:string, lesson_id:number) {
        const uri = `${this.base_url}/do-no-fav/${lesson_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }
}