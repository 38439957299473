<template>
  <ProdevValidatorPortFolio :idToValidate="route.params.id" ></ProdevValidatorPortFolio>
    <div class="portfolio">
      <div class="loading" v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
      </div>
      <div class="content" v-else>
        <div class="header">
          <div class="profile-picture">
            <img :src="candidate?.user.profile_picture_uri" alt="Profile Picture" />
          </div>
          <div class="info">
            <h1>{{ candidate.user.name }}</h1>
            <h4>{{ candidate.user.professional_profile.slogan }}</h4>
            <div class="social-links">
              <a v-for="(link, icon) in candidate.user.professional_profile.social_links" :key="icon" :href="link" target="_blank">
                <v-icon :name="icon" class="icon" hover="true" animation="wrench" fill="#34495e"></v-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="about-me">
          <h2>Acerca de mí</h2>
          <p>{{ candidate.user.professional_profile.profile_description }}</p>
        </div>
        <div class="resume">
          <h2>Mi Resumen</h2>
          <div class="experience">
            <h3>Experiencia Laboral</h3>
            <br>
            <ul>
              <li v-for="(job, index) in candidate.user.previous_jobs" :key="index">
                <div class="job-details">
                  <h4>{{ job.company }}</h4>
                  <p>{{ job.name }}</p>
                  <p>{{ job.description }}</p>
                </div>
                <br>
              </li>
            </ul>
          </div>
          <div class="education">
            <h3>Educación</h3>
            <br>
            <ul>
              <li v-for="(study, index) in candidate.user.studies" :key="index">
                <div class="study-details">
                  <h4>{{ study.name }}</h4>
                  <p>{{ study.organism }}</p>
                  <p>{{ study.description }}</p>
                </div>
                <br>
              </li>
            </ul>
          </div>
        </div>
        <div class="skills">
          <h2>Habilidades</h2>
          <ul>
            <li v-for="(skill, index) in candidate.user.skills" :key="index">
              <div class="skill">
                <v-icon name="bi-graph-up-arrow" class="icon" hover="true" animation="wrench" style="scale: 2;" />
                <h5>{{ skill.name }}</h5>
              </div>
            </li>
          </ul>
        </div>
        <div class="certifications">
          <h2>Certificaciones</h2>
          <div class="columns">
            <div class="column">
          <ul>
            <li v-for="(certification, index) in column1" :key="index">
              <div class="certification">
                <h6>{{ certification.name }}</h6>
              </div>
            </li>
          </ul>
        </div>

        <div class="column">
          <ul>
            <li v-for="(certification, index) in column2" :key="index">
              <div class="certification">
                <h6>{{ certification.name }}</h6>
              </div>
            </li>
          </ul>
        </div>

        <div class="column">
          <ul>
            <li v-for="(certification, index) in column3" :key="index">
              <div class="certification">
                <h6>{{ certification.name }}</h6>
              </div>
            </li>
          </ul>
        </div>

         <div class="column">
          <ul>
            <li v-for="(certification, index) in column4" :key="index">
              <div class="certification">
                <h6>{{ certification.name }}</h6>
              </div>
            </li>
          </ul>
        </div>
        </div>
        </div>
        <div class="contact">
          <h2>¿ Hablamos ?</h2>
          <div class="contact-info">
            <label>Nombre Completo:</label>
            <span>{{ candidate.user.name }}</span>
            <div class="separator"></div>
            <label>Teléfono:</label>
            <span>{{ candidate.user.telephone }}</span>
            <div class="separator"></div>
            <label>Email:</label>
            <span>{{ candidate.user.email }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import PortFolio from '@/services/PortFolioService';
  import SkillServive from '@/services/SkillService'
  import { onMounted, ref } from 'vue';
  import ProfileService from '@/services/ProfileService'
  import { useRoute } from 'vue-router';
import ProdevValidatorPortFolio from '@/components/auth/ProdevValidatorPortFolio.vue';

  const loading = ref(true)
  const route = useRoute()
  const skillService = new SkillServive()
  const profileService = new ProfileService()
  const allSkills = ref([])
  const profile = ref('')
  const candidate = ref({})
  const portFolioService = new PortFolio()
  const userId = ref('')
  const column1 = ref([])
  const column2 = ref([])
  const column3 = ref([])
  const column4 = ref([])

  onMounted(async () => {
      loading.value = true
      profile.value = await profileService.UserInfo(route.params.id)
      allSkills.value = await skillService.GetAllSkills()
      candidate.value = await portFolioService.GetCandidateInfo(route.params.id)
      candidate.value.user.previous_jobs = candidate.value.user.previous_jobs.reverse()
      candidate.value.user.studies = candidate.value.user.studies.reverse()
      userId.value = route.params.id

      const certificationsLength = candidate.value.user.certifications.length
      const columnSize =  Math.ceil(certificationsLength / 4)

      for (let i = 0; i < certificationsLength; i++) {
    const certification = candidate.value.user.certifications[i];

    if (i < columnSize) {
        column1.value.push(certification);
    } else if (i < columnSize * 2) {
        column2.value.push(certification);
    } else if (i < columnSize * 3) {
        column3.value.push(certification);
    } else {
        column4.value.push(certification);
    }
}
      loading.value = false
     setTimeout(() => {window.print()}, 500)
  })
  </script>
  
  <style scoped>
  .certifications-content {
  display: flex;
  flex-wrap: wrap;
}
.columns{
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
}
.certifications-block {
  margin-right: 20px; /* Espacio entre bloques */
}
  .portfolio {
    font-family: 'Roboto', sans-serif;
    color: #2c3e50;
    background-color: #ecf0f1;
    padding: 20px;
  }
  .certification{
    min-height: 30px;
  }
  .separator{
    width: 10px;
  }
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .profile-picture img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
  
  .info {
    margin-left: 20px;
  }
  
  .social-links a {
    margin-right: 10px;
  }
  
  .about-me,
  .resume,
  .skills,
  .certifications,
  .contact {
    margin-bottom: 50px;
  }
  
  .about-me h2,
  .resume h2,
  .skills h2,
  .certifications h2,
  .contact h2 {
    color: #2980b9;
    margin-bottom: 20px;
  }
  
  .about-me p,
  .resume h3,
  .resume ul,
  .education ul,
  .skills ul,
  .certifications ul,
  .contact-info span {
    color: #34495e;
  }
  
  .contact-info label {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .skill {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    min-height: 70px;
  }
  
  .skill h5 {
    margin-left: 10px;
  }
  
  .icon {
    color: #2980b9;
  }
  
  .icon:hover {
    cursor: pointer;
  }
  @media screen and (max-width:500px){
    .header{
        display: flex;
        flex-direction: column;
    }
    
  }
  </style>
  