<template>
    <DefaultLayout>
    <div class="container" style=" background-image: url('/img/photos/social_front2.png');
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: 32%, 32%;" >

        <h1>¡Te Damos la Bienvenida a ProDev!</h1>
        <div class="card">
        <p>Aprovecha al máximo las oportunidades que este servicio te ofrece y sube al siguiente nivel como desarrollador.</p>
        <div ></div>
        <p style="margin-top: 8px;">Con ProDev, no solo tendrás acceso a nuestros recursos gratuitos, sino que también desbloquearás características ProDev exclusivas para suscriptores, incluyendo:</p>
        <br>
        <ul>
          <li><b>- </b>Acceso ilimitado a ofertas de empleo.</li>
          <li><b>- </b>Prácticas garantizadas (cumpliendo los requisitos mínimos).</li>
          <li><b>- </b>Bootcamps de las mejores tecnologías.</li>
          <li><b>- </b>Webinars para resolver dudas sobre los bootcamps y otros temas de interés.</li>
          <li><b>- </b>Ejercicios resueltos para que puedas practicar.</li>
          <li><b>- </b>Portfolio personalizado (recuerda rellenar los nuevos apartados dentro del módulo de 'Mi perfil de empleo').</li>
          <li><b>- </b>Manuales gratuitos de las mejores tecnologías.</li>
          <li><b>- </b>Y muchas más.</li>
        </ul>
      </div>
      <br>
        <p><strong>{{ feedback }}</strong></p>
    </div>
</DefaultLayout>
</template>

<script  lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useRoute } from 'vue-router';
import {onMounted, ref} from 'vue';
import { useAuth } from '@/store/useAuth';
import PayPalService from '@/services/PayPalService'

const route = useRoute()
const subscriptionId = route.params.subscriptionId
const store = useAuth()
const feedback = ref('')
const paypalService = new PayPalService()

onMounted(async () => {
    if(!store.token) {
        feedback.value = "Acceso a página no permitido"
        return;
    }

    feedback.value = "Estamos registrando tu suscripción. Un momento, por favor..."
    const response = await paypalService.storeSub(store.token, subscriptionId)
    if(response.success) {
        feedback.value = "¡Todo listo! Ya puedes comenzar a aprender sin límites."
    }


})

</script>

<style lang="scss" scoped>
li{
    line-height: 2;
}
.buttons{
    display: flex;

}
.price{
  display: flex;
}
.price-original {
    color: red;
    text-decoration: line-through;
}
.price-discount {
    color: #29c682;
}
.separator{
    width: 10px;
}
.cards{
    display: flex;
}
b{
    color: $secondary-color;
}
h1{
    margin-top: 20px;
    margin-bottom: 20px;
}
strong{
    color: #28a3bf!important;
}
h4{
    margin-bottom: 10px;
}
.inputView{
    margin-bottom: 15px;
}
.imgFound{
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
}
.container {
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
   
}

.inputProfile {
    flex-direction: column;
    width: 60%;
    display: flex;
    align-items: center;
}
.card {
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 35px;
    min-height: 300px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 15px;
    margin-top: 15px;
    background-color: white;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.1);

}


.btn-details {
    margin-top: 15px;
    padding: 10px;
    color: white;
    background-color: $secondary-color;
    font-size: 13px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    margin-bottom: 5px;
    font-family: $main-font;
    min-width: 100px;
}



.btn-details:hover {
    background-color: $third-color;
    cursor: pointer;
    scale: 1.1;
}
@media screen and (max-width: 600px) {
    .inputProfile {
        width: 100%;
    }
    .container{
    background-image: none!important; /* Oculta las imágenes de fondo en dispositivos móviles */
  }

    .card {
        width: 100%;
        margin-left: 4.5%;

    }
    .cards{
        flex-direction: column;
    }
}</style>