<template>
    <div class="box card" v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
    </div>
    <div class="animate box card" v-else>
        <div class="img animate">
            <img v-if="windowWidth > 950" v-lazy="{ src: props.content.imageURL, loading: '../../../img/lms/loading.png' }" width="350" />
            <img v-else v-lazy="{ src: props.content.imageURL, loading: '../../../img/lms/loading.png' }" class="mobile-img" />
        </div>
        <h3 class="clickable" @click="handleRoute"><br /><span class="span"
                v-if="props.showOrder == 'true'">#{{ props.content.order }} </span>{{ props.content.name }}</h3>
                <div class="buttons-container">
            <div class="buttons-place">
                <FollowCourseWidget :courseId="card.id" />
                <button @click="handleRoute"><v-icon name="bi-arrow-right-circle-fill" fill="#bece49" hover="true" animation="wrench"  class="heart"  scale="1"></v-icon>Acceder</button>
            </div>
        </div>
        <div class="pro">
            <CourseProgressbar :courseId="props.content.id" />
    </div>
     <!--   <p class="author-info"> <a :href="authorInfo.channelURL" target="_blank"><img
                    v-lazy="{ src: authorInfo.imageURL, loading: '../../../img/lms/loading.png' }" width="25"
                    class="author-img" /> Realizado por {{ authorInfo.name }}</a></p> -->

    </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, onMounted } from 'vue'
import FollowCourseWidget from '../widgets/FollowCourseWidget.vue'
import CourseProgressbar from '../widgets/CourseProgressbar.vue';
import router from '@/router'
import useWindowWidth from '@/composables/useWindowWidth'

const {windowWidth} = useWindowWidth()

const props = defineProps(['content', 'showOrder'])
const card = ref({
    id: 0,
    name: 'cargando...',
    description: 'cargando...',
    order: 0,
    image_url: 'img/lms/loading.png',
    roadmap_id: 0,
    created_at: '',
    updated_at: ''
})
const loading = ref(true)

onMounted(() => {
    loading.value = true
    card.value = props.content
    loading.value = false
})

const handleRoute = () => router.push({name: 'course', params: {id: card.value.id}})
</script>

<style lang="scss" scoped>
.author-info {
    width: 100%;
    display: flex;
    align-items: center;
}

.author-info a {
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.hand {
    margin-right: 5px;
    transform: rotate(54deg) !important;
}

.span {
    margin-right: 5px;
}

.desc {
    height: 150px;
}

.img {
    margin-top: -20px;
    padding-top: 0;
    margin-left: -15px;
    position: relative;
}

.card {
    width: 340px;
    height: 350px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid #ccc;
}

.card h3 {
    color: black;
    margin-top: -20px !important;
}

.divider {
    background-color: $secondary-color;
    width: 100%;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.author-img {
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}


.buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
}

.buttons-place {
    display: flex;
    width: 100%;
}

button {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
    background-color: white;
    font-size: 14px;
    font-family: $main-font;
}
button:hover {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: white;
    span {
        color: white;
    }
}
.heart {
    margin-right: 5px;
}
.mobile-img {
    width: 100%;
}

.pro {
    width: 100%;
}
@media(max-width: 950px) {
    .card {
        height: 330px;
        width: 90% !important;
    }
    .box {
        padding-right: 0;
    }
    .pro {
        width: 95%;
    }
    h3 {
        margin-right: 10px !important;
    }
} 
</style>
