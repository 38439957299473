import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useConversations } from './useConversations'

export const useAuth = defineStore('useAuth', {
  state: () => {
    return {
      base_url: "https://gogodev.net/gogolearn/api/",
      token: ref(null),
      message: ref(''),
      email_verified: ref(''),
      email: ref('')
    }
  },
  actions: {
    async login(email:string, password:string):Promise<boolean> {
      const uri = `${this.base_url}auth/login`
      const rawResponse = await fetch(uri,  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify( {'email': email, 'password': password} )
      })
      const response = await rawResponse.json()
      if(!response.status) {
        this.message = response.message
        this.token = null
        return false
      }
      this.token = response.token
      this.email = email
      this.email_verified = response.email_verified
      this.message = '¡Éxito!'
      return true
    },
    
    async register(name:string, email:string, password:string):Promise<boolean> {
        const uri = `${this.base_url}auth/register`
        const rawResponse = await fetch(uri,  {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify( {'name': name, 'email': email, 'password': password} )
        })
        const response = await rawResponse.json()
        if(!response.status) {
          this.message = response.message
          this.token = null
          this.email = email
          return false
        }
        this.token = response.token
        this.message = '¡Éxito!'
        this.email = email
        return true
    },
    logout():boolean {
      this.token = null 
      this.email = ''
      const conversations = useConversations()
      conversations.conversations = []
      this.message = "Sesión cerrada correctamente."
      return true
    }
  },
  persist: true,
})