<template>
     <div class="no-auth" @click="handleAuth" style="margin-right: 10px;">
            <button><v-icon name="la-user-circle" fill="#fff" /> Acceder / Registro</button>
        </div>
</template>

<script lang="ts" setup>
import router from '@/router'


const handleAuth = () => {
    router.push({name:'auth'})
}
</script>

<style lang="scss" scoped>
.no-auth {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    margin-right: 0;
}
button {
    padding: 10px;
    background-color: $third-color;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-family: $title-font;
}
button:hover {
    background-color: $secondary-color;
}
</style>