export default class {
    private base_url = 'https://gogodev.net/gogolearn/api/channel'

    public async CreateCodeBite(token:string, title:string, content:string) {
        const uri = `${this.base_url}/codebite/create`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'title': title,
                'content': content
            })
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CreateCodePost(token:string, title:string, content:string, short:string, image:any) {
        const uri = `${this.base_url}/codepost/create`
        const formData = new FormData();
        formData.append('image', image);
        formData.append('title', title);
        formData.append('content', content);
        formData.append('short', short);
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${token}`
            },
            body: formData
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetLatestCodeBitesFromAuthUser(token:string, amount:number) {
        const uri = `${this.base_url}/codebite/get-auth-latest/${amount}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetLatestCodeBites(user:number, amount:number) {
        const uri = `${this.base_url}/codebite/get-latest/${user}/${amount}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetLatestCodePosts(user:number, amount:number) {
        const uri = `${this.base_url}/codepost/get-latest/${user}/${amount}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }


    public async DoLike(token:string, id:string | string[]) {
        const uri = `${this.base_url}/codebite/do-like/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async DoFollow(token:string, id:string | string[]) {
        const uri = `${this.base_url}/do-follow/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async DoUnfollow(token:string, id:string | string[]) {
        const uri = `${this.base_url}/do-unfollow/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CheckIfFollows(token:string, id:string | string[]) {
        const uri = `${this.base_url}/check-follow/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async DoCodePostLike(token:string, id:string | string[]) {
        const uri = `${this.base_url}/codepost/do-like/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UndoLike(token:string, id:string | string[]) {
        const uri = `${this.base_url}/codebite/undo-like/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UndoCodePostLike(token:string,id:string | string[]) {
        const uri = `${this.base_url}/codepost/undo-like/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CheckIfIsLiked(token:string,id:string | string[]) {
        const uri = `${this.base_url}/codebite/is-liked/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CheckIfCodePostIsLiked(token:string,id:string | string[]) {
        const uri = `${this.base_url}/codepost/is-liked/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async DeleteCodeBite(token:string, id:string | string[]) {
        const uri = `${this.base_url}/codebite/delete/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async DeleteCodePost(token:string, id:string | string[]) {
        const uri = `${this.base_url}/codepost/delete/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetLatestCodePostsFromAuthUser(token:string, amount:number) {
        const uri = `${this.base_url}/codepost/get-auth-latest/${amount}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CodeBiteFeeder(token:string) {
        const uri = `${this.base_url}/codebite/feeder`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CodePostFeeder(token:string) {
        const uri = `${this.base_url}/codepost/feeder`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }


    public async CountFollowers(id:string | string[]) {
        const uri = `${this.base_url}/followers/count/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async ListFollowers(id:string | string[]) {
        const uri = `${this.base_url}/followers/list/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CountFollowing(id:string | string[]) {
        const uri = `${this.base_url}/following/count/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetMostReputatedChannels() {
        const uri = `${this.base_url}/channel/most-reputated`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async SearchByName(name:string) {
        const uri = `${this.base_url}/channel/search`
        const rawResponse = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name
            })
        })
        const response = await (rawResponse).json()
        return response
    }


    public async GetMostActiveChannels() {
        const uri = `${this.base_url}/channel/most-active`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetMostActiveChannelsMonth() {
        const uri = `${this.base_url}/channel/most-active-month`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetMostActiveChannelsCodePostMonth() {
        const uri = `${this.base_url}/channel/most-active-codepost-month`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetMostActiveChannelsCodePost() {
        const uri = `${this.base_url}/channel/most-active-codepost`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async ListFollowing(id:string | string[]) {
        const uri = `${this.base_url}/following/list/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CountLikes(id:string | string[]) {
        const uri = `${this.base_url}/codebite/likes/count/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CountCodePostLikes(id:string | string[]) {
        const uri = `${this.base_url}/codepost/likes/count/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetSavedCodeBites(token:string) {
        const uri = `${this.base_url}/codebite/saved`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetSavedCodePosts(token:string) {
        const uri = `${this.base_url}/codepost/saved`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async ReadCodePost(id:string | string[]) {
        const uri = `${this.base_url}/codepost/read/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }


    public async MarkCodeBiteAsSaved(token:string, id:string | string[]) {
        const uri = `${this.base_url}/codebite/store/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async MarkCodePostAsSaved(token:string, id:string | string[]) {
        const uri = `${this.base_url}/codepost/store/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UndoCodeBiteAsSaved(token:string, id:string | string[]) {
        const uri = `${this.base_url}/codebite/delete-store/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async UndoCodePostAsSaved(token:string, id:string | string[]) {
        const uri = `${this.base_url}/codepost/delete-store/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }


    public async CheckIfCodeByteIsStored(token:string, id:string | string[]) {
        const uri = `${this.base_url}/codebite/is-saved/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async CheckIfCodePostIsStored(token:string,id:string | string[]) {
        const uri = `${this.base_url}/codepost/is-saved/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    
}