export default class ShopService{

    private base_url = 'https://gogodev.net/gogolearn/api/lms_shop'

    public async ListAllFeaturedItems() {
        const uri = `${this.base_url}/featured-items`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
          }
        })
        const response = await rawResponse.json()
        return response
    }
    public async ListAllCategories() {
        const uri = `${this.base_url}/categories`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
          }
        })
        const response = await rawResponse.json()
        return response
    }
    public async ListAllItemsFromCategory(id:string | string[]) {
        const uri = `${this.base_url}/categories/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
          }

        })
        const response = await rawResponse.json()
        return response
    }
    
    public async GetDetailItem(id:string | string[]){
        const uri = `${this.base_url}/detail-item/${id}`
        const rawResponse = await fetch(uri,{
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        })
        const response = await rawResponse.json()
        return response
    }
}