import { defineStore } from 'pinia'
import { ref } from 'vue'
import {Toast} from '@/interfaces/ToastInterface'

export const useToast = defineStore('useToast', {
  state: () => {
    return {
      message: ref(''),
      type: ref(Toast.INFO),
      show: ref(false)
    }
  },
  actions: {
    showToast(message:string, type:Toast) {
        this.message = message
        this.type = type
        this.show = true
    },
    hideToast() {
        this.message = ''
        this.type = Toast.INFO
        this.show = false
    }
  }
})