<template>
    <div v-if="store.token" class="button-follow">
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="2"/>
        </div>
        <div v-else>
        <div class="button-follow-inner" v-if="follow" @click="handleUnfollowCourse">
            <v-icon name="bi-heart-fill" fill="#bece49" hover="true" animation="wrench"  class="heart"  scale="1"/> <span>Siguiendo</span>
        </div>
        <div class="button-follow-inner" v-else @click="handleFollowCourse">
            <v-icon name="bi-heart-fill" fill="#ccc" hover="true" animation="wrench"  class="heart"  scale="1"/> <span>Seguir</span>
        </div>
    </div>
    </div>
</template>
 
<script lang="ts" setup>
import {defineProps, onMounted, ref} from 'vue'
import { useAuth } from '@/store/useAuth'
import FollowCourseService from '@/services/FollowCourseService'
import {useToast} from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';

const props = defineProps(['courseId'])
const store = useAuth()
const toast = useToast()
const follow = ref(false)
const loading = ref(true)
const followCourseService = new FollowCourseService()

onMounted(async () => {
    if(!store.token) {
        return;
    }
    follow.value = await followCourseService.checkFollowCourseStatus(store.token, props.courseId)
    loading.value = false
})

const handleFollowCourse = async () => {
    if(!store.token) {
        return;
    }
    loading.value = true
    const response = await followCourseService.doFollowToCourse(store.token, props.courseId)
    toast.showToast("Has comenzado a seguir el curso.", Toast.SUCCESS)
    setTimeout(function() {toast.hideToast()}, 4000);
    follow.value = true
    loading.value = false
}

const handleUnfollowCourse = async () => {
    if(!store.token) {
        return;
    }
    loading.value = true
    const response = await followCourseService.doUnfollowToCourse(store.token, props.courseId)
    toast.showToast("Has dejado de seguir el curso.", Toast.WARNING)
    setTimeout(function() {toast.hideToast()}, 4000);
    follow.value = false
    loading.value = false
}
</script>

<style lang="scss" scoped>
.container {
    overflow-y: hidden;
}

.heart {
    cursor: pointer;
}

.container-heart {
    overflow-y: hidden;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
}

.icon {
    overflow-y: hidden;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; 
}

span {
    font-size: 14px;
}
.button-follow-inner {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.button-follow-inner:hover {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: white;
    span {
        color: white;
    }
}
</style>