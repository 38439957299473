<template>
    <DefaultLayout>
        <ProdevBanner></ProdevBanner>
        <div style="margin-top:70vh"></div>
        <WelcomeMessage v-if="store.token" />
        
        <InformationTop v-if="store.token"></InformationTop>
        <BannerTop/>
        <MyRoutes v-if="store.token" />   
        <MyCourses v-if="store.token" />
        <LaterLessons v-if="store.token" />
        <FavLessons v-if="store.token" />
        <div class="spacer" v-if="!store.token" />
        <div class="spacer" v-if="!store.token" />
        <div class="spacer" v-if="!store.token" />
        <div class="spacer" v-if="!store.token" />
        <div class="spacer" v-if="!store.token" />
        <StartHere />
    </DefaultLayout>
</template>

<script lang="ts" setup>
import InformationTop from '@/components/layouts/InformationTop.vue'
import BannerTop from '@/components/layouts/BannerTop.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import MyRoutes from '@/components/sections/MyRoutes.vue'
import MyCourses from '@/components/sections/MyCourses.vue'
import FavLessons from '@/components/sections/FavLessons.vue'
import LaterLessons from '@/components/sections/LaterLessons.vue'
import StartHere from '@/components/sections/StartHere.vue'
import WelcomeMessage from '@/components/sections/WelcomeMessage.vue'
import { useAuth } from '@/store/useAuth'
import ProdevBanner from '@/components/layouts/ProdevBanner.vue';
//import AdvertisementMain from '@/components/advertisement/AdevertisementMain.vue'



const store = useAuth()
</script>
