<template>
    <DefaultLayout>
        <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
        <div v-else>
            <h1>Salón de la Fama</h1>
            <div class="spacer"></div>
            <h3>Top 10 Usuarios Con Mayor Reputación</h3>
            <div class="spacer"></div>
            <div class="table-container">
                <table class="modern-table">
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Nombre (Reputación)</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in mostReputated" :key="item.id">
                            <td>
                                <div style="width: 60px; height: 60px;">
                                    <SocialProfileImage :user="item" />
                                </div>
                            </td>
                            <td> {{ index + 1 }}. {{ item.name }} ({{ item.reputation.toLocaleString() }})</td>
                            <td>
                                <div style="display: flex; align-items: center;">
                                    <v-icon name="md-fileopen-round" class="ver" fill="#57afc4" scale="2"
                                        @click="readMore(item.id)"></v-icon>
                                    <FollowChannelButton :user_id="item.id" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ChannelService from '@/services/ChannelService'
import { onMounted, ref } from 'vue';
import { useToast } from '@/store/useToast'
import { Toast } from '@/interfaces/ToastInterface';
import router from '@/router';
import FollowChannelButton from '@/components/widgets/FollowChannelButton.vue';
import SocialProfileImage from '@/components/widgets/SocialProfileImage.vue';

const service = new ChannelService()
const filtered = ref([])
const loading = ref(true)
const search = ref('')
const toast = useToast()
const mostReputated = ref()


onMounted(async () => {
    loading.value = true
    mostReputated.value = await service.GetMostReputatedChannels()
    loading.value = false
})

const readMore = (id: number) => {
    router.push({ name: 'social-profile', params: { id: id } })
}


</script>

<style lang="scss" scoped>
.search-btn {
    height: 100%;
    width: 100px;
    outline: none;
    border: none;
    background-color: $secondary-color;
    color: white;
    cursor: pointer;
}

.search-btn:hover {
    background-color: $third-color;
}

.modal {
    position: fixed;
    width: 100vw;
    height: 100svh;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-inner {
    padding: 20px;
    z-index: 99999999999999999999999;
    border-radius: 15px;
    background-color: white;
    max-width: 500px;

    h3 {
        margin-bottom: 15px;
    }

    p {
        font-size: 12px;
    }
}

.ver {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.employ-card {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: $secondary-color;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.btn {
    text-decoration: none;
    margin-left: 20px;
}

.input-job {
    width: 100vw;
}

/* Estilos para la tabla */
.modern-table {
    width: 100%;
    border-collapse: collapse;

}

.modern-table th,
.modern-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    color: $secondary-color !important;
}

.modern-table th:first-child,
.modern-table td:first-child {
    padding-left: 20px;
}

.table-container {
    overflow-x: auto;
}

.modern-table th:last-child,
.modern-table td:last-child {
    padding-right: 20px;
}

/* Estilos para el hover */
.modern-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.modern-table tbody tr:hover td {
    color: #333;
}

.modern-table tbody tr:hover a {
    color: $third-color;
    transition: color 0.3s ease;
}
</style>