<template>
    <div class="loading-container icons" v-if="loading">
        <v-icon name="ri-loader-2-fill" animation="spin" class="icon" scale="1" />
    </div>
    <div class="icons" v-else>
        <v-icon name="bi-check-circle-fill" fill="#bece49" v-if="isCompleted"></v-icon>
        <v-icon name="bi-circle" v-else></v-icon>
    </div>
    
</template>

<script lang="ts" setup>
import { ref, onMounted, defineProps, defineExpose } from 'vue'
import CompletedService from '@/services/CompletedService'
import { useAuth } from '@/store/useAuth'

const loading = ref(true)
const completedService = new CompletedService()
const store = useAuth()
const props = defineProps(['lessonId'])
const isCompleted = ref()

onMounted(async () => {
    if(!store.token) {
        loading.value = false
        return
    }

    loading.value = true
    isCompleted.value = await completedService.checkCompletedStatus(store.token, props.lessonId)
    loading.value = false
})

const updateStatus = async () => {
    if(!store.token) {
        loading.value = false
        return
    }

    loading.value = true
    isCompleted.value = await completedService.checkCompletedStatus(store.token, props.lessonId)
    loading.value = false
}

defineExpose({updateStatus})
</script>

<style lang="scss" scoped>
.icons {
    display: inline;
}
</style>