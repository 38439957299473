export default class FollowRoadmapService {
    private base_url = 'https://gogodev.net/gogolearn/api/follow_roadmap'

    public async getAllRoadmapsFromUser(token:string) {
        const uri = this.base_url
        const rawResponse = await fetch(uri,  {
            method: 'GET',
            headers: {
            /*  'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': "*",
              'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK',
              'Access-Control-Allow-Credentials': 'true',*/
              'Authorization': `Bearer ${token}`
            }
          })
        const response = await rawResponse.json()
        return response
    }

    public async checkFollowRoadmapStatus(token:string, roadmap_id:number) {
        const uri = `${this.base_url}/${roadmap_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async doFollowToRoadmap(token:string, roadmap_id:number) {
        const uri = `${this.base_url}/do-follow/${roadmap_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }

    public async doUnfollowToRoadmap(token:string, roadmap_id:number) {
        const uri = `${this.base_url}/do-unfollow/${roadmap_id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await rawResponse.json()
        return response
    }
}