<template>
    <h1 v-if="user">¡Bienvenid@ al campus, {{user.name}}!</h1>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useAuth } from '@/store/useAuth'
const store = useAuth()
const user = ref()
onMounted(async () => {
    const rawResponse = await fetch('https://gogodev.net/gogolearn/api/my-user', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.token}`
        }
    })
    user.value = await rawResponse.json()
})
</script>

<style lang="scss" scoped>
h1 {
    font-size: 20px;
    background: -webkit-linear-gradient(45deg, $secondary-color, $third-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}
</style>