<template>
    <div class="animate img-container">
        <div class="img-container">
            <div class="animate img-profile">
                <img v-if="props.user.profile_picture_uri" :src="props.user.profile_picture_uri" />
                <div v-else class="profile-text"  style="font-size: 40px;">{{ props.user.name.substring(0,1) }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue'
const props = defineProps(['user'])
</script>

<style lang="scss" scoped>
.photo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.img-profile {
    background-color: white;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid $secondary-color;
    overflow: hidden;
}
.profile-text {
    font-size: 1em;
    color: $secondary-color;
    text-transform: uppercase;
}
</style>