<template>
    <div class="animate register">
        <h4>Registrarme</h4>
        <form>
            <div class="form-group">
                <label class="label-space">Nombre completo</label>
                <input type="text" placeholder="Nombre y Apellidos" required v-model="name" />
                <small>Este será el nombre que aparecerá en los diplomas de las certificaciones que obtengas. Asegúrate
                    de introducir el nombre completo deseado.</small>
            </div>
            <div class="spacer" />
            <div class="form-group">
                <label class="label-space">Email</label>
                <input type="text" placeholder="demo@ademass.com" required v-model="email" />
                <small>El email será necesario para cualquier problema que puedas tener. Asegúrate de introducirlo correctamente.</small>
            </div>
            <div class="spacer" />
            <div class="form-group">
                <label class="label-space">Contraseña</label>
                <input type="password" required v-model="password" />
            </div>
            <div class="form-group">
                <label class="label-space">Repetir contraseña</label>
                <input type="password" required v-model="repeatPassword" />
                <small>Introduce una contraseña segura. Son válidos todos los caracteres alfanuméricos. Evita usar
                    tildes o eñes.</small>
            </div>
            <div class="spacer" />
            <div class="form-group check">
                <input type="checkbox" required checked /> <small>He leído y acepto el <a href="https://ademass.com/legal.pdf"
                        target='_blank'>aviso legal</a>, <a href="https://ademass.com/privacidad.pdf" target='_blank'>
                        política de privacidad</a> y <a href="https://ademass.com/datos.pdf" target='_blank'>términos de uso.</a></small>
            </div>
            <input type="submit" value="Comenzar mi formación" class="go-btn" @click.prevent="handleRegister" />
            <p class="feedback">{{ feedback }}</p>
            <div class="long-spacer" />
        </form>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useAuth } from '@/store/useAuth'
import router from '@/router'
 
const store = useAuth()

const feedback = ref('')
const name = ref('')
const email = ref('')
const password = ref('')
const repeatPassword = ref('')

function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-11153245589/-ykTCNCj-psYEJWLpMYp',
        'event_callback': callback
    });
    return false;
  }

const handleRegister = async () => {
    feedback.value = "Enviando datos..."

    if(repeatPassword.value != password.value) {
        feedback.value = "Las contraseñas no coinciden."
        return
    }
    try {
        const success = await store.register(name.value, email.value, password.value)
        if(!success) {
            feedback.value = "Error. " + store.message
            return
        }

        gtag_report_conversion()
        feedback.value = "Éxito. Cargando tu panel de estudios..."
        router.push({name: 'home'})

    } catch(e) {
        feedback.value = "El servidor se ha ido de vacaciones. Por favor, inténtalo más tarde."
    }
}

</script>

<style lang="scss" scoped>
h4 {
    margin-bottom: 10px;
}

small {
    font-size: 12px;
}

.go-btn {
    color:white;
    font-family: $title-font;
}


.register {
    overflow-y: hidden;
}

.check {
    display: flex;
    align-items: center;
}

input[type=checkbox] {
    width: auto;
    margin-right: 5px;
    background: $primary-color;
    accent-color: $secondary-color;
    outline: none;
    border: 0;
    box-shadow: 0;
}
</style>