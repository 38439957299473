export default class CourseService {
    private base_url = 'https://gogodev.net/gogolearn/api/skill'

    public async GetAllSkills() {
        const uri = this.base_url
        const rawResponse = await fetch(uri, {
            method: 'GET'
        })
        const response = await (rawResponse).json()
        return response
    }

    public async GetAllSkillsFromUser(token: string) {
        const uri = `${this.base_url}/user`
        const rawResponse = await fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async AddSkillToUser(token: string, id: number) {
        const uri = `${this.base_url}/add/${id}`
        const rawResponse = await fetch(uri, {
             method: 'GET',
            headers: {
            'Authorization': `Bearer ${token}`
            }
        })
        const response = await (rawResponse).json()
        return response
    }

    public async DeleteSkillToUser(token: string, id: number) {
        const uri = `${this.base_url}/delete/${id}`
        const rawResponse = await fetch(uri, {
            method: 'GET',
           headers: {
           'Authorization': `Bearer ${token}`
           }
       })
        const response = await (rawResponse).json()
        return response
    }

}